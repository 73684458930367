import React, { useEffect, useState } from "react";
import Layout from "../../../../components/biblio/Layout";
import Nav from "../../../../components/biblio/missions/Nav";
import SubNavCoso from "../../../../components/biblio/missions/SubNavCoso";
import { Pencil, Save, Trash } from "../../../../components/Icons";
import Modal from "../../../../components/Modal";
import { USER_ROLES } from "../../../../helpers/Constants";
import { notify, setTitle } from "../../../../helpers/Helper";
import CosoStore from "../../../../store/CosoStore";
import UserStore from "../../../../store/UserStore";

const Form = ({ controle, principes, reset, close }) => {

   const [current, setCurrent] = useState(controle);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      let controle = current;
      if (controle.principe['@id'])
         controle = { ...controle, principe: current.principe['@id'] }

      CosoStore.saveControle(controle).then(_ => {
         notify("Succès", "Le contrôle internet audit coso a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()
      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Le principe COSO <small className="fw-bold">[obligatoire]</small></span>
               <select className="form-control" name="principe" onChange={handleChange} value={current.principe['@id']} required>
                  <option value="">Sélectionner un principe</option>
                  {principes.map(principe => <option key={principe.id} value={principe['@id']}>{principe.libelle}</option>)}
               </select>
            </div>
         </div>
         <div className="mb-3">
            <div className="form-group">
               <span>Libellé <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required />
            </div>
         </div>
         <div className="mb-3">
            <div className="form-group">
               <span>Description </span>
               <textarea name="description" className="form-control" value={current.description} onChange={handleChange}></textarea>
            </div>
         </div>
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Controles() {

   const [controles, setControles] = useState([]);
   const [principes, setPrincipes] = useState([]);
   const [controle, setControle] = useState({ libelle: "", description: "", principe: {} });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle("Contrôle audit coso");
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const { data: _controles } = await CosoStore.getControles();
      const { data: _principes } = await CosoStore.getPrincipes();
      setControles(_controles);
      setPrincipes(_principes);
      setLoaging(false)
   }

   const removeControle = (id) => {
      if (window.confirm("Supprimer ce controle?"))
         CosoStore.removeControle(id).then(_ => init())
   }

   const updateControle = (controle) => {
      setControle(controle);
      setShowModal(true);
   }

   const newControle = (e) => {
      e.preventDefault();
      setControle({ libelle: "", description: "", principe: {} });
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setControle({ libelle: "", description: "", principe: {} })
      setShowModal(false);
   }
   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavCoso/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Contrôle audit coso</span>
            </div>
            <h4 className="page-title">Le contrôle audit coso</h4>
            <button onClick={newControle} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Ajouter un contrôle</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Libelle</th>
                           <th>Description</th>
                           <th>Le principe COSO</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {controles.map((controle, index) =>
                           <tr key={controle.id}>
                              <td>{index + 1}</td>
                              <td>{controle.libelle}</td>
                              <td>{controle.description}</td>
                              <td>{controle.principe.libelle}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateControle(controle)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeControle(controle.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer un contrôle" body={<Form controle={controle} principes={principes} reset={{ libelle: "", description: "", principe: {} }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Detail from "../../../components/biblio/missions/MissionDetail";
import Form from "../../../components/biblio/missions/MissionForm";
import Nav from "../../../components/biblio/missions/Nav";
import { Eye, Pencil, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { setTitle } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import Pagination from "../../../components/Pagination";
import UserStore from "../../../store/UserStore";
import { USER_ROLES } from "../../../helpers/Constants";
import ExportToExcel from "../../../helpers/ExportToExcel";
import exportPDF from "../../../helpers/exportPDF";

const $mission = { refPav: "", intitule: "", objectif: "", resultatAttendu: "", annee: {libelle:''}, timbre: "", periodeExecution: "", nbJourCalendaire: "", nbEquipe: "", nbRapportAttendu: "",  nature: {}, structure:{} };

export default function Mission() {

   const [page, setPage] = useState(1);
   const [pagination, setPagination] = useState({});
   const [missions, setMissions] = useState([]);
   const [sources, setSources] = useState([]);
   const [structures, setStructures] = useState([]);
   const [natures, setNatures] = useState([]);
   const [annees, setAnnees] = useState([]);
   const [mission, setMission] = useState($mission);
   const [detailMission, setDetailMission] = useState($mission);
   const [showModal, setShowModal] = useState(false);
   const [showDetailModal, setShowDetailModal] = useState(false);
   const [loading, setLoaging] = useState(false);
   const [filtredMissions, setFiltredMissions] = useState([]);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle('Missions')
      init();
   }, [page])

   const init = async () => {
      setLoaging(true)
      const { data: _missions, pagination } = await MissionStore.getMissions(page);
      const { data: _natures } = await MissionStore.getNatures();
      const { data: _sources } = await MissionStore.getSources();
      const { data: _annees } = await MissionStore.getAnnees();
      const { data: _structures } = await MissionStore.getStructures();
      setMissions(_missions);
      setFiltredMissions(_missions)
      setNatures(_natures);
      setStructures(_structures)
      setSources(_sources);
      setAnnees(_annees);
      setPagination(pagination)
      setLoaging(false)
   }

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const removeMission = (id) => {
      if (window.confirm("Supprimer cette mission?"))
         MissionStore.removeMission(id).then(_ => init())
   }

   const updateMission = (mission) => {
      setMission(mission);
      setShowModal(true);
   }

   const showMission = (mission) => {
      setDetailMission(mission);
      setShowDetailModal(true);
   }

   const newMission = (e) => {
      e.preventDefault();
      setMission($mission);
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setMission($mission)
      setShowModal(false);
   }

   const handleSearch = async (event) => {
      setLoaging(true)
      event.preventDefault();
      const formData = new FormData(event.target);
      const { data: _missions, pagination } = await MissionStore.getMissions(page, formData.get('intitule'), formData.get('refPav'), formData.get('annee'), formData.get('nature'));
      setFiltredMissions(_missions)
      setPagination(pagination)
      setLoaging(false)
   }

   const exportExcell = () => {
      ExportToExcel(filtredMissions, "missions");
   }

   const toPDF = (mission) => {
      const headers = ['N° d’ordre ou référence','Nature de la mission','Intitulé Mission', 'Objectif de la mission',  "Période d'exécution", "Année de rattachement", "Résultats attendus", "Nombre d'équipe"]
      const data = [mission.refPav,mission.nature.libelle, mission.intitule, mission.objectif,mission.periodeExecution, mission.anneRattachement, mission.resultatAttendu, mission.nbEquipe]
      exportPDF(mission.intitule, headers, data, 'landscape')
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <div className="p-1 breadcrumbs xs-font">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Missions</span>
            </div>
            <h4 className="page-title">Programmations missions</h4>
            <button onClick={newMission} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer une mission</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <div className="p-3" style={{ float: 'right', width: "100%" }}>
                     <form className="row row-cols-lg-auto g-3 align-items-center" style={{ float: 'right' }} onSubmit={handleSearch}>
                     <div className="col-12">
                           <label className="visually-hidden">Nature</label>
                           <select className="form-select" name="nature">
                              <option value="">Sélectionner une nature</option>
                              {natures.map(nature => <option key={nature.id} value={nature.libUnique}>{nature.libelle}</option>)}
                           </select>
                        </div>
                        
                        <div className="col-12">
                           <label className="visually-hidden">Ref PAV</label>
                           <div className="input-group">
                              <div className="input-group-text">Ref PAV</div>
                              <input type="text" className="form-control" name="refPav" placeholder="ref pav" />
                           </div>
                        </div>

                        <div className="col-12">
                           <label className="visually-hidden">Username</label>
                           <div className="input-group">
                              <div className="input-group-text">Intitulé</div>
                              <input type="text" className="form-control" name="intitule" placeholder="Intitulé" />
                           </div>
                        </div>

                        <div className="col-12">
                           <label className="visually-hidden">Année</label>
                           <div className="input-group">
                              <div className="input-group-text">Année</div>
                              <input type="number" className="form-control" name="annee" placeholder="année" />
                           </div>
                        </div>

                        <div className="col-12">
                           <button type="submit" className="btn btn-primary">Rechercher</button>
                        </div>
                        <div className="col-12">
                           <button type="button" onClick={exportExcell} className="btn btn-info"><i className="fas fa-file-excel"></i> Excel</button>
                        </div>
                     </form>
                  </div>
                  <table className={`table table-striped table-bordered ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>Ref PAV</th>
                           <th>Nature de la mission</th>
                           <th>Ministère ou structure</th>
                           <th>Intitulé de la misssion</th>
                           <th style={{width:'20%'}}>Objectifs de la mission</th>
                           {/* <th>Source</th> */}
                           <th>Période d'exécution</th>
                           <th className="text-center">Année de rattachement</th>
                           <th className="text-center"></th>
                           <th className="text-center"></th>
                           <th className="text-center"></th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {filtredMissions.map((mission, index) =>
                           <tr key={mission.id}>
                              <td>{mission.refPav}</td>
                              <td>{mission.nature.libelle}</td>
                              <td>{mission.structure && mission.structure.intitule}</td>
                              <td>{mission.intitule}</td>
                              <td style={{width:'20%'}}>{mission.objectif}</td>
                              {/* <td>{mission.source.libelle}</td> */}
                              <td>{mission.periodeExecution}</td>
                              <td className="text-center">{mission.annee !== undefined ? mission.annee.libelle : ''}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-info" style={{ marginRight: "5px", color: "#FFFFFF" }} onClick={() => toPDF(mission)} title="Export pdf"><i className="fas fa-file-pdf"></i></button>
                                       </td>
                              <td>
                              <button type="button" className="btn btn-sm btn-info" style={{ marginRight: "5px", color: "#FFFFFF" }} onClick={() => showMission(mission)} title="Détails"><Eye /></button>

                              </td>
                              <td>
                              <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "5px" }} onClick={() => updateMission(mission)} title="Modifier"><Pencil /></button>

                              </td>
                              <td>
                              <button type="button" className="btn btn-sm btn-danger" onClick={() => removeMission(mission.id)} title="Supprimer"><Trash /></button>

                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
                  <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
               </div>

            </div>
            <Modal show={showModal} title="Enregistrer une mission" body={<Form mission={mission} annees={annees} sources={sources} natures={natures} structures={structures} reset={$mission} close={onModalClose} />} onClose={onModalClose} />
            <Modal show={showDetailModal} title={mission.intitule} body={<Detail mission={detailMission} />} onClose={() => setShowDetailModal(false)} />

         </>
      );
   }

   return <Layout body={<Body />} />
}
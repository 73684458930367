import React, { useState } from "react";
import Layout from "../../components/biblio/Layout";
import { PUBLIC_URL, USER_ROLES } from "../../helpers/Constants";
import ShareBook from "../../components/biblio/share/ShareBook";
import ShareDocument from "../../components/biblio/share/ShareDocument";
import Modal from "../../components/Modal";
import { Link } from "react-router-dom";
import { setTitle } from "../../helpers/Helper";
import UserStore from "../../store/UserStore";

const Share = () => {

    const [showRapportForm, setShowRapportForm] = useState(false);
    const [showDocumentForm, setShowDocumentForm] = useState(false);
    const [showBookForm, setShowBookForm] = useState(false);
    setTitle('Partager un document');

    const Body = () => {
        return (
            <>
                <div className="content-body">
                    <div className="container-fluid mt-3">
                        <div className="p-1 breadcrumbs xs-font">
                            <Link to="/" className="textPrimary fw-bold">Accueil</Link> <span>/ Partager</span>
                        </div>
                        <h4 className="page-title">Partager</h4>
                        <div className="row">
                            <div className={!UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ? 'col-md-6 pt-5 partage-img' : 'col-md-4 pt-5 partage-img'}>
                                <a onClick={(e) => { e.preventDefault(); setShowRapportForm(true) }} href="/" data-bs-toggle="modal" data-bs-target="#partageModal">
                                    <img src={PUBLIC_URL + '/img/document.png'} alt="" className="img-fluid d-block mx-auto" />
                                    <button className="btn btn-sm btn-outline-warning text-dark mt-4">Partager un rapport protégé</button>
                                </a>
                            </div>
                            <div className="col-md-4 pt-5 partage-img">
                                <a onClick={(e) => { e.preventDefault(); setShowBookForm(true) }} href="/" data-bs-toggle="modal" data-bs-target="#partageModal2">
                                    <img src={PUBLIC_URL + "/img/book.png"} alt="" className="img-fluid d-block mx-auto" />
                                    <button className="btn btn-sm btn-outline-warning text-dark mt-4">Partager un livre</button>
                                </a>
                            </div>
                            {UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) &&
                                <div className="col-md-4 pt-5 partage-img">
                                    <a onClick={(e) => { e.preventDefault(); setShowDocumentForm(true) }} href="/" data-bs-toggle="modal" data-bs-target="#partageModal2">
                                        <img src={PUBLIC_URL + "/img/files.png"} alt="" className="img-fluid d-block mx-auto" />
                                        <button className="btn btn-sm btn-outline-warning text-dark mt-4">Partager un autre document</button>
                                    </a>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <Modal show={showBookForm} onClose={() => setShowBookForm(false)} body={<ShareBook onClose={() => setShowBookForm(false)} />} title="Partager un livre" />
                <Modal show={showRapportForm} onClose={() => setShowRapportForm(false)} body={<ShareDocument onClose={() => setShowRapportForm(false)} title="Partager un rapport confidentiel" type="rapport" />} title="Partager un rapport confidentiel" />
                {UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && <Modal show={showDocumentForm} onClose={() => setShowDocumentForm(false)} body={<ShareDocument onClose={() => setShowDocumentForm(false)} />} title="Partager un document" />}
            </>
        );
    }
    return <Layout body={<Body />} />
}

export default Share;
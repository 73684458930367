import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ActivitiesFilter from '../../../components/front/activities/ActivitiesFilter';
import Layout from "../../../components/front/Layout";
import { Share, Facebook, Twitter, Calendar } from '../../../components/Icons';
import Pagination from '../../../components/Pagination';
import { PUBLIC_URL, SITE_URL } from '../../../helpers/Constants';
import { dateFormat, setTitle } from '../../../helpers/Helper';
import PublicationStore from '../../../store/PublicationStore';
import ShareLink from 'react-facebook-share-link'

export default function Activites() {

   const [articles, setArticles] = useState([]);
   const [communiques, setCommuniques] = useState([]);
   const [reformes, setReformes] = useState([]);
   const [pageArticle, setPageArticle] = useState(1)
   const [pageCommunique, setPageCommunique] = useState(1)
   const [pageReforme, setPageReforme] = useState(1)
   const [paginationArticles, setPaginationArticles] = useState({});
   const [paginationCommuniques, setPaginationCommuniques] = useState({});
   const [paginationReformes, setPaginationReformes] = useState({});

   setTitle("Les activités.");

   useEffect(() => {
      getArticles();
   }, [pageArticle])

   useEffect(() => {
      getReformes();
   }, [pageReforme])

   useEffect(() => {
      getCommuniques();
   }, [pageCommunique])

   const setCurrentArticlePage = (page) => {
      setPageArticle(page);
   }

   const setCurrentReformePage = (page) => {
      setPageReforme(page);
   }

   const setCurrentCommuniquePage = (page) => {
      setPageCommunique(page);
   }


   const getArticles = async () => {
      const { data, pagination } = await PublicationStore.getArticles('actus', pageArticle, 3);
      setArticles(data);
      setPaginationArticles(pagination)
   }

   const getCommuniques = async () => {
      const { data, pagination } = await PublicationStore.getArticles("communiques", pageCommunique);
      setCommuniques(data);
      setPaginationCommuniques(pagination);
   }

   const getReformes = async () => {
      const { data, pagination } = await PublicationStore.getArticles('reformes', pageReforme, 3);
      setReformes(data);
      setPaginationReformes(pagination)
   }

   const Body = () => {
      return (
         <>
            <div className="page-info">
               <h2 className="text-center text-white"> Activités</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase">Activités</span>
                  </div>
               </div>
            </div>
            <div className="container-fluid mb-4 page-content">

               <div className="container mt-3">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                     <li className="nav-item" role="presentation">
                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#actualites" type="button" role="tab" aria-controls="home" aria-selected="true">ACTUALITÉS
                        </button>
                     </li>
                     <li className="nav-item" role="presentation">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#communiques" type="button" role="tab" aria-controls="profile" aria-selected="false">COMMUNIQUÉS
                        </button>
                     </li>
                     <li className="nav-item" role="presentation">
                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#reformes" type="button" role="tab" aria-controls="contact" aria-selected="false">REFORMES
                        </button>
                     </li>

                     <li className="ms-auto">
                        <ActivitiesFilter />
                     </li>

                  </ul>
                  <div className="tab-content" id="myTabContent">
                     <div className="tab-pane fade show active" id="actualites" role="tabpanel">
                        <div className="article-slide owl-carousel d-flex">
                           {articles.map(article =>
                              <div className="item">
                                 <div className="articlebox">
                                    <Link to={`/activites/${article.slug}`} style={{float:'left',height:'300px', overflow:'hidden'}}>
                                       {article.media !== undefined && <img src={SITE_URL + '/' + article.media.contentUrl} alt={article.titre} className="img-fluid" />}
                                    </Link>
                                    <div className="article__item_content">
                                       <div className="d-flex justify-content-between">
                                          <div className="p-1">
                                             <div className="shareBtn">
                                                <div className="shareBtn-content">
                                                   <ShareLink link={`${PUBLIC_URL}/activites/${article.slug}`}>
                                                      {link => (
                                                         <a href={link} className="z-depth-2 "><Facebook /> </a>
                                                      )}
                                                   </ShareLink>
                                                   <a href="#/" className="z-depth-2 "><Twitter /></a>
                                                </div>
                                                <span className="z-depth-2"><Share /></span>
                                             </div>
                                          </div>
                                          <div className="p-1">
                                             <time className="article__item_published">{dateFormat(article.publishedAt)}</time>
                                          </div>
                                       </div>
                                       <Link to={`/activites/${article.slug}`}>
                                          <h6 className="text-uppercase mt-4">{article.titre}</h6>
                                       </Link>
                                       <p className="article__item_introtext" dangerouslySetInnerHTML={{ __html: article.contenuPublication.substring(0, 100) }} />
                                    </div>
                                 </div>
                              </div>
                           )}

                        </div>
                        {!articles.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Pas contenu lié à votre recherche.</h2>}

                        <Pagination pagination={paginationArticles} setCurrentPage={(setCurrentArticlePage)} />
                     </div>
                     <div className="tab-pane fade pt-2" id="communiques" role="tabpanel">
                        <div className="list-group">
                           {communiques.map(article =>
                              <Link key={article.id} to={`/activites/${article.slug}`} className="list-group-item list-group-item-action" aria-current="true">
                                 <div className="d-flex w-100 justify-content-between">
                                    <h5 className="commiqueTitle">{article.titre}</h5>
                                    <p className="comminqueDate"><Calendar /> <br />{dateFormat(article.publishedAt, 'DD MMMM')} <br /> <span className="annee">{dateFormat(article.publishedAt, 'YYYY')}</span></p>
                                 </div>
                              </Link>
                           )}
                           {!communiques.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Pas contenu lié à votre recherche.</h2>}

                        </div>
                        <Pagination pagination={paginationCommuniques} setCurrentPage={(setCurrentCommuniquePage)} />
                     </div>
                     <div className="tab-pane fade" id="reformes" role="tabpanel">
                        <div className="article-slide owl-carousel d-flex">
                           {reformes.map(article =>
                              <div className="item">
                                 <div className="articlebox">
                                    <Link to={`/activites/${article.slug}`}>
                                    {article.media !== undefined && <img src={SITE_URL + article.media.contentUrl} alt={article.titre} className="img-fluid logo" />}
                                    {!article.media  && <img src="img/noimage.jpeg" alt="" className="img-fluid" />}
                                    </Link>
                                    <div className="article__item_content">
                                       <div className="d-flex justify-content-between">
                                          <div className="p-1">
                                             <div className="shareBtn">
                                                <div className="shareBtn-content">
                                                   <a href="#/" className="z-depth-2 "><Facebook /> </a>
                                                   <a href="#/" className="z-depth-2 "><Twitter /></a>
                                                </div>
                                                <span className="z-depth-2"><Share /></span>
                                             </div>
                                          </div>
                                          <div className="p-1">
                                             <time className="article__item_published">{dateFormat(article.publishedAt)}</time>
                                          </div>
                                       </div>
                                       <Link to={`/activites/${article.slug}`}>
                                          <h6 className="text-uppercase mt-4">{article.titre}</h6>
                                       </Link>
                                       <p className="article__item_introtext" dangerouslySetInnerHTML={{ __html: article.contenuPublication.substring(0, 100) }} />
                                    </div>
                                 </div>
                              </div>
                           )}
                        </div>

                        {!reformes.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Pas contenu lié à votre recherche.</h2>}

                        <Pagination pagination={paginationReformes} setCurrentPage={(setCurrentReformePage)} />
                     </div>
                  </div>

               </div>
            </div>
         </>
      )
   }
   return <Layout body={<Body />} />
}
import React, { useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import Layout from "../../../components/front/Layout";
import { shareFacebook, shareTwitter } from '../../../helpers/Helper';
import PublicationStore from '../../../store/PublicationStore';

export default function Page() {

   const { slug } = useParams();
   const [page, setPage] = useState({});
   

   useEffect(() => {
      init()
   }, [slug])

   const init = async () => {
      const _page = await PublicationStore.getPage(slug);
         setPage(_page);
   }

   const Body = () => {
      return (
         <>
            <div className="page-info">
               <h2 className="text-center text-white"> {page.titre}</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase">Page</span>
                  </div>
               </div>
            </div>
            <div className="container-fluid mb-4 page-content">
               
               <div className="container mt-3 mb-4">
                  <div className="row">

                     <div className="col-md-12 shadow bg-light pt-3">
                        {page.media && <img src={page.media.contentUrl} alt={page.titre} className="img-fluid" />}
                        <div className="d-flex">
                           <div dangerouslySetInnerHTML={{ __html: page.contenu }} />
                           <div className="p-2">
                              <ul className="articleLikeShare">
                                 {/* <li><a href="#/" title="Ajoute aux Favoris"><i><Star /></i></a></li>*/}
                                 <li><a href="#/" onClick={() => shareFacebook(window.location.href)} title="Partage sur Facebook"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="#/" onClick={() => shareTwitter(window.location.href, page.titre)} title="Partage sur Twitter"><i className="fab fa-twitter"></i></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }
   return <Layout body={<Body />} />
}
import React, { Fragment, useEffect, useRef, useState } from "react";
import Filter from "../../../components/biblio/resources/Filter";
import Layout from "../../../components/biblio/Layout";
import { Link, useParams } from "react-router-dom";
import ResourceStore from "../../../store/ResourceStore";
import { DEFAULT_URL, SITE_URL } from "../../../helpers/Constants";
import UserStore from "../../../store/UserStore";
import { confirmation, getAuthToken, notify, setTitle } from "../../../helpers/Helper";
import Modal from "../../../components/Modal";
import ShareBook from "../../../components/biblio/share/ShareBook";
import NotificationStore from "../../../store/NotificationStore";
import Video from "../../../components/Video";
import Audio from "../../../components/Audio";
import PDF from "../../../components/PDF";
import Loading from "../../../helpers/Loading";

const token = getAuthToken();

export default function ResourceLivre() {
    const { id } = useParams()
    const [book, setBook] = useState({ domaine: {} })
    const [publisher, setPublisher] = useState({});
    const [notifications, setNotifications] = useState([])

    const [showBookForm, setShowBookForm] = useState(false);
    const [loading, setLoading] = useState(true)

    const sugRef = useRef();

    useEffect(() => {
        init()
    }, [id]);

    const init = async () => {
        const _book = await ResourceStore.getBook(id);
     console.log("_book",_book);
        setBook(_book)
        setTitle(_book.titre);
       // getPublisher(_book);
        setLoading(false)

        const { data } = await NotificationStore.getNotificationsByResource(id, "livre");
        setNotifications(data)
    }
    const removeBook = (book) => {
        confirmation("Supprimer ce livre?", () => {
            ResourceStore.removeBook(book.id).then(response => {
                notify("Succès", "Livre supprimé.", "success")
                window.location.href = "/bibliotheque/mes-livres";
            });
        });
    }

    const unValidate = async (ressource) => {
        confirmation("Annuler la publication du livre?", async () => {
            await ResourceStore.validateBook(ressource.id, false, false)
            ResourceStore.suggestbook({
                livre: book['@id'],
                isForStructure: false,
                isValidated: false
            }, true, ressource.suggestionsLivres.id).then(response => {
                notify('Succès', "Le livre a été dépublié.", "success");
                init()
            })
        })
    }

    const validate = async (ressource, isForStructure = false) => {
        const msg = !isForStructure ? "Publier pour tout le monde?" : "Publier pour les membres de ma stucture?"
        confirmation(msg, async () => {
            await ResourceStore.validateBook(ressource.id, !isForStructure)
            ResourceStore.suggestbook({
                livre: book['@id'],
                isForStructure,
                isValidated: true
            }, true, ressource.suggestionsLivres.id).then(response => {
                notify('Succès', "Le livre a été publié.", "success");
                init()
            })
        })
    }

    const removeSuggest = async (ressource) => {
        confirmation("Annuller la suggestion?", async () => {
            await ResourceStore.validateBook(ressource.id, false, false)
            ResourceStore.removeSuggest(ressource.suggestionsLivres.id).then(response => {
                notify('Succès', "La suggéstion a été supprimée.", "success");
                window.location.href = "/bibliotheque/suggestions/livres"
            })
        })
    }

    const suggestBook = async (book) => {
        confirmation('Suggérer ce livre?', () => {
            ResourceStore.suggestbook({
                livre: book['@id'],
                isForStructure: false,
                isValidated: false
            }).then(response => {
                notify('Succès', "Le livre a été suggéré à votre responsable.", "success");
                //init()
                sugRef.current.style.display = "none"
            })
        });
    }

    const getPublisher = async (book) => {
        if (book.user !== undefined) {
            const _publisher = await ResourceStore.getUser(book.user);
            setPublisher(_publisher);
            setBook({ ...book, user: _publisher });
        }
    }

    const Body = () => {

        const imageUrl = book && book.urlImage !== "" ? book.urlImage : "/img/livre.png"

        return (
            <Fragment>
                {loading && <Loading />}
                <Filter />
                <div className="p-1 breadcrumbs xs-font">
                    <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Livres</span>
                </div>

                <div>      <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-sm-12 col-md-3">
                            {book.image !== undefined ?
                                <img src={DEFAULT_URL + '/' + book.image.contentUrl} className="img-fluid" alt={book.titre} /> :
                                <img src={imageUrl} className="img-fluid" alt={book.titre} />}

                            {UserStore.canRemoveBook(book) && <ul className="list-group p-2 ">
                                {notifications.map(notification =>
                                    <li className={`list-group-item notification-item ${!notification.lu ? 'bg-light' : 'bg-white'} p-4`} key={notification.id}>
                                        <Link className="d-flex">
                                            <div className="ps-2">
                                                <div>{/* <b>{notification.expediteur}</b>*/} {notification.contenu}</div>
                                            </div>
                                        </Link>
                                    </li>
                                )}
                            </ul>}
                        </div>
                        <div className="col-sm-12 col-md-8 book-detail">
                            {(UserStore.canEditBook(book) && book.isValidated) && <div style={{ float: 'right' }}>
                                <span className="badge bg-info">{(book.suggestionsLivres && book.suggestionsLivres.isForStructure) ? 'Accessible à tous les membres de ma structure' : 'Accessible à tout le monde'}</span>
                            </div>}
                            
                            <h1>{book.titre}</h1>
                            <span className="bg-info text-white p-2 mt-2">{book.nbLectures} lectures</span>
                            <div style={{ float: "right", width: '20%', textAlign: "center" }}>
                                {(!book.isValidated && book.suggestionsLivres === undefined) &&
                                    <button onClick={() => suggestBook(book)} ref={sugRef} className="btn btn-success btn-sm m-1">Suggérer le livre</button>}

                                {UserStore.canPublishBook(book) && UserStore.canSeeBook(book) && book.suggestionsLivres &&

                                    <>
                                        {!book.suggestionsLivres.isValidated && (
                                            <>
                                                <button onClick={() => validate(book, true)} className="btn btn-success btn-sm m-1">Publier pour ma structure</button>
                                                <button onClick={() => validate(book, false)} className="btn btn-warning btn-sm m-1">Publier pour tout le monde</button>
                                            </>
                                        )}

                                        {book.suggestionsLivres.isValidated && (
                                            <>
                                                {!book.suggestionsLivres.isForStructure && <button onClick={() => validate(book, true)} className="btn btn-success btn-sm m-1">Publier pour ma structure</button>}
                                                {book.suggestionsLivres.isForStructure && <button onClick={() => validate(book, false)} className="btn btn-warning btn-sm m-1">Publier pour tout le monde</button>}
                                            </>
                                        )}

                                        {book.isValidated && <button onClick={() => unValidate(book)} className="btn btn-danger btn-sm m-1">
                                        Refuser la publication du livre
                                        </button>}

                                        {(!book.isValidated) && <button onClick={() => removeSuggest(book)} className="btn btn-danger btn-sm m-1">
                                        Refuser la suggestion
                                        </button>}
                                    </>
                                }



                                {<button onClick={() => setShowBookForm(true)} className="btn btn-info btn-sm m-1">Modifier le livre</button>}
                                {UserStore.canRemoveBook(book) && <button onClick={() => removeBook(book)} className="btn btn-danger btn-sm m-1">Supprimer le livre</button>}

                                

                            </div>
                            <div className="d-flex">
                                <ul className="col-md-5">

                                    <li><b>Thématiques:</b> <i>{book.domaine.libelle}</i></li>
                                    <li><b>Auteur:</b> <i>{book.auteurLivre}</i></li>
                                    <li><b>Editeur:</b> <i>{book.editeur}</i></li>
                                    <li><b>Date d'edition:</b> <i className="text-danger">{book.annee}</i></li>
                                    {publisher && publisher.agent !== undefined && <li><b>Livre crée par: </b>
                                        <i className="text-danger">{publisher.agent.nom + " " + publisher.agent.prenom}</i>
                                    </li>}
                                </ul>
                                <ul className="col-md-7">
                                    <li>
                                        {(book.fichier !== undefined && book.categorie !== undefined && book.categorie.libUnique === 'word') &&
                                            <>
                                                <div>
                                                    <span className="textPrimary"><i className="fas fa-download textPrimary"></i></span>Télécharger:
                                                </div>
                                                <a href={SITE_URL + '/api/livre/download/' + book.id + "?token=" + token} rel="noreferrer" target="_blank" className="btn btn-danger btn-sm"><i className="fas fa-file-pdf"></i> Document disponible</a>
                                            </>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <p className="border-top pt-3" dangerouslySetInnerHTML={{ __html: book.description }} />
                            {book.fichier &&
                                <PDF src={SITE_URL + '/livre/download/' + book.id + "?token=" + token} />}

                        </div>
                    </div>
                </div>

                    <Modal show={showBookForm} onClose={() => setShowBookForm(false)} body={
                        <ShareBook _book={book} onClose={() => {
                            setShowBookForm(false);
                            init()
                        }} />} title={`Modifier le livre: ${book.titre}`} />
                </div>
            </Fragment>

        );
    }
    return <Layout body={<Body />} />
}
import React, {useEffect, useState} from "react";
import {Link, Redirect} from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import {USER_ROLES} from "../../helpers/Constants";
import StructureStore from "../../store/StructureStore";
import UserStore from "../../store/UserStore";
import {dateFormat} from "../../helpers/Helper";


export default function Histories() {
    const [page, setPage] = useState(1)
    const [histories, setHistories] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [currenHistory, setCurrentHistory] = useState('');

    useEffect(() => {
        if (!UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) {
            return <Redirect to="/bibliotheque"/>
        }
        init();
    }, [page, showHistoryModal])

    const init = async () => {
        const {data, pagination} = await StructureStore.getHistories(page)
        setPagination(pagination)
        setHistories(data);
        console.table(data)
    }

    const setCurrentPage = (page) => {
        setPage(page);
    }

    const Body = () => {
        return (
            <>

                <div className="p-1 breadcrumbs xs-font">
                    <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Log activités</span>
                </div>
                <div className="content-body">
                    <h4 className="page-title">Historique des activités</h4>
                    <div className="container mt-3">
                        <div className="card p-2">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Compte</th>
                                    <th>Action</th>
                                    <th>Objet</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {histories.map(history =>
                                    <tr key={history.id}>
                                        <td>{dateFormat(history.loginAt, 'DD/MM/YYYY à HH:mm')}</td>
                                        <td>{history.login}</td>
                                        <td>{history.action} <strong>({history.entite})</strong></td>
                                        <td>{history.objet}</td>
                                        <td>{history.libelle}</td>
                                    </tr>
                                )}

                                </tbody>
                            </table>
                            <Pagination setCurrentPage={setCurrentPage} pagination={pagination}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return <Layout body={<Body/>}/>
}
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import {
  dateFormat,
  getAuthToken,
  notify,
  setTitle,
} from "../../helpers/Helper";
import StructureStrore from "../../store/StructureStore";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import { SITE_URL, USER_ROLES } from "../../helpers/Constants";
import PDF from "../../components/PDF";
import UserStore from "../../store/UserStore";
import Loading from "../../helpers/Loading";
import DocumentStore from "../../store/DocumentStore";

export default function RapportRecusDetail() {
  const { rapportId } = useParams();
  const [rapport, setRapport] = useState(null);
  const [observations, setObservations] = useState([]);
  const [structures, setStructures] = useState([]);
  const [structuresAll, setStructuresAll] = useState([]);
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [showVersionPanel, setShowVersionPanel] = useState(false);
  const [showInvitPanel, setShowInvitPanel] = useState(false);
  const [partage, setPartage] = useState({});
  const [showViewer, setShowViewer] = useState(false);
  const [docView, setDocView] = useState("");
  const [isIgf, setisIgf] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);

  setTitle("RAPPORTS DE SUIVI ET RECOMMANDATION");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let data = [];
    let st,
      stAll = [];
    try {
      data = await StructureStrore.getRapportsRecusDetail(rapportId);
      st = await StructureStrore.getIsIGF();
      stAll = await StructureStrore.getAll();

      const current_user = await UserStore.getCurrentUser();
      if (current_user.agent) {
        setisIgf(current_user.agent.structure.isIgf);
      }
    } catch (error) {
    } finally {
      setRapport(data);
      setStructures(st);

      setStructuresAll(stAll);
      getObservationRapport(rapportId);
      setPartage({ rapport: `/api/suivi_rapports/${rapportId}` });
      setLoading(false)
    }
  };

  const getObservationRapport = async (rapport) => {
    let { data } = await StructureStrore.getObservationRapport(rapport);
    setObservations(data);
  };

  const shareRapport = async () => {
    try {
      await StructureStrore.shareRapport(partage);
    } catch (error) {
    } finally {
      notify(
        "Succès",
        "Les recommandations sont transmises avec succès !",
        "success"
      );
      setShowSharePanel(false);
      init();
    }
  };

  const token = getAuthToken();

  const readDoc = (doc) => {
    if (doc) {
      setDocView(doc);
      setShowViewer(true);
    }
  };

  const addCommentaireRapport = async (observation, fichier, passwordDoc) => {
    try {
      await StructureStrore.addObservationSuiviRecRapport({
        rapport: rapport["@id"],
        observation,
        fichier,
        passwordDoc
      });
    } catch (error) {
    } finally {
      notify("Succès", "Commentaire envoyé avec succès !", "success");
      setShowVersionPanel(false);
      init();
      // setEditRecom(false);
    }
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    const formData = new FormData(event.target);
    let comment = document.getElementById("comment-add").value;
    let passwordDoc = document.getElementById("passwordDoc").value;

    if (formData.get("file").name !== "") {
      const fichier = await DocumentStore.uploadMedia(
        formData,
        "file",
        "/fichier_documents"
      );
      if (fichier) {
        addCommentaireRapport(comment, fichier["@id"], passwordDoc)
      }
      //form["fichier"] = fichier["@id"];
      //setForm(form);
    }
  }

  const Body = () => {
    return (
      <Fragment>
        {loading && <Loading />}
        {!loading && <div>

          <div className="p-1 breadcrumbs xs-font">
            <Link to="/bibliotheque" className="textPrimary fw-bold">
              Accueil
            </Link>
            <Link to="/bibliotheque/rapports-suivis-recommandation">
              / Rapports de suivi et recommandations
            </Link>
            {rapport && (
              <span>
                /{" "}
                {rapport.libelle && rapport.libelle.length > 40
                  ? rapport.libelle.substr(0, 40)
                  : rapport.libelle}
              </span>
            )}
          </div>

          <div className="content-body">
            <h4 className="page-title">
              Rapports : {rapport && rapport.libelle}{" "}
            </h4>
            <div className="container-fluid mt-3 mb-3">
              <div className="row mt-3 p-3">
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <div className=" pull-right">
                      {showPassword && (
                        <>
                          {" "}
                          <span>
                            RAPPORT :{" "}
                            <b> {rapport && rapport.passwordRapport} </b>
                          </span>
                          |{" "}
                          <span>
                            SYNTHESE:{" "}
                            <b>{rapport && rapport.passwordSynthese} </b>
                          </span>{" "}
                        </>
                      )}
                      <button
                        className="btn btn-warning m-1"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        AFFICHER MOTS DE PASSE DES FICHIERS
                      </button>

                      {UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && (
                        <button
                          className="btn btn-warning m-1"
                          onClick={() => {
                            setShowSharePanel(true);
                          }}
                        >
                          TRANSMETTRE CE RAPPORT
                        </button>
                      )}

                      {UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && (
                        <button
                          className="btn  m-1"
                          onClick={() => {
                            setShowVersionPanel(true);
                          }}
                        >
                          NOUVELLE VERSION
                        </button>
                      )}
                      {isIgf &&
                        UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && (
                          <button
                            className="btn btn-warning m-1"
                            onClick={() => {
                              setShowInvitPanel(!showInvitPanel);
                            }}
                          >
                            INVITER UNE STRUCTURE
                          </button>
                        )}{" "}
                      {rapport && rapport.fichier && (
                        <button
                          className="btn text-uppercase btn-success m-1"
                          type="button"
                          onClick={() =>
                            readDoc(
                              SITE_URL +
                              "/suivi-rapport/synthese/download/" +
                              rapport.id +
                              "?token=" +
                              token
                            )
                          }
                        >
                          Lire la synthèse
                        </button>
                      )}
                      {rapport && rapport.fichier && (
                        <button
                          className="btn text-uppercase btn-info m-1"
                          type="button"
                          onClick={() =>
                            readDoc(
                              SITE_URL +
                              "/suivi-rapport/doc/download/" +
                              rapport.id +
                              "?token=" +
                              token
                            )
                          }
                        >
                          Lire rapport
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {showSharePanel && (
                  <div className="card mb-2  ">
                    <div className="card-body ">
                      <h5 className="card-title">
                        Transmission du rapport {rapport && rapport.libelle}{" "}
                      </h5>
                      <select
                        className="form-control m-3"
                        value={partage.structure}
                        onChange={(e) => {
                          setPartage({ ...partage, structure: e.target.value });
                        }}
                      >
                        <option value={null}>--Choisir une structure---</option>
                        {structures.map((s) => (
                          <option key={s.id} value={`/api/structures/${s.id}`}>
                            {s.intitule}
                          </option>
                        ))}
                      </select>

                      <button
                        className="btn btn-warning m-1"
                        onClick={() => shareRapport()}
                      >
                        TRANSMETTRE
                      </button>
                      <button
                        className="btn btn-primary m-1"
                        onClick={() => setShowSharePanel(false)}
                      >
                        ANNULER
                      </button>
                    </div>
                  </div>
                )}

                {showInvitPanel && (
                  <div className="card mb-2  ">
                    <div className="card-body ">
                      <h5 className="card-title">
                        Invitation d'une structure du rapport{" "}
                        {rapport && rapport.libelle}{" "}
                      </h5>
                      <select
                        className="form-control m-3"
                        value={partage.structure}
                        onChange={(e) => {
                          setPartage({ ...partage, structure: e.target.value });
                        }}
                      >
                        <option value={null}>--Choisir une structure---</option>
                        {structuresAll.map((s) => (
                          <option key={s.id} value={`/api/structures/${s.id}`}>
                            {s.intitule}
                          </option>
                        ))}
                      </select>

                      <button
                        className="btn btn-warning m-1"
                        onClick={() => shareRapport()}
                      >
                        INVITER
                      </button>
                      <button
                        className="btn btn-primary m-1"
                        onClick={() => setShowSharePanel(false)}
                      >
                        ANNULER
                      </button>
                    </div>
                  </div>
                )}


                {showVersionPanel && (
                  <form onSubmit={(e) => handleSubmit(e)} encType="multipart/form-data">
                    <div className="card mb-2  ">
                      <div className="card-body ">
                        <textarea
                          className="form-control"
                          id="comment-add"
                          type="text"
                          name="comment"
                          placeholder="Poster un commentaire"
                        />

                        <div className="mb-3">
                          <div className="form-group">
                            <span>
                              Attachez le rapport de mission [obligatoire]
                              <small className="fw-bold"></small>
                            </span>
                            <input name="file" type="file" className="form-control" required />
                          </div>
                        </div>

                        <div className="mb-3 col-2">
                          <div className="form-group">
                            <span>
                              Mot de passe du document [obligatoire]
                              <small className="fw-bold"></small>
                            </span>
                            <input
                              className="form-control"
                              required
                              id="passwordDoc"
                              type="password"
                              name="passwordDoc"
                            />
                          </div>
                        </div>




                        <button
                          className="btn btn-warning m-1"
                          type="submit"
                        // onClick={() => addCommentRecommandation(false)}
                        >
                          ENVOYER
                        </button>
                        <button
                          className="btn btn-primary m-1"
                          onClick={() => setShowVersionPanel(false)}
                        >
                          ANNULER
                        </button>
                      </div>
                    </div>


                  </form>
                )}


                <div className="card mb-2 col-8  ">
                  <div className="card-body ">
                    <h5 className="card-title">Liste des recommandations</h5>

                    <table className="table thead-dark table-striped table-bordered p-5">
                      <thead
                        style={{
                          color: "#fff",
                        }}
                        className="bckColorDark"
                      >
                        <tr className="  ">
                          <th>N°</th>
                          <th title="Description">Recommandation</th>
                          <th title="Personne responsable">Responsable</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rapport &&
                          rapport.suiviRecRaports.map((suivi, index) => (
                            <tr>
                              <td className="p-3">{index + 1}</td>
                              <td className="p-3">
                                <b>
                                  <Link
                                    to={`/bibliotheque/rapports-suivis-recommandation-detail-recommandation/${suivi.id}`}
                                  >
                                    {" "}
                                    {suivi.libelle}
                                  </Link>
                                </b>{" "}
                              </td>
                              <td>{suivi.agentSuivi && suivi.agentSuivi.nom}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card mb-2  col-4 ">
                  <div className="card-body  ">
                    <h5> Commentaires - Notifications</h5>
                    <ul className="list-group list-group-flush">
                      {observations.map((o) => (
                        <li className="list-group-item " key={o.id}>
                          <b>
                            {dateFormat(o.createdAt, "DD/MM/YYYY à HH:mm")} :{" "}
                          </b>
                          <b>
                            {o.type == "Commentaire"
                              ? `${o.createdBy.agent.nom} ${o.createdBy.agent.prenom} a posté un commentaire : ${o.observation}`
                              : o.observation}{" "}
                          </b>

                          {o.fichier &&
                            <>
                              {/* <a href={SITE_URL + '/observation/file/download' + o.id +"?token=" +token}>lien</a> */}
                              <button
                                className="btn text-uppercase btn-sm"
                                type="button"
                                onClick={() =>
                                  readDoc(
                                    SITE_URL + '/observation/file/download/' + o.id +
                                    "?token=" +
                                    token
                                  )
                                }
                              > 

                                <i className="fa fa-file"></i>
                              </button>
                              (Mot de passe : <b className="text-danger text-bold">{o.passwordDoc}</b>)

                            </>
                          }
                          {/* {o.observation}{" "} */}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            large
            size="lg"
            show={showViewer && docView}
            title="Lecture du Document"
            body={<PDF src={docView} />}
            onClose={() => setShowViewer(false)}
          />


        </div>}
      </Fragment>

    );
  };
  return <Layout body={<Body />} />;
}

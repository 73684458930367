import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import { Pencil, Save, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { USER_ROLES } from "../../../helpers/Constants";
import {notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";

const Form = ({ domaine,reset,close }) => {

   const [current, setCurrent] = useState(domaine);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      MissionStore.saveDomaine(current).then(_ => {
         notify("Succès", "Le domaine de remboursement a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()

      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Domaine de remboursement <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required placeholder="domaine"/>
            </div>
         </div>
      
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Domaines() {

   const [domaines, setDomaines] = useState([]);
   const [domaine, setDomaine] = useState({ libelle: "", libUnique: ""});
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const {data:_domaines} = await MissionStore.getDomaines();
      setDomaines(_domaines);
      setLoaging(false)
   }

   const removeDomaine = (id) => {
      if (window.confirm("Supprimer ce domaine?"))
         MissionStore.removeDomaine(id).then(_ => init())
   }

   const updateDomaine = (domaine) => {
      setDomaine(domaine);
      setShowModal(true);
   }

   const newDomaine = (e) => {
      e.preventDefault();
      setDomaine({});
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setDomaine({ libelle: "" })
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavAutre/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Domaines remboursement</span>
            </div>
            <h4 className="page-title">Les domaines de remboursement missions</h4>
            <button onClick={newDomaine} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer un domaine</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
               <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Domaine de remboursement</th>
                         
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {domaines.map((domaine, index) =>
                           <tr key={domaine.id}>
                              <td>{index + 1}</td>
                              <td>{domaine.libelle}</td>
                             
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateDomaine(domaine)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeDomaine(domaine.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer une domaine de mission" body={<Form domaine={domaine} reset={{ libelle: "" }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
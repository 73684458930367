import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import { dateFormat, setTitle } from "../../helpers/Helper";
import NotificationStore from "../../store/NotificationStore";
import SharedStore from "../../store/SharedStore";

export default function Notifications() {
  const [page, setPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({});
  const [read, setRead] = useState(0);
  const setNotificationCount = SharedStore(
    (state) => state.setNotificationCount
  );
  const notificationCount = SharedStore((state) => state.notificationCount);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  setTitle("Mes notifications");

  useEffect(() => {
    init();
  }, [read]);

  const init = async () => {
    setLoading(true);
    const { data } = await NotificationStore.getNotifications(read, page);
    setNotifications(data);
    setPagination(pagination);
    setLoading(false);
  };

  const setCurrentPage = (page) => {
    setPage(page);
  };

  // const removeNotification = (id) => {
  //    NotificationStore.removeNotification(id).then(() => {
  //       notify('Succès', "Notification supprimée", "success");
  //       init();
  //    })
  // }

  const setReadedNotification = (e, notification) => {
    e.preventDefault();
    NotificationStore.setReadedNotification(
      notification.idDestinataire,
      true
    ).then(() => {
      setNotificationCount(notificationCount - 1);
      history.push(generateLink(notification));
    });
  };

  const generateLink = (r) => {
    let url = "";
    if (!r.idRessource) return "/bibliotheque/notifications?error";
    switch (r.ressource) {
      case "SuiviRecRapport":
        url = `/bibliotheque/rapports-suivis-recommandation-detail-recommandation/${r.idRessource}`;
        break;
      case "rapport":
        url = `/bibliotheque/documents/${r.idRessource}`;
        break;

      case "livre":
        url = `/bibliotheque/livres/${r.idRessource}`;
        break;

      case "SuiviRapport":
        url = `/bibliotheque/rapports-suivis-recommandation/${r.idRessource}`;
        break;

      case "PartageRapport":
        url = `/bibliotheque/rapports-suivis-recommandation-partage/${r.idRessource}/?status=share`;
        break;

      default:
        // url = `/bibliotheque/notfound/${r.idRessource}`

        break;
    }
    // console.log(url,r.type, r.ressource);

    return url;
  };

  const Body = () => {
    return (
      <>
        <div className="p-1 breadcrumbs xs-font">
          <Link to="/bibliotheque" className="textPrimary fw-bold">
            Accueil
          </Link>{" "}
          <span>/ Mes notifications</span>
        </div>
        <div className="content-body">
          <h4 className="page-title">Mes notifications</h4>
          <div className="container-fluid mt-3 mb-3">
            <div className="row mt-3">
              <div className="col-sm-12 col-md-12 p-2">
                {/* <div className="d-flex justify-content-between notif-selectionbtn mb-2">
                           <button onClick={() => setRead(null)} className={`btn  ${read === null ? ' btn-success p-2' : ''}`}>Tout afficher</button>
                           <button onClick={() => setRead(0)} className={`btn ${read === 0 ? ' btn-success p-2' : ''}`}>Afficher les non lues </button>
                           <button onClick={() => setRead(1)} className={`btn ${read === 1 ? ' btn-success p-2' : ''}`}>Afficher les lues </button>
                        </div> */}

                <ul className={`list-group ${loading ? "loading" : ""}`}>
                  {notifications.map((notification) => (
                    <li
                      className={`list-group-item notification-item ${
                        !notification.lu ? "bg-light" : "bg-white"
                      } p-4`}
                      key={notification.id}
                    >
                      <Link
                        to={generateLink(notification)}
                        className="d-flex"
                        onClick={(e) => setReadedNotification(e, notification)}
                      >
                        {/* {notification.ressource === "rapport" ? <span className="badge bg-dark">Rapport</span> : <span className="badge bg-info text-dark">Livre</span>} */}

                        {/* <div>
                                       <i className="fas fa-check p-3 rounded-circle bg-success text-white"></i>
                                    </div> */}
                        <div className="ps-2">
                          <div className={!notification.lu && "textBold"}>
                            {/* <b>{notification.expediteur}</b>*/}{" "}
                            {notification.contenu}
                          </div>
                          <small className={!notification.lu && "textBold"}>
                            le{" "}
                            <b>
                              {dateFormat(
                                notification.date,
                                "DD/MM/YYYY à HH:mm"
                              )}
                            </b>
                          </small>
                        </div>
                        <div className="actionbtns">
                          {/* <button className="btn btn-sm btn-outline-primary rounded-circle" title="Marqué comme lu" onClick={() => setReadedNotification(notification.id)}><i className="fas fa-check"></i></button> */}
                          {/*   <button className="btn btn-sm btn-outline-danger rounded-circle" title="Supprimer" onClick={() => removeNotification(notification.id)}><i className="fas fa-trash-alt"></i></button> */}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
                <Pagination
                  setCurrentPage={setCurrentPage}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return <Layout body={<Body />} />;
}

import httpClient from "./HttpClient";
import UserStore from "./UserStore";

const END_POINT = "/documents";

const DocumentStore = {
  state: {
    image: null,
    file: null,
    documents: [],
    categories: [],
    selectedGroups: [],
    selectedTeams: [],
    domaines: [],
    accessType: [],
    modules: [],
    extension: "",
  },

  getFile(url) {
    return httpClient.get(url);
  },

  saveDocument(document) {
    if (document.id !== undefined && document.id !== "")
      return httpClient.put("/documents/" + document.id, document);
    else return httpClient.post("/documents", document);
  },

  removeSuggest(id) {
    return httpClient.delete("/suggestions_rapports/" + id);
  },

  suggestDocument(document, update = false, id = null) {
    if (update) {
      return httpClient.patch("/suggestions_rapports/" + id, document, {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Accept: "application/ld+json",
        },
      });
    } else return httpClient.post("/suggestions_rapports", document);
  },

  async validateDocument(id, isVisible = false, isValidated = true) {
    try {
      const response = await httpClient.patch(
        "/documents/" + id,
        {
          isValidated,
          isVisible,
        },
        {
          headers: {
            "Content-Type": "application/merge-patch+json",
            Accept: "application/ld+json",
          },
        }
      );
      const data = await response.data;
      return data;
    } catch (e) {
      console.error(e);
    }
  },

  async getSuggestedDocuments(structure, page = 0, q = null) {
    try {
      let url = `/suggestions_rapports?page=${page}&itemsPerPage=10&order[createdAt]=DESC&isValidated=false&structure=${structure}`;
      if (q !== null) url += `&titre=${q}`;
      const response = await httpClient.get(url);
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getMyDocuments(module = null, page = 0, annee = null, q = null) {
    try {
      let url = `?page=${page}&order[createdAt]=DESC&user.email=${UserStore.state.currentUser.email}&itemsPerPage=9`;
      if (module !== null && module !== "")
        url += `&module.libUnique=${module}`;
      if (annee !== null) url += `&annee=${annee}`;
      if (q !== null) url += `&libelle=${q}`;

      const response = await httpClient.get(END_POINT + url);
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getAll(
    module = null,
    page = 0,
    annee = null,
    structureCode = null,
    q = null
  ) {
    try {
      let url = `biblio/rapports/me?page=${page}&isValidated=1&itemsPerPage=9`;

      if (module !== null && module !== "") url += `&module=${module}`;
      if (annee !== null) url += `&annee=${annee}`;
      if (q !== null) url += `&q=${q}`;
      if (structureCode !== null) url += `&strCode=${structureCode}`;
      const response = await httpClient.get(url);
      const data = await response.data["rapports"];

      const pagination = {
        "hydra:first": url + "&page=" + 1,
        "hydra:last": url + "&page=" + response.last,
      };
      if (response.hasPrevousPage)
        pagination["hydra:previous"] = url + "&page=" + response.previousPage;
      if (response.hasNextPage)
        pagination["hydra:next"] = url + "&page=" + response.nextPage;

      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getAllSearch(q, page = 0) {
    try {
      let url = `biblio/rapports/me?page=${page}&isValidated=1&itemsPerPage=9&q=${q}`;

      const response = await httpClient.get(url);
      const data = await response.data["rapports"];
      const pagination = {
        "hydra:first": url + "&page=" + 1,
        "hydra:last": url + "&page=" + response.last,
      };
      if (response.hasPrevousPage)
        pagination["hydra:previous"] = url + "&page=" + response.previousPage;
      if (response.hasNextPage)
        pagination["hydra:next"] = url + "&page=" + response.nextPage;
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getDocuments(module = null, page = 0, annee = null, q = null) {
    try {
      let url = `documents?page=${page}&isValidated=1&order[annee]=DESC&itemsPerPage=9`;

      if (module !== null && module !== "")
        url += `&module.libUnique=${module}`;
      if (annee !== null) url += `&annee=${annee}`;
      if (q !== null) url += `&q=${q}`;

      const response = await httpClient.get(url);
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getByDateBetween(start, end, module = null, page = 1) {
    try {
      let url = `${END_POINT}?page=${page}&itemsPerPage=9`;
      url += `&annee[between]=${start}..${end}`;
      if (module !== null) url += `&module.libUnique=${module}`;
      const response = await httpClient.get(encodeURI(url));
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return [];
    }
  },

  async getDocument(id) {
    try {
      const response = await httpClient.get(END_POINT + "/" + id);
      const data = await response.data;
      return data;
    } catch (e) {
      return {};
    }
  },

  async getCategory(categoryId) {
    try {
      const response = await httpClient.get(
        `/categorie_documents/${categoryId}`
      );
      const data = await response.data["hydra:member"];
      return data;
    } catch (e) {
      console.error(e);
    }
  },

  async getCategories() {
    if (!this.state.categories.length) {
      try {
        const response = await httpClient.get(`/categorie_documents`);
        const data = await response.data["hydra:member"];
        this.state.categories =
          data !== undefined && data !== "undefined" ? data : [];
        return data;
      } catch (e) {
        return this.state.categories;
      }
    } else return this.state.categories;
  },

  async getModules() {
    if (!this.state.modules.length) {
      try {
        const response = await httpClient.get(`/modules`);
        const data = await response.data["hydra:member"];
        this.state.modules =
          data !== undefined && data !== "undefined" ? data : [];
        return data;
      } catch (e) {
        return this.state.modules;
      }
    } else return this.state.modules;
  },

  async findCategoryByLibUnique(libUnique) {
    if (!this.state.categories.length) await this.getCategories();
    return this.state.categories.find((cat) => cat.libUnique === libUnique);
  },

  async findModuleByLibUnique(libUnique) {
    if (!this.state.modules.length) await this.getModules();
    return this.state.modules.find(
      (_module) => _module.libUnique === libUnique
    );
  },

  async uploadMedia(formData, type = "file", path = "/media_objects") {
    try {
      const response = await httpClient.post(path, formData, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
      const data = await response.data;
      this.state[type] = data;
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async uploadDocument(formData, type = "file", path = "/fichier_documents") {
    try {
      const response = await httpClient.post(path, formData, {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      });
      const data = await response.data;
      this.state[type] = data;
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  resetFile() {
    this.state.file = null;
    this.state.image = null;
    this.state.extension = null;
  },

  setSelectedGroups(groups) {
    this.state.selectedGroups = groups;
  },

  setSelectedTeams(teams) {
    this.state.selectedTeams = teams;
  },

  removeDocument(id) {
    return httpClient.delete(`/documents/${id}`);
  },

  async getFavoriteDocuments(page = 1, perPage = 10) {
    try {
      const response = await httpClient.get(
        `/rapport_favoris?page=${page}$itemsPerPage=${perPage}&order[createdAt]=DESC`
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:member"];
      this.state.accessType =
        data !== undefined && data !== "undefined" ? data : [];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  favorite(rapport, user) {
    try {
      return httpClient.post("/rapport_favoris", { rapport, user });
    } catch (error) {
      console.log(error);
    }
  },

  deleteFavorite(id) {
    return httpClient.delete(`/rapport_favoris/${id}`);
  },
};

export default DocumentStore;


import { USER_ROLES } from "../helpers/Constants";
import httpClient from "./HttpClient";
import UserStore from "./UserStore";

const MessageStore = {

   state: {
      messages: []
   },

   async getMessages(lu = null, page = 1) {
      let structure_code = null 
      if(UserStore.state.currentUser.agent && UserStore.state.currentUser.agent.structure ){
         structure_code = UserStore.state.currentUser.agent.structure.code
      }
      if (!this.state.messages.length && UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
      ) {
         try {
            let url = `/avis?page=${page}&&order[createdAt]=DESC`;

            // if (lu !== null) url += `&lu=${lu}`
            if(structure_code) url +=`&structure.code=${structure_code}`

            const response = await httpClient.get(url);
            const data = await response.data['hydra:member'];
            const pagination = await response.data['hydra:view'];
            return { data, pagination };
         } catch (e) {
            return { data: [], pagination: {} }
         }
      }

      else
         return this.state.messages;
   },

   async getNotRead() {
      let structure_code = null 
      if(UserStore.state.currentUser.agent && UserStore.state.currentUser.agent.structure ){
         structure_code = UserStore.state.currentUser.agent.structure.code
      }
      if (!this.state.messages.length && UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
      ) {
         try {
            let url = `/avis?pagination=false&lu=false`;
            if(structure_code) url +=`&structure.code=${structure_code}`

            const response = await httpClient.get(url);
            const count = await response.data['hydra:totalItems'];
            return { count };
         } catch (e) {
            return { count:0}
         }
      }

      else
         return this.state.messages;
   },


   setReadedMessage(id, lu = true) {
      return httpClient.patch("/avis/" + id, { lu }, { headers: { 'Content-Type': 'application/merge-patch+json', 'Accept': 'application/ld+json' } })
   },

}

export default MessageStore;
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Biblio, DEFAULT_URL, SITE_URL, USER_ROLES_LABEL } from "../../helpers/Constants";
import { doLogout } from "../../helpers/Helper";
import HomeStore from "../../store/HomeStore";
import UserStore from "../../store/UserStore";
import { FaLock, User, UserCircle } from "../Icons";
import MainMenu from "./MainMenu";



export default function Header({ layout }) {

   const user = UserStore.state.currentUser;
   const [menus, setMenus] = useState([]);
   const [params, setParams] = useState({});


   useEffect(() => {
      init();
   }, [])

   const init = async () => {
      //  console.log("user",user);

      let _menus = await HomeStore.getMenus();
      const _params = await HomeStore.getParams()
      setParams(_params);
      _menus = _menus.filter(menu => menu.parent === undefined);
      setMenus(_menus);

   }

   return (
      <div id="navMenu" className="container-fluid bckColorDark">
         <nav id="navbar_top" className="navbar navbar-expand-lg navbar-dark pb-0 pt-0">
            <div className="container-fluid pe-0 ps-0">
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
               </button>

               <a className="navbar-brand me-2 pb-0" id="navbar-brand" href="/">
                  <img src={DEFAULT_URL + params.logo} alt={Biblio.NAME} className="site-logo" />
               </a>
               <Link to="/account" className="navbar-toggler waves-effect waves-light secBckColor" aria-expanded="false" aria-label="Toggle navigation">
                  <UserCircle />
               </Link>

               <div className="collapse navbar-collapse ms-4" id="main_nav">
                  <ul className="navbar-nav ms-auto">
                     {layout && <MainMenu menus={layout.menus} />}
                  </ul>
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0 hide4mobile">

                     <li id="showSearch" className="nav-item search-dropdown ms-0">
                        {UserStore.isAuthenticated() && <>
                           <div className="d-flex">

                              <div className="dropdown">
                                 {user && <button className="dropbtn">
                                    <User />
                                    {user.agent.nom} (
                                    {user.roles.map(r => (<span>{USER_ROLES_LABEL[r]}</span>))}
                                    )
                                 </button>}
                                 <div className="dropdown-content">
                                    <Link to="/account">Mon compte</Link>
                                    <a onClick={doLogout} href="/">Deconnexion</a>
                                 </div>
                              </div>
                           </div>
                        </>}
                        {!UserStore.isAuthenticated() && <a className="dropbtn p-2 text-white text-uppercase showSearch waves-effect waves-light secBckColor" title="Le Portail" href="/login">
                           <FaLock /> Se connecter
                        </a>}

                     </li>

                  </ul>
               </div>
            </div>
         </nav>

      </div>
   );
}
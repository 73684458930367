import React, { useEffect, useState } from "react";
import { notify, setTitle, storeItem } from "../../helpers/Helper";
import { useHistory } from "react-router-dom";
import {
  API_URL,
  Biblio,
  SITE_URL,
  storedItemKeys,
} from "../../helpers/Constants";
import UserStore from "../../store/UserStore";
import HomeStore from "../../store/HomeStore";
import { Link } from "react-router-dom";

export default function Login() {
  const history = useHistory();
  const [form, setForm] = useState({
    username: "",
    password: "",
  }); //TODO remove email and password
  const [params, setParams] = useState({});

  useEffect(() => { 
    init();
  }, []);

  const init = async () => {
    let _params = await HomeStore.getParams();
    setParams(_params);
  };

  useEffect(() => {
    if (UserStore.isAuthenticated()) window.location.href = "/bibliotheque";
    setTitle("Connexion à la bibliothèque");
  }, [history]);

  const handleInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    UserStore.login(form.username, form.password)
      .then((response) => {
        storeItem(storedItemKeys.TOKEN, response.data.token);

        fetch(API_URL + "/u/infos", {
          headers: {
            Authorization: `Bearer ${response.data.token}`,
            "X-AUTH-TOKEN": "5fbad276a949f235c5731c8d27a66dd590b8a3df",
          },
        })
          .then((res) => res.json())
          .then((json) => {
            storeItem(storedItemKeys.CURRENT_USER, JSON.stringify(json));
            /*if (hasStoredItem(storedItemKeys.PRIVATE_ROUTE)) {
                  window.location.href = getStoredItem(storedItemKeys.PRIVATE_ROUTE);
               } else*/
            window.location.href = "/bibliotheque";
          });
      })
      .catch((err) => notify("", "Nom d'utilisateur ou mot de passe incorrect.", "danger"));
  };
  if (!UserStore.isAuthenticated())
    return (
      <div id="login">
        <div className="wrapper">
          <div className="container">
            <div className="sign-in-container">
              <form onSubmit={handleSubmit}>
                <h1>Se connecter</h1>
                <div className="social-links">
                  <div>
                    <a href="/">
                      {" "}
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div>
                    <a href="/">
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div>
                    <a href="/">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <span>ou utiliser votre identifiant</span>
                <input
                  type="username"
                  placeholder="Non d'utilisateur"
                  name="username"
                  value={form.username}
                  onChange={handleInput}
                />
                <input
                  type="password"
                  placeholder="Mot de passe"
                  name="password"
                  value={form.password}
                  onChange={handleInput}
                />
                <button className="form_btn" type="submit">
                  S'authentifier
                </button>
                <Link to="/renew-password">Mot de passe oublié ?</Link>
                <a href="/">Revenir à l'accueil</a>
              </form>
            </div>
            <div className="overlay-container ">
              <div className="overlay-right ">
                <img
                  src={SITE_URL + params.logo}
                  alt={`Logo ${Biblio.NAME}`}
                  className="mx-auto d-block"
                  style={{ width: "100%" }}
                />
                <p className="text-center">
                  Saisissez vos données personnelles pour avoir accès aux
                  donnèes privilèges
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else return "";
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DEFAULT_URL, SITE_URL } from "../../../helpers/Constants";
import { notify } from "../../../helpers/Helper";
import ResourceStore from "../../../store/ResourceStore";

export default function Resource({ resource, user }) {

    const [l_favoris, setl_favoris] = useState(user.livreFavoris.map(l => l.livre));

    const favorite = (event) => {
        event.preventDefault();
        ResourceStore.favorite('/api/livres/' + resource['id'], '/api/users/' + user.id).then(_ => notify('Succès', "Le livre a été ajouté à vos favoris.", "success"))
        setl_favoris([...l_favoris, resource])
    }

    const checkFavorite = (r) => {
        const livre_f = l_favoris.filter(l => l.id === r.id)
        if (livre_f.length > 0) return true

        return false
    }


    let imageUrl = (resource && resource.urlImage !== "") ? resource.urlImage : "/img/livre.png"
    if(resource && resource.image)
    imageUrl = (resource.image !== undefined) ? DEFAULT_URL + '/' + resource.image.contentUrl : imageUrl

    return (
        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-3 mt-5">
            <div className="book-showcase">

                <Link to={`/bibliotheque/livres/${resource.id}`} style={{ height: '100%', display: 'block', overflow: 'hidden', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${imageUrl})` }}>
                    {/* <img src={imageUrl} className="img-fluid book" alt={resource.titre} /> */}
                </Link>
                <Link to={`/bibliotheque/livres/${resource.id}`}>
                    <div className="bookdetail">
                        <span>{resource.titre}</span>
                    </div>

                </Link>
                <div className="d-flex justify-content-center bckColorDark">
                    {/* {(resource.suggestionsLivres !== undefined || !resource.isValidated) && <a href="" className="text-white pe-3 ps-3 pb-1 pt-1" title="Livre Suggéré"><i className="fas fa-info"></i></a>} */}
                    {/* {checkFavorite(resource) && <a href="" className="text-white pe-3 ps-3 pb-1 pt-1" title="Livre Suggéré"><i className="fas fa-book"></i></a>} */}
                    {!checkFavorite(resource) && <a onClick={favorite} href="#/" className="text-white pe-3 ps-3 pb-1 pt-1" title="Ajoutez au favoris"><i className="fas fa-heart"></i></a>}
                </div>
            </div>
        </div>
    );
}


import React, { useState, useEffect } from "react";
import { Search } from "../../components/Icons";


export default function FilterRE({ searchRE }) {


   const [q, setQ] = useState('')
   const [timer, setTimer] = useState(null);

   // const changeRequest = query => {
   //   // setQ(query)
   // }
   function changeRequest(change) {
      setQ(change)
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          searchRE(change)
          setQ(change)
        }, 1000)
      );
  }
   return (
      <div className="filterArea bg-white">
         <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">
            {/* <div className="p-2 bd-highlight ">
               <div className="search-box ">
                  <a href="/" className="search-btn" onClick={searchBook}>
                     <Search />
                  </a>
                  <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => setQ(e.target.value)} />

               </div>
            </div> */}

            <div className="p-2 bd-highlight ">
               <div className="search-box">
                  <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => changeRequest(e.target.value)}  />
                  <a className="search-btn" onClick={() => searchRE(q)} >
                     <Search />
                  </a>
               </div>
            </div>

         </div>
      </div>
   );
}

export default function Audio({ src }) {
   return (
      <div className="mt-3 row">
         <audio controls style={{margin:'auto'}}>
            <source src="horse.ogg" type="audio/ogg" />
            <source src={src} type="audio/mpeg" />
         </audio>
      </div>
   )
}
import { dateFormat } from "../../../helpers/Helper";


const Detail = ({ rapport }) => {

   return (
      <div className="row">
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>L'intitulé du rapport de mission </h6>
               <p>{rapport.intitule}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Numéro rapport de mission </h6>
               <p>{rapport.numRapportSp}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>L'ordre de mission</h6>
               <p>{rapport.odreMission.intitule}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>La date de dépot du rapport</h6>
               <p>{dateFormat(rapport.dateDepotSp)}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>La date d'envoi finale du rappport</h6>
               <p>{dateFormat(rapport.dateEnvoieFinalSv)}</p>
            </div>
         </div>
        
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le montant à controller</h6>
               <p>{rapport.montantBudgetController}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le montant en cause</h6>
               <p>{rapport.montantEnCause}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le montant à rembourser</h6>
               <p>{rapport.montantArembourser}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               {rapport.planRecommandation && <h6>Ce rapport possède un plan de recommandation.</h6>}
               {!rapport.planRecommandation && <h6>Ce rapport ne possède pas un plan de recommandation.</h6>}

            </div>
         </div>

      </div>
   );
}

export default Detail;
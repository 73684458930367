
export default function Video({src}) {
   return (
      <div className="mt-3 row">
         <video height="500" controls>
            <source src={src} type="video/mp4" />
            <source src="movie.ogg" type="video/ogg" />
         </video>
      </div>
   )
}
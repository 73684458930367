import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DEFAULT_URL } from "../helpers/Constants";
import { dateFormat, notify, slugify, validateEmail } from "../helpers/Helper";
import HomeStore from "../store/HomeStore";

const Menu = ({ item }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let out = "";
  if (item.page !== undefined) {
    out = (
      <li className="text-white" key={item.id}>
        <Link
          className="text-white"
          to={`/${slugify(item.page.titre.replace(" ", ""))}`}
        >
          {item.page.titre}
        </Link>
      </li>
    );
  } else {
    out = (
      <li className="text-white" key={item.id}>
        <Link className="text-white" to={item.url.replace(" ", "")}>
          {capitalizeFirstLetter(item.name)}
        </Link>
      </li>
    );
  }

  return out;
};

const Liens = ({ item }) => {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  let out = "";
  if (item.page !== undefined) {
    out = (
      <li className="text-white" key={item.id}>
        <a className="text-white" href={`/${slugify(item.page.titre)}`}>
          {item.page.titre}
        </a>
      </li>
    );
  } else {
    out = (
      <li className="text-white" key={item.id}>
        <a className="text-white" href={item.url}>
          {capitalizeFirstLetter(item.libelle)}
        </a>
      </li>
    );
  }

  return out;
};

export default function Footer() {
  const [menus, setMenus] = useState([]);
  const [params, setParams] = useState({});
  const [pied_page, setPiedPage] = useState([]);
  const [newsletter, setNewsletter] = useState("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let _menus = await HomeStore.getMenus();
    let _params = await HomeStore.getParams();
    let _piedpage = await HomeStore.getPiedPage();
    _menus = _menus.filter((menu) => menu.parent === undefined);
    setMenus(_menus);
    setParams(_params);
    setPiedPage(_piedpage);
  };
  const saveNewsletterMail = () => {
    if (!validateEmail(newsletter))
      return notify(
        "Erreur",
        "Veuillez entrer une adresse mail valide.",
        "danger"
      );
    HomeStore.saveNewsletter(newsletter).then((response) => {
      notify("Succès", "Vous êtes enregistrés à la newsletter.", "success");
      setNewsletter("");
    });
  };
  return (
    <div className="pg-footer">
      <footer>
        <div className="container-fluid footer">
          <div className="row  pt-3">
            <div className="col-sm-12 col-md-4">
              <img
                style={{ marginTop: "5%" }}
                src={DEFAULT_URL + params.logo}
                alt="IGF"
                className="img-fluid footer-logo"
              />
              <p className="fw-bold text-light"></p>
            </div>
            <div className="col-sm-12 col-md-4 d-flex justify-content-around">
              <div>
                <h5 className="text-white mb-2">Plan du site</h5>
                <ul>
                  {menus.map((menu) => (
                    <Menu item={menu} key={menu.id} />
                  ))}
                </ul>
              </div>

              <div>
                <h5 className="text-white mb-2">Liens Utiles</h5>
                <ul>
                  {pied_page.map((pied) => (
                    <Liens item={pied} key={pied.id} />
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <h3 className="text-white fw-light text-center mb-3">
                Inscrivez-vous à la newsletter
              </h3>
              <div className="input-group mb-3">
                <input
                  type="email"
                  value={newsletter}
                  onChange={(e) => setNewsletter(e.target.value)}
                  className="form-control"
                  placeholder="votre email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <button
                  id="basic-addo"
                  className="input-group-text btn btn-warning"
                  onClick={saveNewsletterMail}
                >
                  Je m'inscris
                </button>
              </div>
              <div className="d-flex mb-2">
                <div className="social-media">
                  <p className="fw-bold text-light">Suivez-nous sur</p>

                  <a href={`${params.facebook}`} style={{ color: "#fff" }}>
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href={`${params.twitter}`} style={{ color: "#fff" }}>
                    <i className="fab fa-twitter-square"></i>
                  </a>
                  <a href={`${params.youtube}`} style={{ color: "#fff" }}>
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
                {/* <a href={`tel:${params.directeur_phone}`} className="btn btn-secondary mt-3 waves-effect waves-light"> Nous joindre <i className="fas fa-mobile-alt"></i></a> */}
              </div>
            </div>
          </div>
          <div className="border-top">
            <p className="text-center text-white p-2">
              &copy; {dateFormat(new Date(), "YYYY")} Organes de contrôle de
              l'ordre administratif.
            </p>
          </div>
        </div>
        <div className="flag-look-like">
          <div className="col green-block"></div>
          <div className="col yellow-block"></div>
          <div className="col red-block"></div>
        </div>
      </footer>
    </div>
  );
}

import { useLayoutEffect, useState } from "react";

import { getDocumentFormat, notify, setTitle } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import ResourceStore from "../../../store/ResourceStore";

import Upload from "./Upload";
import UserStore from "../../../store/UserStore";
import { USER_ROLES } from "../../../helpers/Constants";


export default function ShareBook({ _book = null, onClose }) {

   const [index, setIndex] = useState(1);
   const [domaines, setDomaines] = useState([])
   const [categories, setCategories] = useState([])
   const [book, setBook] = useState({ titre: '', user: null, auteurLivre: '', isbn: '', urlImage: '', description: '', domaine: '', annee: '', editeur: '', lien: '', isVisible: false, isValidated: false })
   const [saving, setSaving] = useState(false)
   const user = UserStore.state.currentUser;

   useLayoutEffect(() => {
      setTitle("Partager un livre");
      init();
   }, []);

   const init = async () => {
      const validated = UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ? true : false;
      setBook({ ...book, user: 'api/users/' + user.id, isValidated: validated })
      if (_book !== null) {
         DocumentStore.state.file = _book.fichier;
         DocumentStore.state.image = _book.image;
         setBook({
            ...book,
            titre: _book.titre,
            auteurLivre: _book.auteurLivre,
            isbn: _book.isbn,
            urlImage: _book.urlImage,
            description: _book.description,
            domaine: _book.domaine['@id'],
            categorie: _book.categorie !== undefined && _book.categorie['@id'],
            annee: _book.annee,
            editeur: _book.editeur,
            lien: _book.lien,
            isVisible: _book.isVisible,
            isValidated: _book.isValidated,
            user: 'api/users/' + user.id,
            id: _book.id
         });
      }
      const _domaines = await ResourceStore.getDomaines()
      setDomaines(_domaines);
      const _categories = await DocumentStore.getCategories()
      setCategories(_categories);
   }

   const handleInputChange = (e) => {
      setBook({ ...book, [e.target.name]: e.target.value });
   }

   const saveBook = () => {
      if (saving)
         return;
      setSaving(true);
      let toSaveBook = { ...book }

      const format = getDocumentFormat(categories) ;
      if (format !== undefined && format!==null) {
         toSaveBook['categorie'] = format['@id'];
      }

      if (toSaveBook.titre.trim() === "")
         return notify('Erreur', 'Le titre du livre doit être renseigné.', "danger")

      if (toSaveBook.domaine.trim() === "")
         return notify('Erreur', 'Le domaine du livre doit être renseigné.', "danger")

      if (DocumentStore.state.file !== null && DocumentStore.state.file !== undefined) {
         toSaveBook = { ...toSaveBook, fichier: DocumentStore.state.file['@id'] }
      }
      if (DocumentStore.state.image !== null && DocumentStore.state.image !== undefined) {
         toSaveBook = { ...toSaveBook, image: DocumentStore.state.image['@id'] }
      }

      ResourceStore.saveBook(toSaveBook).then(response => {
         const newBook = response.data;
         setBook(newBook);
         if (UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) {
            ResourceStore.suggestbook({ livre: newBook['@id'], isForStructure: false, isValidated: false }).then(response => { })
         }
         notify('Succès', "Le livre a été enregistré.", "success");
         DocumentStore.resetFile();
         setSaving(false);
         onClose()
      });
   }

   return (
      <section className="signup-step-container">
         <div className="container">
            <div className="col-md-12 pt-3 p-0">
               <div className="wizard">
                  <div className="wizard-inner">
                     <div className="connecting-line"></div>
                     <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className={index === 1 ? "active" : "disabled"} style={{ width: '33.33%' }}>
                           <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Le fichier</i></a>
                        </li>
                        <li role="presentation" className={index === 2 ? "active" : "disabled"} style={{ width: '33.33%' }}>
                           <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab" aria-expanded="true"><span className="round-tab">2 </span> <i>L'image</i></a>
                        </li>
                        <li role="presentation" className={index === 3 ? "active" : "disabled"} style={{ width: '33.33%' }}>
                           <a href="#step3" data-toggle="tab" aria-controls="step3" role="tab"><span className="round-tab">3</span> <i>Information livre</i></a>
                        </li>

                     </ul>
                  </div>

                  <div className="tab-content" id="main_form">
                     <div className={index === 1 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                        <Upload next={() => setIndex(2)} required={false} type="file" title="Fichier livre" path="/fichier_livres" />
                     </div>
                     <div className={index === 2 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step2">
                        <Upload next={() => setIndex(3)} required={false} type="image" title="Image livre" />
                     </div>
                     <div className={index === 3 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step3">
                        <div className="form-group">
                           <span>Titre du livre <small className="fw-bold">[obligatoire]</small> </span>
                           <input type="text" className="form-control" required name="titre" value={book.titre} onChange={handleInputChange} placeholder="Titre du livre" />
                        </div>
                        <div className="row  mt-3">
                           <div className="col-md-6">
                              <span>Auteur du livre </span>
                              <div className="form-group">
                                 <input type="text" className="form-control" name="auteurLivre" value={book.auteurLivre} onChange={handleInputChange} placeholder="Auteur du livre" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <span>ISBN du livre</span>
                              <div className="form-group">
                                 <input type="text" className="form-control" name="isbn" value={book.isbn} onChange={handleInputChange} placeholder="ISBN du livre" />
                              </div>
                           </div>
                        </div>

                        <div className="row mt-3">
                           <div className="col-md-6">
                              <span>Année du livre</span>
                              <div className="form-group">
                                 <input type="number" className="form-control" name="annee" value={book.annee} onChange={handleInputChange} placeholder="Année du livre" />
                              </div>
                           </div>
                           <div className="col-md-6">
                              <span>Editeur du livre</span>
                              <div className="form-group">
                                 <input type="text" className="form-control" name="editeur" value={book.editeur} onChange={handleInputChange} placeholder="Editeur du livre" />
                              </div>
                           </div>
                        </div>

                        <div className="form-group mt-3">
                           <span>Lien du livre</span>
                           <input type="text" className="form-control" name="lien" value={book.lien} onChange={handleInputChange} placeholder="Lien du livre" />
                        </div>

                        <div className="form-group mt-3">
                           <span>Lien image du livre</span>
                           <input type="text" className="form-control" name="urlImage" value={book.urlImage} onChange={handleInputChange} placeholder="Lien image livre" />
                        </div>

                        {/* <div className="form-group mt-3">
                           <span>Format du document du livre <small className="fw-bold">[obligatoire]</small></span>
                           <select name="categorie" id="categorie" className="form-control" required value={book.categorie} onChange={handleInputChange}>
                              <option value=""> -- Choisir le format du livre -- </option>
                              {categories.map(categorie => <option value={categorie['@id']} key={categorie.id}>{categorie.libelle}</option>)}
                           </select>
                        </div> */}


                        <div className="row  mt-3">
                           <div className="col-md-12">
                              <span>Domaine du livre <small className="fw-bold">[obligatoire]</small></span>
                              <div className="form-group">
                                 <select name="domaine" id="domaine" className="form-control" required value={book.domaine} onChange={handleInputChange}>
                                    <option value=""> -- Choisir le domaine du livre -- </option>
                                    {domaines.map(domaine => <option value={domaine['@id']} key={domaine.id}>{domaine.libelle}</option>)}
                                 </select>
                              </div>
                           </div>
                        </div>

                        <div className="form-group mt-3">
                           <span>Description du livre</span>
                           <textarea className="form-control" maxLength={250} name="description" id="description" value={book.description} onChange={handleInputChange} placeholder="Description du livre"></textarea>
                        </div>

                        <ul className="list-inline">
                           <li>
                              <button onClick={saveBook} type="button" className="default-btn next-step"> Continuer</button>
                           </li>
                        </ul>
                     </div>
                     <div className="clearfix"></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}
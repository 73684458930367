import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Detail from "../../../components/biblio/missions/OrdreDetail";
import Form from "../../../components/biblio/missions/OrdreForm";
import Nav from "../../../components/biblio/missions/Nav";
import { Eye, Pencil, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import MissionStore from "../../../store/MissionStore";
import { dateFormat, setTitle } from "../../../helpers/Helper";
import Pagination from "../../../components/Pagination";
import UserStore from "../../../store/UserStore";
import { USER_ROLES } from "../../../helpers/Constants";
import SubNavMission from "../../../components/biblio/missions/SubNavMission";

const $ordre = { numDossier: "", reference: "", numeroVehicule: "", nomCva: "", delaisProlongation: "", mission: { intitule: "" }, objet: "", lieuDeMission: {intitule:''}, dateOrdreMission: "", dateFin: "", dateDemarrage: "", structureControlee:{} };

export default function Mission() {

   const [page, setPage] = useState(1);
   const [pagination, setPagination] = useState({});
   const [missions, setMissions] = useState([]);
   const [lieus, setLieus] = useState([]);
   const [ordres, setOrdres] = useState([]);
   const [ordre, setOrdre] = useState($ordre);
   const [showModal, setShowModal] = useState(false);
   const [showDetailModal, setShowDetailModal] = useState(false);
   const [loading, setLoaging] = useState(false);
   const [structures, setStructures] = useState([]);
   const [mission,setMission] = useState({})

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle("Ordres de missions");
      init();
   }, [page])

   const setCurrentPage = (page) => {
      setPage(page);
   }
   const init = async () => {
      setLoaging(true)
      const { data: _missions } = await MissionStore.getMissions(page);
      const { data: _lieus } = await MissionStore.getLieus(page);
      const { data: ordres, pagination } = await MissionStore.getOrdres();
      const { data: _structures } = await MissionStore.getStructures();

      setMissions(_missions);
      setOrdres(ordres);
      setLieus(_lieus)
      setPagination(pagination)
      setLoaging(false)
      setStructures(_structures)

   }

   const removeOrdre = (id) => {
      if (window.confirm("Supprimer cet ordre de mission?"))
         MissionStore.removeOrdre(id).then(_ => init())
   }

   const updateOrdre = (ordre) => {
      setOrdre(ordre);
      setShowModal(true);
   }

   const showOrdre = (ordre) => {
      setOrdre(ordre);
      MissionStore.getMission(ordre.mission.id).then(data => setMission(data))
      setShowDetailModal(true);
   }

   const newOrdre = (e) => {
      e.preventDefault();
      setOrdre($ordre);
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setOrdre($ordre)
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavMission/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Ordre missions</span>
            </div>
            <h4 className="page-title">Les ordres de missions</h4>
            <button onClick={newOrdre} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer un ordre de mission</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           {/* <th>Source de la mission</th>
                           <th>Ministere ou structure</th> */}
                           <th>Référence dans le PAV</th>

                           <th>Numéro dossier</th>
                           
                           <th>Object ordre de mission</th>
                           <th>Mission</th>
                           <th>Lieu mission</th>
                           <th className="text-center">Date ordre de mission</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {ordres.map((ordre, index) =>
                           <tr key={ordre.id}>
                              {/* <td></td>
                              <td>{ordre.mission.structure && ordre.mission.structure.libelle}</td> */}
                              <td>{ordre.reference}</td>
                              <td>{ordre.numDossier}</td>
                              <td>{ordre.objet}</td>
                              <td>{ordre.mission.intitule}</td>
                              <td>{ordre.lieuDeMission !== undefined ? ordre.lieuDeMission.libelle : ''}</td>
                              <td className="text-center">{dateFormat(ordre.dateOrdreMission)}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-info" style={{ marginRight: "20px", color: "#FFFFFF" }} onClick={() => showOrdre(ordre)} title="Détails"><Eye /></button>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateOrdre(ordre)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeOrdre(ordre.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
                  <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
               </div>

            </div>
            <Modal show={showModal} title="Enregistrer une ordre" body={<Form ordre={ordre} missions={missions} lieus={lieus} structures={structures} reset={$ordre} close={onModalClose} />} onClose={onModalClose} />
            <Modal show={showDetailModal} title={ordre.reference} body={<Detail ordre={ordre} mission={mission} />} onClose={() => setShowDetailModal(false)} />

         </>
      );
   }

   return <Layout body={<Body />} />
}
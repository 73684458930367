import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../../components/biblio/Layout";
import {  Download } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { SITE_URL } from "../../../helpers/Constants";
import { confirmation, dateFormat, getAuthToken,  notify, setTitle } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import UserStore from "../../../store/UserStore";
import ShareDocument from "../../../components/biblio/share/ShareDocument";
import NotificationStore from "../../../store/NotificationStore";

import Video from "../../../components/Video";
import Audio from "../../../components/Audio";
import PDF from "../../../components/PDF";

const token = getAuthToken();

export default function Document() {

   const { id } = useParams();
   const [report, setReport] = useState({ module: {}, categorie: {}, fichier: {}, auteur: { agent: { structure: '' } } });
   // const { thisYear, years } = lastYears(2)
   // const [year, setYear] = useState('')
   const [notifications, setNotifications] = useState([])
   const [btn_password, setBtnPassword] = useState(false)

   const sugRef = useRef();

   const [title, setPageTitle] = useState("")

   const [showDocumentForm, setShowDocumentForm] = useState(false);

   useEffect(() => {
      init();
   }, [id])

   const init = async () => {
      const _report = await DocumentStore.getDocument(id);
      if(report.module)
      setPageTitle(_report.module.libelle + ": " + _report.libelle);
      setReport(_report);
      const { data } = await NotificationStore.getNotificationsByResource(id, 'rapport');
      setNotifications(data)
      setTitle(_report.module.libelle + ": " + _report.libelle)
   }


   const validate = async (doc, isVisible = false) => {
      const msg = isVisible ? "Publier pour tout le monde?" : "Publier pour les membres de ma stucture?"
      confirmation(msg, async () => {
         await DocumentStore.validateDocument(doc.id, isVisible)
         console.log(isVisible);
         DocumentStore.suggestDocument({ rapport: doc['@id'], isForStructure: !isVisible, isValidated: true }, true, doc.suggestionsRapports.id).then(response => {
            notify('Succès', "Le document a été publié.", "success");
            init()
         })
      })
   }

   const removeSuggest = async (doc) => {
      confirmation("Refuser la suggestion?", async () => {
         await DocumentStore.validateDocument(doc.id, false, false)
         DocumentStore.removeSuggest(doc.suggestionsRapports.id).then(response => {
            notify('Succès', "La suggéstion a été supprimée.", "success");
            window.location.href = "/bibliotheque/documents?showToValidated=1"
         })
      })
   }

   const removeDocument = (doc) => {
      confirmation("Supprimer ce document?", () => {

         DocumentStore.removeDocument(doc.id).then(response => {
            notify("Succès", "Document supprimé.", "success")
            window.location.href = "/bibliotheque/mes-documents";
         })
      });
   }

   const suggestDocument = async (doc) => {
      confirmation("Suggérer ce document?", () => {
         DocumentStore.suggestDocument({ rapport: doc['@id'], isForStructure: false, isValidated: false }).then(response => {
            notify('Succès', "Le document a été suggéré à votre responsable.", "success");
            //init()
            sugRef.current.style.display = "none"
         })
      });
   }

   const unValidate = async (doc) => {
      confirmation("Annuler la publication du rapport?", async () => {
         await DocumentStore.validateDocument(doc.id, false, false)
         DocumentStore.suggestDocument({ rapport: doc['@id'], isForStructure: false, isValidated: false }, true, doc.suggestionsRapports.id).then(response => {
            notify('Succès', "Le document a été dépublié.", "success");
            init()
         })
      })
   }

   const Body = () => {
      return (
         <>
            <div className="p-1 breadcrumbs xs-font">
               <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span> / Documents / {report.module.libelle}</span>
            </div>
            <h4 className="page-title">{title}</h4>
            <div className="container-fluid mt-3 mb-3">
               <div className="row">
                  <div className="col-md-9">
                     <div className="p-2 card">
                        <div className="d-flex justify-content-evenly pb-2 border-bottom">
                           <small><i className="fas fa-university"></i> Structure <br /><b className="textPrimaryDark">{report.structure !== undefined ? report.structure.intitule : ''}</b></small>
                           <small><i className="fas fa-user"></i> Auteur <br /><b className="textPrimaryDark">{report.auteur.agent.nom}</b></small>
                           <small><i className="fas fa-calendar"></i> Date d'edition <br /><b className="textPrimaryDark">{dateFormat(report.createdAt)}</b></small>
                           {(report.fichier !== undefined && report.categorie.libUnique === 'word') && <a rel="noreferrer" target="_blank" href={SITE_URL + '/doc/download/' + report.id + "?token=" + token} className="btn btn-sm btn-outline-warning" title="TELECHARGE"><Download /></a>}
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: report.description }} />
                        {report.categorie.libUnique === 'pdf' && <PDF src={SITE_URL + '/doc/download/' + report.id + "?token=" + token} />}
                        {report.categorie.libUnique === "videos" && <Video src={SITE_URL + '/doc/download/' + report.id + "?token=" + token} />}
                        {report.categorie.libUnique === "audio" && <Audio src={SITE_URL + '/doc/download/' + report.id + "?token=" + token} />}

                     </div>
                  </div>
                  <div className="col-md-3 ">
                     <div className="p-2 card shadow">

                        {/* <div className="input-group mb-3 border-bottom pb-3">
                           <select name="year" className="form-control" value={year} onChange={(e) => setYear(e.target.value)}>
                              <option value="">Par année</option>
                              <option value={thisYear}>{thisYear}</option>
                              {years.map(year => <option key={year} value={year}>{year}</option>)}
                           </select>
                           <span className="input-group-text" id="basic-addon2" onClick={() => window.location.href = "/documents?annee=" + year}><Search /></span>
                        </div> */}

{/* Url PDF : {SITE_URL + '/doc/download/' + report.id + "?token=" + token} */}

                        {(UserStore.canEditDocument(report) && report.isValidated && report.suggestionsRapports.isValidated ) &&
                           <div style={{ float: 'right', textAlign: 'center' }}>
                              <span className="badge bg-info">
                                 {report.isVisible ? 'Visible sur le site' : (!report.suggestionsRapports.isForStructure ? 'Accessible à tout le monde' : 'Accessible à tous les membres de ma structure')}
                              </span>
                           </div>
                        }

                        {(!report.isValidated && UserStore.isDocumentAuthor(report) && report.suggestionsRapports === undefined) && <button ref={sugRef} onClick={() => suggestDocument(report)} className="btn btn-success btn-sm m-1">Suggérer le document</button>}

                        {UserStore.canPublishDocument(report) && report.suggestionsRapports &&
                           <>
                              {!report.suggestionsRapports.isValidated && (
                                 <>
                                    <button onClick={() => validate(report, false)} className="btn btn-success btn-sm m-1">Publier pour ma structure</button>
                                    <button onClick={() => validate(report, true)} className="btn btn-warning btn-sm m-1">Publier pour tout le monde</button>
                                 </>
                              )}

                              {report.suggestionsRapports.isValidated && (
                                 <>
                                    {!report.suggestionsRapports.isForStructure && <button onClick={() => validate(report, false)} className="btn btn-success btn-sm m-1">Publier pour ma structure</button>}
                                    {report.suggestionsRapports.isForStructure && <button onClick={() => validate(report, true)} className="btn btn-warning btn-sm m-1">Publier pour tout le monde</button>}
                                 </>
                              )}

                              {report.isValidated && <button onClick={() => unValidate(report)} className="btn btn-danger btn-sm m-1">
                              Refuser la publication du document
                              </button>}

                              {(!report.isValidated && !UserStore.canRemoveDocument(report)) && <button onClick={() => removeSuggest(report)} className="btn btn-danger btn-sm m-1">
                                 Refuser la suggestion
                              </button>}
                           </>
                        }

                        {UserStore.canEditDocument(report) && <button onClick={() => setShowDocumentForm(true)} className="btn btn-info btn-sm m-1">Modifier</button>}
                        {UserStore.canRemoveDocument(report) && <button onClick={() => removeDocument(report)} className="btn btn-danger btn-sm m-1">Supprimer</button>}

                        <button onClick={() => setBtnPassword(!btn_password)} className="btn btn btn-sm m-1">Afficher mot de passe</button>

{btn_password && <h4 className="p-5">{report.passwordDoc}</h4>}
                     </div>
                     {UserStore.canRemoveDocument(report) &&
                        <ul className="list-group p-2 ">
                           {notifications.map(notification =>
                              <li className={`list-group-item notification-item ${!notification.lu ? 'bg-light' : 'bg-white'} p-4`} key={notification.id}>
                                 <Link className="d-flex">
                                    <div className="ps-2">
                                       <div>{/* <b>{notification.expediteur}</b> */}{notification.contenu}</div>
                                    </div>
                                 </Link>
                              </li>
                           )}
                        </ul>}
                  </div>
               </div>
            </div>
            <Modal show={showDocumentForm} onClose={() => setShowDocumentForm(false)} body={<ShareDocument _document={report} type={report.module.libUnique==="rapports-proteges"?'rapport':'document'} onClose={() => { setShowDocumentForm(false); init() }} />} title={`Modifier le document: ${report.libelle}`} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
import {Link, useLocation} from "react-router-dom";
import {activeLink} from "../../../helpers/Helper";

export default function Nav() {
    const location = useLocation();
    return (
        <div className="filterArea bg-white">
            <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">


                <div className="p-1 d-flex bd-highlight hide4mobile">
                    <ul className="searchCategory">
                        <li>
                            <a className={activeLink(location.pathname, '/bibliotheque/missions/structures')} href="https://adminbibliotheque.eptechbenin.com/structures">STRUCTURES</a>
                        </li>
                        <li>
                            <a className={activeLink(location.pathname, '/bibliotheque/missions/agents')} href="https://adminbibliotheque.eptechbenin.com/agent">AGENTS</a>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/autres-listes')} to="/bibliotheque/missions/autres-listes">AUTRES LISTES</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/liste-coso')} to="/bibliotheque/missions/composantes-cosos">LISTE COSO</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/missions')} to="/bibliotheque/missions/missions">PROGRAMMATIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/ordres')} to="/bibliotheque/missions/ordres">ORDRES MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/rapports')} to="/bibliotheque/missions/rapports">RAPPORTS MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/recommandations')} to="/bibliotheque/missions/recommandations">RECOMMANDATIONS RAPPORTS</Link>
                        </li>

                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/avis-conseil-ministre')} to="/bibliotheque/missions/avis-conseil-ministre">AVIS CONSEIL MINISTRE</Link>
                        </li>

                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/rapport-igm')} to="/bibliotheque/missions/rapport-igm">RAPPORTS IGM</Link>
                        </li>


                    </ul>
                </div>
            </div>
        </div>
    );
}
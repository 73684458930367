import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Detail from "../../../components/biblio/missions/RecommandationDetail";
import Form from "../../../components/biblio/missions/RecommandationForm";
import Nav from "../../../components/biblio/missions/Nav";
import { Eye, Pencil, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import MissionStore from "../../../store/MissionStore";
import { setTitle } from "../../../helpers/Helper";
import Pagination from "../../../components/Pagination";
import UserStore from "../../../store/UserStore";
import CosoStore from "../../../store/CosoStore";
import { SITE_URL, USER_ROLES } from "../../../helpers/Constants";


const $recommandation = { rapport: { odreMission: {} }, montantRembourse: "", responsableMiseEnOeuvre: "", difficulteRencontre: "", commentaire: "", domaineRemboursementMajeur: {}, libelleRecommandation: "", observation: "", responsableSuivi: "", nivMiseEnOeuvre: {}, elementPreuve: "", nivMiseEnOeuvreAnterieur: {}, composanteCoso: {}, principeCoso: {}, controleCoso: {} };

export default function Mission() {

   const [page, setPage] = useState(1);
   const [pagination, setPagination] = useState({});
   const [rapports, setRapports] = useState([]);
   const [controleCosos, setControlCosos] = useState([]);
   const [principeCosos, setPrincipeCosos] = useState([]);
   const [composanteCosos, setComposanteCosos] = useState([]);
   const [domaines, setDomaines] = useState([]);
   const [niveaus, setNiveaus] = useState([]);
   const [recommandations, setRecommandations] = useState([]);
   const [recommandation, setRecommandation] = useState($recommandation);
   const [showModal, setShowModal] = useState(false);
   const [showDetailModal, setShowDetailModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle("Recommandations de rapports missions");
      init();
   }, [page])

   const setCurrentPage = (page) => {
      setPage(page);
   }
   const init = async () => {
      setLoaging(true)
      const { data: _rapports } = await MissionStore.getRapports();
      const { data: _recommandations, pagination } = await MissionStore.getRecommandations(page);

      const { data: _domaines } = await MissionStore.getDomaines();
      const { data: _niveaus } = await MissionStore.getNiveaus();

      const { data: _principeCoso } = await CosoStore.getPrincipes();
      const { data: _composanteCoso } = await CosoStore.getComposantes();
      const { data: _controleCoso } = await CosoStore.getControles();

      setComposanteCosos(_composanteCoso);
      setPrincipeCosos(_principeCoso);
      setControlCosos(_controleCoso)

      setNiveaus(_niveaus)
      setDomaines(_domaines)

      setRapports(_rapports);
      setRecommandations(_recommandations);
      setPagination(pagination)
      setLoaging(false)
   }

   const removeRecommandation = (id) => {
      if (window.confirm("Supprimer cette recommandation de rapport de mission?"))
         MissionStore.removeRecommandation(id).then(_ => init())
   }

   const updateRecommandation = (recommandation) => {
      setRecommandation(recommandation);
      setShowModal(true);
   }

   const showRecommandation = (recommandation) => {
      setRecommandation(recommandation);
      setShowDetailModal(true);
   }

   const newRecommandation = (e) => {
      e.preventDefault();
      setRecommandation($recommandation);
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setRecommandation($recommandation)
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <div className="p-1 breadcrumbs xs-font">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Recommandations rapports</span>
            </div>
            <h4 className="page-title">Les recommandations de rapports missions</h4>
            <button onClick={newRecommandation} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer une recommandation de rapport</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Le rapport</th>
                           <th>Libellé recommandation</th>
                           <th>Responsable suivi</th>
                           <th>Responsable mise en oeuvre </th>
                           <th className="text-center">Montant remboursé</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {recommandations.map((recommandation, index) =>
                           <tr key={recommandation.id}>
                              <td>{index + 1}</td>
                              <td>{recommandation.rapport.odreMission.objet}</td>
                              <td>{recommandation.libelleRecommandation}</td>
                              <td>{recommandation.responsableSuivi}</td>
                              <td>{recommandation.responsableMiseEnOeuvre}</td>
                              <td className="text-center">{recommandation.montantRembourse}</td>
                              <td>
                                 {recommandation.fichier !== undefined && <a style={{ marginRight: "20px", color: "#FFFFFF" }} rel="noreferrer" className="btn btn-sm btn-secondary" href={`${SITE_URL}${recommandation.fichier.contentUrl}`} target="_blank"><i className="fa fa-download"></i></a>}
                                 <button type="button" className="btn btn-sm btn-info" style={{ marginRight: "20px", color: "#FFFFFF" }} onClick={() => showRecommandation(recommandation)} title="Détails"><Eye /></button>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateRecommandation(recommandation)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeRecommandation(recommandation.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
                  <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
               </div>

            </div>
            <Modal show={showModal} title="Enregistrer une recommandation" body={<Form recommandation={recommandation} composanteCosos={composanteCosos} principeCosos={principeCosos} controleCosos={controleCosos} niveaus={niveaus} domaines={domaines} rapports={rapports} reset={$recommandation} onClose={onModalClose} />} onClose={onModalClose} />
            <Modal show={showDetailModal} title={recommandation.libelleRecommandation} body={<Detail recommandation={recommandation} />} onClose={() => setShowDetailModal(false)} />

         </>
      );
   }

   return <Layout body={<Body />} />
}
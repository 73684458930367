import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import { SITE_URL } from "../../helpers/Constants";
import { notify } from "../../helpers/Helper";
import UserStore from "../../store/UserStore";
import Password from "./Password";

export default function Account() {

   const [currentTab, setCurrentTab] = useState('profile');
   const [user, setUser] = useState(UserStore.state.currentUser);
   const fileRef = useRef()
   const formRef = useRef()

   const setActiveTab = (tab) => {
      setCurrentTab(tab);
   }

   const inputFileChange = async (e) => {
      const value = e.target.value;
      if (value !== "") {
         const formData = new FormData(formRef.current);
         if (formData.get('file').name !== "") {
            const data = await UserStore.updateAvatar(formData);
            UserStore.updateAgent(user.agent.id, { avatar: data['@id'] }).then(response => {
               setUser({ ...user, agent: response.data })
               notify('Succès', 'Photo modifié.', 'success');
            });
         }
      }
   }

   const Body = () => {
      return (

         <div className="content-body">
            <div className="p-1 breadcrumbs xs-font">
               <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Mon compte</span>
            </div>
            <h4 className="page-title">Mon compte</h4>


            <div className="container mt-3">
               <div className="card p-3">
                  <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                     <li className="nav-item" role="presentation">
                        <button onClick={() => setActiveTab('profile')} className={(currentTab === 'profile') ? 'nav-link w-100 active' : 'nav-link w-100'} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="home" aria-selected="true">Mon profil
                        </button>
                     </li>
                     <li className="nav-item" role="presentation">
                        <button onClick={() => setActiveTab('securite')} className={(currentTab === 'securite') ? 'nav-link w-100 active' : 'nav-link w-100'} id="securite-tab" data-bs-toggle="tab" data-bs-target="#securite" type="button" role="tab" aria-controls="profile" aria-selected="false">Changer mot de passe
                        </button>
                     </li>

                  </ul>
                  <div className="tab-content" id="myTabContent">
                     <div className={(currentTab === 'profile') ? 'tab-pane fade show active' : 'tab-pane fade'} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="card profileTop">
                           <div className=" row">
                              <div className="col-sm-12 col-md-6">
                                 <div className="d-flex card-head mt-5">
                                    <div className="profilePic">
                                       {user.agent.avatar !== null && <img src={SITE_URL + user.agent.avatar.contentUrl} alt={user.agent.nom} className="rounded-circle img-fluid shadow-sm" />}
                                    </div>
                                    <div className="userInfo">
                                       <h4 className="text-white uppercase">{user.agent.nom + ' ' + user.agent.prenom}</h4>
                                       <h6 className="text-white">{user.agent.qualite.libelle}</h6>
                                       <form encType="multipart/form-data" ref={formRef}>
                                          <input type="file" name="file" className="hidden" ref={fileRef} accept="image/*" onChange={inputFileChange} />
                                          <button type="button" className="btn btn-outline-warning btn-sm" onClick={() => fileRef.current.click()}>Changer Photo</button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                 <div className="otherInfo p-5 mt-5">
                                    <div className="text-white">Stucture: <b>{user.agent.structure && user.agent.structure.intitule}</b></div> <br />
                                    <div className="text-white">Email: <b>{user.email}</b></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className={(currentTab === 'securite') ? 'tab-pane fade show active' : 'tab-pane fade'} id="securite" role="tabpanel" aria-labelledby="securite-tab">
                        <Password />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
   }

   return <Layout body={<Body />} />
}
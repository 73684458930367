import { dateFormat } from "../../../helpers/Helper";

const Detail = ({ ordre, mission }) => {

   return (
      <div className="row">

         {mission.id && <div className="row">
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>N° d’ordre ou référence</h6>
                  <p>{mission.refPav}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Nature de la mission</h6>
                  <p>{mission.nature.libelle}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Structure</h6>
                  <p>{mission.structure && mission.structure.intitule}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Intitulé de la mission</h6>
                  <p>{mission.intitule}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Objectifs de la mission</h6>
                  <p>{mission.objectif}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Résultats attendus</h6>
                  <p>{mission.resultatAttendu}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Période d’exécution</h6>
                  <p>{mission.periodeExecution}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Nombre de jours calendaires</h6>
                  <p>{mission.nbJourCalendaire}</p>
               </div>
            </div>
         </div>}

         <div className="row">
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>N° d’ordre ou référence</h6>
                  <p>{ordre.reference}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Mission</h6>
                  <p>{ordre.mission.intitule}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Numéro de dossier</h6>
                  <p>{ordre.numDossier}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Objet de l'ordre</h6>
                  <p>{ordre.objet}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Structure Controlée</h6>
                  <p>{ordre.structureControlee && ordre.structureControlee.abrege}</p>
               </div>
            </div>

            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Lieu de la mission</h6>
                  <p>{ordre.lieuDeMission !== undefined ? ordre.lieuDeMission.libelle : ''}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Date de l'ordre de mission</h6>
                  <p>{dateFormat(ordre.dateOrdreMission)}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Date de démarrage</h6>
                  <p>{dateFormat(ordre.dateDemarrage)}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Date de fin</h6>
                  <p>{dateFormat(ordre.dateFin)}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Numéro du véhicule</h6>
                  <p>{ordre.numeroVehicule}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Délais de prolongation de la mission</h6>
                  <p>{ordre.delaisProlongation}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="card p-2 mb-2 mt-2">
                  <h6>Le nom du CVA</h6>
                  <p>{ordre.nomCva}</p>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Detail;
import { useEffect, useState } from "react";
import { dateFormat, notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import { Save } from "../../Icons";
import Step from "../Step";

const Form = ({ rapport, ordres, reset, close }) => {

   const [current, setCurrent] = useState(rapport);
   const [index, setIndex] = useState(1);

   useEffect(() => {
      setCurrent({ ...current, dateEnvoieFinalSv: dateFormat(new Date(current.dateEnvoieFinalSv), 'YYYY-MM-DDTHH:mm'), dateDepotSp: dateFormat(new Date(current.dateEnvoieFinalSv), 'YYYY-MM-DDTHH:mm') })
   }, [])

   const handleChange = (e) => {
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      setCurrent({ ...current, [target.name]: value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      let rapport = { ...current, delaisProlongation: +current.delaisProlongation, numRapportSp: +current.numRapportSp }
      if (rapport.odreMission['@id'])
         rapport = { ...rapport, odreMission: rapport.odreMission['@id'] }
    
      MissionStore.saveRapport(rapport).then(_ => {
         notify("Succès", "Le rapport de mission a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close();

      }).catch(err => notify("Erreur", "Certains champs du formulaire ne sont pas correctement renseignés.", "danger"));
   }

   return (
      <form onSubmit={handleSubmit}>

         <Step size={3} index={index} />

         <div className={index === 1 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>L'ordre de mission <small className="fw-bold">[obligatoire]</small></span>
                  <select className="form-control" name="odreMission" onChange={handleChange} value={rapport.odreMission['@id']} >
                     <option value="">Sélectionner un ordre de mission</option>
                     {ordres.map(ordre => <option key={ordre.id} value={ordre['@id']}>{ordre.reference}</option>)}
                  </select>
               </div>
            </div>
            
            <div className="mb-3">
               <div className="form-group">
                  <span>L'intitulé du rapport de mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="text" name="intitule" className="form-control" value={current.intitule} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Numéro de rapport <small className="fw-bold">[obligatoire]</small></span>
                  <input type="number" name="numRapportSp" className="form-control" value={current.numRapportSp} onChange={handleChange} />
               </div>
            </div>
            <button type="button" disabled={current.odreMission.intitule=="" ||current.odreMission=="" || !current.numRapportSp } onClick={() => setIndex(2)} className="btn btn-primary mx-auto d-block">Continuer</button>
         </div>
         <div className={index === 2 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>Date de dépôt du rapport chez SP</span>
                  <input type="datetime-local" name="dateDepotSp" className="form-control" value={current.dateDepotSp} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Date envoi rapport final aux structures vérifiées</span>
                  <input type="datetime-local" name="dateEnvoieFinalSv" className="form-control" value={current.dateEnvoieFinalSv} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Montant du budget contrôlé</span>
                  <input type="number" name="montantBudgetController" className="form-control" value={current.montantBudgetController} onChange={handleChange} />
               </div>
            </div>
            <button type="button" onClick={() => setIndex(1)} className="btn btn-default mx-auto d-block" style={{ width: '20%', float: "left" }}>Retour</button>
            <button type="button" disabled={current.dateDepotSp  =="Invalid Date" || current.dateEnvoieFinalSv  =="Invalid Date" || !current.montantBudgetController }  onClick={() => setIndex(3)} className="btn btn-secondary mx-auto d-block" style={{ width: '20%', float: "right" }}>Continuer</button>
         </div>
         <div className={index === 3 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>Le montant en cause</span>
                  <input type="number" name="montantEnCause" className="form-control" value={current.montantEnCause} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Le montant à rembourser</span>
                  <input type="number" name="montantArembourser" className="form-control" value={current.montantArembourser} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <input type="checkbox" name="planRecommandation" value={current.planRecommandation} onChange={handleChange} />
                  <em style={{ paddingLeft: '5px' }}>Existence d'un plan de mise en œuvre des recommandations</em>
               </div>
            </div>
            <button type="button" onClick={() => setIndex(2)} className="btn btn-default mx-auto d-block" style={{ width: '20%', float: "left" }}>Retour</button>
            <button type="submit" disabled={!current.montantEnCause || !current.montantArembourser } className="btn btn-warning mx-auto d-block" style={{ width: '20%', float: "right" }}><Save /> Enregister</button>
         </div>

      </form>
   );
}

export default Form;
import React, {useEffect, useState} from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import {Pencil, Save, Trash} from "../../../components/Icons";
import Modal from "../../../components/Modal";
import {USER_ROLES} from "../../../helpers/Constants";
import {dateFormat, extract, notify} from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";
import Pagination from "../../../components/Pagination";

const Form = ({rapportIgm, reset, close, user, annees, ordres, structures}) => {

    rapportIgm['auteur'] = user['@id']
    rapportIgm['structure'] = "/api/structures/" + user.agent.structure.id

    const [current, setCurrent] = useState(rapportIgm);

    const handleChange = (e) => {
        setCurrent({...current, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        current['numOrdre']= +current.numOrdre;
        current['numRapport']= +current.numRapport;
        MissionStore.saveRapportIgm(current).then(_ => {
            notify("Succès", "Rapport IGM enregistré.", "success");
            setCurrent(reset);
            e.target.reset();
            close()

        })
    }

    return (
        <form onSubmit={handleSubmit}>

            <div className="mb-3">
                <div className="form-group">
                    <span>Numéro d'ordre <small className="fw-bold">[obligatoire]</small></span>
                    <input type="number" name="numOrdre" className="form-control" value={current.numOrdre} onChange={handleChange} required placeholder="numéro d'ordre"/>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Numéro rapport <small className="fw-bold">[obligatoire]</small></span>
                    <input type="number" name="numRapport" className="form-control" value={current.numRapport} onChange={handleChange} required placeholder="numéro rapport"/>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Ordre de mission </span>
                    <select name="ordreMission" className="form-control" value={current.ordreMission} onChange={handleChange}>
                        <option value="">selectionner un ordre de mission</option>
                        {ordres.map(ordre => <option value={ordre['@id']}>{ordre.reference} - {ordre.objet}</option>)}
                    </select>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Structure contrôlée </span>
                    <select name="structureControllee" className="form-control" value={current.structureControllee} onChange={handleChange}>
                        <option value="">selectionner une structure</option>
                        {structures.map(structure => <option value={structure['@id']}>{extract(structure.intitule)}</option>)}
                    </select>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Année du rapport </span>
                    <select name="anneRapport" className="form-control" value={current.anneRapport} onChange={handleChange}>
                        <option value="">selectionner une année</option>
                        {annees.map(annee => <option value={annee['@id']}>{annee.libelle}</option>)}
                    </select>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Date transmission à l'IGF</span>
                    <input type="date" name="datetransmissionIgf" className="form-control" value={current.datetransmissionIgf} onChange={handleChange}/>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Année de rattachement </span>
                    <select name="anneeRatachement" className="form-control" value={current.anneeRatachement} onChange={handleChange}>
                        <option value="">selectionner une année</option>
                        {annees.map(annee => <option value={annee['@id']}>{annee.libelle}</option>)}
                    </select>
                </div>
            </div>
            <div className="mb-3">
                <div className="form-group">
                    <span>Année de transmission </span>
                    <select name="AnneeTransmission" className="form-control" value={current.AnneeTransmission} onChange={handleChange}>
                        <option value="">selectionner une année</option>
                        {annees.map(annee => <option value={annee['@id']}>{annee.libelle}</option>)}
                    </select>
                </div>
            </div>
            <button type="submit" style={{width: '100%'}} className="btn btn-warning mx-auto d-block"><Save/> Enregister
            </button>
        </form>
    )
        ;
}
const resetUser = {
    numOrdre: "",
    numRapport: "",
    structure: "",
    anneRapport: "",
    datetransmissionIgf: "",
    anneeRatachement: "",
    AnneeTransmission: "",
    ordreMission: "",
    auteur: "",
    structureControllee: ""
};
export default function RapportIgm() {
    const user = UserStore.state.currentUser;
    const [rapportIgms, setRapportIgms] = useState([]);
    const [rapportIgm, setRapportIgm] = useState(resetUser);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoaging] = useState(false);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const [ordres, setOrdres] = useState([]);
    const [structures, setStructures] = useState([]);
    const [annees, setAnnees] = useState([]);

    useEffect(() => {
        if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
            window.location.href = "/bibliotheque";
        }
        init();
    }, [page])

    const init = async () => {
        setLoaging(true)
        const {data: _rapportIgm, pagination} = await MissionStore.getRapportsImg(page);
        const {data: _annees} = await MissionStore.getAnnees(page);
        const {data: ordres} = await MissionStore.getOrdres();
        const {data: _structures} = await MissionStore.getStructures();
        console.table(_rapportIgm);
        setRapportIgms(_rapportIgm);
        setStructures(_structures)
        setAnnees(_annees)
        setOrdres(ordres);
        setLoaging(false)
        setPagination(pagination)
    }

    const removeRapportIgm = (id) => {
        if (window.confirm("Supprimer ce rapport?"))
            MissionStore.removeRapportIgm(id).then(_ => init())
    }

    const updateRapportIgm = (rapportIgm) => {
        setRapportIgm(rapportIgm);
        setShowModal(true);
    }

    const newRapportIgm = (e) => {
        e.preventDefault();
        setRapportIgm(resetUser);
        setShowModal(true);
    }

    const onModalClose = () => {
        init();
        setRapportIgm(resetUser)
        setShowModal(false);
    }

    const setCurrentPage = (page) => {
        setPage(page);
    }

    const Body = () => {
        return (
            <>
                <Nav/>

                <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
                    <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a>
                    <span>/ Rapports IGM</span>
                </div>
                <h4 className="page-title">Les rapports IGM</h4>
                <button onClick={newRapportIgm} className="btn btn-primary" style={{
                    float: "right",
                    marginRight: "30px"
                }}>Créer un rapport IGM
                </button>
                <div className="container-fluid mt-4 mb-5">
                    <div className="card p-3">
                        <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Numéro ordre </th>
                                <th>Numéro rapport</th>
                                <th>Ordre de mission</th>
                                <th>Structure contrôlée</th>
                                <th>Année du rapport</th>
                                <th>Date de transmission</th>
                                <th>Année de rattachement</th>
                                <th className="text-center"/>
                            </tr>
                            </thead>
                            <tbody>
                            {rapportIgms.map((rapportIgm, index) =>
                                <tr key={rapportIgm.id}>
                                    <td>{index + 1}</td>
                                    <td>{rapportIgm.numOrdre}</td>
                                    <td>{rapportIgm.numRapport}</td>
                                    <td>{rapportIgm.ordreMission && rapportIgm.ordreMission.reference}</td>
                                    <td>{rapportIgm.structure && rapportIgm.structure.intitule}</td>
                                    <td>{rapportIgm.anneRapport && rapportIgm.anneRapport.libelle}</td>
                                    <td>{dateFormat(rapportIgm.datetransmissionIgf)}</td>
                                    <td>{rapportIgm.anneeRatachement && rapportIgm.anneeRatachement.libelle}</td>
                                    <td>
                                        <button type="button" className="btn btn-sm btn-success" style={{marginRight: "20px"}} onClick={() => updateRapportIgm(rapportIgm)} title="Modifier">
                                            <Pencil/></button>
                                        <button type="button" className="btn btn-sm btn-danger" onClick={() => removeRapportIgm(rapportIgm.id)} title="Supprimer">
                                            <Trash/></button>
                                    </td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                        <Pagination pagination={pagination} setCurrentPage={setCurrentPage}/>
                    </div>

                </div>
                <Modal size="modal-lg"
                       show={showModal}
                       title="Enregistrer un rapport IGM"
                       body={
                           <Form rapportIgm={rapportIgm} reset={{libelle: ""}} close={onModalClose} user={user} annees={annees} structures={structures} ordres={ordres}/>}
                       onClose={onModalClose}
                />
            </>
        );
    }

    return <Layout body={<Body/>}/>
}
import httpClient from "./HttpClient";

const END_POINT = "/video_youtubes";

const YoutubeStore = {

   state: {
      videos: [],
   },

   async getAll(page = 1) {
      if (!this.state.videos.length) {
         try {
            const response = await httpClient.get(END_POINT + `?page=${page}&itemsPerPage=9`);
            const data = await response.data['hydra:member'];
            const pagination = await response.data['hydra:view'];
            return { data, pagination };
         } catch (e) {
            return { data: [], pagination: {} }
         }
      }
      return this.state.structures;
   },

   async get(id) {
      try {
         const response = await httpClient.get(END_POINT + "/" + id);
         const data = await response.data;
         return data;
      } catch (e) {
         console.error(e);
      }
   },

}

export default YoutubeStore;
import React, { useEffect, useRef, useState } from 'react';
import {  useParams } from 'react-router-dom';
import Layout from "../../../components/front/Layout";
import { setTitle } from '../../../helpers/Helper';
import { Filter } from "../../../components/Icons";
import DocumentStore from '../../../store/DocumentStore';
import Pagination from '../../../components/Pagination';
import Document from "../../../components/front/Document";


export default function Documents() {

   const { moduleLibUnique , parent} = useParams();
   const [page, setPage] = useState(1)
   const [documents, setDocuments] = useState([]);
   const [pagination, setPagination] = useState({});
   const [pageTitle, setPageTitle] = useState('Les documents')
   const [current_module_document, setCurrentModuleDocument] = useState(null)

   const startRef = useRef('')
   const endRef = useRef('')

   useEffect(() => {
      findDocuments();
   }, [moduleLibUnique, page])

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const findDocuments = async () => {
      const _module = await DocumentStore.findModuleByLibUnique(moduleLibUnique);
      const { data, pagination } = await DocumentStore.getDocuments(moduleLibUnique, page);
      setPagination(pagination)
      if (_module !== undefined) {
         setPageTitle(`Les documents - ${_module.libelle} `);
         setTitle(`Les documents - ${_module.libelle} `);
         setCurrentModuleDocument(_module)
      }

      setDocuments(data);
   }

   const filter = async () => {
      const start = startRef.current.value;
      const end = endRef.current.value;
      if (start !== "" && end !== "") {
         const { data, pagination } = await DocumentStore.getByDateBetween(start, end, moduleLibUnique, 1);
         setDocuments(data);
         setPagination(pagination)
      }

   }

   const Body = () => {
      return (
         <>
         
            <div className="page-info">
               <h2 className="text-center text-white"> {pageTitle}</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase">Documentations / </span>
                     <span className="text-uppercase">{current_module_document && current_module_document.libelle}</span>
                  </div>
               </div>
            </div>
            <div className="container-fluid mb-4 page-content p-0">

               <div className="container documentDetail">
                  <div className="row">
                     <div className="col-md-9">
                        {documents.map((doc, index) => <Document key={index} doc={doc} />)}
                        {!documents.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun document disponible.</h2>}
                        <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
                     </div>
                     <div className="col-md-3">
                        <div className="card shadow-sm p-3 bg-light">
                           <h6 className="mb-2">Filtrer par période</h6>
                           <div className="input-group input-daterange">
                              <input type="number" className="form-control" ref={startRef} name="start" />
                              <div className="input-group-addon p-2">à</div>
                              <input type="number" className="form-control" ref={endRef} name="end" />
                           </div>
                           <div className="text-center mt-3">
                              <button onClick={filter} className="btn btn-danger" title="Cliquez pour filtrer/éinitialiser la liste."><Filter size={30} /></button>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </>
      );
   }

   return <Layout body={<Body />} />
}


const Detail = ({ recommandation }) => {

   return (
      <div className="row">
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le rapport</h6>
               <p>{recommandation.rapport.odreMission.objet}</p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Libellé de la recommandation </h6>
               <p>{recommandation.libelleRecommandation}</p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Observations</h6>
               <p dangerouslySetInnerHTML={{ __html: recommandation.observation }} />
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Personne responsable</h6>
               <p>{recommandation.responsableMiseEnOeuvre}</p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le niveau de mise en oeuvre du rapport</h6>
               <p>{recommandation.niveauMiseEnOeuvre}</p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Les éléments de preuve</h6>
               <p dangerouslySetInnerHTML={{ __html: recommandation.elementPreuve }} />
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le montant remboursé</h6>
               <p>{recommandation.montantRembourse}</p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Le domaine de remboursement majeur</h6>
               <p>{recommandation.domaineRemboursementMajeur.libelle}</p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Les difficultés rencontrées</h6>
               <p dangerouslySetInnerHTML={{ __html: recommandation.difficulteRencontre }} />
            </div>
         </div>
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Les commentaires</h6>
               <p dangerouslySetInnerHTML={{ __html: recommandation.commentaire }} />
            </div>
         </div>
      </div>
   );
}

export default Detail;
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import {
  dateFormat,
  getAuthToken,
  notify,
  setTitle,
} from "../../helpers/Helper";
import StructureStrore from "../../store/StructureStore";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import { SITE_URL, USER_ROLES } from "../../helpers/Constants";
import PDF from "../../components/PDF";
import UserStore from "../../store/UserStore";

export default function RapportPartageDetail() {
  const { rapportId } = useParams();
  const [rapport, setRapport] = useState(null);
  const [observations, setObservations] = useState([]);
  const [structures, setStructures] = useState([]);
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [partage, setPartage] = useState({});
  const [showViewer, setShowViewer] = useState(false);
  const [docView, setDocView] = useState("");
  const [isIgf, setisIgf] = useState(false);
  const [showInvitPanel, setShowInvitPanel] = useState(false);
  const [structuresAll, setStructuresAll] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const search = useLocation().search;
  const status = new URLSearchParams(search).get("status");

  setTitle("RAPPORTS DE SUIVI ET RECOMMANDATION");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let data = [];
    let st,
      stAll = [];
    try {
      data = !status
        ? await StructureStrore.getRapportsRecusDetail(rapportId)
        : await StructureStrore.getPartageRapportRecusDetail(rapportId);
      st = await StructureStrore.getIsSupervisionAll();
      const current_user = await UserStore.getCurrentUser();
      stAll = await StructureStrore.getAll();
      if (current_user.agent) {
        setisIgf(current_user.agent.structure.isIgf);
      }
    } catch (error) {
    } finally {
      setRapport(data);
      setStructures(st);
      setStructuresAll(stAll);
      getObservationRapport(rapportId);
      setPartage({ rapport: `/api/suivi_rapports/${rapportId}` });
    }
  };

  const getObservationRapport = async (rapport) => {
    let { data } = await StructureStrore.getObservationRapport(rapport);
    setObservations(data);
  };

  const shareRapport = async () => {
    try {
      await StructureStrore.shareRapport(partage);
    } catch (error) {
    } finally {
      notify(
        "Succès",
        "Les recommandations sont transmises avec succès !",
        "success"
      );
      setShowSharePanel(false);
      init();
    }
  };

  const token = getAuthToken();

  const readDoc = (doc) => {
    if (doc) {
      setDocView(doc);
      setShowViewer(true);
    }
  };

  const Body = () => {
    return (
      <>
        <div className="p-1 breadcrumbs xs-font">
          <Link to="/bibliotheque" className="textPrimary fw-bold">
            Accueil
          </Link>
          <Link to="/bibliotheque/rapports-suivis-recommandation-partage">
            / Rapports de suivi et recommandations partagées
          </Link>
          {rapport && <span>/ {rapport.libelle}</span>}
        </div>

        <div className="content-body">
          <h4 className="page-title">
            Rapport partagé: {rapport && rapport.libelle}{" "}
          </h4>
          <div className="container-fluid mt-3 mb-3">
            <div className="row mt-3 p-3">
              <div className="card mb-2  ">
                <div className="card-body ">
                  <div className=" pull-right">
                    {showPassword && (
                      <>
                        {" "}
                        <span>
                          RAPPORT :{" "}
                          <b> {rapport && rapport.passwordRapport} </b>
                        </span>
                        |{" "}
                        <span>
                          SYNTHESE:{" "}
                          <b>{rapport && rapport.passwordSynthese} </b>
                        </span>{" "}
                      </>
                    )}{" "}
                    <button
                      className="btn  m-1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      AFFICHER MOTS DE PASSE DES FICHIERS
                    </button>{" "}
                    {UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && (
                      <button
                        className="btn btn-info m-1"
                        onClick={() => {
                          setShowSharePanel(!showSharePanel);
                          setShowInvitPanel(false);
                        }}
                      >
                        TRANSMETTRE CE RAPPORT
                      </button>
                    )}
                    {isIgf &&
                      UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && (
                        <button
                          className="btn btn-warning m-1"
                          onClick={() => {
                            setShowInvitPanel(!showInvitPanel);
                            setShowSharePanel(false);
                          }}
                        >
                          INVITER UNE STRUCTURE
                        </button>
                      )}{" "}
                    {rapport && rapport.fichier && (
                      <button
                        className="btn text-uppercase btn-success m-1"
                        type="button"
                        onClick={() =>
                          readDoc(
                            SITE_URL +
                              "/suivi-rapport/synthese/download/" +
                              rapport.id +
                              "?token=" +
                              token
                          )
                        }
                      >
                        Lire la synthèse
                      </button>
                    )}
                    {rapport && rapport.fichier && (
                      <button
                        className="btn text-uppercase btn-info m-1"
                        type="button"
                        onClick={() =>
                          readDoc(
                            SITE_URL +
                              "/suivi-rapport/doc/download/" +
                              rapport.id +
                              "?token=" +
                              token
                          )
                        }
                      >
                        Lire rapport
                      </button>
                    )}
                  </div>
                </div>
              </div>

              {showInvitPanel && (
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <h5 className="card-title">
                      Invitation d'une structure à lire le rapport{" "}
                      {rapport && rapport.libelle}{" "}
                    </h5>
                    <select
                      className="form-control m-3"
                      value={partage.structure}
                      onChange={(e) => {
                        setPartage({ ...partage, structure: e.target.value });
                      }}
                    >
                      <option value={null}>--Choisir une structure---</option>
                      {structuresAll.map((s) => (
                        <option key={s.id} value={`/api/structures/${s.id}`}>
                          {s.intitule}
                        </option>
                      ))}
                    </select>

                    <button
                      className="btn btn-warning m-1"
                      onClick={() => shareRapport()}
                    >
                      INVITER
                    </button>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => setShowSharePanel(false)}
                    >
                      ANNULER
                    </button>
                  </div>
                </div>
              )}

              {showSharePanel && (
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <h5 className="card-title">
                      Transmission du rapport {rapport && rapport.libelle}{" "}
                    </h5>
                    <select
                      className="form-control m-3"
                      value={partage.structure}
                      onChange={(e) => {
                        setPartage({ ...partage, structure: e.target.value });
                      }}
                    >
                      <option value={null}>--Choisir une structure---</option>
                      {structures.map((s) => (
                        <option key={s.id} value={`/api/structures/${s.id}`}>
                          {s.intitule}
                        </option>
                      ))}
                    </select>

                    <button
                      className="btn btn-warning m-1"
                      onClick={() => shareRapport()}
                    >
                      TRANSMETTRE
                    </button>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => setShowSharePanel(false)}
                    >
                      ANNULER
                    </button>
                  </div>
                </div>
              )}

              <div className="card mb-2 col-8  ">
                <div className="card-body ">
                  <h5 className="card-title">Liste des recommandations</h5>

                  <table className="table thead-dark table-striped table-bordered p-5">
                    <thead
                      style={{
                        color: "#fff",
                      }}
                      className="bckColorDark"
                    >
                      <tr className="  ">
                        <th>N°</th>
                        <th title="Description">Recommandation</th>
                        <th title="Personne responsable">Responsable</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rapport &&
                        rapport.suiviRecRaports.map((suivi, index) => (
                          <tr>
                            <td className="p-3">{index + 1}</td>
                            <td className="p-3">
                              <b>
                                <Link
                                  to={`/bibliotheque/rapports-suivis-recommandation-partage-detail-recommandation/${suivi.id}`}
                                >
                                  {" "}
                                  {suivi.libelle}
                                </Link>
                              </b>{" "}
                            </td>
                            <td>{suivi.agentSuivi && suivi.agentSuivi.nom}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="card mb-2  col-4 ">
                <div className="card-body  ">
                  <h5> Commentaires - Notifications</h5>
                  <ul className="list-group list-group-flush">
                    {observations.map((o) => (
                      <li className="list-group-item " key={o.id}>
                        {dateFormat(o.createdAt, "DD/MM/YYYY à HH:mm")} :{" "}
                        {o.observation}{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          large
          size="lg"
          show={showViewer && docView}
          title="Lecture du Document"
          body={<PDF src={docView} />}
          onClose={() => setShowViewer(false)}
        />
      </>
    );
  };
  return <Layout body={<Body />} />;
}

import create from "zustand";

const SharedStore = create(set => ({
    sideNavClass: 'hide4mobile',
    showSideBar: false,
    showModal: false,
    notificationCount: 0,
    setShowSideBar: (show) => set(state => ({ showSideBar: show })),
    setSideNavClass: (className) => set(state => ({ sideNavClass: className })),
    setShowModal: (show) => set(state => ({ showModal: show })),
    setNotificationCount: (count) => set(state => ({ notificationCount: count }))
}));

export default SharedStore;

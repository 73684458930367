import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Audio from "../../../components/Audio";
import Layout from "../../../components/biblio/Layout";
import { Search, Download } from "../../../components/Icons";
import PDF from "../../../components/PDF";
import Video from "../../../components/Video";
import { SITE_URL } from "../../../helpers/Constants";
import { confirmation, dateFormat, getAuthToken, lastYears, notify } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import UserStore from "../../../store/UserStore";

const token = getAuthToken();

export default function Report() {
   const { id } = useParams();
   const [report, setReport] = useState({ odreMission: {}, auteur: { agent: { structure: '' } } });
   // const [loading, setLoading] = false
   // const { thisYear, years } = lastYears(2)
   // const [year, setYear] = useState('')
   const [btn_password, setBtnPassword] = useState(false)


   useEffect(() => {
      init();
   }, [id])



   const init = async () => {
      const _report = await DocumentStore.getDocument(id);
      setReport(_report);
      // setLoading(true)
   }

   // const getRapport = async (year) => {
   //    if (report.auteur.agent.structure !== undefined)
   //       window.location.href = `/bibliotheque/rapports-publics?annee=${year}&structureCode=${report.auteur.agent.structure.code}`
   //    else
   //       window.location.href = `/bibliotheque/rapports-publics?annee=${year}`
   // }

   const Body = () => {
      return (
         <>
            <div className="p-1 breadcrumbs xs-font">
               <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span> / Rapports d'activités</span>
            </div>
            <h4 className="page-title">{report.libelle}</h4>
            <span className="bg-info text-white p-2 mt-2">{report.nbLectures} lectures</span>

            <div className="container-fluid mt-3">
               <div className="row">
                  <div className="col-md-9">
                     <div className="p-2 card">
                        <div className="d-flex justify-content-evenly pb-2 border-bottom">
                           <small><i className="fas fa-university"></i> Structure <br /><b className="textPrimaryDark">{report.structure !== undefined ? report.structure.intitule : ''}</b></small>
                           <small><i className="fas fa-user"></i> Auteur <br /><b className="textPrimaryDark">{report.auteur.agent.nom}</b></small>
                           <small><i className="fas fa-calendar"></i> Date d'edition <br /><b className="textPrimaryDark">{dateFormat(report.createdAt)}</b></small>
                           {(report.fichier !== undefined && report.categorie.libUnique === 'word') && <a rel="noreferrer" target="_blank" href={SITE_URL + '/doc/download/' + report.id + "?token=" + token} className="btn btn-sm btn-outline-warning" title="TELECHARGER"><Download /></a>}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: report.description }} />
                        {(report.categorie !== undefined && report.categorie.libUnique === 'pdf') && <PDF src={SITE_URL + '/doc/download/' + report.id + "?token=" + token} />}
                        {(report.categorie !== undefined && report.categorie.libUnique === "videos") && <Video src={SITE_URL + '/doc/download/' + report.id + "?token=" + token} />}
                        {(report.categorie !== undefined && report.categorie.libUnique === "audio") && <Audio src={SITE_URL + '/doc/download/' + report.id + "?token=" + token} />}
                     </div>
                  </div>
                  <div className="col-md-3 ">
                     <div className="p-2 card shadow">
                        {UserStore.canPublishDocument(report) && report.suggestionsRapports && UserStore.canSeeDocument(report) &&
                           <>
                              <Link to={`/bibliotheque/documents/${report.id}`} className="btn btn-secondary text-white mt-5 m-3">
                                 Modifier le rapport.
                              </Link>
                           </>
                        }
                        <button onClick={() => setBtnPassword(!btn_password)} className="btn btn btn-sm m-1">Afficher mot de passe</button>

                        {btn_password && <h4 className="p-5">{report.passwordDoc}</h4>}
                        {/* <div className="input-group mb-3 border-bottom pb-3">
                           <select name="year" className="form-control" value={year} onChange={(e) => setYear(e.target.value)}>
                              <option value="">Par année</option>
                              <option value={thisYear}>{thisYear}</option>
                              {years.map(year => <option key={year} value={year}>{year}</option>)}
                           </select>
                           <span className="input-group-text" id="basic-addon2" onClick={() => window.location.href = "/rapports-publics?annee=" + year}><Search /></span>
                        </div>
                        {years.map(year =>
                           <div key={year} className="card rapportRecent pt-4 mt-4">
                              <h5 className="text-center">Rapports</h5>
                              <h3 className="text-center">{year}</h3>
                              <button className="btn btn-outline-warning text-dark mt-5 m-3" onClick={() => getRapport(year)}>Lire</button>
                           </div>
                        )} */}

                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }

   return <Layout body={<Body />} />
}
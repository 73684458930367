import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavMission from "../../../components/biblio/missions/SubNavMission";
import { Pencil, Save, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { USER_ROLES } from "../../../helpers/Constants";
import { notify, setTitle } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import StructureStrore from "../../../store/StructureStore";
import UserStore from "../../../store/UserStore";

const Form = ({ personnel, ordres, profils, agents,reset,close }) => {

   const [current, setCurrent] = useState(personnel);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      let personnel = current;
      if (personnel.agent['@id'])
         personnel = { ...personnel, agent: current.agent['@id'] }
      if (personnel.ordreMission['@id'])
         personnel = { ...personnel, ordreMission: current.ordreMission['@id'] }
      if (personnel.profil['@id'])
         personnel = { ...personnel, profil: current.profil['@id'] }
      MissionStore.savePersonnel(personnel).then(_ => {
         notify("Succès", "Le personnel a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()
      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>L'agent qui a pris part à la mission <small className="fw-bold">[obligatoire]</small></span>
               <select className="form-control" name="agent" onChange={handleChange} value={current.agent['@id']} required>
                  <option value="">Sélectionner un agent</option>
                  {agents && agents.map(agent => <option key={agent.id} value={agent['@id']}>{agent.nom + " " + agent.prenom}</option>)}
               </select>
            </div>
         </div>
         <div className="mb-3">
            <div className="form-group">
               <span>L'ordre de mission concerné <small className="fw-bold">[obligatoire]</small></span>
               <select className="form-control" name="ordreMission" onChange={handleChange} required value={current.ordreMission['@id']}>
                  <option value="">Sélectionner un ordre de mission</option>
                  {ordres.map(ordre => <option key={ordre.id} value={ordre['@id']}>{ordre.reference}</option>)}
               </select>
            </div>
         </div>
         <div className="mb-3">
            <div className="form-group">
               <span>Le profil de l'agent pendant la mission <small className="fw-bold">[obligatoire]</small></span>
               <select className="form-control" name="profil" onChange={handleChange} required value={current.profil['@id']}>
                  <option value="">Sélectionner un profil</option>
                  {profils.map(profil => <option key={profil.id} value={profil['@id']}>{profil.libelle}</option>)}
               </select>
            </div>
         </div>
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Personnels() {

   const [personnels, setPersonnels] = useState([]);
   const [profils, setProfils] = useState([]);
   const [ordres, setOrdres] = useState([]);
   const [agents, setAgents] = useState([]);
   const [personnel, setPersonnel] = useState({ profil: {}, ordreMission: {}, agent: {} });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle("Personnel de missions");
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const {data:_personnels} = await MissionStore.getPersonnels();
      const {data:_ordres} = await MissionStore.getOrdres();
      const {data:_profils} = await MissionStore.getProfils();
      const _agents = await StructureStrore.getTeams();
      
      setPersonnels(_personnels);
      setOrdres(_ordres);
      setProfils(_profils);
      setAgents(_agents);
      setLoaging(false)
   }

   const removePersonnel = (id) => {
      if (window.confirm("Supprimer cet personne?"))
         MissionStore.removePersonnel(id).then(_ => init())
   }

   const updatePersonnel = (personnel) => {
      setPersonnel(personnel);
      setShowModal(true);
   }

   const newPersonnel = (e) => {
      e.preventDefault();
      setPersonnel({ profil: {}, ordreMission: {}, agent: {} });
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setPersonnel({ profil: {}, ordreMission: {}, agent: {} })
      setShowModal(false);
   }
   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavMission/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Personnel de missions</span>
            </div>
            <h4 className="page-title">Le personnel de missions</h4>
            <button onClick={newPersonnel} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Ajouter une personne</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
               <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>L'ordre de mission concerné</th>
                           <th>L'agent qui a pris part à la mission</th>
                           <th>Le profil de l'agent pendant la mission</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {personnels.map((personnel, index) =>
                           <tr key={personnel.id}>
                              <td>{personnel.ordreMission.reference}</td>
                              <td>{personnel.agent.nom + " " + personnel.agent.prenom}</td>
                              <td>{personnel.profil.libelle}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updatePersonnel(personnel)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removePersonnel(personnel.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer une personne"  body={<Form personnel={personnel} profils={profils} agents={agents} ordres={ordres} reset={{ profil: {}, ordreMission: {}, agent: {} }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
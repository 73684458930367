import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import { Pencil, Save, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { USER_ROLES } from "../../../helpers/Constants";
import {notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";

const Form = ({ status,reset,close }) => {

   const [current, setCurrent] = useState(status);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      MissionStore.saveStatus(current).then(_ => {
         notify("Succès", "Le status a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()

      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Statut de la mission <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required placeholder="Statut mission"/>
            </div>
         </div>

         {/* <div className="mb-3">
            <div className="form-group">
               <span>Description</span>
               <textarea name="description" cols="30" rows="5" className="form-control" value={current.description} onChange={handleChange} placeholder="description..."></textarea>
            </div>
         </div> */}
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Statuss() {

   const [statuss, setStatuss] = useState([]);
   const [status, setStatus] = useState({ libelle: "", description: "" });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const {data:_statuss} = await MissionStore.getStatus();
      setStatuss(_statuss);
      setLoaging(false)
   }

   const removeStatus = (id) => {
      if (window.confirm("Supprimer ce status?"))
         MissionStore.removeStatus(id).then(_ => init())
   }

   const updateStatus = (status) => {
      setStatus(status);
      setShowModal(true);
   }

   const newStatus = (e) => {
      e.preventDefault();
      setStatus({});
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setStatus({ libelle: "", description: "" })
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavAutre/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Statuts  missions</span>
            </div>
            <h4 className="page-title">Les statuts de missions</h4>
            <button onClick={newStatus} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer un statut de mission</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
               <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Statut de la mission</th>
                        
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {statuss.map((status, index) =>
                           <tr key={status.id}>
                              <td>{index + 1}</td>
                              <td>{status.libelle}</td>
                           
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateStatus(status)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeStatus(status.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer un status de mission" body={<Form status={status} reset={{ libelle: "", description: "" }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
import React, { useEffect, useState } from "react";
import Layout from "../../../../components/biblio/Layout";
import Nav from "../../../../components/biblio/missions/Nav";
import SubNavCoso from "../../../../components/biblio/missions/SubNavCoso";
import { Pencil, Save, Trash } from "../../../../components/Icons";
import Modal from "../../../../components/Modal";
import { USER_ROLES } from "../../../../helpers/Constants";
import { notify, setTitle } from "../../../../helpers/Helper";
import CosoStore from "../../../../store/CosoStore";
import UserStore from "../../../../store/UserStore";

const Form = ({ principe, composantes, reset, close }) => {

   const [current, setCurrent] = useState(principe);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      let principe = current;
      if (principe.composante['@id'])
         principe = { ...principe, composante: current.composante['@id'] }

      principe['num'] = +current.num

      CosoStore.savePrincipe(principe).then(_ => {
         notify("Succès", "Le principe coso a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()
      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>La composante coso <small className="fw-bold">[obligatoire]</small></span>
               <select className="form-control" name="composante" onChange={handleChange} value={current.composante['@id']} required>
                  <option value="">Sélectionner une composante</option>
                  {composantes.map(composante => <option key={composante.id} value={composante['@id']}>{composante.libelle}</option>)}
               </select>
            </div>
         </div>

         <div className="mb-3">
            <div className="form-group">
               <span>Numéro <small className="fw-bold">[obligatoire]</small></span>
               <input type="number" name="num" className="form-control" value={current.num} onChange={handleChange} required />
            </div>
         </div>

         <div className="mb-3">
            <div className="form-group">
               <span>Libellé <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required />
            </div>
         </div>

         <div className="mb-3">
            <div className="form-group">
               <span>Définition</span>
               <input type="text" name="definition" className="form-control" value={current.definition} onChange={handleChange} />
            </div>
         </div>

         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Principes() {

   const [principes, setPrincipes] = useState([]);
   const [composantes, setComposantes] = useState([]);
   const [principe, setPrincipe] = useState({ libelle: "", definition: "", num: "", composante: {} });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle("Contrôle audit coso");
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const { data: _principes } = await CosoStore.getPrincipes();
      const { data: _composantes } = await CosoStore.getComposantes();
      setPrincipes(_principes);
      setComposantes(_composantes);
      setLoaging(false)
   }

   const removePrincipe = (id) => {
      if (window.confirm("Supprimer ce principe?"))
         CosoStore.removePrincipe(id).then(_ => init())
   }

   const updatePrincipe = (principe) => {
      setPrincipe(principe);
      setShowModal(true);
   }

   const newPrincipe = (e) => {
      e.preventDefault();
      setPrincipe({ libelle: "", definition: "", num: "", composante: {} });
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setPrincipe({ libelle: "", definition: "", num: "", composante: {} })
      setShowModal(false);
   }
   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavCoso/>
            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Principes coso</span>
            </div>
            <h4 className="page-title">Le principe de missions</h4>
            <button onClick={newPrincipe} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Ajouter un principe</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Numéro</th>
                           <th>Libelle</th>
                           <th>Définition</th>
                           <th>Composante COSO</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {principes.map((principe, index) =>
                           <tr key={principe.id}>
                              <td>{index + 1}</td>
                              <td>{principe.num}</td>
                              <td>{principe.libelle}</td>
                              <td>{principe.definition}</td>
                              <td>{principe.composante.libelle}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updatePrincipe(principe)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removePrincipe(principe.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer un principe" body={<Form principe={principe} composantes={composantes} reset={{ libelle: "", definition: "", num: "", composante: {} }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
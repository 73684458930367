import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/biblio/Layout";
import Filter from "../../../components/biblio/resources/Filter";
import Resource from "../../../components/biblio/resources/Resource";
import Pagination from "../../../components/Pagination";
import { useQuery } from "../../../helpers/Helper";
import ResourceStore from "../../../store/ResourceStore";
import UserStore from "../../../store/UserStore";

export default function Resources() {
   const [page, setPage] = useState(1)
   const [books, setBooks] = useState([]);
   const [pagination, setPagination] = useState({});
   const [total, setTotal] = useState(null);
   const [loading,setLoading]=useState(false)

   const query = useQuery()
   const domaineLibUnique = query.get('domaine');
   const search = query.get('q') !== null ? query.get('q') : '';

   const user = UserStore.state.currentUser;
   useEffect(() => {
      init();
   }, [domaineLibUnique, page])

   const init = async () => {
      setLoading(true)
      const { data, pagination, total } = await ResourceStore.getBooks(domaineLibUnique, page, query.get('q'), 0)
      setPagination(pagination)
      setBooks(data);
      setTotal(total)
      setLoading(false)
   }

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const Body = () => {
      return (
         <>
            <Filter domaineLibUnique={domaineLibUnique} />
            <div className="page-info">
               <h2 className="text-center text-white">Bibliothèque des livres</h2>
               <div className="bread-crumb">
                  <div className="container">
                  <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <Link to="/bibliotheque/livres" className="textPrimary fw-bold">/ Bibliothèque des livres</Link>
                  </div>
               </div>
            </div>
            <div className="container pt-5">
            { search && <h4 className="page-title">Recherche :  {search !== '' && `: ${search}`}</h4>}
            {total > 0 && <span className="livre-dispo mt-4 "> <i className="fas fa-book-reader " size={40}></i>  {total} livre(s) disponible(s) </span>}
            {/* {total === 0 && <span className="livre-dispo mt-4 "> <i className="fas fa-exclamation " size={40}></i> Aucun livre disponible </span>} */}
         </div>


            <div className="container-fluid mb-4 page-content">
               <div className={`row ${loading ? 'loading' : ''}`}>
                  {books && books.map((resource, index) => <Resource resource={resource} key={index} user={user} />)}
                  {!books.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucune ressource disponible.</h2>}
               </div>
               <br />
               <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
            </div>
         </>
      );
   }

   return <Layout body={<Body />} />
}

import {Link, useLocation} from "react-router-dom";
import {activeLink} from "../../../helpers/Helper";

export default function SubNavAutre() {
    const location = useLocation();
    return (
        <div className="filterArea bg-white pt-5 mb-5">
            <h3 className="page-title">AUTRES LISTES</h3>

            <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">

                <div className="p-2 d-flex bd-highlight hide4mobile">
                    <ul className="searchCategory">
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/sources')} to="/bibliotheque/missions/sources">SOURCES MISSIONS</Link>
                        </li>

                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/niveau-mis-oeuvre')} to="/bibliotheque/missions/niveau-mis-oeuvre">NIVEAU DE MISE EN OEUVRE MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/lieux')} to="/bibliotheque/missions/lieux">LIEUX MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/annees')} to="/bibliotheque/missions/annees">ANNÉES MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/natures')} to="/bibliotheque/missions/natures">NATURES MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/status')} to="/bibliotheque/missions/status">STATUT MISSIONS</Link>
                        </li>
                        <li>
                            <Link className={activeLink(location.pathname, '/bibliotheque/missions/domaine-remboursement')} to="/bibliotheque/missions/domaine-remboursement">DOMAINES REMBOURSEMENTS</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/biblio/Layout";
import Pagination from "../../../components/Pagination";
import YoutubeStore from "../../../store/YoutubeStore";


const Video = ({ video }) => {

   return (
      <div className="col-12 col-sm-6 col-md-4 image-grid-item">
         <div style={{ backgroundImage: `url(https://img.youtube.com/vi/${ video.videoId }/mqdefault.jpg)` }} className="image-grid-cover">
            <Link to={`/bibliotheque/youtube-videos/${video.id}`} className="image-grid-clickbox"></Link>
            <Link to={`/bibliotheque/youtube-videos/${video.id}`} className="cover-wrapper">{video.titre}</Link>
         </div>
      </div>
   )
}


export default function YoutubeVideos() {
   const [page, setPage] = useState(1)
   const [videos, setVideos] = useState([]);
   const [pagination, setPagination] = useState({});
   const [loading, setLoading] = useState(false)


   useEffect(() => {
     init();
   }, [page])

   const init = async () => {
      setLoading(true)
      const { data, pagination } = await YoutubeStore.getAll(page)
      setPagination(pagination)
      setVideos(data);
      setLoading(false)
   }

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const Body = () => {
      return (
         <>
            <div className="page-info">
               <h2 className="text-center text-white">Chaine youtube</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <Link to="/bibliotheque/youtube-videos" className="textPrimary fw-bold">/ Chaine youtube</Link>
                  </div>
               </div>
            </div>


            <div className="container-fluid mb-4 page-content">
               <div className={`row ${loading ? 'loading' : ''}`}>
                  {videos && videos.map((video, index) => <Video video={video} key={index} />)}
                  {!videos.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucune vidéo disponible.</h2>}
               </div>
               <br />
               <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
            </div>
         </>
      );
   }

   return <Layout body={<Body />} />
}

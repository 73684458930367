import { useEffect, useRef } from "react";
import { Croix } from "./Icons";

export default function Modal({ show, title, body, onClose,large, size = 'modal-lg', style = {} }) {

   const modalRef = useRef();

   useEffect(() => {
      if (show) {
         document.querySelector("body").classList.add('modal-open');
         modalRef.current.style.display = "block";
      } else {
         document.querySelector("body").classList.remove('modal-open');
         modalRef.current.style.display = "none";
      }
   }, [show]);

   return (
      <>
         <div className={`modal fade show `} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref={modalRef}>
            <div className={`modal-dialog ${size} ${large && 'modal-large'}` } >
               <div className="modal-content">
                  <div className="modal-header" style={{ backgroundColor: '#3b9969', color: '#fff', textAlign: 'center' }}>
                     <h6 className="modal-title" style={{ color: '#FFF', width: "100%" }}>{title}</h6>
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose} style={{ padding: '7px 7px 10px 7px', backgroundImage: 'none', color: '#fff' }}>
                        <Croix />
                     </button>
                  </div>
                  <div className="modal-body" style={style}>
                     {body}
                  </div>
               </div>
            </div>
         </div>
         {show && <div className="modal-backdrop fade show" onClick={onClose}/>}
      </>
   )
}
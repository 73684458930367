import React, { useEffect, useState } from "react";
import Filter from "../../../components/biblio/resources/Filter";
import Layout from "../../../components/biblio/Layout";
import { Link, useParams } from "react-router-dom";
import { setTitle } from "../../../helpers/Helper";
import YoutubeStore from "../../../store/YoutubeStore";


export default function YoutubeVideo() {

    const { id } = useParams()
    const [video, setVideo] = useState({ domaine: {} })

    useEffect(() => {
        init()
    }, [id]);

    const init = async () => {
        const _video = await YoutubeStore.get(id);
        setVideo(_video)
        setTitle(_video.titre);
    }

    const Body = () => {

        return (
            <>
                <Filter />
                <div className="p-1 breadcrumbs xs-font">
                    <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Chaine youtube</span>
                </div>
                <div className="container-fluid mb-4">
                    <div className="row">
                       
                        <div className="col-sm-12 col-md-8 book-detail">

                            <h1>{video.titre}</h1>

                            <iframe title={video.titre} style={{width: '100%'}} src={`https://www.youtube.com/embed/${ video.videoId }`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            <p className="border-top pt-3" dangerouslySetInnerHTML={{ __html: video.description }} />

                        </div>
                    </div>
                </div>
            </>
        );
    }
    return <Layout body={<Body />} />
}
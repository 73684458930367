import React, { useEffect, useState } from "react";
import { notify } from "../../helpers/Helper";
import DocumentStore from "../../store/DocumentStore";
import HomeStore from "../../store/HomeStore";
import StructureStrore from "../../store/StructureStore";
import { Send } from "../Icons";
import Select from 'react-select'


export default function Contact({ organe = null, onClose = null }) {

   const [form, setForm] = useState({ nom: "", prenom: "", email: "", numTel: "", contenu: "", autreService: "", structure: [] })

   const [organes, setOrganes] = useState([]);
   const [options, setOptions] = useState([]);
   const [typeAvis, setTypeAvis] = useState([]);

   useEffect(() => {
      init();
   }, []);



   const init = async () => {
      const _typeAvis = await HomeStore.getTypeAvis();
      setTypeAvis(_typeAvis)
      const _organes = await StructureStrore.getAll();
      setOrganes(_organes);
      if (organe !== null) {
         setForm({ ...form, structure: organe['id'] })
      }
      const _options = _organes.map(organe => {
         return { value: organe['id'], label: organe.organeControle }
      })
      setOptions(_options);
   }

   const handleSubmit = async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      let stop = false

      if (formData.get('file').name !== "") {
         const fileTab = formData.get('file').name.split('.')
         if(fileTab.length>0){
            if(fileTab[1]=="pdf" || fileTab[1]=="png" || fileTab[1]=="jpg" || fileTab[1]=="jpeg" ){
               const fichier = await DocumentStore.uploadMedia(formData, "file");
               form['fichier'] = fichier['@id']
               setForm(form);
               stop=false

            }else{
               stop = true
               notify("Avertissement", "Vous devez soumettre un fichier PDF ou image", "warning");

            }
         }
       
      }

      if(!stop && form){
         if(typeof(form.structure=="string")){

               const formNew = {...form,structure:`api/structures/${form.structure}`}

               HomeStore.sendMessageToIGF(formNew).then(_ => {
                  event.target.reset();
                  //setForm({ nom: "", prenom: "", email: "", numTel: "", contenu: "", autreService: ""})
                  window.location.reload(false)

                  notify("Succès", "Votre message a été envoyé.", "success");
                  if (null !== onClose) {
                     onClose();
                  }
               }).catch(err=>{
                  notify("Erreur", err.response.data.message, "warning");
      
               });


         }else{
        form.structure.map(s=>{

            const formNew = {...form,structure:`/api/structures/${s.value}`}
   
            HomeStore.sendMessageToIGF(formNew).then(_ => {
               event.target.reset();
               //setForm({ nom: "", prenom: "", email: "", numTel: "", contenu: "", autreService: ""})
               notify("Succès", "Votre message a été envoyé.", "success");
               
               if (null !== onClose) {
                  onClose();
               }
            }).catch(err=>{
               notify("Erreur", err.response.data.message, "warning");
   
            });
   
         })
         }
 
      }



   }

   const handleOrganeChange = (option) => {
      setForm({ ...form, structure: option })
   }

   const handleChange = (event) => {
      setForm({ ...form, [event.target.name]: event.target.value })
   }

   return (
      <form onSubmit={handleSubmit} encType="multipart/form-data">

         {organe == null &&
            <div className="row mb-3">
               <div className="col-md-12">
                  <div className="form-group">
                     <span>Organes de contrôle <small className="fw-bold">[obligatoire]</small></span>
                     <Select  isMulti options={options} onChange={handleOrganeChange} placeholder="Organes de contrôle" />
                     {/* <select onChange={handleChange} name="structure" className="form-control" required value={form.structure.id}>
                        <option value="">--Choisir l'organe de contrôle--</option>
                        {organes.map(organe => <option value={organe['@id']} key={organe.id}>{organe.intitule}</option>)}
                     </select> */}
                  </div>
               </div>
            </div>
         }
         <div className="row mb-3">
            <div className="col-md-6">
               <div className="form-group">
                  <span>Nom  <small className="fw-bold">[obligatoire]</small></span>
                  <input onChange={handleChange} name="nom" value={form.nom} required type="text" className="form-control" />
               </div>
            </div>
            <div className="col-md-6">
               <div className="form-group">
                  <span>Prenom <small className="fw-bold">[obligatoire]</small></span>
                  <input onChange={handleChange} name="prenom" value={form.prenom} required type="text" className="form-control" />
               </div>
            </div>
         </div>
         <div className="row mb-3">
            <div className="col-md-6 mt-3">
               <div className="form-group">
                  <span>Téléphone <small className="fw-bold">[optionnel]</small></span>
                  <input onChange={handleChange} name="numTel" value={form.numTel} type="text" className="form-control" />
               </div>
            </div>
            <div className="col-md-6 mt-3">
               <div className="form-group">
                  <span>Email <small className="fw-bold">[obligatoire]</small></span>
                  <input onChange={handleChange} name="email" value={form.email} required type="email" className="form-control" />
               </div>
            </div>
         </div>
         <div className="mb-3">
            <div className="form-group">
               <span>Sujet <small className="fw-bold">[obligatoire]</small></span>
               <select onChange={handleChange} name="autreService" value={form.autreService} required className="form-control">
                  <option value="">--Choisir un sujet--</option>
                  {typeAvis.map(type => <option value={type.libelle}>{type.libelle}</option>)}
               </select>
            </div>
         </div>

         <div className="mb-3">
            <div className="form-group">
               <span>Fichier joint (Image ou PDF) <small className="fw-bold">[optionnel]</small></span>
               <input name="file" type="file" className="form-control" />
            </div>
         </div>
         <div className="mb-3">
            <div className="form-group">
               <span>Commentaire <small className="fw-bold">[obligatoire]</small></span>
               <textarea onChange={handleChange} value={form.contenu} required name="contenu" cols="30" rows="5" className="form-control"></textarea>
            </div>
         </div>
         <button type="submit" className="btn btn-warning mx-auto d-block" style={{ width: "100%" }}><Send /> Envoyer</button>
      </form>
   )
}
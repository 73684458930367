import { useLayoutEffect, useState } from "react";

import { getDocumentFormat, notify, setTitle } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import Upload from "./Upload";
import HomeStore from "../../../store/HomeStore";
import UserStore from "../../../store/UserStore";
import { USER_ROLES } from "../../../helpers/Constants";

export default function ShareForm({ title = "Partager un document", type = "document", _document = null, onClose }) {

   const [index, setIndex] = useState(1);
   const [domaines, setDomaines] = useState([])
   const [modules, setModules] = useState([])
   const [fileRequired, setFileRequired] = useState(true);
   const [document, setDocument] = useState({ passwordDoc: null, libelle: '', description: '', originalName: '', module: '', categorie: '', annee: '', fichier: '', tags: [], isVisible: false, isValidated: false })

   const [saving, setSaving] = useState(false)

   const user = UserStore.state.currentUser;

   useLayoutEffect(() => {
      setTitle(title);
      init();
   }, []);

   const init = async () => {
      const validated = UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ? true : false;
      setDocument({ ...document, user: 'api/users/' + user.id, isValidated: validated });

      if (_document !== null) {
         setFileRequired(false);
         DocumentStore.state['file'] = _document.fichier
         const fichier = _document.fichier !== undefined ? _document.fichier['@id'] : ''
         setDocument({
            ...document,
            libelle: _document.libelle,
            description: _document.description,
            originalName: _document.originalName,
            module: _document.module['@id'],
            categorie: _document.categorie['@id'],
            annee: _document.annee,
            fichier: fichier,
            passwordDoc:_document.passwordDoc,
            isVisible: _document.isVisible,
            isValidated: _document.isValidated,
            user: 'api/users/' + user.id,
            id: _document.id
         });
      }

      let categories = await DocumentStore.getCategories()

      let _modules = await HomeStore.getModules();
      const rapport_proteges_domaines = _modules.find(module => module.libUnique === 'rapports-proteges');

      _modules = _modules.filter(module => module.template.libUnique === 'document');
      setModules(_modules);

      if (type === "rapport" && rapport_proteges_domaines !== undefined) {
         setDocument({ ...document, module: rapport_proteges_domaines['@id'] });
      }
      setDomaines(categories);

   }

   const handleInputChange = (e) => {
      setDocument({ ...document, [e.target.name]: e.target.value });
   }

   const findModules = (id) => {
      return modules.find(module => module['@id'] === id)
   }

   const saveDocument = (e) => {
      e.preventDefault();
      if (saving)
         return;
      setSaving(true);

      const format = getDocumentFormat(domaines);
      if (format !== undefined && format !== null) {
         document['categorie'] = format['@id'];
      }

      const _document = { ...document, fichier: DocumentStore.state.file['@id'], annee: parseInt(document.annee) }
      if (_document.libelle.trim() === "")
         return notify('Erreur', `Le titre du ${type} doit être renseigné.`, "danger")

      if (_document.categorie.trim() === "")
         return notify('Erreur', `Le format du ${type} doit être renseigné.`, "danger")

      if (type === "document") {

         if (_document.module.trim() === "")
            return notify('Erreur', `Le module du ${type} doit être renseigné.`, "danger")
      }
      if (type === "rapport") {
         _document['isVisible'] = false
      } else {
         _document['isVisible'] = Boolean(findModules(_document.module).isVisible)
      }

      DocumentStore.saveDocument(_document).then(response => {
         setDocument(response.data)
         if (UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) {
            DocumentStore.suggestDocument({ rapport: response.data['@id'], isForStructure: false, isValidated: false }).then(response => { })
         }
         notify('Succès', `Le ${type} a été enregistré.`, 'success');
         DocumentStore.resetFile();
         setSaving(false)
         onClose();

      })
   }

   return (
      <section className="signup-step-container">
         <div className="container">
            <div className="col-md-12 pt-3 p-0">
               <div className="wizard">
                  <div className="wizard-inner">
                     <div className="connecting-line"></div>
                     <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className={index === 1 ? "active" : "disabled"} style={{ width: '50%' }}>
                           <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab" aria-expanded="true"><span className="round-tab">1 </span> <i>Le fichier</i></a>
                        </li>
                        <li role="presentation" className={index === 2 ? "active" : "disabled"} style={{ width: '50%' }}>
                           <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab"><span className="round-tab">2</span> <i>Informations document </i></a>
                        </li>
                        {/* <li role="presentation" className={index === 3 ? "active" : "disabled"}>
                           <a href="#step4" data-toggle="tab" aria-controls="step4" role="tab" style={{ width: '33.33%' }}><span className="round-tab">3</span> <i>Confirmation</i></a>
                        </li> */}
                     </ul>
                  </div>

                  <div className="tab-content" id="main_form">
                     <div className={index === 1 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step1">
                        <Upload next={() => setIndex(2)} required={fileRequired} type="file" path="/fichier_documents" />
                     </div>
                     <div className={index === 2 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step2">
                        <div className="form-group">
                           <span>Titre du {type} <small className="fw-bold">[obligatoire]</small></span>
                           <input type="text" className="form-control" required name="libelle" value={document.libelle} onChange={handleInputChange} placeholder={`Titre du ${type}`} />
                        </div>

                        <div className="form-group mt-3">
                           <span>Année du {type}</span>
                           <input type="number" className="form-control" name="annee" value={document.annee} onChange={handleInputChange} placeholder={`Année du ${type}`} />
                        </div>
                        <div className="form-group mt-3">
                           <span>Libellé original du {type}</span>
                           <input type="text" className="form-control" name="originalName" value={document.originalName} onChange={handleInputChange} placeholder={`Libellé original du ${type}`} />
                        </div>

                        {/* <div className="form-group mt-3">
                           <span>Format du document du {type} <small className="fw-bold">[obligatoire]</small></span>
                           <select name="categorie" id="categorie" className="form-control" required value={document.categorie} onChange={handleInputChange}>
                              <option value=""> -- Choisir le format du document -- </option>
                              {domaines.map(domaine => <option value={domaine['@id']} key={domaine.id}>{domaine.libelle}</option>)}
                           </select>
                        </div> */}

                        {type !== "rapport" &&
                           <div className="form-group mt-3">
                              <span>Module de partage du {type} <small className="fw-bold">[obligatoire]</small></span>
                              <select name="module" id="module" className="form-control" required value={document.module} onChange={handleInputChange}>
                                 <option value=""> -- Choisir le module de partage du {`${type}`} -- </option>
                                 {modules.map(module => <option value={module['@id']} key={module.id}>{module.libelle}</option>)}
                              </select>
                           </div>
                        }

                        <div className="form-group mt-3">
                           <span>Description du {type} </span>
                           <textarea className="form-control"  maxLength={250} name="description" id="description" value={document.description} onChange={handleInputChange} placeholder={`Description du ${type}`}></textarea>
                        </div>

                        {type === "rapport" && <div className="form-group mt-3">
                           <span>Mot de passe du {type}</span>
                           <input type="password" className="form-control" name="passwordDoc" value={document.passwordDoc} onChange={handleInputChange} placeholder={`Mot de passe du ${type}`} />
                        </div>}

                        {/* <button onClick={_ => setIndex(1)} type="button" style={{ width: '20%', float: "left" }} className="default-btn btn-secondary prev-step">Retour </button> */}
                        <button style={{ width: '20%', float: "right" }} onClick={saveDocument} type="button" className="default-btn next-step"> Continuer</button>

                     </div>

                     {/* <div className={index === 3 ? "tab-pane active" : "tab-pane"} role="tabpanel" id="step3">
                        <Confirmation prev={_ => setIndex(2)} next={handleSubmit} docName={`${type}`} />
                     </div> */}
                     <div className="clearfix"></div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}
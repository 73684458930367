import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import { Pencil, Save, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { USER_ROLES } from "../../../helpers/Constants";
import { notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";

const Form = ({ lieu, reset, close }) => {

   const [current, setCurrent] = useState(lieu);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      MissionStore.saveLieu(current).then(_ => {
         notify("Succès", "Le lieu de mission a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()

      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Lieu de la mission <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required placeholder="lieu" />
            </div>
         </div>
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Lieu() {

   const [lieus, setLieus] = useState([]);
   const [lieu, setLieu] = useState({ libelle: "" });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const { data: _lieus } = await MissionStore.getLieus();
      setLieus(_lieus);
      setLoaging(false)
   }

   const removeLieu = (id) => {
      if (window.confirm("Supprimer ce lieu de mission?"))
         MissionStore.removeLieu(id).then(_ => init())
   }

   const updateLieu = (lieu) => {
      setLieu(lieu);
      setShowModal(true);
   }

   const newLieu = (e) => {
      e.preventDefault();
      setLieu({});
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setLieu({ libelle: "" })
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavAutre/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Lieux missions</span>
            </div>
            <h4 className="page-title">Les lieux de missions</h4>
            <button onClick={newLieu} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer une lieu de mission</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Lieu de la mission</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {lieus.map((lieu, index) =>
                           <tr key={lieu.id}>
                              <td>{index + 1}</td>
                              <td>{lieu.libelle}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateLieu(lieu)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeLieu(lieu.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer une lieu de mission" body={<Form lieu={lieu} reset={{ libelle: "" }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
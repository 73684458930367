
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ResourceStore from "../../../store/ResourceStore";
import { Search } from "../../Icons";

export default function Filter({ domaineLibUnique }) {

   const [domaines, setDomaines] = useState([]);

   const [q, setQ] = useState('')
   useEffect(() => {
      init();
   }, []);

   const init = async () => {
      const domaines = await ResourceStore.getDomaines();
      setDomaines(domaines);
   }

   const searchBook = (e) => {
      e.preventDefault();
      window.location.href = "/bibliotheque/livres?q=" + q;
   }

   return (
      <div className="filterArea bg-white">
         <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">
            {/* <div className="p-2 bd-highlight ">
               <div className="search-box ">
                  <a href="/" className="search-btn" onClick={searchBook}>
                     <Search />
                  </a>
                  <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => setQ(e.target.value)} />

               </div>
            </div> */}

            <div className="p-2 bd-highlight ">
               <div className="search-box">
                  <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => setQ(e.target.value)} />
                  <a href="/" className="search-btn" onClick={searchBook}>
                     <Search />
                  </a>
               </div>
            </div>


            <div className="p-2 d-flex bd-highlight hide4mobile  justify-content-end " >
               <ul className="searchCategory">
                  {domaines.map(domaine => <li key={domaine.id}>
                     <Link to={`/bibliotheque/livres?domaine=${domaine.libUnique}`} className={`${(domaineLibUnique == domaine.libUnique) && 'text-active '} `}>{domaine.libelle}</Link>
                  </li>)}
               </ul>

            </div>
         </div>
      </div>
   );
}
import httpClient from "./HttpClient";


import { storedItemKeys, USER_ROLES } from "../helpers/Constants";
import {
  doLogout,
  getStoredItem,
  hasStoredItem,
  resetAllData,
  storeItem,
} from "../helpers/Helper";

const currentUserType = getStoredItem(storedItemKeys.CURRENT_USER_TYPE);

let storeItemData = getStoredItem(storedItemKeys.CURRENT_USER)

if(storeItemData==="undefined") {
  resetAllData()
}


const UserStore = {
  state: {
    users: [],
    authorizedQualite: [
      "ROLE_GESTIONS_MISSION",
      "ROLE_INSPECTEUR",
      "ROLE_RESPONSABLE_ORGANE",
      "ROLE_COLLABORATEUR",
      "ROLE_SUPERVISEUR",
    ],
    currentUser: hasStoredItem(storedItemKeys.CURRENT_USER)
      ? JSON.parse(storeItemData)
      : null,
    currentUserType: currentUserType,
  },
  

  isDocumentAuthor(document) {
    // if (document.auteur !== undefined)
    //   return (
    //     document.auteur["@id"] === "/api/users/" + this.state.currentUser.id
    //   );
    return true;
  },

  isBookAuthor(document) {
    if (document.user !== undefined)
      return document.user.id === this.state.currentUser.id;
    return false;
  },

  canEditBook(book) {
    return this.isBookAuthor(book); //|| this.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
  },

  canPublishBook(book) {
    return (
      book.suggestionsLivres !== undefined &&
      UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
    );
  },

  canSeeBook(book) {
    // if (UserStore.state.currentUser.agent) {
    //   const structure_code = UserStore.state.currentUser.agent.structure.code;
    //   if (book.structure && book.structure.code === structure_code) return true;
    // }

    return true;
  },

  canRemoveBook(book) {
    return this.canEditBook(book) && !book.isValidated;
  },

  canPublishDocument(doc) {
    return (
      doc.suggestionsRapports !== undefined &&
      UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
    );
  },
  canSeeDocument(document) {
    if (UserStore.state.currentUser.agent) {
      const structure_code = UserStore.state.currentUser.agent.structure.code;
      if (document.structure && document.structure.code === structure_code)
        return true;
    }

    return false;
  },
  canRemoveDocument(document) {
    return this.canEditDocument(document) && !document.isValidated;
  },

  canEditDocument(document) {
    //return this.isDocumentAuthor(document); //|| this.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
    return true
  },

  async getCurrentUser() {
    try {
      if (this.hasToken()) {
        const response = await httpClient.get("u/infos");
        const data = await response.data;
        this.setCurrentUser(data);
        storeItem(storedItemKeys.CURRENT_USER, JSON.stringify(data));
        return data;
      }
    } catch (e) {}
  },

  hasToken() {
    return getStoredItem(storedItemKeys.TOKEN) !== null;
  },

  isAuthenticated() {
    return (
      getStoredItem(storedItemKeys.TOKEN) !== null &&
      getStoredItem(storedItemKeys.CURRENT_USER) !== null
    );
  },

  setCurrentUser(user) {
    this.state.currentUser = user;
  },
  updatePassword(lastPassword, newPassword) {
    return httpClient.post("/u/reset-new-password", {
      lastPassword,
      newPassword,
    });
  },
  lostPassword(email) {
    return httpClient.post("/u/reset-lost-password", { email });
  },
  login(username, password) {
    return httpClient.post("/authentication_token", { username, password });
  },

  logout() {
    httpClient.get("/logout").then((response) => doLogout());
  },

  getRole() {
    return this.state.currentUser !== null && this.state.currentUser.roles;
  },

  hasRole(role) {
    return this.state.currentUser.roles.includes(role);
  },

  hasAccess() {
    return (
      UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) ||
      UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) ||
      UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ||
      UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)
    );
  },

  async updateAvatar(formData) {
    try {
      const response = await httpClient.post("/media_objects", formData);
      const data = await response.data;
      return data;
    } catch (error) {
      return {};
    }
  },

  updateAgent(id, data) {
    return httpClient.put("/agents/" + id, data);
  },
};

export default UserStore;

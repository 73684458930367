import httpClient from "./HttpClient";


const CosoStore = {

   state: {

   },

   async getComposantes() {
      try {
         const response = await httpClient.get(`/composantes_cosos`);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         return { data: [], pagination: {} };
      }
   },

   saveComposante(composante) {
      if (composante.id !== "" && composante.id !== undefined)
         return httpClient.put('/composantes_cosos/' + composante.id, composante);
      else
         return httpClient.post('/composantes_cosos', composante);
   },

   removeComposante(id) {
      return httpClient.delete('/composantes_cosos/' + id);
   },

   async getPrincipes() {
      try {
         const response = await httpClient.get(`/principes_cosos`);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         return { data: [], pagination: {} };
      }
   },

   savePrincipe(composante) {
      if (composante.id !== "" && composante.id !== undefined)
         return httpClient.put('/principes_cosos/' + composante.id, composante);
      else
         return httpClient.post('/principes_cosos', composante);
   },

   removePrincipe(id) {
      return httpClient.delete('/principes_cosos/' + id);
   },

   async getControles() {
      try {
         const response = await httpClient.get(`/controle_interne_audit_cosos`);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         return { data: [], pagination: {} };
      }
   },

   saveControle(composante) {
      if (composante.id !== "" && composante.id !== undefined)
         return httpClient.put('/controle_interne_audit_cosos/' + composante.id, composante);
      else
         return httpClient.post('/controle_interne_audit_cosos', composante);
   },

   removeControle(id) {
      return httpClient.delete('/controle_interne_audit_cosos/' + id);
   }


}

export default CosoStore;
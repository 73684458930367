import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import {
  dateFormat,
  getAuthToken,
  notify,
  setTitle,
} from "../../helpers/Helper";
import StructureStrore from "../../store/StructureStore";
import { SITE_URL, USER_ROLES } from "../../helpers/Constants";
import Modal from "../../components/Modal";
import UserStore from "../../store/UserStore";
import FilterRE from "./FilterRE";
import PDF from "../../components/PDF";
import Loading from "../../helpers/Loading";

export default function RapportPartage() {
  const [page, setPage] = useState(1);
  const [rapports, setRapports] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);
  const [showViewer, setShowViewer] = useState(false);
  const [docView, setDocView] = useState("");

  const token = getAuthToken();

  setTitle("RAPPORTS DE SUIVI ET RECOMMANDATION PARTAGES");

  useEffect(() => {
    init();
  }, [page]);

  const init = async () => {
    const { data, pagination } = await StructureStrore.getRapportsPartage(page);
    const current_user = await UserStore.getCurrentUser();
    // let roles = current_user.roles.filter(r => r == "ROLE_SUPERVISEUR")
    // if (roles.length > 0) setIsSupervisor(true)
    // else setIsSupervisor(false)

    setRapports(data);
    setPagination(pagination);
    setLoading(false);
  };

  const setCurrentPage = (page) => {
    setPage(page);
  };

  const readDoc = (doc) => {
    if (doc) {
      setDocView(doc);
      setShowViewer(true);
    }
  };

  const searchRE = async (q) => {
    const { data, pagination } = await StructureStrore.getRapportsRecus(
      page,
      q
    );
    setRapports(data);
    setPagination(pagination);
  };

  const Body = () => {
    return (
      <Fragment>
      {loading && <Loading/>}
     {!loading && <div>
        <div className="p-1 breadcrumbs xs-font">
          <Link to="/bibliotheque" className="textPrimary fw-bold">
            Accueil
          </Link>
          <span>/ Rapports de suivi et recommandation partagés avec l'IGF</span>
        </div>
        <div></div>
        <div className="content-body">
          <h4 className="page-title">Rapports partagés avec l'IGF</h4>
          <div className="container-fluid mt-3 mb-3">
            <div className="row mt-3">
              <FilterRE searchRE={searchRE} />

              <div className="col-sm-12 col-md-12 p-2">
                <table className="table table-bordered table-striped">
                  <thead
                    style={{
                      textTransform: "uppercase",
                      color: "#fff",
                    }}
                    className="bckColorDark"
                  >
                    <tr>
                      <th>Date</th>

                      <th>Titre</th>
                      <th>Expediteur</th>
                      <th>Structure</th>
                      {/* <th>Fichier Rapport</th>
                      <th>Fichier Synthèse</th> */}
                      <th>Recommandations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rapports &&
                      rapports.map((partage) => (
                        <>
                          <tr key={partage.rapport.id}>
                            <td>
                              {partage.rapport.createdAt &&
                                dateFormat(
                                  partage.rapport.createdAt,
                                  "DD/MM/YYYY à HH:mm"
                                )}
                            </td>

                            <td>
                              <strong>
                                <Link
                                  to={`rapports-suivis-recommandation-partage/${partage.rapport.id}`}
                                >
                                  {partage.rapport.libelle}
                                </Link>
                              </strong>
                            </td>
                            <td>
                              {partage.rapport.senderAgent.prenom &&
                                partage.rapport.senderAgent.prenom.toUpperCase() +
                                  " " +
                                  partage.rapport.senderAgent.nom.toUpperCase()}
                            </td>
                            <td>
                              {partage.rapport.senderStructure.intitule &&
                                partage.rapport.senderStructure.intitule}
                            </td>
                            {/* <td>
                              {partage.rapport.fichier && (
                                <button
                                  className="btn btn-sm btn-info"
                                  type="button"
                                  onClick={() =>
                                    readDoc(
                                      SITE_URL +
                                        "/suivi-rapport/doc/download/" +
                                        partage.rapport.id +
                                        "?token=" +
                                        token
                                    )
                                  }
                                >
                                  Visualiser
                                </button>
                              )}
                            </td>

                            <td>
                              {partage.rapport.fichier && (
                                <button
                                  className="btn btn-sm btn-warning"
                                  type="button"
                                  onClick={() =>
                                    readDoc(
                                      SITE_URL +
                                        "/suivi-rapport/synthese/download/" +
                                        partage.rapport.id +
                                        "?token=" +
                                        token
                                    )
                                  }
                                >
                                  Visualiser
                                </button>
                              )}
                            </td> */}
                            <td>
                              <Link
                                to={`rapports-suivis-recommandation-partage/${
                                  partage.rapport && partage.rapport.id
                                }`}
                                className="mb-4"
                              >
                                {" "}
                                {partage.rapport.fichier && (
                                  <button
                                    className="btn btn-sm btn-info mb-2"
                                    type="button"
                                    onClick={() =>
                                      readDoc(
                                        SITE_URL +
                                          "/suivi-rapport/doc/download/" +
                                          partage.rapport.id +
                                          "?token=" +
                                          token
                                      )
                                    }
                                  >
                                    Voir les détails du rapport{" "}
                                  </button>
                                )}
                              </Link>

                              {/* {partage.rapport.suiviRecRaports.filter(
                                (r) => !r.etat
                              ).length > 0 && (
                                <span className="badge badge-secondary  secBckColor ">
                                  Reste{" "}
                                  {
                                    partage.rapport.suiviRecRaports.filter(
                                      (r) => r.etat != "valide"
                                    ).length
                                  }{" "}
                                  en attente de lecture{" "}
                                </span>
                              )} */}
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
                <Pagination
                  setCurrentPage={setCurrentPage}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          large
          size="lg"
          show={showViewer && docView}
          title="Lecture du Document"
          body={<PDF src={docView} />}
          onClose={() => setShowViewer(false)}
        />
         </div> }
      </Fragment>
    );
  };
  return <Layout body={<Body />} />;
}

import { useState } from "react";
import { notify } from "../../helpers/Helper";
import UserStore from "../../store/UserStore";

export default function Password() {

    const [form, setForm] = useState({ currentPassword: "", password: "", passwordConfirm: "" })

    const handleChange = (event) => {
        event.preventDefault();
        setForm({ ...form, [event.target.name]: event.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.password !== form.passwordConfirm)
            return notify("Erreur", "Les mots de passe ne correspondent pas.", "danger")

            let response = null
        try {
            UserStore.updatePassword(form.currentPassword,form.password).then(res => {
                window.location.href="/account";
                response = res
             })
        } catch (error) {
            console.log(error);
        } finally{
            // console.log(response);
            //notify("Succès","Mot de passe modifié.");
        }

        // UserStore.updatePassword(form.currentPassword,form.password).then(res => {
        //     // window.location.href="/account";
        //     response = res
        //  }).catch(error=>{
        //     console.log(error.message);
        //  })
       
    }



    return (

        <>
            <h5 className="mb-3 mt-3 text-center">Changer mon mot de passe</h5>
            <div className="row">
                <form action="" onSubmit={handleSubmit}>
                    <div className="col-sm-12 col-md-6 offset-md-3">
                        <span>Ancien mot de passe</span>
                        <input type="password" name="currentPassword" onChange={handleChange} value={form.currentPassword} required name="currentPassword" className="form-control" />
                    </div>
                    <div className="col-sm-12 col-md-6 offset-md-3">
                        <span>Nouveau mot de passe</span>
                        <input type="password" name="password" onChange={handleChange} value={form.password} required name="password" className="form-control" />
                    </div>
                    <div className="col-sm-12 col-md-6 offset-md-3">
                        <span>Confirmer nouveau mot de passe</span>
                        <input type="password" name="passwordConfirm" onChange={handleChange} value={form.passwordConfirm} required name="passwordConfirm" className="form-control" />
                    </div>
                    <div className="col-sm-12 col-md-6 offset-md-3 text-center pt-5">
                        <button type="submit" className="btn btn-success">Valider</button>
                    </div>
                </form>
            </div>
        </>

    );
}
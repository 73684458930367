import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PublicationStore from "../../store/PublicationStore";
import Slider from "react-slick";
import { sliderConfig } from "../../helpers/Constants";



export default function SliderHome() {

   const [posts, setPosts] = useState([]);

   useEffect(() => {
      init()
   }, [])

   const init = async () => {
      const data = await PublicationStore.sliderPublication();
      setPosts(data);
   }

   return (
      <div id="sildeArea" className="container-fluid ps-0 pe-0 building">
         <div className="slick-slider">
            <div className="slick-slider-init">
               <Slider {...sliderConfig}>

                  {posts.map(post =>
                     <div className="slick-item" key={post.id}>
                        <div className="dgi-slide-item w-100 text-white">
                           <div className="dgi-slide-caption">
                              <div className="captionContent">
                                 <h2 data-animation-in="fadeIn" data-delay-in="1">
                                    {post.titre.substring(0, 62)}
                                 </h2>
                                 {/* <p data-animation-in="bounceInUp" data-delay-in="2" dangerouslySetInnerHTML={{ __html: post.contenuPublication.substring(0, 100) }}/>*/}
                                 <Link to={`/activites/${post.slug}`} data-animation-in="slideInUp" className="btn btn-warning text-dark btn-lg slideBtn waves-effect waves-light">
                                    Lire la  suite <i className="fas fa-long-arrow-alt-right"></i></Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  )}
               </Slider>


            </div>

         </div>
      </div>
   );
}
import React, { useEffect, useState } from 'react';
import Layout from "../../../components/front/Layout";
import { Calendar } from '../../../components/Icons';
import Pagination from '../../../components/Pagination';
import { dateFormat, getRandomColor, setTitle, useQuery } from '../../../helpers/Helper';
import PublicationStore from '../../../store/PublicationStore';
import ActivitiesFilter from "../../../components/front/activities/ActivitiesFilter"
import { Link } from 'react-router-dom';

export default function Recherche() {

   const query = useQuery();
   const [articles, setArticles] = useState([]);
   const [page, setPage] = useState(1)
   const [pagination, setPagination] = useState({});
   const categorie = query.get('categorie');
   const q = query.get('q')
   const color = { actus: 4, reformes: 1, communiques: 2 }

   useEffect(() => {
      init()
   }, [page, q, categorie])

   setTitle("Recherche: " + q)

   const init = async () => {
      const { data, pagination } = await PublicationStore.filters(q, categorie, page)
      setArticles(data);
      setPagination(pagination)
   }

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const Body = () => {
      return (
         <>
            <div className="page-info">
               <h2 className="text-center text-white">Votre recherche: {q}</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase"> RÉSULTAT DE VOTRE RECHERCHE</span>
                  </div>
               </div>
            </div>
            <div className="container mt-3  mb-4 page-content card">
               <div className="d-flex justify-content-between pt-2">
                  <div>Affichage {/*: <b> 1-20</b> de <b>200 </b>*/} résultats pour le terme <b className="text-decoration-underline">'{q}'</b></div>
                  <ActivitiesFilter />
               </div>

               <table className="table table-hover mt-4 search-result-table w-100">
                  <thead>
                     <tr>
                        <th className="w-50">Titre|Resume</th>
                        <th className="w-25">Date de création</th>
                        <th className="w-25">Catégorie</th>
                     </tr>
                  </thead>
                  <tbody>
                     {articles.map(article =>
                        <tr key={article.id}>
                           <td>
                              <h6><Link to={`/articles/${article.id}`}>{article.titre}</Link></h6>
                              <small dangerouslySetInnerHTML={{ __html: article.contenuPublication.substring(0, 150) + "..." }} className="text-mute" />
                           </td>
                           <td>
                              <div className="search-result-datetime">
                                 <Calendar /> <span>{dateFormat(article.publishedAt)}</span> à <span className="text-mute">{dateFormat(article.publishedAt, 'HH:mm')}</span></div>
                           </td>
                           <td><span style={{ backgroundColor: getRandomColor(color[article.categorie.libUnique]) }} className="badge rounded-pill">{article.categorie.libelle}</span></td>
                        </tr>
                     )}

                  </tbody>
               </table>
               {!articles.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun résultat pour votre recherche.</h2>}
               <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
               <br />
            </div>
         </>
      )
   }
   return <Layout body={<Body />} />
}
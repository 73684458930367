import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import { Eye } from "../../components/Icons";
import MessageStore from "../../store/MessageStore";
import Modal from "../../components/Modal";
import { DEFAULT_URL, SITE_URL } from "../../helpers/Constants";
import moment from "moment";
import 'moment/locale/fr'
moment.locale('fr')


const Message = ({ contenu }) => (<div dangerouslySetInnerHTML={{__html: contenu}}/>)


export default function Messages() {
    const [page, setPage] = useState(1)
    const [messages, setMessages] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [currenMessage, setCurrentMessage] = useState('');

    useEffect(() => {
        init();
    }, [page,showMessageModal ])

    const init = async () => {
        const { data, pagination } = await MessageStore.getMessages(page)
        setPagination(pagination)
        setMessages(data);
    }

    const openModal = async (message) => {
        setCurrentMessage(message)
        setShowMessageModal(true)
        await MessageStore.setReadedMessage(message.id)
    }

  


   const setCurrentPage = (page) => {
      setPage(page);
   }

    const Body = () => {
        return (
            <>

                <div className="p-1 breadcrumbs xs-font">
                    <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Messages</span>
                </div>
                <div className="content-body">
                    <h4 className="page-title">Messages</h4>
                    <div className="container mt-3">
                        <div className="card p-2">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                    <th >Reçu le </th>
                                    <th >Titre</th>
                                        <th>Expediteur</th>
                                        <th>Email</th>
                                        <th>Telephone</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {messages && messages.map(message =>
                                        <tr key={message.id}>
                                            <td className={`${!message.lu && 'textNotRead'}`}>{message.createdAt && moment(message.createdAt).format('DD MMMM YYYY hh:mm:ss')}</td>
                                            <td className={`${!message.lu && 'textNotRead'}`}>{message.autreService && message.autreService.toUpperCase()}</td>
                                            <td className={`${!message.lu && 'textNotRead'}`}>{message.prenom.toUpperCase()} {message.nom.toUpperCase()}</td>
                                            <td className={`${!message.lu && 'textNotRead'}`}>{message.email}</td>
                                            <td className={`${!message.lu && 'textNotRead'}`}>{message.numTel}</td>
                                            <td>
                                                <button style={{ color: '#fff', marginRight: '10px' }} className="btn btn-warning mr-3" onClick={()=>openModal(message)}>
                                                    <Eye />
                                                </button>
                                               { message.fichier &&  <a title="fichier joint" target="_blank" href={`${DEFAULT_URL}/${ message.fichier.contentUrl}` }><i className="fas fa-download"></i></a>}
                                                {/* <button className="btn btn-sm btn-danger" onClick={() => deleteFavorite(message.id)}><Trash/></button> */}
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                            <Pagination setCurrentPage={setCurrentPage} pagination={pagination} />
                        </div>
                    </div>
                </div>
                <Modal size="" body={<Message contenu={currenMessage.contenu} />} title={`${currenMessage.autreService} de ${currenMessage.prenom}`} show={showMessageModal} onClose={() => setShowMessageModal(false)} />

            </>
        );
    }
    return <Layout body={<Body />} />

}


import React from "react";
import { Link } from "react-router-dom";
import { dateFormat, getRandomColor, notify } from "../../../helpers/Helper";
import UserStore from "../../../store/UserStore";
import DocumentStore from "../../../store/DocumentStore";


export default function Report({ report, module = 'rapport-activite-generale', favorites = [] }) {

    const user = UserStore.state.currentUser;

    const checkFavorite = (document) => {
        const document_favorites = favorites.filter(l => l.rapport.id === document.id)
        if (document_favorites.length > 0) return true

        return false
    }

    const favorite = (event) => {
        event.preventDefault();
        DocumentStore.favorite('/api/documents/' + report.id, '/api/users/' + user.id).then(_ => notify('Succès', "Le document a été ajouté à vos favoris.", "success"))
    }

    return (
        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mt-5">
            <div className="book-showcase w-100">
                {(user !== null && !checkFavorite(report)) &&
                    <ul className="extra ">
                        <li>
                            <Link onClick={favorite} to={`/${module}/${report.id}`} title="Ajoute au favoris"><i className="fas fa-heart"></i></Link>
                        </li>
                    </ul>
                }
                <Link to={`/${module}/${report.id}`}>
                    <div className="p-2 d-flex justify-content-center w-100" style={{
                        backgroundColor: getRandomColor(),
                        paddingBottom: '7px!important'
                    }}>
                        <span className="xs-font text-white fw-bold">{report.annee}</span>
                    </div>
                    <h6 className="text-center ps-2 pt-3">{report.libelle}</h6>
                    <p className="summary"> {report.description.substring(0, 300)} </p>
                    <div className="extraDescription">
                        <p>
                            <i className="fas fa-university"></i> Structure : <br /><b>{report.structure !== undefined ? report.structure.intitule : ''}</b>
                        </p>
                        {/* <p><i className="fas fa-user"></i> Auteur <br /><b>{report.auteur.agent.nom}</b></p> */}
                        <p>
                            <i className="fas fa-calendar"></i> Date d'edition : <br /><b>{dateFormat(report.createdAt)}</b>
                        </p>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default function Step({ size = 3, index }) {

   let steps = [];
   for (var i = 1; i <= size; i++) {
      steps.push(i.toString());
   }
   return (
      <div className="stepwizard">

         <div className="stepwizard-row setup-panel">
            {steps.map(step =>
               <div className="stepwizard-step" key={step}>
                  <a href={`#step-${step}`} type="button" className={index === +step ? "btn btn-danger btn-circle" : "btn btn-secondary btn-circle"}>{step}</a>
                  <p className={index === +step ? "btn-danger" : "btn-secondary"}>Etape {step}</p>
               </div>
            )}

         </div>

      </div>
   )
}
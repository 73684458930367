import React from 'react';
import ReactLoading from 'react-loading';


const Loading = () => {
    return ( 
        <ReactLoading type="bubbles" color="#03a16f" height={800} width={80} />

     );
}
 
export default Loading;
import { Route, Redirect } from "react-router-dom"
//import { storedItemKeys } from "../helpers/Constants";
//import { storeItem } from "../helpers/Helper";
import UserStore from "../store/UserStore"

export default function PrivateRoute({ path, component }) {

   //storeItem(storedItemKeys.PRIVATE_ROUTE, path);

   if (!UserStore.isAuthenticated())
      return <Redirect to="/login" />

   if (!UserStore.hasAccess())
      return <Redirect to="/" />

   return <Route path={path} component={component} />
}
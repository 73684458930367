import httpClient from "./HttpClient";


const MissionStore = {

  state: {
    sources: [],
    orders: [],
    natures: [],
    status: [],
    missions: []
  },

  async getSources() {
    try {
      const response = await httpClient.get(`/source_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveSource(source) {
    if (source.id !== "" && source.id !== undefined)
      return httpClient.put('/source_missions/' + source.id, source);
    else
      return httpClient.post('/source_missions', source);
  },

  removeSource(id) {
    return httpClient.delete('/source_missions/' + id);
  },

  async getNatures() {
    try {
      const response = await httpClient.get(`/nature_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveNature(nature) {
    if (nature.id !== "" && nature.id !== undefined)
      return httpClient.put('/nature_missions/' + nature.id, nature);
    else
      return httpClient.post('/nature_missions', nature);
  },

  removeNature(id) {
    return httpClient.delete('/nature_missions/' + id);
  }
  ,

  async getStatus() {
    try {
      const response = await httpClient.get(`/statut_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveStatus(status) {
    if (status.id !== "" && status.id !== undefined)
      return httpClient.put('/statut_missions/' + status.id, status);
    else
      return httpClient.post('/statut_missions', status);
  },

  removeStatus(id) {
    return httpClient.delete('/statut_missions/' + id);
  }
  ,

  async getDomaines() {
    try {
      const response = await httpClient.get(`/domaine_remboursements`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveDomaine(domaine) {
    if (domaine.id !== "" && domaine.id !== undefined)
      return httpClient.put('/domaine_remboursements/' + domaine.id, domaine);
    else
      return httpClient.post('/domaine_remboursements', domaine);
  },

  removeDomaine(id) {
    return httpClient.delete('/domaine_remboursements/' + id);
  }
  ,

  async getNiveaus() {
    try {
      const response = await httpClient.get(`/niv_mis_oeuvre_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

 

  saveNiveau(niveau) {
    if (niveau.id !== "" && niveau.id !== undefined)
      return httpClient.put('/niv_mis_oeuvre_missions/' + niveau.id, niveau);
    else
      return httpClient.post('/niv_mis_oeuvre_missions', niveau);
  },

  removeNiveau(id) {
    return httpClient.delete('/niv_mis_oeuvre_missions/' + id);
  }
  ,
  async getMissions(page = 1, intitule = "", refPav = "", annee = "", nature = "") {
    try {
      let url = `/missions?page=${page}&itemsPerPage=10&order%5BcreatedAt%5D=desc`
      if (intitule !== "")
        url += `&intitule=${intitule}`
      if (refPav !== "")
        url += `&refPav=${refPav}`
      if (annee !== "")
        url += `&anneRattachement=${annee}`
      if (nature !== "")
        url += `&nature.libUnique=${nature}`
      const response = await httpClient.get(url);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getMission(id) {
    const response = await httpClient.get("/missions/" + id);
    const data = await response.data;
    return data;
  },

  saveMission(mission) {
    if (mission.id !== "" && mission.id !== undefined)
      return httpClient.put('/missions/' + mission.id, mission);
    else
      return httpClient.post('/missions', mission);
  },

  removeMission(id) {
    return httpClient.delete('/missions/' + id);
  },

  async getOrdres(page = 1) {
    try {
      const response = await httpClient.get(`/ordre_missions?page=${page}&order[createdAt]=DESC`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveOrdre(ordre) {
    if (ordre.id !== "" && ordre.id !== undefined)
      return httpClient.put('/ordre_missions/' + ordre.id, ordre);
    else
      return httpClient.post('/ordre_missions', ordre);
  },

  removeOrdre(id) {
    return httpClient.delete('/ordre_missions/' + id);
  },

  async getPersonnels() {
    try {
      const response = await httpClient.get(`/personnel_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  savePersonnel(personnel) {
    if (personnel.id !== "" && personnel.id !== undefined)
      return httpClient.put('/personnel_missions/' + personnel.id, personnel);
    else
      return httpClient.post('/personnel_missions', personnel);
  },

  removePersonnel(id) {
    return httpClient.delete('/personnel_missions/' + id);
  },

  async getProfils() {
    try {
      const response = await httpClient.get(`/profil_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveProfil(profil) {
    if (profil.id !== "" && profil.id !== undefined)
      return httpClient.put('/profil_missions/' + profil.id, profil);
    else
      return httpClient.post('/profil_missions', profil);
  },

  removeProfil(id) {
    return httpClient.delete('/profil_missions/' + id);
  },

  async getRapports(page = 1) {
    try {
      const response = await httpClient.get(`/rapport_missions?page=${page}`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveRapport(rapport) {
    if (rapport.id !== "" && rapport.id !== undefined)
      return httpClient.put('/rapport_missions/' + rapport.id, rapport);
    else
      return httpClient.post('/rapport_missions', rapport);
  },

  removeRapport(id) {
    return httpClient.delete('/rapport_missions/' + id);
  },

  async getRecommandations(page = 1) {
    try {
      const response = await httpClient.get(`/recommandation_rapports?page=${page}`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveRecommandation(recommandation) {
    if (recommandation.id !== "" && recommandation.id !== undefined)
      return httpClient.put('/recommandation_rapports/' + recommandation.id, recommandation);
    else
      return httpClient.post('/recommandation_rapports', recommandation);
  },

  removeRecommandation(id) {
    return httpClient.delete('/recommandation_rapports/' + id);
  },

  async getLieus() {
    try {
      const response = await httpClient.get(`/lieu_missions`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveLieu(lieu) {
    if (lieu.id !== "" && lieu.id !== undefined)
      return httpClient.put('/lieu_missions/' + lieu.id, lieu);
    else
      return httpClient.post('/lieu_missions', lieu);
  },

  removeLieu(id) {
    return httpClient.delete('/lieu_missions/' + id);
  },

  async getAnnees() {
    try {
      const response = await httpClient.get(`/annees`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveAnnee(annee) {
    if (annee.id !== "" && annee.id !== undefined)
      return httpClient.put('/annees/' + annee.id, annee);
    else
      return httpClient.post('/annees', annee);
  },

  removeAnnee(id) {
    return httpClient.delete('/annees/' + id);
  },
  async getStructures() {
    try {
      const response = await httpClient.get(`/structures?pagination=false`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  async getRapportsImg(page=1) {
    try {
      const response = await httpClient.get(`/rapports_igms?page=${page}&itemsPerPage=10`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveRapportIgm(rapport) {
    if (rapport.id !== "" && rapport.id !== undefined)
      return httpClient.put('/rapports_igms/' + rapport.id, rapport);
    else
      return httpClient.post('/rapports_igms', rapport);
  },

  removeRapportIgm(id) {
    return httpClient.delete('/rapports_igms/' + id);
  },

  async getAvis(page=1) {
    try {
      const response = await httpClient.get(`/avis_conseil_ministres?page=${page}&itemsPerPage=10&order[createdAt]=DESC`);
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (e) {
      return { data: [], pagination: {} };
    }
  },

  saveAvis(avis) {
    if (avis.id !== "" && avis.id !== undefined)
      return httpClient.put('/avis_conseil_ministres/' + avis.id, avis);
    else
      return httpClient.post('/avis_conseil_ministres', avis);
  },

  removeAvis(id) {
    return httpClient.delete('/avis_conseil_ministres/' + id);
  },

}

export default MissionStore


import React, { useEffect, useState } from "react";
import Layout from "../../../../components/biblio/Layout";
import Nav from "../../../../components/biblio/missions/Nav";
import SubNavCoso from "../../../../components/biblio/missions/SubNavCoso";
import { Pencil, Save, Trash } from "../../../../components/Icons";
import Modal from "../../../../components/Modal";
import { USER_ROLES } from "../../../../helpers/Constants";
import { notify, setTitle } from "../../../../helpers/Helper";
import CosoStore from "../../../../store/CosoStore";
import UserStore from "../../../../store/UserStore";

const Form = ({ composante, reset, close }) => {

   const [current, setCurrent] = useState(composante);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      CosoStore.saveComposante(current).then(_ => {
         notify("Succès", "La composante coso a été enregistrée.", "success");
         setCurrent(reset);
         e.target.reset();
         close()

      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Libellé <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required />
            </div>
         </div>
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Composante() {

   const [composantes, setComposantes] = useState([]);
   const [composante, setComposante] = useState({ libelle: "" });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      init();
   }, [])

   const init = async () => {
      setTitle("Composantes coso")
      setLoaging(true)
      const { data: _composantes } = await CosoStore.getComposantes();
      setComposantes(_composantes);
      setLoaging(false)
   }

   const removeComposante = (id) => {
      if (window.confirm("Supprimer cette composante coso?"))
         CosoStore.removeComposante(id).then(_ => init())
   }

   const updateComposante = (composante) => {
      setComposante(composante);
      setShowModal(true);
   }

   const newComposante = (e) => {
      e.preventDefault();
      setComposante({});
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setComposante({ libelle: "" })
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavCoso/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Composantes COSO</span>
            </div>
            <h4 className="page-title">Les composantes coso</h4>
            <button onClick={newComposante} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer une composante coso</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
                  <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Libellé</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {composantes.map((composante, index) =>
                           <tr key={composante.id}>
                              <td>{index + 1}</td>
                              <td>{composante.libelle}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateComposante(composante)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeComposante(composante.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer une composante de mission" body={<Form composante={composante} reset={{ libelle: "" }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
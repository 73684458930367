import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import {
  Biblio,
  DEFAULT_URL,
  USER_ROLES,
  USER_ROLES_LABEL,
} from "../../helpers/Constants";
import { doLogout, notify } from "../../helpers/Helper";
import UserStore from "../../store/UserStore";
import { Send, User } from "../Icons";
import SharedStore from "../../store/SharedStore";
import NotificationStore from "../../store/NotificationStore";
import HomeStore from "../../store/HomeStore";
import MessageStore from "../../store/MessageStore";
import Modal from "../Modal";
import Select from "react-select";
import DocumentStore from "../../store/DocumentStore";
import StructureStrore from "../../store/StructureStore";
import SendRapportSuivi from "./send_rapport/suivi";
import { SUIVI_DES_RECOMMADATIONS } from "../../helpers/Constants";
import { getStoredItem } from "../../helpers/Helper";
import { storedItemKeys } from "../../helpers/Constants";

const Contact = ({ onClose = null, user }) => {
  const [form, setForm] = useState({
    nom: user.agent.nom,
    prenom: user.agent.prenom,
    email: user.email,
    numTel: "#",
    contenu: "",
    autreService: "Rapport protégé.",
    structure: {},
  });

  const [organes, setOrganes] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _organes = await StructureStrore.getAll();
    setOrganes(_organes);

    const _options = _organes.map((organe) => {
      return { value: organe["@id"], label: organe.organeControle };
    });
    setOptions(_options);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    if (formData.get("file").name !== "") {
      const fichier = await DocumentStore.uploadMedia(formData, "file");
      form["fichier"] = fichier["@id"];
      setForm(form);
    }
    HomeStore.sendMessageToIGF(form).then((_) => {
      event.target.reset();
      setForm({
        nom: "",
        prenom: "",
        email: "",
        numTel: "",
        contenu: "",
        autreService: "",
        structure: {},
      });
      notify("Succès", "Le rapport a été partagé.", "success");
      if (null !== onClose) {
        onClose();
      }
    });
  };

  const handleOrganeChange = (option) => {
    setForm({ ...form, structure: option.value });
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <div className="row mb-3">
        <div className="col-md-12">
          <div className="form-group">
            <span>
              Organes de contrôle{" "}
              <small className="fw-bold">[obligatoire]</small>
            </span>
            <Select
              options={options}
              onChange={handleOrganeChange}
              placeholder="Organes de contrôle"
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="form-group">
          <span>
            Joinder un fichier <small className="fw-bold">[obligatoire]</small>
          </span>
          <input name="file" type="file" className="form-control" />
        </div>
      </div>
      <div className="mb-3">
        <div className="form-group">
          <span>
            Commentaire <small className="fw-bold">[obligatoire]</small>
          </span>
          <textarea
            onChange={handleChange}
            value={form.contenu}
            required
            name="contenu"
            cols="30"
            rows="5"
            className="form-control"
          ></textarea>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-warning mx-auto d-block"
        style={{ width: "100%" }}
      >
        <Send /> Envoyer
      </button>
    </form>
  );
};

export default function Header() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const setSideNavClass = SharedStore((state) => state.setSideNavClass);
  const setShowSideBar = SharedStore((state) => state.setShowSideBar);
  const [count_message, setCountMessage] = useState(0);
  const user = UserStore.state.currentUser;
  const [params, setParams] = useState({});
  const [showContactModal, setShowContactModal] = useState(false);
  const [showRapportModal, setShowRapportModal] = useState(false);
  const [token, setToken] = useState();
  const notificationCount = SharedStore((state) => state.notificationCount);
  const setNotificationCount = SharedStore(
    (state) => state.setNotificationCount
  );

  useEffect(() => {
    init();
    setToken(getStoredItem(storedItemKeys.TOKEN))
  }, []);

  const showSideNav = () => {
    setShowSideBar(true);
    setSideNavClass("openNav");
  };

  const init = async () => {
    const { data } = await NotificationStore.getNotifications(false);
    const { count } = await MessageStore.getNotRead();
    setCountMessage(count);
    const _params = await HomeStore.getParams();
    setParams(_params);
    setNotificationCount(data.filter((n) => !n.lu).length);
  };

  const showRapportMissionForm = (e) => {
    e.preventDefault();
    setShowRapportModal(true);
  };

  const showContactForm = (e) => {
    e.preventDefault();
    setShowContactModal(true);
  };

  return (
    <>
      <div id="navMenu" className="container-fluid bckColorDark">
        <nav id="navbar_top">
          <div className="d-flex justify-content-between">
            <div className="show4Mobile">
              {isMobile && (
                <button
                  className="navbar-toggler textPrimary show4Mobile"
                  type="button"
                  id="es-navtoggle"
                  onClick={showSideNav}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-menu-2"
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="4" y1="6" x2="20" y2="6" />
                    <line x1="4" y1="12" x2="20" y2="12" />
                    <line x1="4" y1="18" x2="20" y2="18" />
                  </svg>
                </button>
              )}
            </div>
            <a className="navbar-brand me-3 pb-0" id="navbar-brand" href="/">
              <img
                src={DEFAULT_URL + params.logo}
                alt={Biblio.NAME}
                className="site-logo"
              />
            </a>
            <div className="d-flex" style={{ paddingTop: "9px" }}>
              <>
                {UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && (
                  <a
                    href="#/"
                    onClick={showContactForm}
                    className="p-3 textPrimary hide4mobile"
                    title="TRANSMISSION DE RAPPORT À UN CHEF D'ORGANE"
                  >
                    <i className="fas fa-envelope fa-lg notification"></i>
                    <small className="text-white ">
                      {" "}
                      ENVOI RAPPORT CHEF D'ORGANE
                    </small>
                  </a>
                )}

                {/* {(UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) ||
                  UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) && (
                    <a
                      href="#"
                      onClick={showRapportMissionForm}
                      className="p-3 pe-3 textPrimary hide4mobile"
                      title="TRANSMISSION DE RAPPORT À UN ORGANE DE CONTRÔLE"
                    >
                      <i className="fas fa-envelope fa-lg  notification" />
                      <span className="text-white  ">
                        {" "}
                        <small>ENVOI RAPPORT SUIVI</small>

                      </span>
                    </a>
                  )} */}

                {/* {   UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) &&                                 <Link to="/bibliotheque/rapports-envoyes" className="p-1 textPrimary hide4mobile" title="RAPPORTS ENVOYES">
                                        <i className="far fa-file notification"/>
                                        <span className="badge bg-danger"/>
                                        <span className="text-white "> RAPPORTS ENVOYES</span>
                                    </Link>} */}

                {/* {(UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) ||
                  UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR) ||
                  UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) && (
                    <Link
                      to="/bibliotheque/rapports-suivis-recommandation"
                      className="p-3 textPrimary hide4mobile"
                      title="RAPPORTS REÇUS"
                    >
                      <i className="far fa-file-alt fa-lg  notification" />
                      <span className="badge bg-danger" />
                      <small className="text-white "> RAPPORTS DE SUIVI</small>
                    </Link>
                  )} */}

                {/* {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ||
                  UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) && (
                    <Link
                      to="/bibliotheque/rapports-suivis-recommandation-partage"
                      className="p-3 textPrimary hide4mobile"
                      title="RAPPORTS REÇUS"
                    >
                      <i className="far fa-file-alt fa-lg notification" />
                      <span className="badge bg-danger" />
                      <small className="text-white ">
                        {" "}
                        RAPPORTS DE SUIVI PARTAGES
                      </small>
                    </Link>
                  )} */}

                <a
                  href={SUIVI_DES_RECOMMADATIONS + token}
                  className="me-2 mt-3"
                >
                  <i className="far fa-file-alt fa-lg notification" />
                  <span className="badge bg-danger" />
                  <small className="text-white ">
                    {" "}
                    SUIVI DES RECOMMANDATIONS
                  </small>
                </a>

                {notificationCount > 0 &&
                  <Link
                    to="/bibliotheque/notifications"
                    className="p-3 textPrimary hide4mobile"
                    title="NOTIFICATIONS"
                  >
                    <i className="far fa-bell fa-lg notification" />
                    <span className="badge bg-danger">{notificationCount}</span>
                  </Link>
                }

                {count_message > 0 && <Link
                  to="/bibliotheque/messages"
                  className="p-3 textPrimary hide4mobile"
                  title="MESSAGES REÇUS"
                >
                  <i className="far fa-envelope fa-lg notification" />
                  <span className="badge bg-danger">{count_message}</span>
                </Link>}
              </>

              {/*<img src={Biblio.LOGO} alt={`logo ${Biblio.NAME}`} className="logo" />*/}
              <div className="dropdown">
                {user.agent && (
                  <button className="dropbtn">
                    <User />
                    {user.agent.nom[0]}.{user.agent.prenom[0]} (
                    {user.roles.map((r) => (
                      <span key={r}>{USER_ROLES_LABEL[r]}</span>
                    ))}
                    )
                  </button>
                )}
                <div className="dropdown-content">
                  <Link to="/account">Mon compte</Link>
                  <a onClick={doLogout} href="/">
                    Deconnexion
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Modal
        body={
          <Contact
            user={UserStore.state.currentUser}
            onClose={() => setShowContactModal(false)}
          />
        }
        title=" TRANSMISSION RAPPORT A UN ORGANE DE CONTROLE"
        show={showContactModal}
        onClose={() => setShowContactModal(false)}
      />

      <Modal
        body={
          <SendRapportSuivi
            user={UserStore.state.currentUser}
            onClose={() => setShowRapportModal(false)}
          />
        }
        title="TRANSMISSION DE RAPPORT SUIVI"
        show={showRapportModal}
        onClose={() => setShowRapportModal(false)}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Search, Filter as IconFilter } from "../../../components/Icons";
import Footer from '../../../components/Footer';
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import { dateFormat, getRandomColor, lastYears, notify, setTitle, useQuery } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import StructureStore from "../../../store/StructureStore";
import Nav from "../../../components/biblio/Nav";
import Header from "../../../components/biblio/Header";
import { Link } from "react-router-dom";
import UserStore from "../../../store/UserStore";
import Select from "react-select";

const FilterForm = ({ options }) => {

   const { thisYear, years } = lastYears(5)

   const handleOrganeChange = (option) => {
      window.location.href = "/bibliotheque/rapports-proteges?structureCode=" + option.value
   }

   return (
      <>

         <nav>
            <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
               <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Structure
               </button>
               <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Année
               </button>

            </div>
         </nav>
         <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
               <Select options={options} onChange={handleOrganeChange} placeholder="Organes de contrôle" />
               {/* <select name="" className="form-control mt-3" onChange={(e) => window.location.href = "/bibliotheque/rapports-proteges?structureCode=" + e.target.value}>
                     <option value="">--Choisir le Ministère --</option>
                     {structures.map(structure => <option value={structure.id} key={structure.id}>{structure.intitule}</option>)}
                  </select> */}
            </div>
            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
               <select name="" className="form-control mt-3" onChange={(e) => window.location.href = "/bibliotheque/rapports-proteges?annee=" + e.target.value}>
                  <option value="">--Recherche par année--</option>
                  <option value={thisYear}>{thisYear}</option>
                  {years.map(year => <option key={year} value={year}>{year}</option>)}
               </select>
            </div>
         </div>

      </>
   )
}

const Reporte = ({ report, module = 'rapport-activite-generale', favorites }) => {
   const user = UserStore.state.currentUser;
   const favorite = (event) => {
      event.preventDefault();
      DocumentStore.favorite('/api/documents/' + report.id, '/api/users/' + user.id).then(_ => notify('Succès', "Le document a été ajouté à vos favoris.", "success"))
   }

   const checkFavorite = (document) => {
      const document_favorites = favorites.filter(l => l.rapport.id === document.id)
      return document_favorites.length > 0;
   }

   return (
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mt-5">
         <div className="book-showcase w-100">
            {!checkFavorite(report) && <ul className="extra ">
               <li><Link onClick={favorite} to={`/bibliotheque/${module}/${report.id}`} title="Ajoute au favoris"><i className="fas fa-heart"/></Link></li>
            </ul>}
            <Link to={`/bibliotheque/${module}/${report.id}`}>
               <div className="p-2 d-flex justify-content-center w-100" style={{ backgroundColor: getRandomColor(), paddingBottom: '7px!important' }}>
                  <span className="xs-font text-white fw-bold">{report.annee}</span>
               </div>
               <h6 className="text-center ps-2 pt-3">{report.libelle}</h6>
               {/* <p className="summary"> {report.description.substring(0, 300)} </p> */}
               <div className="extraDescription">
                  <p><i className="fas fa-university"/> Structure :  <br /><b>{report.auteur.agent.structure !== undefined ? report.auteur.agent.structure.intitule : ''}</b></p>
                  <p><i className="fas fa-folder"/> Type document :  <br /><b>{report.module.libelle}</b></p>
                  <p><i className="fas fa-calendar"/> Date d'edition :  <br /><b>{dateFormat(report.createdAt)}</b></p>
               </div>
            </Link>
         </div>
      </div>
   );
}


export default function Reports() {

   const [showFilterForm, setshowFilterForm] = useState(false);
   const [reports, setReports] = useState([]);
   const [page, setPage] = useState(1)
   const [pagination, setPagination] = useState({});
   const [structures, setStructures] = useState([]);
   const [options, setOptions] = useState([]);
   const [loading, setLoaging] = useState(false);

   const [favorites, setFavorites] = useState([])

   let query = useQuery();

   const annee = query.get('annee') !== null ? query.get('annee') : '';
   const search = query.get('q') !== null ? query.get('q') : '';

   const title = "Les rapports de mission " + annee + " " + search;

   const [q, setQ] = useState(search)

   setTitle(title);

   useEffect(() => {
      init();
     
   }, [page])

   useEffect(() => {
      init(q);
     
   }, [q])


   const setCurrentPage = (page) => {
      setPage(page);
   }

   const init = async (q) => {
      setLoaging(true)
      const { data, pagination } = !q?await DocumentStore.getAll('rapports-proteges', page, query.get('annee'), query.get('structureCode'), query.get('q')):await DocumentStore.getAllSearch(q,page);
      setPagination(pagination)
      setReports(data);
      setLoaging(false)
      const _structures = await StructureStore.getAll();
      setStructures(_structures);
      const { data: noticationData } = await DocumentStore.getFavoriteDocuments(page, 100)
      setFavorites(noticationData)
      const _options = _structures.map(organe => {
         return { value: organe['id'], label: organe.intitule }
      })

      setOptions(_options);
      
   }

   const searchReport = (e) => {
      e.preventDefault();
      window.location.href = "/bibliotheque/rapports-proteges?q=" + q;
   }

   return (
      <>
         <Nav />
         <div className="es-content">
            <Header />
            <div className="filterArea bg-white">
               <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">
                  <div className="p-2 bd-highlight ">
                     <div className="search-box">
                        <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => setQ(e.target.value)} />
                        <a href="/" className="search-btn" onClick={searchReport}>
                           <Search />
                        </a>
                     </div>
                  </div>

                  <div className="p-2 d-flex bd-highlight me-3 hide4mobile">
                     <div className="p-2 flex-shrink-1 bd-highlight  hide4mobile">
                        <button className="btn btn-sm btn-warning" onClick={() => setshowFilterForm(true)} data-bs-toggle="modal" data-bs-target="#filterModal">Filtre <IconFilter /></button>
                     </div>
                  </div>
               </div>
            </div>
            <div className="page-info">
               <h2 className="text-center text-white text-uppercase"> {title}</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase">{title}</span>
                  </div>
               </div>
            </div>

            <div className={`container-fluid mb-4 page-content`}>
               <div className={`row ${loading ? 'loading' : ''}`}>
                  {reports && reports.map((report, index) => <Reporte key={index} report={report} favorites={favorites} module='rapports-proteges' />)}
                  {reports && !reports.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun document disponible.</h2>}
                  {!loading && <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />}
               </div>
            </div>
            <Modal style={{overflowY:'initial'}} show={showFilterForm} onClose={() => setshowFilterForm(false)} body={<FilterForm options={options} />} title="Filtrer par" />

            <Footer />
         </div>

      </>
   )
}
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import {
  dateFormat,
  getAuthToken,
  notify,
  setTitle,
} from "../../helpers/Helper";
import StructureStrore from "../../store/StructureStore";
import { useParams } from "react-router-dom";
import UserStore from "../../store/UserStore";
import { USER_ROLES } from "../../helpers/Constants";
import MissionStore from "../../store/MissionStore";
import Loading from "../../helpers/Loading";

export default function RapportRecusDetailRecommandation() {
  const { recommandationId } = useParams();
  const [recommandation, setRecommandation] = useState(null);
  const [observations, setObservations] = useState([]);
  const [showCommentPanel, setShowCommentPanel] = useState(false);
  const [editRecom, setEditRecom] = useState(false);
  const [showCommentAddPanel, setShowCommentAddPanel] = useState(false);

  const [domaine_remboursements, setDomaineRemboursements] = useState([]);
  const [niveaux, setNiveaux] = useState([]);
  const [loading, setLoading] = useState(true);

  setTitle("RAPPORTS DE SUIVI ET RECOMMANDATION");

  useEffect(() => {
    init();
  }, []);

  const approveRecommandation = async () => {
    try {
      await StructureStrore.updateSuiviRecRapport({
        ...recommandation,
        etat: "valide",
      });
    } catch (error) {
    } finally {
      notify("Succès", "Recommandation approuvé avec succès !", "success");
      init();

    }
  };

  const demandeReformuleRecommandation = async (commentaire) => {
    try {
      await StructureStrore.updateSuiviRecRapport({
        ...recommandation,
        etat: "dem_reformule",
        commentaire,
      });
    } catch (error) {
    } finally {
      notify(
        "Succès",
        "Demande de reformulation de Recommandation envoyée avec succès !",
        "success"
      );
      init();
      setShowCommentPanel(false);
    }
  };

  const sendData = async () => {
    const rec = {
      nivMiseEnOeuvre: recommandation.nivMiseEnOeuvre,
      libelle: document.getElementById("libelle-rec").value,
      ActionsAMener: document.getElementById("action-rec").value,
      constatation: recommandation.constatation,
      etat: "reformule",
      id: recommandation.id,
    };
    // console.log(rec);
    setEditRecom(false);
    await StructureStrore.updateRecommandation(rec);
    init();
  };

  const addCommentaireRecommandation = async (observation) => {
    try {
      // console.log({recommandation:recommandation['@id'], rapport:recommandation.rapport['@id'],  observation });
      await StructureStrore.addObservationSuiviRecRapport({
        recommandation: recommandation["@id"],
        rapport: recommandation.rapport["@id"],
        observation,
      });
    } catch (error) {
    } finally {
      notify("Succès", "Commentaire envoyé avec succès !", "success");
      setShowCommentAddPanel(false);
      init();
      // setEditRecom(false);
    }
  };

  const postCommentRecommandation = async () => {
    let comment = document.getElementById("comment").value;
    await demandeReformuleRecommandation(comment);
  };

  const addCommentRecommandation = async () => {
    let comment = document.getElementById("comment-add").value;
    await addCommentaireRecommandation(comment);
  };

  const getObservationRecommandation = async (rec) => {
    let { data } = await StructureStrore.getObservationRecommandation(rec);
    setObservations(data);
  };

  const init = async () => {
    let data = null;

    try {
      data = await StructureStrore.getRecommandationDetail(recommandationId);

      const _domaineR = await StructureStrore.getDomainesRemboursements();
      const { data: _niveaux } = await MissionStore.getNiveaus();
      setNiveaux(_niveaux);
      setDomaineRemboursements(_domaineR);
    } catch (error) {
    } finally {
      setRecommandation(data);

      if (
        data.etat == "dem_reformule" &&
        UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR)
      ) {
        setEditRecom(true);
      }
      await getObservationRecommandation(data);
      setLoading(false)

    }
  };

  const Body = () => {
    return (
      <Fragment>
      {loading && <Loading/>}
     {!loading && <div>
        <div className="p-1 breadcrumbs xs-font mb-3">
          <Link to="/bibliotheque" className="textPrimary fw-bold">
            Accueil{" "}
          </Link>
          <Link to="/bibliotheque/rapports-suivis-recommandation">
            / Rapports de suivi et recommandations
          </Link>
          {recommandation && (
            <Link
              to={`/bibliotheque/rapports-suivis-recommandation/${
                recommandation.rapport && recommandation.rapport.id
              }`}
            >
              / {recommandation.rapport.libelle}
            </Link>
          )}
          {/* {recommandation && <span>/ {recommandation.libelle}</span>
                    } */}

          {recommandation && (
            <span title={recommandation.libelle}>
              /{" "}
              {recommandation.libelle.length > 40
                ? `${recommandation.libelle.substr(0, 40)}...`
                : recommandation.libelle}
            </span>
          )}
        </div>

        <div className="content-body">
          <h4 className="page-title mt-5">
            Détails de la Recommandation
            {/* {recommandation && recommandation.libelle}  */}
            {/* {recommandation.libelle.length>40?`${recommandation.libelle.substr(1, 40)}...`:recommandation.libelle} */}
          </h4>
          <div className="container-fluid mt-3 mb-3">
            <div className="row mt-3 p-3">
              <div className="card mb-2  ">
                <div className="card-body ">
                  <div className=" pull-right flex">
                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ||
                      UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) &&
                      recommandation &&
                      recommandation.etat != "valide" && (
                        <button
                          className="btn btn-sm  btn-success m-1"
                          onClick={() =>
                            window.confirm(
                              "Êtes vous sûr de transmettre cette mention ?"
                            ) && approveRecommandation()
                          }
                        >
                          PAS DE COMMENTAIRE
                        </button>
                      )}
                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ||
                      UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) &&
                      recommandation &&
                      recommandation.etat != "dem_reformule" && (
                        <button
                          className="btn btn-sm  btn-warning m-1"
                          onClick={() => setShowCommentPanel(!showCommentPanel)}
                        >
                          DEMANDER UNE REFORMULATION
                        </button>
                      )}
                    {UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) &&
                      editRecom &&
                      recommandation &&
                      recommandation.etat != "reformule" && (
                        <button
                          className="btn btn-info m-1"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Êtes vous sur d'envoyer cette recommandation"
                              )
                            ) {
                              sendData();
                            }
                          }}
                        >
                          SOUMETTRE LA RECOMMANDATION
                        </button>
                      )}
                    {
                      <button
                        className="btn btn-sm  m-1"
                        onClick={() =>
                          setShowCommentAddPanel(!showCommentAddPanel)
                        }
                      >
                        AJOUTER COMMENTAIRE
                      </button>
                    }
                  </div>
                </div>
              </div>

              {showCommentPanel && (
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <textarea
                      className="form-control"
                      id="comment"
                      type="text"
                      name="comment"
                      placeholder="Envoyer un commentaire pour la demande de reformulation"
                    />
                    <button
                      className="btn btn-warning m-1"
                      onClick={() => postCommentRecommandation(false)}
                    >
                      ENVOYER LA DEMANDE DE REFORMULATION
                    </button>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => setShowCommentPanel(false)}
                    >
                      ANNULER
                    </button>
                  </div>
                </div>
              )}

              {showCommentAddPanel && (
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <textarea
                      className="form-control"
                      id="comment-add"
                      type="text"
                      name="comment"
                      placeholder="Poster un commentaire"
                    />
                    <button
                      className="btn btn-warning m-1"
                      onClick={() => addCommentRecommandation(false)}
                    >
                      ENVOYER
                    </button>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => setShowCommentAddPanel(false)}
                    >
                      ANNULER
                    </button>
                  </div>
                </div>
              )}

              <div className="card mt-3 col-8">
                <div className="card-body ">
                  <h5 className="card-title">
                    {recommandation && recommandation.libelle}
                  </h5>
                  {recommandation && (
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item p-4">
                        Etat de la demande{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          <span>
                            {recommandation.etat ? (
                              recommandation.etat == "valide" ? (
                                <span className="badge badge-secondary bckColorDark ">
                                  SANS COMMENTAIRE
                                </span>
                              ) : recommandation.etat == "dem_reformule" ? (
                                <span className="badge badge-secondary secBckColor">
                                  A REFORMULER
                                </span>
                              ) : (
                                <span className="badge badge-primary  secBckColor">
                                  REFORMULÉE
                                </span>
                              )
                            ) : (
                              <span className="badge badge-secondary darkGrayColor">
                                TRANSMIS
                              </span>
                            )}
                          </span>{" "}
                        </span>
                      </li>

                      <li className="list-group-item p-4">
                        Recommandation{" "}
                        {!editRecom && (
                          <span className="pull-right text-bold">
                            {" "}
                            {recommandation.libelle}{" "}
                          </span>
                        )}
                        {editRecom && (
                          <textarea
                            className="pull-right form-control"
                            name="libelle"
                            // onChange={(event) => {
                            //   setRecommandation({
                            //     ...recommandation,
                            //     [event.target.name]: `${event.target.value}`,
                            //   });
                            // }}
                            id="libelle-rec"
                            defaultValue={
                              recommandation.libelle
                                ? recommandation.libelle
                                : ""
                            }
                          />
                        )}{" "}
                      </li>
                      <li className="list-group-item p-4">
                        Responsable{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.agentSuivi &&
                            recommandation.agentSuivi.nom}{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Action à mener{" "}
                        {!editRecom && (
                          <span className="pull-right text-bold">
                            {" "}
                            {recommandation.ActionsAMener}{" "}
                          </span>
                        )}
                        {editRecom && (
                          <textarea
                            className="pull-right form-control"
                            name="ActionsAMener"
                            id="action-rec"
                            defaultValue={
                              recommandation.ActionsAMener
                                ? recommandation.ActionsAMener
                                : ""
                            }
                          />
                        )}{" "}
                      </li>
                      <li className="list-group-item p-4">
                        Niveau de mise en oeuvre{" "}
                        {!editRecom && (
                          <span className="pull-right text-bold">
                            {" "}
                            {recommandation.nivMiseEnOeuvre && (
                              <span className="badge badge-secondary grayBckColor text-uppercase ">
                                {recommandation.nivMiseEnOeuvre.libelle}
                              </span>
                            )}{" "}
                          </span>
                        )}
                        {editRecom && recommandation && (
                          <select
                            name="nivMiseEnOeuvre"
                            value={
                              recommandation.nivMiseEnOeuvre
                                ? recommandation.nivMiseEnOeuvre["@id"]
                                : null
                            }
                            className="form-control"
                            onChange={(event) => {
                              setRecommandation({
                                ...recommandation,
                                nivMiseEnOeuvre: {
                                  "@id": `${event.target.value}`,
                                },
                              });
                            }}
                          >
                            <option>Sélectionner </option>
                            {niveaux &&
                              niveaux.map((d, index) => (
                                <option value={d["@id"]}>{d.libelle} </option>
                              ))}
                          </select>
                        )}
                      </li>
                      <li className="list-group-item p-4">
                        Echéance de fin d'exécution{" "}
                        {!editRecom && (
                          <span className="pull-right text-bold">
                            {recommandation.delaisMisEnOeuvre} jours{" "}
                          </span>
                        )}
                        {editRecom && (
                          <input
                            className="pull-right form-control"
                            name="delaisMisEnOeuvre"
                            id="delai-rec"
                            type="number"
                            defaultValue={
                              recommandation.delaisMisEnOeuvre
                                ? recommandation.delaisMisEnOeuvre
                                : ""
                            }
                          />
                        )}{" "}
                      </li>
                      <li className="list-group-item p-4">
                        Constatation{" "}
                        {!editRecom && (
                          <span className="pull-right text-bold">
                            {" "}
                            {recommandation.constatation &&
                              recommandation.constatation.libelle}{" "}
                          </span>
                        )}
                        {editRecom && recommandation && (
                          <select
                            name="constatation"
                            value={
                              recommandation.constatation
                                ? recommandation.constatation["@id"]
                                : null
                            }
                            className="form-control"
                            onChange={(event) =>
                              setRecommandation({
                                ...recommandation,
                                [event.target.name]: {
                                  "@id": `${event.target.value}`,
                                },
                              })
                            }
                          >
                            <option>Sélectionner </option>
                            {domaine_remboursements &&
                              domaine_remboursements.map((d, index) => (
                                <option value={d["@id"]}>{d.libelle} </option>
                              ))}
                          </select>
                        )}
                      </li>
                      {/* <li className="list-group-item p-4">
                        Dernier commentaire{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.commentaire}{" "}
                        </span>
                      </li> */}
                    </ul>
                  )}
                </div>
              </div>
              <div className="card mb-2 mt-3 col-4 ">
                <div className="card-body  ">
                  <h5> Liste des Observations</h5>
                  <ul className="list-group list-group-flush">
                    {observations.map((o) => (
                      <li className="list-group-item " key={o.id}>
                        <b>
                          {dateFormat(o.createdAt, "DD/MM/YYYY à HH:mm")} :{" "}
                        </b>
                        {o.type == "Commentaire"
                          ? `${o.createdBy.agent.nom} ${o.createdBy.agent.prenom} a posté un commentaire : ${o.observation}`
                          : o.observation}{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div> }
      </Fragment>
    );
  };
  return <Layout body={<Body />} />;
}

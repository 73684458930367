import React, { useEffect, useState } from "react";
import { Search, Filter as IconFilter } from "../../../components/Icons";
import Footer from '../../../components/Footer';
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import { dateFormat, getRandomColor, lastYears, notify, setTitle, useQuery } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import Nav from "../../../components/biblio/Nav";
import Header from "../../../components/biblio/Header";
import { Link } from "react-router-dom";
import HomeStore from "../../../store/HomeStore";
import UserStore from "../../../store/UserStore";
import { USER_ROLES } from "../../../helpers/Constants";

const FilterForm = ({ modules }) => {
   const { thisYear, years } = lastYears(5)
   return (
      <>
         <div className="modal-body">
            <nav>
               <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Catégorie document
                  </button>
                  <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Année
                  </button>

               </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
               <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <select name="" className="form-control mt-3" onChange={(e) => window.location.href = "/bibliotheque/mes-documents?module=" + e.target.value}>
                     <option value="">--Choisir le type de document --</option>
                     {modules.map(module => <option value={module.libUnique} key={module.id}>{module.libelle}</option>)}
                  </select>
               </div>
               <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <select name="" className="form-control mt-3" onChange={(e) => window.location.href = "/bibliotheque/mes-documents?annee=" + e.target.value}>
                     <option value="">--Recherche par année--</option>
                     <option value={thisYear}>{thisYear}</option>
                     {years.map(year => <option key={year} value={year}>{year}</option>)}
                  </select>
               </div>
            </div>
         </div>
      </>
   )
}

const Reporte = ({ report,user }) => {
   
   const favorite = (event) => {
      event.preventDefault();
      DocumentStore.favorite(report['@id'], '/api/users/' + user.id).then(_ => notify('Succès', "Le document a été ajouté à vos favoris.", "success"))
  }

   return (
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 mt-5">
         <div className="book-showcase w-100">
            <ul className="extra ">
               <li><Link onClick={favorite} to={`/bibliotheque/documents/${report.id}`} title="Ajoute au favoris"><i className="fas fa-heart"></i></Link></li>
            </ul>
            <Link to={`/bibliotheque/documents/${report.id}`}>
               <div className="p-2 d-flex justify-content-center w-100" style={{ backgroundColor: getRandomColor(), paddingBottom: '7px!important' }}>
                  <span className="xs-font text-white fw-bold">{report.annee}</span>
               </div>
               <h6 className="text-center ps-2 pt-3">{report.libelle}</h6>
               <p className="summary"> {report.description.substring(0, 300)} </p>
               <div className="extraDescription ps-2  pt-3">
                  <p><i className="fas fa-university"></i> Structure :  <br /><b>{report.structure !== undefined ? report.structure.intitule : ''}</b></p>
                  <p><i className="fas fa-folder"></i> Type document :  <br /><b>{report.module.libelle}</b></p>
                  <p><i className="fas fa-calendar"></i> Date d'edition :  <br /><b>{dateFormat(report.createdAt)}</b></p>
               </div>
            </Link>
         </div>
      </div>
   );
}


export default function MyDocuments() {

   const [showFilterForm, setshowFilterForm] = useState(false);
   const [reports, setReports] = useState([]);
   const [page, setPage] = useState(1)
   const [pagination, setPagination] = useState({});
   const [modules, setModules] = useState([]);
   const [loading,setLoading]=useState(false)

   let query = useQuery();

   const annee = query.get('annee') !== null ? query.get('annee') : '';
   const module = query.get('module') !== null ? query.get('module') : '';
   const search = query.get('q') !== null ? query.get('q') : '';


   let title=UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR)?'Mes rapports ':'Mes documents ';

   title = title + annee + " " + search + " " + module;

   const [q, setQ] = useState(search)

   setTitle(title);

   useEffect(() => {
      init();
   }, [page])

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const init = async () => {
      setLoading(true)
      const { data, pagination } = await DocumentStore.getMyDocuments(module, page, query.get('annee'), query.get('q'));
      setPagination(pagination)
      setReports(data);
      setLoading(false)
      let _modules = await HomeStore.getModules();
      _modules = _modules !== undefined && _modules.filter(module => module.template.libUnique === 'document');
      setModules(_modules);
   }

   const searchReport = (e) => {
      e.preventDefault();
      window.location.href = `/bibliotheque/mes-documents?module=${module}&q=${q}`;
   }

   return (
      <>
         <Nav />
         <div className="es-content">
            <Header />
            <div className="filterArea bg-white">
               <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">
                  <div className="p-2 bd-highlight ">
                     <div className="search-box">
                        <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => setQ(e.target.value)} />
                        <a href="/" className="search-btn" onClick={searchReport}>
                           <Search />
                        </a>
                     </div>
                  </div>

                  <div className="p-2 d-flex bd-highlight me-3 hide4mobile">
                     <div className="p-2 flex-shrink-1 bd-highlight  hide4mobile">
                        <button className="btn btn-sm btn-warning" onClick={() => setshowFilterForm(true)} data-bs-toggle="modal" data-bs-target="#filterModal">Filtre <IconFilter /></button>
                     </div>
                  </div>
               </div>
            </div>
            <div className="page-info">
               <h2 className="text-center text-white text-uppercase"> {title}</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase">{title}</span>
                  </div>
               </div>
            </div>

            <div className="container-fluid mb-4 page-content">
               <div className={`row ${loading ? 'loading' : ''}`}>
                  {reports.map((report, index) => <Reporte key={index} user={UserStore.state.currentUser} report={report} />)}
                  {!reports.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun document disponible.</h2>}
                  <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
               </div>
            </div>
            <Modal show={showFilterForm} onClose={() => setshowFilterForm(false)} body={<FilterForm modules={modules} />} title="Filtrer par" />

            <Footer />
         </div>

      </>
   )
}
import React, { useEffect } from "react";
import PDFObject from "pdfobject";

const PDF = React.memo((props) => {
  useEffect(() => {
    var options = {
      pdfOpenParams: {
        navpanes: 0,
        toolbar: 0,
        statusbar: 0,
        view: "FitV",
        pagemode: "thumbs",
        page: 1,
      },
      forcePDFJS: true,
      PDFJS_URL: "https://mozilla.github.io/pdf.js/web/viewer.html",
      // PDFJS_URL: PUBLIC_URL + "/viewer.html"
    };

    PDFObject.embed(props.src, "#my-container", options);
  }, [props.src]);

  return (
    <div className="card mt-3" id="my-container" style={{ height: 700 }} />
  );
});

export default PDF;

// export default function PDF({ src }) {

//    useEffect(() => {
//       var options = {
//          pdfOpenParams: {
//             navpanes: 0,
//             toolbar: 0,
//             statusbar: 0,
//             view: "FitV",
//             pagemode: "thumbs",
//             page: 1
//          },
//          forcePDFJS: true,
//          PDFJS_URL: "https://mozilla.github.io/pdf.js/web/viewer.html"
//          // PDFJS_URL: PUBLIC_URL + "/viewer.html"
//       };

//       PDFObject.embed(src, "#my-container", options);
//    }, [src])

//    return (
//       <div className="card mt-3" id="my-container" style={{ height: 700 }} />
//    )
// }

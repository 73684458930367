import httpClient from "./HttpClient";

const END_POINT = "/avis";

const HomeStore = {

  state: {
    modules: [],
    menus: [],
    parent_menu: null,
    pied_pages: [],
    params: [],
    typeAvis:[],
  },

  sendMessageToIGF(formData) {
    return httpClient.post(END_POINT, formData);
  },

  saveNewsletter(email) {
    return httpClient.post('/new_letters', { email })
  },

  async getHomeLayout() {
    try {
      const response = await httpClient.get(`/layout`);
      const data = await response.data;
      this.state.modules = (data !== undefined && data !== 'undefined') ? data : [];
      return data;
    } catch (e) {
      console.error(e);
    }
  },

  async getHomeStat(stat_url) {
    
    try {
      const response = await httpClient.get(stat_url);
      const data = await response.data;
      return data;
    } catch (e) {
      console.error(e);
    }
  },

  async getHome() {
    try {
      const response = await httpClient.get(`/home`);
      const data = await response.data['hydra:member'];
      this.state.modules = (data !== undefined && data !== 'undefined') ? data : [];
      return data;
    } catch (e) {
      console.error(e);
    }
  },

  async getModules() {
    if (!this.state.modules.length) {
      try {
        const response = await httpClient.get(`/modules`);
        const data = await response.data['hydra:member'];
        this.state.modules = (data !== undefined && data !== 'undefined') ? data : [];
        return data;
      } catch (e) {
        return this.state.modules;
      }
    } else return this.state.modules;
  },

  async getTypeAvis() {
    if (!this.state.typeAvis.length) {
      try {
        const response = await httpClient.get(`/type_avis`);
        const data = await response.data['hydra:member'];
        return data;
      } catch (e) {
        return this.state.typeAvis;
      }
    } else return this.state.typeAvis;
  },

  async getMenus() {
    if (!this.state.menus.length) {
      try {
        const response = await httpClient.get(`/menus?page=1&order[position]=asc`);
        const data = await response.data['hydra:member'];
        this.state.menus = (data !== undefined && data !== 'undefined') ? data : [];
        return data;
      } catch (e) {
        return this.state.menus;
      }
    } else return this.state.menus;
  },

  async getMenusItem(menuId) {
    if (!this.state.parent_menu) {
      try {
        const response = await httpClient.get(`/menus/${menuId}`);
        const data = await response.data;
        this.state.parent_menu = (data !== undefined && data !== 'undefined') ? data : [];
        return data;
      } catch (e) {
        return this.state.parent_menu;
      }
    } else return this.state.parent_menu;
  },
  async getPiedPage() {
    if (!this.state.pied_pages.length) {
      try {
        const response = await httpClient.get(`/liens?&order[position]=asc&categorie.libUnique=menu-pied-de-page`);
        const data = await response.data['hydra:member'];
        this.state.pied_pages = (data !== undefined && data !== 'undefined') ? data : [];
        return data;
      } catch (e) {
        return this.state.pied_pages;
      }
    } else return this.state.pied_pages;
  },

  async getParams() {
    if (!this.state.params.length) {
      try {
        const response = await httpClient.get(`/parametres?pagination=false`);
        const data = await response.data['hydra:member'];
        this.state.params = (data !== undefined && data !== 'undefined') ? data : [];
      } catch (e) {
        console.error(e);
      }
    }

    const directeur_phone = this.state.params.find(param => param.libUnique === 'directeur_phone')
    const logo = this.state.params.find(param => param.libUnique === 'logo')
    const message = this.state.params.find(param => param.libUnique === 'welcome_message')
    const message_title = this.state.params.find(param => param.libUnique === 'welcome_message_title')
    const photo_structure = this.state.params.find(param => param.libUnique === 'photo_structure')
    const structure_email = this.state.params.find(param => param.libUnique === 'structure_email')
    const directeur_email = this.state.params.find(param => param.libUnique === 'directeur_email')
    const directeur_image = this.state.params.find(param => param.libUnique === 'directeur_image')
    const directeur_name = this.state.params.find(param => param.libUnique === 'directeur_name')
    const youtube = this.state.params.find(param => param.libUnique === 'youtube_url')
    const facebook = this.state.params.find(param => param.libUnique === 'facebook_url')
    const twitter = this.state.params.find(param => param.libUnique === 'twitter_url')

    return { message_title: message_title && message_title.valeur, directeur_name: directeur_name && directeur_name.valeur, directeur_image: directeur_image && directeur_image.valeur, directeur_email: directeur_email && directeur_email.valeur, structure_email: structure_email && structure_email.valeur, directeur_phone: directeur_phone && directeur_phone.valeur, logo: logo && logo.valeur, message: message && message.valeur, photo_structure: photo_structure && photo_structure.valeur, youtube: youtube && youtube.valeur, facebook: facebook && facebook.valeur, twitter: twitter && twitter.valeur }
  },

}

export default HomeStore
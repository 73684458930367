import { useLayoutEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "react-confirm-alert/src/react-confirm-alert.css";

import Home from "./pages/front/Home";
import Contact from "./pages/front/Contact";
import Texte from "./pages/front/Texte";
import Documents from "./pages/front/documents/documents";
import Document from "./pages/front/documents/document";
import Article from "./pages/front/articles/article";

import Login from "./pages/auth/Login";

import Dashboard from "./pages/bibliotheque/Home";

import Lieu from "./pages/bibliotheque/missions/Lieu";
import Niveau from "./pages/bibliotheque/missions/Niveau";
import Domaine from "./pages/bibliotheque/missions/Domaine";
import Annee from "./pages/bibliotheque/missions/Annee";
import Mission from "./pages/bibliotheque/missions/Missions";
import SourceMission from "./pages/bibliotheque/missions/Sources";
import NatureMission from "./pages/bibliotheque/missions/Nature";
import StatusMission from "./pages/bibliotheque/missions/Status";
import OrdreMission from "./pages/bibliotheque/missions/Ordre";
import ProfilMission from "./pages/bibliotheque/missions/Profil";
import PersonnelMission from "./pages/bibliotheque/missions/Personnel";
import RecommandationMission from "./pages/bibliotheque/missions/Recommandation";
import AvisMinistre from "./pages/bibliotheque/missions/AvisMinistre";
import RapportIgm from "./pages/bibliotheque/missions/RapportIgm";
import RapportMission from "./pages/bibliotheque/missions/Rapport";

import ComposanteCoso from "./pages/bibliotheque/missions/coso/Composante";
import PrincipeCoso from "./pages/bibliotheque/missions/coso/Principe";
import ControleCoso from "./pages/bibliotheque/missions/coso/Controle";

import Resources from "./pages/bibliotheque/resources/Resources";
import SuggestionsLivres from "./pages/bibliotheque/resources/Suggetions";
import Favorite from "./pages/bibliotheque/resources/Favorite";
import RapportFavorite from "./pages/bibliotheque/documents/Favorite";

import RapportGenerals from "./pages/front/reports/RapportGenerals";
import RapportGeneral from "./pages/front/reports/RapportGeneral";

import Reports from "./pages/bibliotheque/reports/Reports";
import Report from "./pages/bibliotheque/reports/Report";

import MyDocuments from "./pages/bibliotheque/documents/MyDocuments";
import OneDocument from "./pages/bibliotheque/documents/Document";
import AllDocuments from "./pages/bibliotheque/documents/Documents";

import Share from "./pages/bibliotheque/Share";
import PrivateRoute from "./components/PrivateRoute";

import Account from "./pages/account/Account";
import Organes from "./pages/front/organes/organes";
import Organe from "./pages/front/organes/organe";

import Activites from "./pages/front/activites/activites";

import StructureStrore from "./store/StructureStore";
import UserStore from "./store/UserStore";
import { Up } from "./components/Icons";
import Recherche from "./pages/front/activites/recherche";

import Page from "./pages/front/pages/page";
import MesResources from "./pages/bibliotheque/resources/MesResources";
import ScrollToTop from "./components/ScrollToTop";

import Notifications from "./pages/bibliotheque/Notifications";
import RapportRecus from "./pages/bibliotheque/RapportRecus";
import Messages from "./pages/bibliotheque/Messages";

import YoutubeVideo from "./pages/bibliotheque/videos/YoutubeVideo";
import YoutubeVideos from "./pages/bibliotheque/videos/YoutubeVideos";
import Histories from "./pages/bibliotheque/Histories";
import RapportRecusDetail from "./pages/bibliotheque/RapportRecusDetail";
import RapportRecusDetailRecommandation from "./pages/bibliotheque/RapportRecusDetailRecommandation";
import RapportPartageDetail from "./pages/bibliotheque/RapportPartageDetail";
import RapportPartage from "./pages/bibliotheque/RapportPartage";
import RapportPartageDetailRecommandation from "./pages/bibliotheque/RapportPartageDetailRecommandation";
import Password from "./pages/auth/Password";
import ResourceLivre from "./pages/bibliotheque/resources/ResourceLivre";
import RecommandationsRapportSuivi from "./pages/bibliotheque/RecommandationsRapportSuivi";

const App = () => {
  useLayoutEffect(() => {
    init();
  }, []);

  const toTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const init = async () => {
    await UserStore.getCurrentUser();
    await StructureStrore.getAll();
  };



  return (
    <Router>
      <>
        <ScrollToTop />
        <ReactNotification />
        <button onClick={toTop} id="myBtn" title="Retour au début">
          <Up />
        </button>
        <Switch>
          <PrivateRoute
            path="/bibliotheque/missions/recommandations"
            component={RecommandationMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/rapports"
            component={RapportMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/personnels"
            component={PersonnelMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/sources"
            component={SourceMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/ordres"
            component={OrdreMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/natures"
            component={NatureMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/status"
            component={StatusMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/niveau-mis-oeuvre"
            component={Niveau}
          />
          <PrivateRoute
            path="/bibliotheque/missions/domaine-remboursement"
            component={Domaine}
          />
          <PrivateRoute
            path="/bibliotheque/missions/avis-conseil-ministre"
            component={AvisMinistre}
          />
          <PrivateRoute
            path="/bibliotheque/missions/rapport-igm"
            component={RapportIgm}
          />
          <PrivateRoute
            path="/bibliotheque/missions/profils"
            component={ProfilMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/missions"
            component={Mission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/annees"
            component={Annee}
          />
          <PrivateRoute path="/bibliotheque/missions/lieux" component={Lieu} />

          <PrivateRoute
            path="/bibliotheque/missions/composantes-cosos"
            component={ComposanteCoso}
          />
          <PrivateRoute
            path="/bibliotheque/missions/liste-coso"
            component={ComposanteCoso}
          />
          <PrivateRoute
            path="/bibliotheque/missions/autres-listes"
            component={SourceMission}
          />
          <PrivateRoute
            path="/bibliotheque/missions/principes-cosos"
            component={PrincipeCoso}
          />
          <PrivateRoute
            path="/bibliotheque/missions/controles-audits-cosos"
            component={ControleCoso}
          />

          <PrivateRoute
            path="/bibliotheque/rapports/favoris"
            component={RapportFavorite}
          />
          <PrivateRoute
            path="/bibliotheque/livres/favoris"
            component={Favorite}
          />

          <PrivateRoute
            path="/bibliotheque/youtube-videos/:id"
            component={YoutubeVideo}
          />
          <PrivateRoute
            path="/bibliotheque/youtube-videos"
            component={YoutubeVideos}
          />

          <PrivateRoute
            path="/bibliotheque/suggestions/livres"
            component={SuggestionsLivres}
          />
          <PrivateRoute path="/bibliotheque/livres/:id" component={ResourceLivre} />
          <PrivateRoute path="/bibliotheque/livres" component={Resources} />
          <PrivateRoute
            path="/bibliotheque/mes-livres"
            component={MesResources}
          />

          <PrivateRoute
            path="/bibliotheque/rapports-proteges/:id"
            component={Report}
          />
          <PrivateRoute
            path="/bibliotheque/rapports-proteges"
            component={Reports}
          />

          <PrivateRoute
            path="/bibliotheque/documents/:id"
            component={OneDocument}
          />
          <PrivateRoute
            path="/bibliotheque/mes-documents"
            component={MyDocuments}
          />
          <PrivateRoute
            path="/bibliotheque/documents"
            component={AllDocuments}
          />

          <PrivateRoute path="/bibliotheque/partager" component={Share} />

          <PrivateRoute
            path="/bibliotheque/notifications"
            component={Notifications}
          />
          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandation/:rapportId"
            component={RapportRecusDetail}
          />
          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandation"
            component={RapportRecus}
          />

          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandations-list"
            component={RecommandationsRapportSuivi}
          />



          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandation-partage/:rapportId"
            component={RapportPartageDetail}
          />
          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandation-partage"
            component={RapportPartage}
          />

          {/* <PrivateRoute path="/bibliotheque/rapports-envoyes" component={RapportEnvoyes} /> */}
          <PrivateRoute path="/bibliotheque/messages" component={Messages} />
          <PrivateRoute
            path="/bibliotheque/historiques"
            component={Histories}
          />

          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandation-detail-recommandation/:recommandationId"
            component={RapportRecusDetailRecommandation}
          />
          <PrivateRoute
            path="/bibliotheque/rapports-suivis-recommandation-partage-detail-recommandation/:recommandationId"
            component={RapportPartageDetailRecommandation}
          />

          <PrivateRoute path="/bibliotheque" component={Dashboard} />

          <PrivateRoute path="/account" component={Account} />

          <Route exact path="/" component={Home} />

          <Route exact path="/contact" component={Contact} />
          <Route
            exact
            path="/publications-et-textes-legaux"
            component={Texte}
          />

          <Route
            exact
            path="/documentations/document/:id"
            component={Document}
          />
          <Route
            path="/documentations/:parent/:moduleLibUnique"
            component={Documents}
          />

          <Route exact path="/organe-de-controle" component={Organes} />
          <Route exact path="/organe-de-controle/:id" component={Organe} />

          <Route exact path="/activites/:slug" component={Article} />

          {/*<Route exact path="/page/:slug/:id" component={Page} />*/}

          <Route
            exact
            path="/rapport-activite-generale/:id"
            component={RapportGeneral}
          />
          <Route
            exact
            path="/rapport-activite-generale"
            component={RapportGenerals}
          />

          <Route exact path="/activites" component={Activites} />
          <Route exact path="/recherche" component={Recherche} />

          <Route path="/login" exact component={Login} />
          <Route path="/renew-password" exact component={Password} />
          <Route path="/:slug" component={Page} />
        </Switch>
      </>
    </Router>
  );
};

export default App;

import { Link } from "react-router-dom";
import { SITE_URL } from "../../helpers/Constants";
import { Clock, Pdf } from "../Icons";

const Document = ({ doc }) => {
   return (
      <div className="card shadow-sm p-3 bg-light mb-3">
         <div className="row">
            <div className="col-md-1 d-none d-lg-block" style={{ color: '#9a2e48' }}>
               <Pdf size='5x' />
               <span className="badge rounded-pill bg-warning text-dark">Documents</span>
            </div>
            <div className="col-sm-12 col-md-11 px-4">
               <h4 className="pb-2 border-bottom mb-2">
                  <Link to={`/documentations/document/${doc.id}`} className="text-dark">{doc.libelle}</Link></h4>
               <div className="d-flex justify-content-between">
                  <div>
                     <span className="documentTime bckColorDark text-dark text-bold p-1 rounded ">
                        <Clock />{doc.annee}
                     </span>
                  </div>
                  {doc.fichier !== undefined &&
                     <div>
                        <a target="_blank" href={SITE_URL + '/doc/download/' + doc.id} className="btn btn-outline-warning text-dark"><i title="TELECHARGE" className="fas fa-download xs-font"></i> Télécharger</a>
                     </div>
                  }
               </div>
            </div>
         </div>
      </div>
   );
}
export default Document;
import React, { useEffect, useState } from 'react';
import Pagination from '../../components/Pagination';
import { setTitle } from '../../helpers/Helper';
import DocumentStore from '../../store/DocumentStore';
import Document from "../../components/front/Document";
import Layout from '../../components/front/Layout';

function Texte() {

   const [page, setPage] = useState(1)
   const [documents, setDocuments] = useState([]);
   const [pagination, setPagination] = useState({});

   const [q, setQ] = useState('')

   setTitle("Publications et textes légaux.");


   useEffect(() => {
      findDocuments();
   }, [page])


   const findDocuments = async () => {
      const { data, pagination } = await DocumentStore.getDocuments("publications-et-textes-legaux", page);
      setPagination(pagination)
      setDocuments(data);
   }
   const setCurrentPage = (page) => {
      setPage(page);
   }

   const search = async () => {
      const { data, pagination } = await DocumentStore.getAll("publications-et-textes-legaux", page, null, null, q);
      setPagination(pagination)
      setDocuments(data);
   }

   const Body = () => {
      return (
         <>

            <div className="page-info">
               <h2 className="text-center text-white"> Textes légaux et publications</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <span className="text-uppercase">Publications et textes légaux</span>
                  </div>
               </div>
            </div>
            <div className="container-fluid mb-4 page-content">
               <div className="container mt-3">
                  <form>
                     <div className=" d-flex">
                        <div className="input-group mb-3 ms-1">
                           <input type="text" className="form-control" name="q" placeholder="Rechercher un document" value={q} onChange={(e) => setQ(e.target.value)} />
                           <button type="button" className="input-group-text" id="basic-addon1" onClick={search}><i className="fas fa-search"></i></button>
                        </div>
                     </div>
                  </form>
                  <div className="card p-3">
                     {/* <nav>
                     <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <button onClick={() => setActiveCategory({})} className={undefined === categorie.id ? `nav-link active` : `nav-link`} id="nav-lois-tab" data-bs-toggle="tab" data-bs-target="#nav-lois" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Tous les documents</button>
                        {categories.map(cat =>
                           <button onClick={() => setActiveCategory(cat)} className={cat.id === categorie.id ? `nav-link active` : `nav-link`} id="nav-lois-tab" data-bs-toggle="tab" data-bs-target="#nav-lois" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{cat.libelle}</button>
                        )}
                     </div>
                  </nav> */}
                     <div className="tab-content" id="nav-tabContent" style={{ marginTop: '10px' }}>
                        <div className="tab-pane fade show active" id="nav-lois" role="tabpanel" aria-labelledby="nav-lois-tab">
                           {documents.map((doc, index) => <Document key={index} doc={doc} />)}
                        </div>
                        {!documents.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun document disponible.</h2>}
                     </div>
                     <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
                  </div>
               </div>
            </div>
         </>
      )
   }
   return <Layout body={<Body />} />

}

export default Texte;
import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import { Pencil, Save, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { USER_ROLES } from "../../../helpers/Constants";
import {notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";

const Form = ({ niveau,reset,close }) => {

   const [current, setCurrent] = useState(niveau);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      //delete current.libUnique;
      MissionStore.saveNiveau(current).then(_ => {
         notify("Succès", "Le niveau a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()

      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Intitulé niveau de mise en oeuvre<small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required placeholder="Intitulé niveau de mise en oeuvre"/>
            </div>
         </div>
      
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Niveaus() {

   const [niveaus, setNiveaus] = useState([]);
   const [niveau, setNiveau] = useState({ libelle: ""});
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const {data:_niveaus} = await MissionStore.getNiveaus();
      setNiveaus(_niveaus);
      setLoaging(false)
   }

   const removeNiveau = (id) => {
      if (window.confirm("Supprimer ce niveau?"))
         MissionStore.removeNiveau(id).then(_ => init())
   }

   const updateNiveau = (niveau) => {
      setNiveau(niveau);
      setShowModal(true);
   }

   const newNiveau = (e) => {
      e.preventDefault();
      setNiveau({});
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setNiveau({ libelle: ""})
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavAutre/>

            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Niveaux mise en oeuvres missions</span>
            </div>
            <h4 className="page-title">Les niveaux mise en oeuvre missions</h4>
            <button onClick={newNiveau} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer un niveau de mise en oeuvre</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
               <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Intitulé niveau de mise en oeuvre</th>
                         
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {niveaus.map((niveau, index) =>
                           <tr key={niveau.id}>
                              <td>{index + 1}</td>
                              <td>{niveau.libelle}</td>
                           
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateNiveau(niveau)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeNiveau(niveau.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer une niveau de mission" body={<Form niveau={niveau} reset={{ libelle: "" }} close={onModalClose} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
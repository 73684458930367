import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import {
  dateFormat,
  getAuthToken,
  notify,
  setTitle,
} from "../../helpers/Helper";
import StructureStrore from "../../store/StructureStore";
import { SITE_URL, USER_ROLES } from "../../helpers/Constants";
import Modal from "../../components/Modal";
import UserStore from "../../store/UserStore";
import FilterRE from "./FilterRE";
import PDF from "../../components/PDF";
import Loading from "../../helpers/Loading";
import MissionStore from "../../store/MissionStore";
import DatePicker from "react-date-picker";

export default function RecommandationsRapportSuivi(props) {
  const [page, setPage] = useState(1);
  const [rapports, setRapports] = useState([]);
  const [pagination, setPagination] = useState({});
  const [showViewer, setShowViewer] = useState(false);
  const [docView, setDocView] = useState("");
  const [loading, setLoading] = useState(true);
  const [niveaux, setNiveaux] = useState([]);
  const [filter, setFilter] = useState({niveau:"",etat:"",delaiAfter:"",delaiBefore:""});

  const token = getAuthToken();

  setTitle("LISTE DES RECOMMANDATIONS DES RAPPORTS");

  const query = new URLSearchParams(props.location.search);
  const status = query.get('status')

  useEffect(() => {
    init();
  }, [page]);

  
  useEffect(() => {
    // setLoading(true)
    init()
  }, [filter]);

  const init = async () => {
    const { data, pagination } = await StructureStrore.getRecommandationRapportSuivi(page,null,filter);
    const current_user = await UserStore.getCurrentUser();
    // let roles = current_user.roles.filter(r => r == "ROLE_SUPERVISEUR")
    // if (roles.length > 0) setIsSupervisor(true)
    // else setIsSupervisor(false)
    const { data: _niveaux } = await MissionStore.getNiveaus();
    setNiveaux(_niveaux);


    setRapports(data);
    setPagination(pagination);
    setLoading(false);
  };

  const setCurrentPage = (page) => {
    setPage(page);
  };

  const readDoc = (doc) => {
    if (doc) {
      setDocView(doc);
      setShowViewer(true);
    }
  };

  const searchRE = async (q) => {
    const { data, pagination } = await StructureStrore.getRecommandationRapportSuivi(
      page,
      q
    );
    setRapports(data);
    setPagination(pagination);
  };

  const Body = () => {
    return (
      <Fragment>
        {loading && <Loading />}
        {!loading && <div>
          <div className="p-1 breadcrumbs xs-font">
            <Link to="/bibliotheque" className="textPrimary fw-bold">
              Accueil
            </Link>
            <span>/ Rapports de suivi et recommandation</span>
          </div>
          <div></div>
          <div className="content-body">
            <h4 className="page-title">LISTE DES RECOMMANDATIONS DES RAPPORTS</h4>
            <div className="container-fluid mt-3 mb-3">
              <div className="row mt-3">
                <FilterRE searchRE={searchRE} />

                <div className="col-2">
                  <label>Niveau de mise en oeuvre</label>
                  <select
                    name="nivMiseEnOeuvre"
                    value={filter.niveau}
                    className="form-control"
                    onChange={(event) => {setFilter({...filter,niveau:event.target.value}) }}
                  >
                    <option>SELECTIONNER</option>
                    {niveaux.map((niveau, index) => (
                      <option value={niveau["id"]} key={niveau["@id"]}>{niveau.libelle.toUpperCase()} </option>
                    ))}
                  </select>
                </div>

                <div className="col-2">
                  <label>Etat</label>
                  <select
                    name="nivMiseEnOeuvre"
                    value={filter.etat}
                    className="form-control"
                    onChange={(event) => {setFilter({...filter,etat:event.target.value}) }}
                  >
                    <option value="">SELECTIONNER</option>
                    <option value="valide">REJETÉ</option>
                    <option value="valide">VALIDÉ</option>
                    <option value="reformule">A REFORMULER</option>
                
                  </select>
                </div>

                <div className="col-2">
                  <label>Délai mis en oeuvre commence le</label>
                  <DatePicker
                  name="delaisMisEnOeuvre"
                  onChange={(event) =>{setFilter({...filter,delaiAfter:event})}}
                  value={filter.delaiAfter}
                  format="dd/MM/y"

                />
                </div>

                <div className="col-2">
                  <label>Délai mis en oeuvre fini le</label>
                  <DatePicker
                  name="delaisMisEnOeuvre"
                  onChange={(event) =>{setFilter({...filter,delaiBefore:event})}}
                  value={filter.delaiBefore}
                  format="dd/MM/y"

                />
                </div>




                <div className="col-sm-12 col-md-12 p-2">
                  <table className="table table-bordered table-striped">
                    <thead
                      style={{
                        textTransform: "uppercase",
                        color: "#fff",
                      }}
                      className="bckColorDark"
                    >
                      <tr>
                        <th>Date</th>

                        <th>Titre</th>
                        <th>Rapport concerné</th>
                        {/* <th>Agent de Suivi</th> */}
                        {/* <th>Structure</th> */}
                        <th>Etat</th>
                        <th>Echéance</th>
                        <th />
                        {/* <th>Fichier Rapport</th>
                                            <th>Fichier Synthèse</th> */}
                        {/* <th>Recommandations</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {rapports &&
                        rapports.map((recommandation) => (
                          <>
                            <tr key={recommandation.id}>
                              <td>
                                {recommandation.createdAt &&
                                  dateFormat(
                                    recommandation.createdAt,
                                    "DD/MM/YYYY à HH:mm"
                                  )}
                              </td>

                              <td>
                                <strong>
                                  <Link
                                    to={`rapports-suivis-recommandation/${recommandation.id}`}
                                  >
                                    {recommandation.libelle}
                                  </Link>
                                </strong>
                              </td>


                              <td>
                                <strong>
                                  {recommandation.rapport && <Link
                                    to={`rapports-suivis-recommandation/${recommandation.rapport && recommandation.rapport.id}`}
                                  >
                                    {recommandation.rapport.libelle}
                                  </Link>}
                                </strong>
                              </td>

                              {/* <td>
                              {recommandation.agentSuivi && recommandation.agentSuivi.prenom &&
                                recommandation.agentSuivi.prenom.toUpperCase() +
                                  " " +
                                  recommandation.agentSuivi.nom.toUpperCase()}
                            </td> */}

                              <td>
                                {
                                  recommandation.etat}
                              </td>

                              <td>
                                {recommandation.delaisMisEnOeuvre &&
                                  dateFormat(
                                    recommandation.delaisMisEnOeuvre,
                                    "DD/MM/YYYY à HH:mm"
                                  )}
                              </td>

                              <td>
                                <Link to={`rapports-suivis-recommandation-detail-recommandation/${recommandation.id}`} className="textPrimary fw-bold">
                                  Traiter
                                </Link>
                              </td>

                              {/* <td>
                              {recommandation.senderAgent.prenom &&
                                recommandation.senderAgent.prenom.toUpperCase() +
                                  " " +
                                  recommandation.senderAgent.nom.toUpperCase()}
                            </td> */}
                              {/* <td>
                              {recommandation.senderStructure.intitule &&
                                recommandation.senderStructure.intitule}
                            </td> */}
                              {/* <td >
                                                    {recommandation.fichier && <button className="btn btn-sm btn-info" type="button" onClick={()=>readDoc(SITE_URL + '/suivi-rapport/doc/download/' + recommandation.id + "?token=" + token)}>Visualiser</button>}
                                                    </td>

                                                    <td >
                                                    {recommandation.fichier && <button className="btn btn-sm btn-warning" type="button" onClick={()=>readDoc(SITE_URL + '/suivi-rapport/synthese/download/' + recommandation.id + "?token=" + token)}>Visualiser</button>}

                                                    </td> */}
                              {/* <td> */}
                              {/* <Link
                                to={`rapports-suivis-recommandation/${recommandation.id}`}
                                className="mb-4"
                              >
                                {" "}
                                {recommandation.fichier && (
                                  <button
                                    className="btn btn-sm btn-info mb-2"
                                    type="button"
                                    onClick={() =>
                                      readDoc(
                                        SITE_URL +
                                          "/suivi-rapport/doc/download/" +
                                          recommandation.id +
                                          "?token=" +
                                          token
                                      )
                                    }
                                  >
                                    Voir les détails du rapport{" "}
                                  </button>
                                )}
                              </Link> */}

                              {/* {recommandation.suiviRecRaports.filter((r) => !r.etat)
                                .length > 0 && (
                                <span className="badge badge-secondary  secBckColor ">
                                  Reste{" "}
                                  {
                                    recommandation.suiviRecRaports.filter(
                                      (r) => r.etat != "valide"
                                    ).length
                                  }{" "}
                                  en attente de lecture{" "}
                                </span>
                              )} */}
                              {/* </td> */}

                              <td>

                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                  <Pagination
                    setCurrentPage={setCurrentPage}
                    pagination={pagination}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal
            large
            size="lg"
            show={showViewer && docView}
            title="Lecture du Document"
            body={<PDF src={docView} />}
            onClose={() => setShowViewer(false)}
          />
        </div>}
      </Fragment>
    );
  };
  return <Layout body={<Body />} />;
}

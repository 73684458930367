import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from "../../../components/front/Layout";
import StructureStrore from '../../../store/StructureStore';
import Contact from "../../../components/front/Contact"
import { PUBLIC_URL, SITE_URL, SITE_URL_MEDIA } from '../../../helpers/Constants';
import { Envelope, Phone } from '../../../components/Icons';
import Modal from "../../../components/Modal"

const Inspecteur = ({ agent }) => {
    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            {agent.avatar !== undefined ? <img className="img-fluid" src={SITE_URL + agent.avatar.contentUrl} alt={agent.nom} /> : <img className="img-fluid" src={PUBLIC_URL + '/img/no_person.png'} alt={agent.nom} />}

            <p dangerouslySetInnerHTML={{ __html: '' }}></p>
        </div>
    );
}

export default function Organe() {

    const [index, setIndex] = useState(1);
    const [organe, setOrgane] = useState({ intitule: '', parametreStructures: [] });
    const [agents, setAgents] = useState([]);
    const [contact, setContact] = useState({})
    const { id } = useParams();
    const [inspecteur, setInspecteur] = useState({ qualite: {} });
    const [modal, setModal] = useState(false);

    useEffect(() => {
        init();
    }, [id])

    const init = async () => {
        const _organe = await StructureStrore.getStructure(id);
        const _agents = await StructureStrore.getAgentsByStructureCode(_organe.code)
        setOrgane(_organe);
        setAgents(_agents);
        if (_organe.parametreStructures !== undefined && _organe.parametreStructures.length) {
            setContact(_organe.parametreStructures[0])
        }
    }

    const showInspecteur = (_inspecteur) => {
        setInspecteur(_inspecteur)
        setModal(true)
    }

    const Body = () => {
        return (
            <>
                <div className="page-info">
{    organe.organeControle &&                <h2 className="text-center text-white"> {organe.organeControle.length>70 ?`${organe.organeControle.substr(0,70)}...`:organe.organeControle}</h2>
}                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> / </span>
                            <span className="text-uppercase"><a href="/organe-de-controle">Organe de contrôle</a>  / </span>
                            <span className="text-uppercase">{organe.organeControle && organe.organeControle}</span>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mb-4 page-content">

                    <div className="container mt-3">
                        <div className="card p-3">
                            <div className="row mt-3" style={{ position: 'relative' }}>

                                <div className="col-sm-12 col-lg-4">

                                    <ul className="list-group igf-list-group">
                                        <li className={index === 1 ? 'list-group-item active' : 'list-group-item text-white text-bold '}><a href="#/" onClick={_ => setIndex(1)}>{organe.organeControle && organe.organeControle.toUpperCase()}</a></li>
                                        {/* <li className={index === 2 ? 'list-group-item active' : 'list-group-item '}><a href="#/" onClick={_ => setIndex(2)}>NOS MISSIONS</a></li> */}
                                        <li className={index === 3 ? 'list-group-item active' : 'list-group-item '}><a href="#/" onClick={_ => setIndex(3)}>ORGANIGRAMME</a></li>
                                        <li className={index === 4 ? 'list-group-item active' : 'list-group-item '}><a href="#/" onClick={_ => setIndex(4)}>contactez-nous </a></li>
                                        <li className={index === 5 ? 'list-group-item active' : 'list-group-item '}><a href="#/" onClick={_ => setIndex(5)}>Inspecteurs </a></li>
                                    </ul>
                                </div>

                                <div className="col-md-8 bg-light card-body ps-4 rounded custom-shadow">
                                    {index === 1 && <div className="row " style={{ position: 'relative', padding: '0px 11px 0px 7px', 'zIndex': 3 }}>
                                        <div className="col-sm-12">
                                            {organe.motDirecteur && <p className="text-justify" dangerouslySetInnerHTML={{ __html: organe.motDirecteur }} />
                                            }  {!organe.motDirecteur && <div className="alert alert-warning" role="alert">
                                                <i className='fa fa-alert'></i>Pas d'informations pour cet organe de contrôle !
                                            </div>}
                                        </div>

                                    </div>}
                                    {/* {index === 2 && <div className="row " style={{ position: 'relative', padding: '0px 11px 0px 7px', 'zIndex': 3 }}>
                                        <div className="col-sm-12">
                                            {organe.mission && <p className="text-justify" dangerouslySetInnerHTML={{ __html: organe.mission }} />}
                                            {!organe.mission && <div className="alert alert-warning" role="alert">
                                                <i className='fa fa-alert'></i>Mission non renseigné actuellement pour cet organe de contrôle !
                                            </div>}
                                        </div>
                                    </div>} */}
                                    {index === 3 && <div className="row " style={{ position: 'relative', padding: '0px 11px 0px 7px', 'zIndex': 3 }}>
                                        <div className="col-sm-12">
                                            {organe.organigramme && <p className="text-justify" dangerouslySetInnerHTML={{ __html: organe.organigramme }} />}
                                            {!organe.organigramme && <div className="alert alert-warning" role="alert">
                                                <i className='fa fa-alert'></i>Organigramme non renseigné actuellement pour cet organe de contrôle !
                                            </div>}
                                        </div>
                                    </div>}
                                    {index === 4 &&
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12">
                                                <Contact organe={organe} />
                                            </div>
                                            {/* <div className="col-sm-12 col-md-4">
                                                <div className="">
                                                    <h5 className="text-secondary text-center">Nous joindre</h5>
                                                    <div className="list-group shadow-sm">
                                                        <button className="list-group-item  list-group-item-action d-flex justify-content-between">
                                                            <div><Phone /></div>
                                                            <div>
                                                                {contact.phone}
                                                            </div>
                                                        </button>
                                                        <button className="list-group-item list-group-item-action d-flex justify-content-between">
                                                            <div><Envelope /></div>
                                                            <div>{contact.email}</div>
                                                        </button>
                                                        <button className="list-group-item list-group-item-action d-flex justify-content-between">
                                                            <div><i className="fa fa-map-signs"></i></div>
                                                            <div>{contact.adresse}</div>
                                                        </button>
                                                        <button className="list-group-item list-group-item-action d-flex justify-content-around">
                                                            <a href={contact.facebook} rel="noreferrer" target="_blank" className="facebook"><Facebook /></a>
                                                            <a href={contact.twitter} rel="noreferrer" target="_blank" className="twitter"><Twitter /></a>
                                                            <a href={contact.youtube} rel="noreferrer" target="_blank" className="youtube"><Youtube /></a>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    }
                                    {index === 5 && inspecteur && <div className="row mt-3"> {agents && agents.map(agent =>
                                        <div className="col-lg-4 col-sm-6" key={agent.id} onClick={() => showInspecteur(agent)}>
                                            <div className="single-teachers mt-30 text-center">
                                                <div className="image">
                                                    {agent.avatar !== undefined ? <img data-animation="fadeInLeft" data-delay="1s" src={SITE_URL_MEDIA + agent.avatar.contentUrl} alt={agent.nom} /> : <img data-animation="fadeInLeft" data-delay="1s" src={PUBLIC_URL + '/img/no_person.png'} alt={agent.nom} />}
                                                </div>
                                                <div className="cont">
                                                    <a href="#/">
                                                        <h6 data-animation="zoomInLeft" data-delay="1s">{agent.nom + ' ' + agent.prenom}</h6>
                                                    </a>
                                                    <span>{agent.qualite.libelle}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                        {!agents && <div className="alert alert-warning" role="alert">
                                            <i className='fa fa-alert'></i>Aucun inspecteur disponble actuellement pour cet organe de contrôle !
                                        </div>}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={modal} onClose={() => setModal(false)} body={<Inspecteur agent={inspecteur} onClose={() => setModal(false)} />} title={inspecteur.nom + " " + inspecteur.prenom + ' (' + inspecteur.qualite.libelle + ')'} />
            </>
        );
    }

    return <Layout body={<Body />} />
}
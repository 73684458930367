import httpClient from "./HttpClient";

const END_POINT = "/publications";

const PublicationStore = {

  async getMarquees() {
    try {
      const response = await httpClient.get(END_POINT + `?page=1&inFlashInfo=true`)
      const data = await response.data['hydra:member'];
      return data;
    } catch (error) {
      return []
    }
  },

  async get(slug) {
    try {
      const response = await httpClient.get(END_POINT + `?page=1&itemsPerPage=1&slug=${slug}`)
      const data = await response.data['hydra:member'];
      return data.length ? data[0] : {};
    } catch (error) {
      return {}
    }
  },

  async getPage(slug) {
    try {
      const response = await httpClient.get(`/pages?page=1&itemsPerPage=1&slug=${slug}`)
      const data = await response.data['hydra:member'];
      return data.length ? data[0] : {};
    } catch (error) {
      return {};
    }
  },


  async getArticles(categorie, page = 1, itemsPerPage = 10) {
    try {
      const response = await httpClient.get(END_POINT + `?page=${page}&categorie.libUnique=${categorie}&itemsPerPage=${itemsPerPage}`)
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (error) {
      return [];
    }
  },

  async filters(titre, categorie = "", page = 1) {
    try {
      const response = await httpClient.get(END_POINT + `?page=${page}&titre=${titre}`)
      const data = await response.data['hydra:member'];
      const pagination = await response.data['hydra:view'];
      return { data, pagination };
    } catch (error) {
      return [];
    }
  },

  async sliderPublication() {
    try {
      const response = await httpClient.get(END_POINT + `?page=1&inSlider=true`)
      const data = await response.data['hydra:member'];
      return data;
    } catch (error) {
      return [];
    }
  },
}

export default PublicationStore
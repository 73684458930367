import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/biblio/Layout";
import Filter from "../../../components/biblio/resources/Filter";
import Resource from "../../../components/biblio/resources/Resource";
import Pagination from "../../../components/Pagination";
import { useQuery } from "../../../helpers/Helper";
import ResourceStore from "../../../store/ResourceStore";
import UserStore from "../../../store/UserStore";

export default function MesResources() {


   const [page, setPage] = useState(1)
   const [books, setBooks] = useState([]);
   const [pagination, setPagination] = useState({});
   const [loading, setLoading]=useState(false)

   const query = useQuery()
   const domaineLibUnique = query.get('domaine');
   const search = query.get('q') !== null ? query.get('q') : '';

   const user = UserStore.state.currentUser;

   useEffect(() => {
      init();
   }, [domaineLibUnique, page])

   const init = async () => {
      setLoading(true)
      const { data, pagination } = await ResourceStore.getMyBooks(domaineLibUnique, page, query.get('q'))
      setPagination(pagination)
      setBooks(data);
      setLoading(false)
   }

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const Body = () => {
      return (
         <>
            <Filter />
            <div className="p-1 breadcrumbs xs-font">
               <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Bibliothèque</span>
            </div>
            <h4 className="page-title">Mes livres {search !== '' && `: ${search}`}</h4>
            <div className="container-fluid mb-4 page-content">
               <div className={`row ${loading ? 'loading' : ''}`}>
                  {books.map((resource, index) => <Resource resource={resource} key={index} user={user} />)}
                  {!books.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun livre disponible.</h2>}
               </div>
               <br />
               <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
            </div>
         </>
      );
   }

   return <Layout body={<Body />} />
}
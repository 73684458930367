import React, { useEffect, useLayoutEffect } from "react";
import UserStore from "../../store/UserStore";
import Footer from "../Footer";
import Header from "./Header";
import Nav from "./Nav";

export default function Layout({ body }) {

   useEffect(()=>{
      document.querySelector('body').style.paddingTop=0;
   },[])

   useLayoutEffect(() => {
      if (!UserStore.isAuthenticated()) {
         window.location.href = "/login";
      }
   }, []);
   if (UserStore.isAuthenticated()) {
      return (
         <>
            <Nav />
            <div className="es-content">
               <Header />
               {body}
               <Footer />
            </div>
         </>
      );
   }
   else
      return <div/>
}
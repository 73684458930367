import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from "../../../components/front/Layout";
import { Facebook, Twitter } from '../../../components/Icons';
import { SITE_URL } from '../../../helpers/Constants';
import { setTitle, shareFacebook, shareTwitter } from '../../../helpers/Helper';
import PublicationStore from '../../../store/PublicationStore';

export default function Article() {

   const { slug } = useParams();
   const [article, setArticle] = useState({});
   const [articles, setArticles] = useState([]);

   useEffect(() => {
      init()
   }, [slug])

   const init = async () => {
      const _article = await PublicationStore.get(slug);
      const { data } = await PublicationStore.getArticles(_article.categorie.libUnique);
      setTitle(_article.titre)
      setArticle(_article);
      setArticles(data);
   }

   const Body = () => {
      return (
         <>
            <div className="page-info">
               <h2 className="text-center text-white"> {article.titre}</h2>
               <div className="bread-crumb">
                  <div className="container">
                     <span><a href="/">ACCUEIL</a> / </span>
                     <Link to="/activites" className="text-uppercase">Actualites /</Link>
                     <span className="text-uppercase"> {article.titre}</span>
                  </div>
               </div>
            </div>
            <div className="container-fluid mb-4 page-content">
               
               <div className="container mt-3 mb-4">
                  <div className="row">

                     <div className="col-md-9 shadow bg-light pt-3">
                        {article.media && <img src={SITE_URL + '/'+ article.media.contentUrl} alt={article.titre} className="img-fluid" />}
                        <div className="d-flex">
                           <div dangerouslySetInnerHTML={{ __html: article.contenuPublication }} />
                           <div className="p-2">
                              <ul className="articleLikeShare">
                                 {/* <li><a href="#/" title="Ajoute aux Favoris"><i><Star /></i></a></li>*/}
                                 <li><a href="#/" onClick={() => shareFacebook(window.location.href)} title="Partage sur Facebook"><Facebook /></a></li>
                                 <li><a href="#/" onClick={() => shareTwitter(window.location.href, article.titre)} title="Partage sur Twitter"><Twitter /></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3">
                        <h5 className="textPrimary">Articles similaires</h5>
                        <div className="w-25 divider">
                           &nbsp;
                        </div>
                        <div className="list-group article-list">
                           {articles.map(article =>
                              <Link key={article.id} to={`/activites/${article.slug}`} className="list-group-item list-group-item-action" aria-current="true">
                                 {article.titre}
                              </Link>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }
   return <Layout body={<Body />} />
}
import { USER_ROLES } from "../helpers/Constants";
import httpClient from "./HttpClient";
import UserStore from "./UserStore";

const END_POINT = "/rapport_missions";

const ResourceStore = {

   state: {
      domaines: [],
   },

   async getUser(url) {
      let path=url;

      if(url){
         path = url.replace('/api', '')
      }
      try {
         const response = await httpClient.get(path);
         return await response.data;
      } catch (e) {
         console.error(e);
      }
   },

   async getSuggestedBooks(structure, page = 0, q = null) {
      try {

         let url = `/suggestions_livres?page=${page}&itemsPerPage=12&isValidated=false&structure=${structure}`;
         if (q !== null)
            url += `&titre=${q}`
         const response = await httpClient.get(url);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         return { data: [], pagination: {} }
      }
   },


   async getBooks(domaine, page = 0, q = null, showToValidated = false) {
      try {

         let url = `/biblio/livres/me?page=${page}&itemsPerPage=2&order[createdAt]=DESC`;

         if (domaine !== null)
            url += `&domaine=${domaine}`;
         if (q !== null)
            url += `&q=${q}`
         const response = await httpClient.get(url);
         const data = await response.data['livres'];

         const pagination = { 'hydra:first': url + '&page=' + 1, 'hydra:last': url + '&page=' + response.last };
         if (response.hasPrevousPage)
            pagination['hydra:previous'] = url + '&page=' + response.previousPage;
         if (response.hasNextPage)
            pagination['hydra:next'] = url + '&page=' + response.nextPage

         return { data, pagination, total: response.total };
      } catch (e) {
         return { data: [], pagination: {}, total: 0 }
      }
   },

   async getMyBooks(domaine, page = 0, q = null) {
      try {
         let url = `/livres?page=${page}&order[createdAt]=DESC&itemsPerPage=12&user.email=${UserStore.state.currentUser.email}`;
         if (domaine !== null)
            url += `&domaine.libUnique=${domaine}`;
         if (q !== null)
            url += `&titre=${q}`

         const response = await httpClient.get(url);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         return { data: [], pagination: {} }
      }
   },


   async getBook(id) {
      try {
         const response = await httpClient.get("/livres/" + id);
         return await response.data;
      } catch (e) {
         console.error(e);
      }
   },

   saveBook(book) {
      if (book.id !== undefined && book.id !== "")
         return httpClient.put('/livres/' + book.id, book);
      else
         return httpClient.post('/livres', book);
   },

   removeSuggest(id) {
      return httpClient.delete('/suggestions_livres/' + id)
   },

   suggestbook(book, update = false, id = null) {
      if (update) {
         return httpClient.patch('/suggestions_livres/' + id, book, { headers: { 'Content-Type': 'application/merge-patch+json', 'Accept': 'application/ld+json' } });
      }
      else
         return httpClient.post('/suggestions_livres', book);
   },

   async validateBook(id, isVisible = false, isValidated = true) {
      try {
         const response = await httpClient.patch("/livres/" + id, { isValidated, isVisible }, { headers: { 'Content-Type': 'application/merge-patch+json', 'Accept': 'application/ld+json' } });
         const data = await response.data;
         return data;
      } catch (e) {
         console.error(e);
      }
   },

   async getTypes() {
      try {
         const response = await httpClient.get(`/type_publications`);
         const data = await response.data['hydra:member'];
         return data;
      } catch (e) {
         console.error(e);
      }
   },

   async findByCategory(categoryId) {
      try {
         const response = await httpClient.get(END_POINT + `/categories/${categoryId}`);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         console.error(e);
      }
   },

   async findByType(typeId) {
      try {
         const response = await httpClient.get(END_POINT + `/types/${typeId}`);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:view'];
         return { data, pagination };
      } catch (e) {
         console.error(e);
      }
   },

   async getDomaines() {
      if (!this.state.domaines.length) {
         try {
            const response = await httpClient.get(`/domaine_livres`);
            const data = await response.data['hydra:member'];
            this.state.domaines = (data !== undefined && data !== 'undefined') ? data : [];
            return data;
         } catch (e) {
            return this.state.domaines;
         }
      } else return this.state.domaines;
   },

   favorite(livre, user) {
      try {
         return httpClient.post('/livre_favoris', { livre, user });
      } catch (error) {
         console.log(error);
      }
   },

   async getFavoriteBooks(page = 1) {
      try {
         const response = await httpClient.get(`/livre_favoris?page=${page}&order[createdAt]=DESC`);
         const data = await response.data['hydra:member'];
         const pagination = await response.data['hydra:member'];
         this.state.accessType = (data !== undefined && data !== 'undefined') ? data : [];
         return { data, pagination };
      } catch (e) {
         return { data: [], pagination: {} };
      }
   },

   deleteFavorite(id) {
      return httpClient.delete(`/livre_favoris/${id}`);
   },

   removeBook(id) {
      return httpClient.delete(`/livres/${id}`);
   },
}

export default ResourceStore;

import React, { useEffect, useState } from "react";
import StructureStrore from "../../../store/StructureStore";
import DocumentStore from "../../../store/DocumentStore";
import { extract, notify } from "../../../helpers/Helper";
import { Save, Send, Trash } from "../../Icons";
import MissionStore from "../../../store/MissionStore";
import DatePicker from "react-date-picker";
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import moment from 'moment'

const SendRapportSuivi = ({ onClose = null, user, rapport }) => {
  const [recommandations, setRecommandations] = useState([]);
  const [domaine_remboursements, setDomaineRemboursements] = useState([]);
  const [niveaux, setNiveaux] = useState([]);
  const [recommandation, setRecommandation] = useState(INITIAL_RECOMMAND);
  const [loading, setLoading] = useState(false);

  const [passwordRapport, showPasswordRapport] = useState(false);
  const [passwordSynthese, showPasswordSynthese] = useState(false);
  const [withRecommand, setWithRecommand] = useState(false);





  const [form, setForm] = useState({
    senderAgent: "/api/agents/" + user.agent.id,
    senderStructure: "/api/structures/" + user.agent.structure.id,
    // structureDest: "",
    fichierSynthese: null,
    fichier: null,
    suiviRecRaports: [],
    passwordSynthese: "",
    passwordRapport: "",
  });

  const [organes, setOrganes] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const _organes = await StructureStrore.getAll();
    const _domaineR = await StructureStrore.getDomainesRemboursements();
    const { data: _niveaux } = await MissionStore.getNiveaus();
    setRecommandation({
      ...recommandation,
      agentSuivi: `/api/agents/${user.agent.id}`,
    });
    setDomaineRemboursements(_domaineR);
    setOrganes(_organes.filter((org) => org.isSupervision == true));
    setNiveaux(_niveaux);
  };

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const formData = new FormData(event.target);
    if (formData.get("file").name !== "") {
      const fichier = await DocumentStore.uploadMedia(
        formData,
        "file",
        "/fichier_documents"
      );
      form["fichier"] = fichier["@id"];
      setForm(form);
    }

    if (formData.get("file_synthese").name !== "") {
      formData.delete("file");
      formData.set(
        "file",
        formData.get("file_synthese"),
        formData.get("file_synthese").name
      );
      const fichierSynthese = await DocumentStore.uploadMedia(
        formData,
        "file",
        "/fichier_documents"
      );
      form["fichierSynthese"] = fichierSynthese["@id"];
      setForm(form);
    }

    form["senderAgent"] = "/api/agents/" + user.agent.id;
    form["senderStructure"] = "/api/structures/" + user.agent.structure.id;
    form["suiviRecRaports"] = recommandations;
    try {
      StructureStrore.sendRapport(form).then((_) => {
        event.target.reset();
        setForm({
          senderAgent: user.agent["@id"],
          senderStructure: user.agent.structure["@id"],
          // structureDest: "",
          fichier: null,
          fichierSynthese: null,
          suiviRecRaports: [],
        });
        notify("Succès", "Le rapport a été partagé.", "success");

        setRecommandations([]);
        if (null !== onClose) {
          onClose();
        }
        window.location.href = "/bibliotheque/rapports-suivis-recommandation";

      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const addRecommandation = (event) => {
    event.preventDefault();
    const rec = { ...recommandation, delaisMisEnOeuvre: moment(recommandation.delaisMisEnOeuvre).format('YYYY-MM-DD') }
    setRecommandations([...recommandations, rec]);
    setRecommandation({
      ...INITIAL_RECOMMAND,
      agentSuivi: `/api/agents/${user.agent.id}`,
    });
  };

  const removeRecommandation = (libelle) => {
    const _recommantions = recommandations.filter(
      (recommandation) => recommandation.libelle !== libelle
    );
    setRecommandations(_recommantions);
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      {/* <div className="row mb-3">
                <div className="col-md-12">
                    <div className="form-group">
                        <span>Structure de supervision <small className="fw-bold">[obligatoire]</small></span>
                        {<select onChange={handleChange} className="form-control" required name="structureDest">
                            <option value="">Structures de supervision</option>
                            {organes.map(organe => (<option value={organe['@id']} title={organe.intitule}>{extract(organe.intitule)}</option>)
                            )}
                        </select>}
                    </div>
                </div>
            </div> */}



      <div className="mb-3">
        <div className="form-group">
          <label >
            <Toggle
              defaultChecked={withRecommand}
              onChange={() => setWithRecommand(!withRecommand)} />
            <span className="ml-2"> Rapport avec des recommandations</span>
          </label>
        </div>
      </div>

      <div className="mb-3">
        <div className="form-group">
          <span>
            Attachez le rapport de mission
            <small className="fw-bold">[obligatoire]</small>
          </span>
          <input name="file" type="file" className="form-control" required />
        </div>
      </div>

      <div className="mb-3">
        <div className="form-group">
          <span>
            Mot de passe du fichier rapport
            <small className="fw-bold">[obligatoire]</small>
          </span>

          <div className="input-group mb-3">
            <input
              name="passwordRapport"
              type={passwordRapport ? "text" : "password"}
              className="form-control"
              value={rapport ? rapport.passwordRapport : form.passwordRapport}
              required
              onChange={handleChange}
            />
            <div className="input-group-append">
              <button className="btn btn-sm " onClick={() => showPasswordRapport(!passwordRapport)} type="button"> <i className={`fa ${passwordRapport ? "fa-eye-slash" : "fa-eye"}`}></i></button>
            </div>
          </div>


        </div>
      </div>

      <div className="mb-3">
        <div className="form-group">
          <span>
            Joindre la fiche de synthèse{" "}
            <small className="fw-bold">[obligatoire]</small>
          </span>
          <input name="file_synthese" type="file" className="form-control" />
        </div>
      </div>

      <div className="mb-3">
        <div className="form-group">
          <span>
            Mot de passe du fichier de synthèse
            <small className="fw-bold">[obligatoire]</small>
          </span>
          <div className="input-group mb-3">

            <input
              name="passwordSynthese"
              type={passwordSynthese ? "text" : "password"}
              className="form-control"
              value={rapport ? rapport.passwordSynthese : form.passwordSynthese}
              required
              onChange={handleChange}
            />

            <div className="input-group-append">
              <button className="btn btn-sm " onClick={() => showPasswordSynthese(!passwordSynthese)} type="button"> <i className={`fa ${passwordSynthese ? "fa-eye-slash" : "fa-eye"}`}></i></button>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-3">
        <div className="form-group">
          <span>
            Titre du document <small className="fw-bold">[obligatoire]</small>
          </span>
          <input
            name="libelle"
            type="text"
            value={rapport ? rapport.libelle : form.libelle}
            required
            onChange={handleChange}
            className="form-control"
            placeholder="Titre du document"
          />
        </div>
      </div>

      <div className="mb-3">
        {recommandations.length > 0 && (
          <h5 className="text-center">Recommandations Rapport</h5>
        )}{" "}
        <table className="table table-striped ">
          <thead>
            <tr>
              <th>Recommandation</th>
              <th>Personne Responsable</th>
              <th />
            </tr>
          </thead>
          <tbody>
            { withRecommand && recommandations.map((recommandation, index) => (
              <tr key={index}>
                <td>{recommandation.libelle}</td>
                <td>{user.agent && user.agent.nom}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => removeRecommandation(recommandation.libelle)}
                    title="Supprimer"
                  >
                    <Trash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
{  withRecommand &&      <form style={{ paddingTop: "10px" }} className="bg-light p-3">
          <div className="row mb-3">
            <div className="col-md-12 mb-2">
              <div className="form-group">
                <span>
                  Recommandation{" "}
                  <small className="fw-bold">[obligatoire]</small>
                </span>
                <textarea
                  required
                  cols="30"
                  rows="4"
                  className="form-control"
                  name="libelle"
                  value={recommandation.libelle ? recommandation.libelle : ""}
                  placeholder="Recommandation"
                  onChange={(event) =>
                    setRecommandation({
                      ...recommandation,
                      [event.target.name]: event.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <div className="form-group">
                <span>
                  Personne Responsable{" "}
                  <small className="fw-bold">[obligatoire]</small>
                </span>
                <input
                  className="form-control"
                  disabled
                  value={user.agent && `${user.agent.nom} ${user.agent.prenom}`}
                />
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group">
                <span>
                  Constatation <small className="fw-bold">[obligatoire]</small>
                </span>
                <select
                  name="constatation"
                  value={
                    recommandations.constatation
                      ? recommandations.constatation
                      : null
                  }
                  className="form-control"
                  onChange={(event) =>
                    setRecommandation({
                      ...recommandation,
                      [event.target.name]: `${event.target.value}`,
                    })
                  }
                >
                  <option>Sélectionner </option>
                  {domaine_remboursements &&
                    domaine_remboursements.map((d, index) => (
                      <option value={d["@id"]}>{d.libelle} </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6 mb-2">
              <div className="form-group">
                <span>Niveau mise en oeuvre <small className="fw-bold">[obligatoire]</small></span>
                <select
                  name="nivMiseEnOeuvre"
                  value={
                    recommandation.nivMiseEnOeuvre
                      ? recommandation.nivMiseEnOeuvre
                      : null
                  }
                  className="form-control"
                  onChange={(event) =>
                    setRecommandation({
                      ...recommandation,
                      [event.target.name]: event.target.value,
                    })
                  }
                >
                  <option>Sélectionner</option>
                  {niveaux.map((niveau, index) => (
                    <option value={niveau["@id"]}>{niveau.libelle} </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <div className="form-group">
                <span>Fin d'éxécution </span>
                {/* <input
                  onChange={(event) =>
                    setRecommandation({
                      ...recommandation,
                      [event.target.name]: event.target.value,
                    })
                  }
                  name="delaisMisEnOeuvre"
                  value={recommandation.delaisMisEnOeuvre}
                  type="number"
                  className="form-control"
                  placeholder="Échéance de fin d'exécution (en jours)"
                /> */}

                <DatePicker
                  name="delaisMisEnOeuvre"
                  onChange={(event) =>
                    setRecommandation({
                      ...recommandation,
                      delaisMisEnOeuvre: event
                    })
                  }
                  value={recommandation.delaisMisEnOeuvre}
                  format="dd/MM/y"

                />


              </div>
            </div>

            <div className="col-md-12 mb-2">
              <div className="form-group">
                <span>Action à mener</span>
                <textarea
                  required
                  cols="30"
                  rows="4"
                  className="form-control"
                  name="ActionsAMener"
                  value={recommandation.ActionsAMener}
                  placeholder="Chaque action sur une ligne"
                  onChange={(event) =>
                    setRecommandation({
                      ...recommandation,
                      [event.target.name]: event.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>

          <button
            disabled={!recommandation.libelle || !recommandation.agentSuivi}
            type="button"
            className="btn btn-sm btn-success"
            onClick={addRecommandation}
            style={{
              width: "20%",
              float: "right",
              marginBottom: "10px",
            }}
          >
            <Save /> Ajouter
          </button>
        </form>}
      </div>

      <button
        type="submit"
        disabled={
           !form.libelle || !form.senderStructure
        }
        className="btn btn-warning mx-auto d-block"
        style={{ width: "100%" }}
      >
        <Send /> {loading ? "Envoi en cours ..." : "Envoyer"}
      </button>
    </form>
  );
};

export default SendRapportSuivi;

const INITIAL_RECOMMAND = {
  libelle: "",
  agentSuivi: "",
  personneAutre: "",
  ActionsAMener: "",
  nivMiseEnOeuvre: "",
};

import React, { useState } from 'react';
import Layout from "../../components/front/Layout";
import { Envelope, Phone, Send } from '../../components/Icons';
import HomeStore from '../../store/HomeStore';
import { notify, setTitle } from '../../helpers/Helper';

function Contact() {

   const [form, setForm] = useState({ nom: "", prenom: "", email: "", numTel: "", contenu: "", sujet: "" })

   setTitle("Nous joindre");

   const handleChange = (e) => {
      setForm({ ...form, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      HomeStore.sendMessageToIGF(form).then(response => {
         notify("Succès", "Message envoyé.", "success");
         e.target.reset();
      })
   }

   const Body = () => {
      return (
         <div className="container-fluid mb-4 page-content">
            <div className="p-1 breadcrumbs xs-font">
               <a href="/" className="textPrimary fw-bold">Accueil</a> <span>/ Nous joindre</span>
            </div>
            <h4 className="page-title">Nous joindre</h4>

            <div className="container mt-3">
               <div className="row">
                  <div className="col-sm-12 col col-lg-4 p-0">
                     <div className="contact-bg-info-content custom-shadow">
                        <div className="contact-bg-info">
                           <h4 className="text-center text-white">Nous joindre sur</h4>
                           <button style={{ display: 'block', margin: 'auto', fontSize: '20px' }} className="btn btn-outline-warning mt-3 mb-4 waves-effect waves-light numvertbtn text-center">
                              <span className="numvert">
                                 <Phone /> <b>Nº</b>
                              </span>
                              <span className="numeroVert"> 90 19 00 00</span>
                           </button>

                           <button style={{ display: 'block', margin: 'auto', fontSize: '20px' }} className="btn btn-outline-warning mt-3 mb-4 waves-effect waves-light numvertbtn text-center">
                              <span className="numvert">
                                 <Envelope /> <small><b>E-mail</b></small>
                              </span>
                              <span className="numeroVert"> contact@igf.bj</span>
                           </button>
                        </div>
                     </div>
                     <div className="contact-bg-image"></div>

                  </div>
                  <div className="col-sm-12 col-lg-8 p-0 ps-4">
                     <div className="bg-light card-body ps-4 rounded custom-shadow">
                        <h3 className="text-secondary mb-3">Votre message</h3>
                        <form action="" enctype="multipart/form-data" onSubmit={handleSubmit}>
                           <div className="row mb-3">

                              <div className="col-md-6">
                                 <div className="form-group">
                                    <span>Nom <small className="fw-bold">[obligatoire]</small></span>
                                    <input required name="nom" value={form.nom} onChange={handleChange} type="text" className="form-control" />
                                 </div>
                              </div>
                              <div className="col-md-6">
                                 <div className="form-group">
                                    <span>Prenom <small className="fw-bold">[obligatoire]</small></span>
                                    <input required name="prenom" value={form.prenom} onChange={handleChange} type="text" className="form-control" />
                                 </div>
                              </div>
                              <div className="col-md-6 mt-3">
                                 <div className="form-group">
                                    <span>Numero Téléphone <small className="fw-bold">[optionnel]</small></span>
                                    <input required name="numTel" value={form.numTel} onChange={handleChange} type="text" className="form-control" />
                                 </div>
                              </div>
                              <div className="col-md-6 mt-3">
                                 <div className="form-group">
                                    <span>Email <small className="fw-bold">[obligatoire]</small></span>
                                    <input required name="email" value={form.email} onChange={handleChange} type="email" className="form-control" />
                                 </div>
                              </div>
                           </div>
                           <div className="mb-3">
                              <div className="form-group">
                                 <span>Sujet <small className="fw-bold">[obligatoire]</small></span>
                                 <input required name="sujet" value={form.sujet} onChange={handleChange} type="text" className="form-control" />
                              </div>
                           </div>


                           <div className="mb-3">
                              <div className="form-group">
                                 <span>Message <small className="fw-bold">[obligatoire]</small></span>
                                 <textarea name="contenu" value={form.contenu} onChange={handleChange} id="" cols="30" rows="5" className="form-control"></textarea>
                              </div>
                           </div>
                           <div className="d-flex">
                              <button type="submit" className="btn btn-warning mx-auto d-block"><Send /> Envoyer le message</button>
                           </div>
                        </form>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      );
   }

   return <Layout body={<Body />} />
}

export default Contact;
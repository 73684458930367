import { PUBLIC_URL } from "./Constants"

export const urlGenerator = menu => {
    const url = "#"
    const parent = menu.parent ? menu.parent.id ?menu.parent.id.split("/"):menu.parent.split("/") : null

    if (menu.page) {
        if (!menu.parent)
            return `/content/${menu.page.slug}`
        else {
            return `/content/${parent && parent[3]}/${menu.page.slug}`
        }

    }

    if (menu.module) {

        switch (menu.module.template.libUnique) {
            case 'document':
                return `/documentations/${parent && parent[3]}/${menu.module.libUnique}`
                break;

             case 'documentation':
                    return `/documentations/${parent && parent[3]}/${menu.module.libUnique}`
                    break;

            case 'menu-au-centres':
                    return `/documentations_thematiques/${menu.id}/${menu.name}`
                    break;    

            case 'actus':
                    return `/publications/${parent && parent[3]}/${menu.module.libUnique}`
                    break;

            default:
                break;
        }
        return `/module/${menu.module.id}`
    }
    if (menu.url) {
        if(menu.url.includes('http')) return menu.url
        else return `${PUBLIC_URL}/${menu.url}`
    }

    return url
}

import {useState} from "react";
import {notify} from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import MissionStore from "../../../store/MissionStore";
import {Save} from "../../Icons";
import Step from "../Step";

const Form = ({
                  recommandation,
                  rapports,
                  principeCosos,
                  controleCosos,
                  niveaus,
                  composanteCosos,
                  domaines,
                  reset,
                  onClose
              }) => {

    const [current, setCurrent] = useState(recommandation);
    const [index, setIndex] = useState(1);

    const handleChange = (e) => {
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        setCurrent({...current, [target.name]: value})
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        let recommandation = {...current, montantRembourse: current.montantRembourse}

        if (recommandation.rapport['@id'])
            recommandation = {...recommandation, rapport: recommandation.rapport['@id']}

        if (recommandation.domaineRemboursementMajeur['@id'])
            recommandation = {
                ...recommandation, domaineRemboursementMajeur: recommandation.domaineRemboursementMajeur['@id']
            }

        if (recommandation.nivMiseEnOeuvreAnterieur['@id'])
            recommandation = {
                ...recommandation, nivMiseEnOeuvreAnterieur: recommandation.nivMiseEnOeuvreAnterieur['@id']
            }

        if (recommandation.nivMiseEnOeuvre['@id'])
            recommandation = {...recommandation, nivMiseEnOeuvre: recommandation.nivMiseEnOeuvre['@id']}

        if (recommandation.composanteCoso['@id'])
            recommandation = {...recommandation, composanteCoso: recommandation.composanteCoso['@id']}

        if (recommandation.controleCoso['@id'])
            recommandation = {...recommandation, controleCoso: recommandation.controleCoso['@id']}

        if (recommandation.principeCoso['@id'])
            recommandation = {...recommandation, principeCoso: recommandation.principeCoso['@id']}

        if (recommandation.fichier['@id'])
            recommandation = {...recommandation, fichier: recommandation.fichier['@id']}

        const formData = new FormData(event.target);
        if (formData.get('file').name !== "") {
            const fichier = await DocumentStore.uploadMedia(formData, "file");
            recommandation['fichier'] = fichier['@id']
        }

        if (Object.keys(recommandation.domaineRemboursementMajeur).length === 0 && recommandation.domaineRemboursementMajeur.constructor === Object)
            delete recommandation.domaineRemboursementMajeur

        if (Object.keys(recommandation.principeCoso).length === 0 && recommandation.principeCoso.constructor === Object)
            delete recommandation.principeCoso

        if (Object.keys(recommandation.composanteCoso).length === 0 && recommandation.composanteCoso.constructor === Object)
            delete recommandation.composanteCoso

        if (Object.keys(recommandation.controleCoso).length === 0 && recommandation.controleCoso.constructor === Object)
            delete recommandation.controleCoso

        MissionStore.saveRecommandation(recommandation).then(_ => {
            notify("Succès", "La recommandation de rapport mission a été enregistrée.", "success");
            setCurrent(reset);
            event.target.reset();
            onClose();
        }).catch(err => notify("Erreur", "Certains champs du formulaire ne sont pas correctement renseignés.", "danger"));
    }

    return (
        <form onSubmit={handleSubmit}>

            <Step size={3} index={index}/>

            <div className={index === 1 ? "" : "hidden"}>
                <div className="mb-3">
                    <div className="form-group">
                        <span>Le rapport de mission <small className="fw-bold">[obligatoire]</small></span>
                        <select className="form-control" name="rapport" onChange={handleChange} value={recommandation.rapport['@id']}>
                            <option value="">Sélectionner un rapport de mission</option>
                            {rapports.map(rapport => <option key={rapport.id} value={rapport['@id']}>{rapport.intitule}</option>)}
                        </select>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>L'intitulé de recommandation de mission <small
                            className="fw-bold">[obligatoire]</small></span>
                        <input type="text" name="libelleRecommandation" className="form-control"
                               value={current.libelleRecommandation} onChange={handleChange}/>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Personne responsable suivi </span>
                        <input type="text" name="responsableSuivi" className="form-control"
                               value={current.responsableSuivi} onChange={handleChange}/>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <span>Personne responsable mise en oeuvre</span>
                        <input type="text" name="responsableMiseEnOeuvre" className="form-control"
                               value={current.responsableMiseEnOeuvre} onChange={handleChange}/>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Le niveau de mise en oeuvre du rapport</span>
                        <select className="form-control" name="nivMiseEnOeuvre" onChange={handleChange}
                                value={recommandation.nivMiseEnOeuvre['@id']}>
                            <option value="">Sélectionner un niveau</option>
                            {niveaus.map(niveau => <option key={niveau.id}
                                                           value={niveau['@id']}>{niveau.libelle}</option>)}
                        </select>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Le niveau de mise en oeuvre anterieur</span>
                        <select className="form-control" name="nivMiseEnOeuvreAnterieur" onChange={handleChange}
                                value={recommandation.nivMiseEnOeuvreAnterieur['@id']}>
                            <option value="">Sélectionner un niveau</option>
                            {niveaus.map(niveau => <option key={niveau.id}
                                                           value={niveau['@id']}>{niveau.libelle}</option>)}
                        </select>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Le montant remboursé</span>
                        <input type="number" name="montantRembourse" className="form-control"
                               value={current.montantRembourse} onChange={handleChange}/>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <span>Le domaine de remboursement majeur</span>
                        <select className="form-control" name="domaineRemboursementMajeur" onChange={handleChange}
                                value={recommandation.domaineRemboursementMajeur['@id']}>
                            <option value="">Sélectionner un domaine</option>
                            {domaines.map(domaine => <option key={domaine.id}
                                                             value={domaine['@id']}>{domaine.libelle}</option>)}
                        </select>
                    </div>
                </div>
                <button type="button" onClick={() => setIndex(2)}
                        className="btn btn-primary mx-auto d-block">Continuer
                </button>
            </div>
            <div className={index === 2 ? "" : "hidden"}>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Composante COSO</span>
                        <select className="form-control" name="composanteCoso" onChange={handleChange}
                                value={recommandation.composanteCoso['@id']}>
                            <option value="">Sélectionner une composante</option>
                            {composanteCosos.map(c => <option key={c.id} value={c['@id']}>{c.libelle}</option>)}
                        </select>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Principe COSO</span>
                        <select className="form-control" name="principeCoso" onChange={handleChange}
                                value={recommandation.principeCoso['@id']}>
                            <option value="">Sélectionner un principe</option>
                            {principeCosos.map(p => <option key={p.id} value={p['@id']}>{p.libelle}</option>)}
                        </select>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Contrôle audit COSO</span>
                        <select className="form-control" name="controleCoso" onChange={handleChange}
                                value={recommandation.controleCoso['@id']}>
                            <option value="">Sélectionner un contrôle</option>
                            {controleCosos.map(c => <option key={c.id} value={c['@id']}>{c.libelle}</option>)}
                        </select>
                    </div>
                </div>


                <div className="mb-3">
                    <div className="form-group">
                        <span>Observations</span>
                        <textarea name="observation" cols="30" rows="5" className="form-control" value={current.observation} onChange={handleChange}></textarea>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <span>Les éléments de preuve</span>
                        <textarea name="elementPreuve" cols="30" rows="5" className="form-control" value={current.elementPreuve} onChange={handleChange}></textarea>
                    </div>
                </div>
                <button type="button" onClick={() => setIndex(1)} className="btn btn-default mx-auto d-block"
                        style={{width: '20%', float: "left"}}>Retour
                </button>
                <button type="button" onClick={() => setIndex(3)} className="btn btn-secondary mx-auto d-block"
                        style={{width: '20%', float: "right"}}>Continuer
                </button>
            </div>
            <div className={index === 3 ? "" : "hidden"}>

                <div className="mb-3">
                    <div className="form-group">
                        <span>Les difficultés rencontrées</span>
                        <textarea name="difficulteRencontre" cols="30" rows="5" className="form-control"  value={current.difficulteRencontre} onChange={handleChange}></textarea>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <span>Les commentaires</span>
                        <textarea name="commentaire" cols="30" rows="5" className="form-control" value={current.commentaire} onChange={handleChange}></textarea>
                    </div>
                </div>
                <div className="mb-3">
                    <div className="form-group">
                        <span>Joinder un fichier <small className="fw-bold">[optionnel]</small></span>
                        <input name="file" type="file" className="form-control"/>
                    </div>
                </div>
                <button type="button" onClick={() => setIndex(2)} className="btn btn-default mx-auto d-block"
                        style={{width: '20%', float: "left"}}>Retour
                </button>
                <button type="submit" className="btn btn-warning mx-auto d-block"
                        style={{width: '20%', float: "right"}}><Save/> Enregister
                </button>
            </div>

        </form>
    );
}

export default Form;
import React from 'react';
import { Link } from 'react-router-dom';

function Divider() {
  
   return (
      <div className="divideRse">

         <div className="portalButtonsWrapper">
            <Link className="portalButtonWrapper" to="/bibliotheque/rapports-publics">
               <div className="portalButton "><span className="portalButtonContent pe-5">Les rapports de mission</span>
               </div>
            </Link>
            <Link className="portalButtonWrapper" to="/bibliotheque/livres">
               <div className="portalButton"><span className="portalButtonContent">Les ressources de formation</span>
               </div>
            </Link>
         </div>

      </div>
   )
}

export default Divider;
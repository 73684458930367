import { dateFormat } from "../../../helpers/Helper";

const Detail = ({ mission }) => {

   return (
      <div className="row">
         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>N° d’ordre ou référence</h6>
               <p>{mission.refPav}</p>
            </div>
         </div>

         {/* <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Source de la mission</h6>
               <p>{mission.source && mission.source.libelle}</p>
            </div>
         </div> */}


         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Nature de la mission</h6>
               <p>{mission.nature.libelle}</p>
            </div>
         </div>

         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Structure</h6>
               <p>{mission.structure && mission.structure.intitule}</p>
            </div>
         </div>

         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Intitulé de la mission</h6>
               <p>{mission.intitule}</p>
            </div>
         </div>

         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Objectifs de la mission</h6>
               <p>{mission.objectif}</p>
            </div>
         </div>

         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Résultats attendus</h6>
               <p>{mission.resultatAttendu}</p>
            </div>
         </div>

         <div className="col-md-12">
            <div className="card p-2 mb-2 mt-2">
               <h6>Période d’exécution</h6>
               <p>{mission.periodeExecution}</p>
            </div>
         </div>


         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Nombre de jours calendaires</h6>
               <p>{mission.nbJourCalendaire}</p>
            </div>
         </div>
    
     
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Nombre d'équipes</h6>
               <p>{mission.nbEquipe}</p>
            </div>
         </div>

  
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Nombre de rapports attendus</h6>
               <p>{mission.nbRapportAttendu}</p>
            </div>
         </div>

         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Année de rattachement</h6>
               <p>{mission.anneRattachement}</p>
            </div>
         </div>
 
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Date de transmission au MEF</h6>
               <p>{dateFormat(mission.dateTransmissionMef)}</p>
            </div>
         </div>
         <div className="col-md-6">
            <div className="card p-2 mb-2 mt-2">
               <h6>Date d'approbation par le MEF</h6>
               <p>{dateFormat(mission.dateApprobationMef)}</p>
            </div>
         </div>
      </div>
   );
}

export default Detail;
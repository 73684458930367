import React, { useEffect, useState } from 'react';
import Layout from "../../components/front/Layout";
import Slider from "../../components/front/Slider";
import Divider from "../../components/Divider";
import { Link } from 'react-router-dom';
import { DEFAULT_URL, PUBLIC_URL, SITE_URL } from '../../helpers/Constants';
import Modal from "../../components/Modal";
import Contact from "../../components/front/Contact"
import HomeStore from '../../store/HomeStore';
import { setTitle } from '../../helpers/Helper';


function Home() {

   const [showContactModal, setShowContactModal] = useState(false);
   const [params, setParams] = useState({});

   setTitle('Inspections Générales des finances.')

   useEffect(() => {
      init()
   }, [])

   const init = async () => {
      let _params = await HomeStore.getParams()
      setParams(_params);
      console.log(_params);
   }

   const showContactForm = (e) => {
      e.preventDefault();
      setShowContactModal(true);
   }

   const Body = () => {
      return (
         <>
            <Slider />
            <div id="motdirecteur" className="motdirecteur">
               <section className="welcome-section">
                  <div className="container">
                     <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                           <div className="welcome-wrap">
                              <div className="welcome-text pt-lg-5">
                                 <h2 className="section-title">{params.message_title}</h2>
                                 <div dangerouslySetInnerHTML={{__html:params.message}}>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-3">
                           <div className="facts-counter">
                              <ul>
                                 <li className="bg-light-green">
                                    <Link to="/rapport-activite-generale" className="text-white">
                                       <div className="fact-item d-flex">
                                          <div className="fact-icon">
                                             <img src={PUBLIC_URL + '/img/budget.png'} alt="Catégories" />
                                          </div>
                                          <div>RAPPORTS ACTIVITES GENERALES </div>
                                       </div>
                                    </Link>
                                 </li>
                                 <li className="bg-red">
                                    <Link to="/publications-et-textes-legaux" className="text-white">
                                       <div className="fact-item d-flex">
                                          <div className="fact-icon">
                                             <img src={PUBLIC_URL + 'img/law.png'} alt="Categories" />
                                          </div>
                                          <div>PUBLICATION ET TEXTES LEGAUX <br />
                                             {/* <strong className="fact-counter counter">50</strong> Articles */}
                                          </div>
                                       </div>
                                    </Link>
                                 </li>
                                 <li className="bg-green">
                                    <a onClick={showContactForm} href="/" data-bs-toggle="modal" data-bs-target="#nousEcrisModal" className="text-white text-uppercase">
                                       <div className="fact-item d-flex">
                                          <div className="fact-icon">
                                             <img src={PUBLIC_URL + '/img/alert.png'} alt="Organes de contrôles" />
                                          </div>
                                          <div>Ecrire à un organe de contrôle</div>
                                       </div>
                                    </a>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="welcome-image" style={{ backgroundImage: `url(${DEFAULT_URL + params.directeur_image})` }}></div>
               </section>
            </div>
            <Divider />
            <Modal body={<Contact onClose={() => setShowContactModal(false)} />} title="ECRIRE À UN ORGANE DE CONTRÔLE" show={showContactModal} onClose={() => setShowContactModal(false)} />
         </>
      );
   }

   return <Layout body={<Body />} />
}

export default Home;
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faLock, faEnvelopeOpenText, faAngleUp, faShareAlt, faTimes, faSave, faPencilAlt, faTrash, faPlusCircle, faUserCircle, faLongArrowAltRight, faFilePdf, faBookReader, faPaperPlane, faExclamationTriangle, faExpand } from '@fortawesome/free-solid-svg-icons'

export const Facebook = () => <i className="fab fa-facebook"></i>;
export const Mobile = () => <i className="fas fa-mobile-alt"></i>;
export const Twitter = () => <i className="fab fa-twitter-square"></i>;
export const Youtube = () => <i className="fab fa-youtube"></i>;
export const FaLock = () => <FontAwesomeIcon icon={faLock} />;
export const Envelope = () => <FontAwesomeIcon icon={faEnvelopeOpenText} />;
export const Phone = () => <FontAwesomeIcon icon={faPhoneAlt} />;
export const Up = () => <FontAwesomeIcon icon={faAngleUp} />;
export const UserCircle = () => <FontAwesomeIcon icon={faUserCircle} />;
export const Pencil = () => <FontAwesomeIcon icon={faPencilAlt} />;
export const Trash = () => <FontAwesomeIcon icon={faTrash} />;
export const Share = () => <FontAwesomeIcon icon={faShareAlt} />;
export const Save = ({ size = '1x' }) => <FontAwesomeIcon icon={faSave} size={size} />;
export const Croix = ({ size = '1x' }) => <FontAwesomeIcon icon={faTimes} size={size} />;
export const LongArrowAltRight = () => <FontAwesomeIcon icon={faLongArrowAltRight} />;
export const Pdf = ({ size = 'sm' }) => <FontAwesomeIcon icon={faFilePdf} size={size} />;
export const Reader = ({ size = 'sm' }) => <FontAwesomeIcon icon={faBookReader} size={size} />;
export const Send = ({ size = 'sm' }) => <FontAwesomeIcon icon={faPaperPlane} size={size} />;
export const Expand = ({ size = 'sm' }) => <FontAwesomeIcon icon={faExpand} size={size} />;
export const PlusCircle = ({ size = 'sm', classes = '' }) => <FontAwesomeIcon className={classes} icon={faPlusCircle} size={size} />;
export const Exclamation = ({ size = '2x' }) => <FontAwesomeIcon icon={faExclamationTriangle} size={size} />;


export const Circle = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <circle cx="12" cy="12" r="9" />
      </svg>
   );
}

export const Folder = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-folders" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
         <path d="M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2" />
      </svg>
   );
}

export const Clock = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clock" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <circle cx="12" cy="12" r="9" />
         <polyline points="12 7 12 12 15 15" />
      </svg>
   );
}

export const Eye = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <circle cx="12" cy="12" r="2" />
         <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
      </svg>
   );
}

export const ArrawLeft = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-left" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <line x1="5" y1="12" x2="19" y2="12" />
         <line x1="5" y1="12" x2="11" y2="18" />
         <line x1="5" y1="12" x2="11" y2="6" />
      </svg>

   );
}

export const Star = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-star" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
      </svg>

   );
}

export const Upload = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-upload" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
         <polyline points="7 9 12 4 17 9" />
         <line x1="12" y1="4" x2="12" y2="16" />
      </svg>
   );
}


export const Filter = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5" />
      </svg>
   );
}

export const Lock = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <rect x="5" y="11" width="14" height="10" rx="2" />
         <circle cx="12" cy="16" r="1" />
         <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
      </svg>
   );
}

export const Search = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <circle cx="10" cy="10" r="7" />
         <line x1="21" y1="21" x2="15" y2="15" />
      </svg>
   );
}

export const Heart = ({ size = 25 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
      </svg>
   );
}


export const User = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <circle cx="12" cy="7" r="4" />
         <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
      </svg>

   );
}


export const Calendar = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-calendar-event" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <rect x="4" y="5" width="16" height="16" rx="2" />
         <line x1="16" y1="3" x2="16" y2="7" />
         <line x1="8" y1="3" x2="8" y2="7" />
         <line x1="4" y1="11" x2="20" y2="11" />
         <rect x="8" y="15" width="2" height="2" />
      </svg>

   );
}


export const Building = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-bank" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <line x1="3" y1="21" x2="21" y2="21" />
         <line x1="3" y1="10" x2="21" y2="10" />
         <polyline points="5 6 12 3 19 6" />
         <line x1="4" y1="10" x2="4" y2="21" />
         <line x1="20" y1="10" x2="20" y2="21" />
         <line x1="8" y1="14" x2="8" y2="17" />
         <line x1="12" y1="14" x2="12" y2="17" />
         <line x1="16" y1="14" x2="16" y2="17" />
      </svg>

   );
}

export const Resource = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-license" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" />
         <line x1="9" y1="7" x2="13" y2="7" />
         <line x1="9" y1="11" x2="13" y2="11" />
      </svg>

   );
}

export const Rapport = ({ size = 20 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-text" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M14 3v4a1 1 0 0 0 1 1h4" />
         <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
         <line x1="9" y1="9" x2="10" y2="9" />
         <line x1="9" y1="13" x2="15" y2="13" />
         <line x1="9" y1="17" x2="15" y2="17" />
      </svg>
   );
}

export const Dashboard = ({ size = 35 }) => {
   return (
      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-desktop-analytics" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <rect x="3" y="4" width="18" height="12" rx="1" />
         <path d="M7 20h10" />
         <path d="M9 16v4" />
         <path d="M15 16v4" />
         <path d="M9 12v-4" />
         <path d="M12 12v-1" />
         <path d="M15 12v-2" />
         <path d="M12 12v-1" />
      </svg>
   );
}

export const Book = ({ size = 20 }) => {
   return (

      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-book" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
         <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
         <line x1="3" y1="6" x2="3" y2="19" />
         <line x1="12" y1="6" x2="12" y2="19" />
         <line x1="21" y1="6" x2="21" y2="19" />
      </svg>

   );
}


export const Download = ({ size = 20 }) => {
   return (

      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-download" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
         <polyline points="7 11 12 16 17 11" />
         <line x1="12" y1="4" x2="12" y2="16" />
      </svg>

   );
}

export const File = ({ size = 20 }) => {
   return (

      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-analytics" width={size} height={size} viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
         <path stroke="none" d="M0 0h24v24H0z" fill="none" />
         <path d="M14 3v4a1 1 0 0 0 1 1h4" />
         <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
         <line x1="9" y1="17" x2="9" y2="12" />
         <line x1="12" y1="17" x2="12" y2="16" />
         <line x1="15" y1="17" x2="15" y2="14" />
      </svg>

   );
}


import { useEffect, useState } from "react";
import { extract, notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import { Save } from "../../Icons";
import Step from "../Step";
import DatePicker from 'react-date-picker';

function isEmpty(obj) {
   return obj!==undefined && Object.keys(obj).length === 0;
}

const Form = ({ mission,annees, natures, structures, reset, close }) => {

   const [current, setCurrent] = useState(mission);
   const [index, setIndex] = useState(1);
   const [options, setOptions] = useState([]);

  // const structure = UserStore.state.currentUser.agent.structure;

   useEffect(()=>{

      const _options = structures.map(organe => {
         return { value: organe['@id'], label: organe.intitule }
      })
      setOptions(_options);
   },[]);

   const handleOrganeChange = (option) => {
      setCurrent({ ...current, structure: option.value })
   }

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleChangeDate = (e, inputName) => {
      // const format = "YYYY-MM-DD"
      // const dateInput = moment(e).format(format);
      const currentObj = current
      currentObj[inputName] = e
      setCurrent({ ...currentObj })

   }

   const handleSubmit = (e) => {
      e.preventDefault();

      let mission = { ...current, nbJourCalendaire: +current.nbJourCalendaire, nbEquipe: +current.nbEquipe, nbRapportAttendu: +current.nbRapportAttendu, structure: current.structure }

      // if (mission.source['@id'])
      //    mission = { ...mission, source: mission.source['@id'] }

      if (mission.nature['@id'])
         mission = { ...mission, nature: mission.nature['@id'] }

      if (mission.annee['@id'])
         mission = { ...mission, annee: mission.annee['@id'] }
      if(isEmpty(mission.source)){
        delete mission.source
      }

      MissionStore.saveMission(mission).then(_ => {
         notify("Succès", "La mission a été enregistrée.", "success");
         e.target.reset();
         close()
         setCurrent(reset)
      }).catch(err => notify("Erreur", "Certains champs du formulaire ne sont pas correctement renseignés.", "danger"));

   }

   return (
      <form onSubmit={handleSubmit}>

         <Step size={3} index={index} />
         <div className={index === 1 ? "" : "hidden"}>

            {/* <div className="mb-3">
               <div className="form-group">
                  <span>Source mission </span>
                  <select className="form-control" name="source" onChange={handleChange} value={current.source['@id']}>
                     <option value="">Sélectionner une source</option>
                     {sources.map(source => <option key={source.id} value={source['@id']}>{source.libelle}</option>)}
                  </select>
               </div>
            </div> */}

            <div className="mb-3">
               <div className="form-group">
                  <span>La référence dans le PAV <small className="fw-bold">[obligatoire]</small></span>
                  <input type="text" name="refPav" className="form-control" value={current.refPav} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Nature mission <small className="fw-bold">[obligatoire]</small></span>
                  <select className="form-control" name="nature" onChange={handleChange} value={current.nature['@id']} >
                     <option value="">Sélectionner une nature</option>
                     {natures.map(nature => <option key={nature.id} value={nature['@id']}>{nature.libelle}</option>)}
                  </select>
               </div>
            </div>

             <div className="mb-3">
               <div className="form-group">
                  <span>Structure <small className="fw-bold">[obligatoire]</small></span>
                   {/* <Select options={options} onChange={handleOrganeChange} placeholder="Organes de contrôle" /> */}
                   <select className="form-control" name="structure" onChange={handleChange} value={current.structure&&current.structure['@id']} >
                     <option value="">Sélectionner une structure</option>
                     {structures.map(structure => <option key={structure.id} value={structure['@id']}>{extract(structure.intitule,80)}</option>)}
                  </select>
               </div>
            </div> 

            <div className="mb-3">
               <div className="form-group">
                  <span>Intitulé de la mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="text" name="intitule" className="form-control" value={current.intitule} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Objectifs de la mission <small className="fw-bold">[obligatoire]</small></span>
                  <textarea name="objectif" cols="30" rows="5" className="form-control" value={current.objectif} onChange={handleChange}></textarea>
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Résultats attendus <small className="fw-bold">[obligatoire]</small></span>
                  <textarea name="resultatAttendu" cols="30" rows="5" className="form-control" value={current.resultatAttendu} onChange={handleChange}></textarea>
               </div>
            </div>


            <button type="button" disabled={!current.objectif||!current.resultatAttendu||!current.intitule|| isEmpty(current.structure) || isEmpty(current.nature)} onClick={() => setIndex(2)} className="btn btn-primary mx-auto d-block">Continuer</button>
         </div>
         <div className={index === 2 ? "" : "hidden"}>

            <div className="mb-3">
               <div className="form-group">
                  <span>Periode d'exécution <small className="fw-bold">[obligatoire]</small></span>
                  <input type="text" name="periodeExecution" className="form-control" value={current.periodeExecution} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Nombre de jours calendaires <small className="fw-bold">[obligatoire]</small></span>
                  <input type="number" name="nbJourCalendaire" required className="form-control" value={current.nbJourCalendaire} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Nombre d'équipes <small className="fw-bold">[obligatoire]</small></span>
                  <input type="number" name="nbEquipe" className="form-control" required value={current.nbEquipe} onChange={handleChange} />
               </div>
            </div>


            {/* <div className="mb-3">
               <div className="form-group">
                  <span>Timbre </span>
                  <input type="text" name="timbre" className="form-control" value={current.timbre} onChange={handleChange} />
               </div>
            </div> */}

            <button type="button" onClick={() => setIndex(1)} className="btn btn-default mx-auto d-block" style={{ width: '20%', float: "left" }}>Retour</button>
            <button type="button" disabled={!current.nbJourCalendaire || !current.nbEquipe  } onClick={() => setIndex(3)} className="btn btn-secondary mx-auto d-block" style={{ width: '20%', float: "right" }}>Continuer</button>
         </div>
         <div className={index === 3 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>Nombre de rapports attendus <small className="fw-bold">[obligatoire]</small></span>
                  <input type="number" name="nbRapportAttendu" required className="form-control" value={current.nbRapportAttendu} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Année de rattachement <small className="fw-bold">[obligatoire]</small></span>
                  <select className="form-control" name="annee" onChange={handleChange} value={current.annee && current.annee['@id']} >
                     <option value="">Sélectionner un annee</option>
                     {annees.map(an => <option key={an.id} value={an['@id']}>{an.libelle}</option>)}
                  </select>
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Date transmission MEF <small className="fw-bold">[obligatoire]</small></span>
                  <DatePicker
                     name="dateTransmissionMef"
                     onChange={(e) => handleChangeDate(e, 'dateTransmissionMef')}
                     value={current.dateTransmissionMef}
                     format="dd/MM/y"

                  />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Date approbation MEF <small className="fw-bold">[obligatoire]</small></span>
                  <DatePicker
                     name="dateApprobationMef"
                     onChange={(e) => handleChangeDate(e, 'dateApprobationMef')}
                     value={current.dateApprobationMef}
                     format="dd/MM/y"
                  />               </div>
            </div>

            <button type="button" onClick={() => setIndex(2)} className="btn btn-default mx-auto d-block" style={{ width: '20%', float: "left" }}>Retour</button>
            <button type="submit" disabled={!current.dateApprobationMef || !current.dateTransmissionMef || !current.refPav || isEmpty(current.annee) || !current.nbRapportAttendu}  className="btn btn-warning mx-auto d-block" style={{ width: '20%', float: "right" }}><Save /> Enregister</button>
         </div>
      </form>
   );
}

export default Form;
import httpClient, { get } from "./HttpClient";
import { SITE_URL, USER_ROLES } from "../helpers/Constants";
import UserStore from "./UserStore";
import moment from 'moment'

const END_POINT = "/structures";

const StructureStrore = {
  state: {
    structures: [],
    organes: [],
    teams: [],
    users: [],
    groups: [],
    qualities: [],
  },

  async getGroups() {
    if (!this.state.groups.length) {
      try {
        const response = await httpClient.get("/groupes");
        const data = response.data["hydra:member"];
        this.state.groups = data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return this.state.groups;
  },

  async getStatistiqueInspecteur() {

    //if(!UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR)   || !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)  ) return false
      try {
        const response = await httpClient.get("/stats/me");
   
        return response.data;
      } catch (error) {
        console.error(error);
      }
    
    return [];
  },

  async getStatistiqueStructure() {
    if(!UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) return false

    try {
      const response = await httpClient.get("/stats/structure");
      const data = response;
      
      return data;
    } catch (error) {
      console.error(error);
    }
  
  return [];
},


  async getAgentsByStructureCode(code) {
    try {
      const response = await httpClient.get(
        `/agents?page=1&structure.code=${code}`
      );
      const data = response.data["hydra:member"];
      this.state.teams = data;
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTeams() {
    if (!this.state.teams.length) {
      try {
        const response = await httpClient.get("/agents");
        const data = response.data["hydra:member"];
        this.state.teams = data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return this.state.teams;
  },

 

  async getDomainesRemboursements() {
    try {
      const response = await httpClient.get(`/domaine_remboursements`);
      const data = response.data["hydra:member"];
      this.state.teams = data;
      return data;
    } catch (error) {
      console.error(error);
    }
  },

  async getUsers() {
    if (!this.state.users.length) {
      try {
        const response = await httpClient.get("/users");
        const data = response.data["hydra:member"];
        this.state.users = data;
        return data;
      } catch (error) {
        console.error(error);
      }
    }
    return this.state.users;
  },

  async getOrganes(page = 1, search = "") {
    if (!this.state.structures.length) {
      try {
        const response = await httpClient.get(
          END_POINT + `?page=${page}&itemsPerPage=10&intitule=${search}&isActivated=true`
        );
        const data = await response.data["hydra:member"];
        const pagination = await response.data["hydra:view"];
        return { data, pagination };
      } catch (e) {
        return { data: [], pagination: {} };
      }
    }
    return this.state.structures;
  },

  async getAll() {
    if (!this.state.structures.length) {
      try {
        const response = await httpClient.get(END_POINT + "?itemsPerPage=100");
        const data = await response.data["hydra:member"];
        this.state.stuctures = data;
        this.state.organes = data;
        return data;
      } catch (e) {
        console.error(e);
      }
    }
    return this.state.structures;
  },

  async getIsSupervisionAll() {
    if (!this.state.structures.length) {
      try {
        const response = await httpClient.get(
          END_POINT + "?isSupervision=true&pagination=false"
        );
        const data = await response.data["hydra:member"];
        this.state.stuctures = data;
        this.state.organes = data;
        return data;
      } catch (e) {
        console.error(e);
      }
    }
    return this.state.structures;
  },

  async getIsIGF() {
    if (!this.state.structures.length) {
      try {
        const response = await httpClient.get(
          END_POINT + "?isIgf=true&pagination=false"
        );
        const data = await response.data["hydra:member"];
        this.state.stuctures = data;
        this.state.organes = data;
        return data;
      } catch (e) {
        console.error(e);
      }
    }
    return this.state.structures;
  },

  async getStructure(id) {
    try {
      const response = await httpClient.get(END_POINT + "/" + id);
      const data = await response.data; //['hydra:member'];
      return data;
    } catch (e) {
      console.error(e);
    }
  },

  async getQualities() {
    if (!this.state.qualities.length) {
      try {
        const response = await httpClient.get(`/qualites`);
        const data = await response.data["hydra:member"];
        this.state.qualities =
          data !== undefined && data !== "undefined" ? data : [];
        return data;
      } catch (e) {
        return this.state.qualities;
      }
    } else return this.state.qualities;
  },

  sendRapport(formData) {
    return httpClient.post("/suivi_rapports", formData);
  },

  async updateRecommandation(recommandation) {
    let red = {
      ...recommandation,
      constatation: recommandation.constatation["@id"],
      nivMiseEnOeuvre: recommandation.nivMiseEnOeuvre["@id"],
    };

    return httpClient.patch("/suivi_rec_raports/" + recommandation.id, red, {
      headers: {
        "Content-Type": "application/merge-patch+json",
        Accept: "application/ld+json",
      },
    });
  },

  async getRapportsRecus(page = 1, search = null) {
    try {
      const response = await httpClient.get(
        `suivi_rapports?page=${page}&itemsPerPage=12&order[createdAt]=desc&${
          search && `libelle=${search}`
        }`
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return this.state.qualities;
    }
  },

  async getRecommandationRapportSuivi(page = 1, search = null,filters) {
    try {
      const response = await httpClient.get(
        `suivi_rec_raports?page=${page}&itemsPerPage=12&order[createdAt]=desc${ search?`&libelle=${search}`:""}${ filters.etat?`&etat=${filters.etat}`:""}${ filters.delaiBefore ?`&delais[before]=${moment(filters.delaiBefore).format('YYYY-MM-DD') }`:""}${ filters.delaiAfter ? `&delais[after]=${moment(filters.delaiAfter).format('YYYY-MM-DD')}`:""}${ filters.niveau ? `&nivMiseEnOeuvre.id=${filters.niveau}`:""}`
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return this.state.qualities;
    }
  },

  async getRapportsPartage(page = 1, search = null) {
    try {
      const response = await httpClient.get(
        `partage_rapport_structures?page=${page}&itemsPerPage=12&order[createdAt]=desc&${
          search && `libelle=${search}`
        }`
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      return this.state.qualities;
    }
  },
  async getObservationRecommandation(recommandation) {
    try {
      const response = await httpClient.get(
        `rec_rapport_observations?pagination=false&recommandation.id=${recommandation.id}`
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {}
  },

  async getObservationRapport(rapport) {
    try {
      const response = await httpClient.get(
        `rec_rapport_observations?pagination=false&rapport.id=${rapport}`
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {}
  },

  async getRapportsRecusDetail(rid) {
    try {
      const response = await httpClient.get(`suivi_rapports/${rid}`);
      const data = await response.data;
      return data;
    } catch (e) {
      return this.state.qualities;
    }
  },

  async getPartageRapportRecusDetail(rid) {
    try {
      const response = await httpClient.get(
        `partage_rapport_structures/${rid}`
      );
      let data = await response.data;
      data = await this.getRapportsRecusDetail(data.rapport.id);
      return data;
    } catch (e) {
      return this.state.qualities;
    }
  },
  async getRecommandationDetail(rid) {
    try {
      const response = await httpClient.get(`suivi_rec_raports/${rid}`);
      const data = await response.data;
      return data;
    } catch (e) {
      return this.state.qualities;
    }
  },
  removeRapportRecu(id) {
    return httpClient.delete("/suivi_rapports/" + id);
  },

  async getRecommandation(recom) {
    if (recom)
      try {
        const response = await httpClient.get("/suivi_rec_raports/" + recom.id);
        const data = await response.data;
        return data;
      } catch (e) {
        return {};
      }
  },

  updateSuiviRecRapport(rec) {
    return httpClient.patch(
      "/suivi_rec_raports/" + rec.id,
      {
        etat: rec.etat,
        commentaire: rec.commentaire,
        // delaisMisEnOeuvre: rec.delaisMisEnOeuvre
        //   ? rec.delaisMisEnOeuvre
        //   : "RAS",
      },
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Accept: "application/ld+json",
        },
      }
    );
  },

  addObservationSuiviRecRapport(obs) {
    return httpClient.post("/rec_rapport_observations", obs);
  },

  addFileVersionSuiviRecRapport(obs) {
    return httpClient.post("/rec_rapport_observations", obs);
  },

  shareRapport(rapport) {
    return httpClient.post("/partage_rapport_structures", rapport);
  },

  async updateNiveauRecRapport(id, nivMiseEnOeuvre) {
    return httpClient.patch(
      "/suivi_rec_raports/" + id,
      { nivMiseEnOeuvre: nivMiseEnOeuvre },
      {
        headers: {
          "Content-Type": "application/merge-patch+json",
          Accept: "application/ld+json",
        },
      }
    );
  },

  async getHistories(page = 1) {
    try {
      const response = await httpClient.get(
        SITE_URL + `/api/mouchards?page=${page}&itemsPerPage=30`,
        {}
      );
      const data = await response.data["hydra:member"];
      const pagination = await response.data["hydra:view"];
      return { data, pagination };
    } catch (e) {
      console.log("");
    }
  },
  async getHistory(id) {
    try {
      const response = await get(SITE_URL + "/mouchards/" + id, {});
      const data = await response.data;
      return data;
    } catch (e) {
      console.error(e);
    }
  },
};

export default StructureStrore;

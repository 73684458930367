import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Audio from "../../../components/Audio";
import Layout from "../../../components/front/Layout";
import { Search, Download } from "../../../components/Icons";
import PDF from "../../../components/PDF";
import Video from "../../../components/Video";
import { SITE_URL } from "../../../helpers/Constants";
import { dateFormat, lastYears, notify } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import ReportStore from "../../../store/ReportStore";

export default function RapportGeneral() {
   const { id } = useParams();
   const [report, setReport] = useState({ odreMission: {}, auteur: { agent: { structure: '' } } });
   const { thisYear, years } = lastYears(2)
   const [year, setYear] = useState('')

   useEffect(() => {
      init();
   }, [id])

   const init = async () => {
      const _report = await DocumentStore.getDocument(id);
      setReport(_report);
   }

   const getRapport = async (year) => {
      const _report = await ReportStore.getRapportYear(report.structure.code, year);
      if (_report.length)
         setReport(_report[0])
      else notify('RAPPORT', "Pas de rapport d'actitité pour l'année séléctionnée.", "info");
   }

   const Body = () => {
      return (
         <>
            <div className="p-1 breadcrumbs xs-font">
               <Link to="/" className="textPrimary fw-bold">Accueil</Link> <span> / Rapports d'activités</span>
            </div>
            <h4 className="page-title">{report.libelle}</h4>
            <div className="container mt-3">
               <div className="row">
                  <div className="col-md-9">
                     <div className="p-2 card">
                        <div className="d-flex justify-content-evenly pb-2 border-bottom">
                           <small><i className="fas fa-university"></i> Structure <br /><b className="textPrimaryDark">{report.structure !== undefined ? report.structure.intitule : ''}</b></small>
                           <small><i className="fas fa-user"></i> Auteur <br /><b className="textPrimaryDark">{report.auteur.agent.nom}</b></small>
                           <small><i className="fas fa-calendar"></i> Date d'edition <br /><b className="textPrimaryDark">{dateFormat(report.createdAt)}</b></small>
                           {(report.fichier !== undefined && report.categorie.libUnique === 'word') &&
                              <a rel="noreferrer" target="_blank" href={SITE_URL + '/doc/download/' + report.id} className="btn btn-sm btn-outline-warning" title="TELECHARGE"><Download /></a>}
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: report.description }} />
                        {(report.categorie !== undefined && report.categorie.libUnique === 'pdf') && <PDF src={SITE_URL + '/doc/download/' + report.id} />}
                        {(report.categorie !== undefined && report.categorie.libUnique === "videos") && <Video src={SITE_URL + '/doc/download/' + report.id} />}
                        {(report.categorie !== undefined && report.categorie.libUnique === "audio") && <Audio src={SITE_URL + '/doc/download/' + report.id} />}

                     </div>
                  </div>
                  <div className="col-md-3 ">
                     <div className="p-2 card shadow">

                        <div className="input-group mb-3 border-bottom pb-3">
                           <select name="year" className="form-control" value={year} onChange={(e) => setYear(e.target.value)}>
                              <option value="">Par année</option>
                              <option value={thisYear}>{thisYear}</option>
                              {years.map(year => <option key={year} value={year}>{year}</option>)}
                           </select>
                           <span className="input-group-text" id="basic-addon2" onClick={() => window.location.href = "/rapport-activite-generale?annee=" + year}><Search /></span>
                        </div>
                        {years.map(year =>
                           <div key={year} className="card rapportRecent pt-4 mt-4">
                              <h5 className="text-center">Rapport</h5>
                              <h3 className="text-center">{year}</h3>
                              <button className="btn btn-outline-warning text-dark mt-5 m-3" onClick={() => getRapport(year)}>Lire</button>
                           </div>
                        )}

                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }

   return <Layout body={<Body />} />
}

import { USER_ROLES } from "../helpers/Constants";
import httpClient from "./HttpClient";
import UserStore from "./UserStore";

const NotificationStore = {

   state: {
      notifications: []
   },

   async getNotifications(lu = null, page = 1) {
      try {
         let url = `/notifications/me?page=${page}&order[createdAt]=DESC`;
         // if (lu !== null)
         //    url += `&lu=${lu}`
         const response = await httpClient.get(url);
         //const data = await response.data['hydra:member'];
         //const pagination = await response.data['hydra:view'];
         return { data: response.data.notifs, pagination: {} };
      } catch (e) {
         return { data: [], pagination: {} }
      }
   },

   async getNotRead() {
      let structure_code = null 
      if(UserStore.state.currentUser.agent && UserStore.state.currentUser.agent.structure ){
         structure_code = UserStore.state.currentUser.agent.structure.code
      }
      if (!this.state.notifications.length && UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)
      ) {
         try {
            let url = `/notifications?pagination=false&lu=false`;
            if(structure_code) url +=`&structure.code=${structure_code}`

            const response = await httpClient.get(url);
            const count_notifs = await response.data['hydra:totalItems'];
            return { count_notifs };
         } catch (e) {
            return { count_notifs:0}
         }
      }

      else
         return 0;
   },

   

   async getNotificationsByResource(resource, type) {
      try {
         let url = `/notifications/ressource/${resource}?type=${type}&order[createdAt]=DESC`;

         const response = await httpClient.get(url);
         //const data = await response.data['hydra:member'];
         //const pagination = await response.data['hydra:view'];
         return { data: response.data.notifs };
      } catch (e) {
         return { data: [], pagination: {} }
      }
   },

   setReadedNotification(id, lu = false) {
      return httpClient.patch("/destinataires/" + id, { lu }, { headers: { 'Content-Type': 'application/merge-patch+json', 'Accept': 'application/ld+json' } })
   },

   removeNotification(id) {
      return httpClient.delete('/destinataires/' + id);
   }

}

export default NotificationStore;
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from "../../../components/front/Layout";
import Pagination from '../../../components/Pagination';
import { setTitle } from '../../../helpers/Helper';
import StructureStrore from '../../../store/StructureStore';


export default function Organes() {

    const [organes, setOrganes] = useState([]);
    const [filtredOrganes, setFiltredOrganes] = useState([]);
    const [page, setPage] = useState(1)
    const [pagination, setPagination] = useState({});
    const [search, setSearch] = useState("");

    useEffect(() => {
        init();
        setTitle('Les organes de contrôles');
    }, [page])

    const setCurrentPage = (page) => {
        setPage(page);
     }

    const init = async (search) => {
        const { data, pagination } = await StructureStrore.getOrganes(page,search)
        setPagination(pagination)
        setFiltredOrganes(data);
        setOrganes(data);
    }

    const filterOrganes = (e) => {
        e.preventDefault();
        const value = document.getElementById("q").value;
        if (value !== "") {
            setSearch(value)
            // const _organes = organes.filter(organe => organe.intitule.toLowerCase().includes(value.toLowerCase()));
            // setFiltredOrganes(_organes)
            init(value)
        } else {
            init()
        }
    }

    

    const Body = () => {
        return (
            <>
                <div className="page-info">
                    <h2 className="text-center text-white"> Organes de contrôle</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> / </span>
                            <span className="text-uppercase">Organes de contrôle</span>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mb-4 page-content p-0">
                    <div className="container mt-3">
                        <div className="card p-3">
                            <form onSubmit={filterOrganes}>
                                <div className=" d-flex">
                                    <div className="input-group mb-3 ms-1">
                                        <input type="text"   className="form-control" name="q" id="q" placeholder={`Votre recherche ${search}`} />
                                        <button type="submit" className="input-group-text" id="basic-addon1">
                                            <i className="fas fa-search" /></button>
                                    </div>
                                </div>
                            </form>
                            <table className="table table-hover table-bordered mt-3 organedeControle">
                                <thead className="bckColorDark text-white">
                                    <tr>
                                        <th style={{ width: '50%', textAlign: "center" }}>Organe de contrôle </th>
                                        <th className="text-center"><small>Structure de tutelle
                                        </small></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredOrganes.map(organe =>
                                        <tr key={organe.id}>
                                            <td>{organe.organeControle && organe.organeControle.toUpperCase()}</td>
                                            <td>{organe.intitule}</td>
                                            <td>
                                                <Link title="Voir le detail" to={`/organe-de-controle/${organe.id}`} className="btn btn-warning"><i className="fas fa-eye"></i></Link>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                            <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
                        </div>

                    </div>
                </div>
            </>
        );
    }

    return <Layout body={<Body />} />
}

import React, {useEffect, useState} from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import {Pencil, Save, Trash} from "../../../components/Icons";
import Modal from "../../../components/Modal";
import {USER_ROLES} from "../../../helpers/Constants";
import {notify} from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";
import Pagination from "../../../components/Pagination";

const Form = ({avis, reset, close}) => {

    const [current, setCurrent] = useState(avis);

    const handleChange = (e) => {
        setCurrent({...current, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        MissionStore.saveAvis(current).then(_ => {
            notify("Succès", "Avis conseil des ministres enregistré.", "success");
            setCurrent(reset);
            e.target.reset();
            close()

        })
    }

    return (
        <form onSubmit={handleSubmit}>

            <div className="mb-3">
                <div className="form-group">
                    <span>Avis conseil ministre <small className="fw-bold">[obligatoire]</small></span>
                    <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required placeholder="avis conseil"/>
                </div>
            </div>
            <button type="submit" style={{width: '100%'}} className="btn btn-warning mx-auto d-block"><Save/> Enregister
            </button>
        </form>
    );
}

export default function Avi() {

    const [avis, setAvis] = useState([]);
    const [avi, setAvi] = useState({libelle: ""});
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoaging] = useState(false);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);

    useEffect(() => {
        if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
            window.location.href = "/bibliotheque";
        }
        init();
    }, [page])

    const init = async () => {
        setLoaging(true)
        const {data: _avis,pagination} = await MissionStore.getAvis(page);
        setAvis(_avis);
        setLoaging(false)
        setPagination(pagination)
    }

    const removeAvi = (id) => {
        if (window.confirm("Supprimer cet avis?"))
            MissionStore.removeAvis(id).then(_ => init())
    }

    const updateAvi = (avi) => {
        setAvi(avi);
        setShowModal(true);
    }

    const newAvi = (e) => {
        e.preventDefault();
        setAvi({});
        setShowModal(true);
    }

    const onModalClose = () => {
        init();
        setAvi({libelle: ""})
        setShowModal(false);
    }

    const setCurrentPage = (page) => {
        setPage(page);
    }

    const Body = () => {
        return (
            <>
                <Nav/>

                <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
                    <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a>
                    <span>/ Avis conseil ministres</span>
                </div>
                <h4 className="page-title">Les avis conseils des ministres</h4>
                <button onClick={newAvi} className="btn btn-primary" style={{
                    float: "right",
                    marginRight: "30px"
                }}>Créer un avis
                </button>
                <div className="container-fluid mt-4 mb-5">
                    <div className="card p-3">
                        <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Avis conseil ministre</th>
                                <th className="text-center"/>
                            </tr>
                            </thead>
                            <tbody>
                            {avis.map((avi, index) =>
                                <tr key={avi.id}>
                                    <td>{index + 1}</td>
                                    <td>{avi.libelle}</td>
                                    <td>
                                        <button type="button" className="btn btn-sm btn-success" style={{marginRight: "20px"}} onClick={() => updateAvi(avi)} title="Modifier">
                                            <Pencil/></button>
                                        <button type="button" className="btn btn-sm btn-danger" onClick={() => removeAvi(avi.id)} title="Supprimer">
                                            <Trash/></button>
                                    </td>
                                </tr>
                            )}

                            </tbody>
                        </table>
                        <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
                    </div>

                </div>
                <Modal size="" show={showModal} title="Enregistrer un avis" body={<Form avis={avi} reset={{libelle: ""}} close={onModalClose}/>} onClose={onModalClose}/>
            </>
        );
    }

    return <Layout body={<Body/>}/>
}
import {store} from 'react-notifications-component';
import "dayjs/locale/fr";
import {EXTENSIONS, LOCAL_STORAGE_PREFIX, storedItemKeys} from "./Constants";
import {useLocation} from 'react-router-dom';
import {confirmAlert} from 'react-confirm-alert';
import DocumentStore from '../store/DocumentStore';

export function getAuthToken() {
    return getStoredItem(storedItemKeys.TOKEN)
}

export function isEmpty(obj) {
    return obj!==undefined && Object.keys(obj).length === 0;
}

const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
dayjs.locale("fr");

export const relative_time = function (timestamp) {
    return dayjs(timestamp).fromNow();
};

export const dateFormat = function ($date, format = 'DD/MM/YYYY') {
    return dayjs($date).format(format)
};

export function setTitle(title) {
    document.title = title;
}

export function setDescription(description) {
    const meta = document.querySelector('meta[name="description"]');
    meta.setAttribute('content', description);
}


/**
 * Will store in item by applying a prefix on its key.
 *
 * @param {string} key the key of the item to store
 * @param {*} value the value of the item to store
 */
export function storeItem(key, value) {
    localStorage.setItem(LOCAL_STORAGE_PREFIX + key, value);
}

/**
 * Get a stored item by key by applying a prefix on its key.
 *
 * @param {string} the key of the item to retreive
 * @returns {string}
 */
export function getStoredItem(key) {
    return localStorage.getItem(LOCAL_STORAGE_PREFIX + key);
}

export function resetAllData (){

    removeStoredItem(storedItemKeys.TOKEN);
    removeStoredItem(storedItemKeys.CURRENT_USER);

}

/**
 * remove a stored item by applying a prefix on its key.
 *
 * @param {string} key the key of the item to remove
 */
export function removeStoredItem(key) {
    localStorage.removeItem(LOCAL_STORAGE_PREFIX + key);
}

export function hasStoredItem(key) {
    
    return getStoredItem(key) !== null;
}

export function doLogout() {
    localStorage.clear()
    window.location.reload()
}


export const notify = (title, message, type = 'default') => {

    store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

export const activeLink = (pathname, link) => {
    let active = "";

    if (pathname === link) {
        active = "active";
    } else {
        active = (pathname.startsWith(link) && link !== "/bibliotheque") ? "active" : "";
    }
    return active;
}

export const createStyleTag = (css) => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    head.appendChild(style);

    style.appendChild(document.createTextNode(css));
}

export const acronym = (name) => {
    function firstLetter(word) {
        return word[0];
    };
    var words = name.split(" ");
    var acr = words.map(firstLetter);
    return acr.join(".").toUpperCase();
}

export const makeString = (length = 10) => {
    var result = '';
    var characters = ' ABCDEFGHI JKLMNOP QRSTUVWXYZabcdef ghijklmno pqrstuvwx yz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const slugify = (text) => {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

export const getRandomColor = (i = 0) => {
    const color = ["#8B4513", "#778899", '#45ac05', '#ff8c00', '#ae08cb', '#079fdb', '#dc3545', "#883492", "rgb(212 113 12)", "#2a2b2b", "#008080", "#000080", "#808080"];
    if (i === 0)
        return color[Math.floor(Math.random() * color.length)]
    return color[i];
}

export const lastYears = (size = 20) => {
    let years = [];
    const thisYear = new Date().getFullYear();

    for (let i = size; i > 0; i--) {
        years.push((thisYear - i).toString());
    }
    years = years.reverse();
    return {thisYear, years}
}

const popupCenter = function (url, title, width, height) {
    const popupWidth = width || 640;
    const popupHeight = height || 320;
    const windowLeft = window.screenLeft || window.screenX;
    const windowTop = window.screenTop || window.screenY;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const popupLeft = windowLeft + windowWidth / 2 - popupWidth / 2;
    const popupTop = windowTop + windowHeight / 2 - popupHeight / 2;
    const popup = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + popupTop + ', left=' + popupLeft);
    popup.focus();
    return true;
};

export const shareTwitter = (url, title) => {
    const shareUrl = "https://twitter.com/intent/tweet?text=" + encodeURIComponent(title) +
        "&via=IGF" +
        "&url=" + encodeURIComponent(url);
    popupCenter(shareUrl, "Partager sur Twitter");
}

export const shareFacebook = (url) => {
    const shareUrl = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
    console.log(shareUrl);
    popupCenter(shareUrl, "Partager sur facebook");
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const confirmation = (title = "Suppression!", callback) => {
    confirmAlert({
        title: title,
        message: "",
        buttons: [
            {
                label: 'Oui',
                onClick: callback
            },
            {
                label: 'Non',
                onClick: () => false,
            }
        ]
    });
}


export const fileExtension = (fileName) => {
    const fileExtension = fileName.split('.').pop();
    return fileExtension.toLowerCase();
}

export const getDocumentFormat = (domaines) => {

    const fileExtension = DocumentStore.state.extension;
    let correspondingCategoryLibelle = 'pdf';
    for (const [key, value] of Object.entries(EXTENSIONS)) {
        if (value.includes(fileExtension)) {
            correspondingCategoryLibelle = key;
            break;
        }
    }
    return domaines.find(domaine => domaine.libUnique === correspondingCategoryLibelle);
}

export const extract = (text, count = 80, insertDots = true) => {
    if (!text) return "";
    return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
}


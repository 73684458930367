import { useState } from "react";
import { useQuery } from "../../../helpers/Helper";

export default function ActivitiesFilter() {
   const query = useQuery()
   const [categorie, setCategorie] = useState(query.get('categorie'));
   const [q, setQ] = useState(query.get('q'))
   return (
      <form action="recherche">
         <div className=" d-flex">
            {/* <select className="ms-1 result-filter-select-activite" name="categorie" value={categorie} onChange={(e) => setCategorie(e.target.value)}>
               <option value="">Filter par:</option>
               <option value="actus">Articles</option>
               <option value="communiques">Communique</option>
               <option value="reformes">Reformes</option>
            </select> */}
            <div className="input-group mb-3 ms-1">
               <input type="text" className="form-control" name="q" value={q} onChange={(e) => setQ(e.target.value)} placeholder="Mot clé" aria-label="Username" aria-describedby="basic-addon1" />
               <button type="submit" className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></button>
            </div>
         </div>
      </form>
   )
}
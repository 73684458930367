export const PUBLIC_URL = process.env.PUBLIC_URL;

export const API_URL = "https://api-ocoa.gouv.bj/api";
export const SITE_URL = "https://api-ocoa.gouv.bj/";
export const SITE_URL_MEDIA = "https://api-ocoa.gouv.bj/";
export const SUIVI_DES_RECOMMADATIONS = "https://suivi-ocoa.gouv.bj/login-";

export const DEFAULT_URL = "https://api-ocoa.gouv.bj";


export const Biblio = {
  NAME: "Bibliothèque IGF",
  LOGO: "http://afyamedico.com/bibliotheque/dist/img/logo_igf.jpg",
};

export const PasswordType = {
  SHOW: "text",
  HIDE: "password",
};

export const LOCAL_STORAGE_PREFIX = "bib2z_";

export const storedItemKeys = {
  TOKEN: "token",
  CURRENT_USER: "currentUser",
  CURRENT_USER_TYPE: "currentUserType",
  PRIVATE_ROUTE: "privateRoute",
};

export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  DANGER: "danger",
  INFO: "info",
  DEFAULT: "default",
  WARNING: "warning",
};

export const USER_ROLES = {
  ROLE_GESTIONS_MISSION: "ROLE_GESTIONS_MISSION",
  ROLE_INSPECTEUR: "ROLE_INSPECTEUR",
  ROLE_RESPONSABLE_ORGANE: "ROLE_RESPONSABLE_ORGANE",
  ROLE_COLLABORATEUR: "ROLE_COLLABORATEUR",
  ROLE_SUPERVISEUR: "ROLE_SUPERVISEUR",
};

export const USER_ROLES_LABEL = {
  ROLE_GESTIONS_MISSION: "Gestionnaire de mission",
  ROLE_INSPECTEUR: "Inspecteur",
  ROLE_RESPONSABLE_ORGANE: "Chef d'Organe",
  ROLE_COLLABORATEUR: "Collaborateur",
  ROLE_SUPERVISEUR: "Superviseur",
};

export const ITEMSPERPAGE = 5;

export const sliderConfig = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: (
    <button className="slick-prev">
      <i className="fas fa-long-arrow-alt-left"></i>
    </button>
  ),
  nextArrow: (
    <button className="slick-next">
      <i className="fas fa-long-arrow-alt-right"></i>
    </button>
  ),
  focusOnSelect: true,
  draggable: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const EXTENSIONS = {
  audio: ["mp3", "wav", "BWF", "Ogg", "mp3PRO", "VQF", "WMA"],
  videos: ["mp4", "mov", "avi", "flv", "mpeg", "mpg", "vob", "dat"],
  image: ["jpg", "jpeg", "png", "gif", "svg"],
  pdf: ["pdf"],
  word: ["doc", "docx", "odt", "xlsx"],
};

import axios from "axios";
import {API_URL, storedItemKeys} from "../helpers/Constants";
import {getAuthToken, removeStoredItem} from "../helpers/Helper";


export const getResource = async (resource_url) => {
    const response = await axios.get(resource_url, {
        headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
            "cache-control": "no-cache"
        }
    });
    const data = await response.data['hydra:member'];
    return data;
}

const token = getAuthToken();

export const get = async (resource_url, headers = {
    'Content-Type': 'application/ld+json',
    'Accept': 'application/ld+json',
    "cache-control": "no-cache",
    "Authorization": `Bearer ${token}`,
    "X-AUTH-TOKEN": "5fbad276a949f235c5731c8d27a66dd590b8a3df"
}) => {
    const response = await axios.get(resource_url, {
        headers
    });
    const data = await response.data['hydra:member'];
    const pagination = await response.data['hydra:view'];
    return {data, pagination};
}

let HttpClient;

if (token !== null && token !== "") {
    HttpClient = axios.create({
        baseURL: API_URL,
        headers: {
            "Content-Type": "application/ld+json",
            'Accept': 'application/ld+json',
            "Authorization": `Bearer ${token}`,
            "X-AUTH-TOKEN": "5fbad276a949f235c5731c8d27a66dd590b8a3df"
            //"Access-Control-Allow-Origin": "*"
        }
    });

    HttpClient.interceptors.response.use(
        response => response,
        error => {
            if (!error.response) {
            }
            else if (error.response.status === 401) {
                console.log("error 401");
            }
            return error;
        });
} else {
    HttpClient = axios.create({
        baseURL: API_URL,
        headers: {
            "Content-Type": "application/ld+json",
            'Accept': 'application/ld+json',
            "X-AUTH-TOKEN": "5fbad276a949f235c5731c8d27a66dd590b8a3df"
            //"Access-Control-Allow-Origin": "*"
        }
    });
}

HttpClient.interceptors.response.use((response) => response, (error) => {
    try {
        if ("Expired JWT Token" === error.response.data.message) {
            removeStoredItem(storedItemKeys.TOKEN);
            removeStoredItem(storedItemKeys.CURRENT_USER);
            window.location.href = "/"
        }
    } catch (error) {
        console.log(error)
    }
    if (!error.response) {
    }
    else if (error.response.status === 401) {
        //console.log("error 401");
        return error.message
    }
    return error;

});


export default HttpClient;
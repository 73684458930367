import { useEffect, useState } from "react";
import { dateFormat, notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import { Save } from "../../Icons";
import Step from "../Step";

function isEmpty(obj) {
   return Object.keys(obj).length === 0;
}

const Form = ({ ordre, missions, lieus, reset, close, structures }) => {

   const [current, setCurrent] = useState(ordre);
   const [index, setIndex] = useState(1);
   const [mission, setMission] = useState({ id: "" });

   useEffect(() => {
      setCurrent({ ...current, dateOrdreMission: dateFormat(new Date(current.dateOrdreMission), 'YYYY-MM-DDTHH:mm'), dateDemarrage: dateFormat(new Date(current.dateDemarrage), 'YYYY-MM-DDTHH:mm'), dateFin: dateFormat(new Date(current.dateFin), 'YYYY-MM-DDTHH:mm') })
   }, [])

   

   useEffect(() => {
      if (ordre.mission && ordre.mission.id !== undefined) {
         MissionStore.getMission(ordre.mission.id).then(data => setMission(data))
      }
   }, [current])

   const handleChange = (e) => {
      const targetName = e.target.name;
      const targetValue = e.target.value;
      setCurrent({ ...current, [targetName]: targetValue })
      if (targetName === "mission") {

         let ordreNew = { ...ordre, mission: targetValue}
         setCurrent(ordreNew)

         if (targetValue === "") {
            return setMission({ id: "" })
         }
         setMission(targetValue);
         // const arr = targetValue.split("/");
         // MissionStore.getMission(arr[3]).then(data => setMission(data))
      }
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      let ordre = { ...current, delaisProlongation: +current.delaisProlongation }
      if (ordre.mission['@id'])
         ordre = { ...ordre, mission: ordre.mission['@id'] };
      if (ordre.lieuDeMission['@id'])
         ordre = { ...ordre, lieuDeMission: ordre.lieuDeMission['@id'] }

      MissionStore.saveOrdre(ordre).then(_ => {
         notify("Succès", "L'ordre de mission a été enregistré.", "success");
         setCurrent(reset);
         e.target.reset();
         close()
      }).catch(err => notify("Erreur", "Certains champs du formulaire ne sont pas correctement renseignés.", "danger"));
   }

   return (
      <form onSubmit={handleSubmit}>

         <Step size={3} index={index} />
         <div className={index === 1 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>La mission <small className="fw-bold">[obligatoire]</small></span>
                  <select className="form-control" name="mission" onChange={handleChange} value={ordre && ordre.mission && ordre.mission['@id']}>
                     <option value="">Sélectionner une mission</option>
                     {missions.map(mission => <option key={mission.id} value={mission['@id']}>{mission.intitule}</option>)}
                  </select>
               </div>
            </div>

            {mission.id && <div className="row">
               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>N° d’ordre ou référence</h6>
                     <p>{mission.refPav}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Nature de la mission</h6>
                     <p>{mission.nature.libelle}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Structure</h6>
                     <p>{mission.structure && mission.structure.intitule}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Intitulé de la mission</h6>
                     <p>{mission.intitule}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Objectifs de la mission</h6>
                     <p>{mission.objectif}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Résultats attendus</h6>
                     <p>{mission.resultatAttendu}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Période d’exécution</h6>
                     <p>{mission.periodeExecution}</p>
                  </div>
               </div>

               <div className="col-md-6">
                  <div className="card p-2 mb-2 mt-2">
                     <h6>Nombre de jours calendaires</h6>
                     <p>{mission.nbJourCalendaire}</p>
                  </div>
               </div>
            </div>}


            {/* <div className="mb-3">
               <h5>Profil de la mission </h5>
               <div className="form-group">
                  <select className="form-control" name="structure" onChange={handleChange} value={current.structure['@id']} >
                     <option value="">Agent Participant à la mission</option>
                     {structures.map(structure => <option key={structure.id} value={structure['@id']}>{structure.intitule}</option>)}
                  </select>
               </div>
            </div> */}

            {/* <div className="mb-3">
               <div className="form-group">
                  <span>Numéro du dossier</span>
                  <input type="text" name="numDossier" className="form-control" value={current.numDossier} onChange={handleChange} />
               </div>
            </div> */}

            <button type="button" disabled={mission.id === ""} onClick={() => setIndex(2)} className="btn btn-primary mx-auto d-block">Continuer</button>
         </div>
         <div className={index === 2 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>Référence de l'ordre de mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="text" name="reference" className="form-control" value={current.reference} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>L'objet de l'ordre de mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="text" name="objet" className="form-control" value={current.objet} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Structure controlée <small className="fw-bold">[obligatoire]</small></span>
                  <select className="form-control" name="structureControlee" onChange={handleChange} value={current.structureControlee['@id']} >
                     <option value="">Sélectionner une structure</option>
                     {structures.map(structure => <option key={structure.id} value={structure['@id']}>{structure.intitule}</option>)}
                  </select>
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Le lieu de la mission <small className="fw-bold">[obligatoire]</small></span>
                  <select className="form-control" name="lieuDeMission" onChange={handleChange} value={ordre.lieuDeMission['@id']}>
                     <option value="">Sélectionner un lieu de mission</option>
                     {lieus.map(lieu => <option key={lieu.id} value={lieu['@id']}>{lieu.libelle}</option>)}
                  </select>
               </div>
            </div>


            <button type="button" onClick={() => setIndex(1)} className="btn btn-default mx-auto d-block" style={{ width: '20%', float: "left" }}>Retour</button>
            <button type="button" disabled={current.mission.intitule == "" || current.mission == "" || !current.objet || !current.reference || isEmpty(current.structureControlee) || current.lieuDeMission.intitule == "" || current.lieuDeMission == ""} onClick={() => setIndex(3)} className="btn btn-secondary mx-auto d-block" style={{ width: '20%', float: "right" }}>Continuer</button>
         </div>
         <div className={index === 3 ? "" : "hidden"}>
            <div className="mb-3">
               <div className="form-group">
                  <span>Date ordre de mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="datetime-local" name="dateOrdreMission" className="form-control" value={current.dateOrdreMission} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Date démarrage de l'ordre de mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="datetime-local" name="dateDemarrage" className="form-control" value={current.dateDemarrage} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Date fin de l'ordre de mission <small className="fw-bold">[obligatoire]</small></span>
                  <input type="datetime-local" name="dateFin" className="form-control" value={current.dateFin} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Numéro de véhicule</span>
                  <input type="text" name="numeroVehicule" className="form-control" value={current.numeroVehicule} onChange={handleChange} />
               </div>
            </div>

            <div className="mb-3">
               <div className="form-group">
                  <span>Le nom du CVA</span>
                  <input type="text" name="nomCva" className="form-control" value={current.nomCva} onChange={handleChange} />
               </div>
            </div>
            <div className="mb-3">
               <div className="form-group">
                  <span>Le délais de prolongation de la mission en jours</span>
                  <input type="number" name="delaisProlongation" className="form-control" value={current.delaisProlongation} onChange={handleChange} />
               </div>
            </div>

            <button type="button" onClick={() => setIndex(2)} className="btn btn-default mx-auto d-block" style={{ width: '20%', float: "left" }}>Retour</button>
            <button type="submit" disabled={current.dateOrdreMission == "Invalid Date" || current.dateDemarrage == "Invalid Date" || current.dateFin == "Invalid Date" || !current.numeroVehicule || !current.delaisProlongation || !current.nomCva} className="btn btn-warning mx-auto d-block" style={{ width: '20%', float: "right" }}><Save /> Enregister</button>
         </div>
      </form>
   );
}

export default Form;
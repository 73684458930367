import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/biblio/Layout";
import Pagination from "../../components/Pagination";
import {
  dateFormat,
  getAuthToken,
  notify,
  setTitle,
} from "../../helpers/Helper";
import StructureStrore from "../../store/StructureStore";
import { useParams } from "react-router-dom";
import UserStore from "../../store/UserStore";
import { USER_ROLES } from "../../helpers/Constants";

export default function RapportPartageDetailRecommandation() {
  const { recommandationId } = useParams();
  const [recommandation, setRecommandation] = useState(null);
  const [observations, setObservations] = useState([]);
  const [showCommentPanel, setShowCommentPanel] = useState(false);
  const [showCommentAddPanel, setShowCommentAddPanel] = useState(false);

  setTitle("RAPPORTS DE SUIVI ET RECOMMANDATION PARTAGES");

  useEffect(() => {
    init();
  }, []);

  const approveRecommandation = async () => {
    try {
      await StructureStrore.updateSuiviRecRapport({
        ...recommandation,
        etat: "valide",
      });
    } catch (error) {
    } finally {
      notify("Succès", "Recommandation approuvé avec succès !", "success");
      init();
    }
  };

  const demandeReformuleRecommandation = async (commentaire) => {
    try {
      await StructureStrore.updateSuiviRecRapport({
        ...recommandation,
        etat: "dem_reformule",
        commentaire,
      });
    } catch (error) {
    } finally {
      notify(
        "Succès",
        "Demande de reformulation de Recommandation envoyée avec succès !",
        "success"
      );
      init();
      setShowCommentPanel(false);
    }
  };

  // const addCommentaireRecommandation = async (observation) => {
  //   try {
  //     console.log({ ...recommandation, observation });
  //     // await StructureStrore.updateSuiviRecRapport({ ...recommandation,  observation })
  //   } catch (error) {
  //   } finally {
  //     notify(
  //       "Succès",
  //       "Demande de reformulation de Recommandation envoyée avec succès !",
  //       "success"
  //     );
  //     init();
  //     setShowCommentPanel(false);
  //   }
  // };

  const postCommentRecommandation = async () => {
    let comment = document.getElementById("comment").value;
    await demandeReformuleRecommandation(comment);
  };

  const getObservationRecommandation = async (rec) => {
    let { data } = await StructureStrore.getObservationRecommandation(rec);
    setObservations(data);
  };

  const init = async () => {

    let data = null;
    try {
      data = await StructureStrore.getRecommandationDetail(recommandationId);
    } catch (error) {
    } finally {
      setRecommandation(data);
      await getObservationRecommandation(data);
    }
  };

  const addCommentaireRecommandation = async (observation) => {
    try {
      // console.log({recommandation:recommandation['@id'], rapport:recommandation.rapport['@id'],  observation });
      await StructureStrore.addObservationSuiviRecRapport({
        recommandation: recommandation["@id"],
        rapport: recommandation.rapport["@id"],
        observation,
      });
    } catch (error) {
    } finally {
      notify("Succès", "Commentaire envoyé avec succès !", "success");
      setShowCommentAddPanel(false);
      init();
    }
  };

  const addCommentRecommandation = async () => {
    let comment = document.getElementById("comment-add").value;
    await addCommentaireRecommandation(comment);
  };

  const Body = () => {
    return (
      <>
        <div className="p-1 breadcrumbs xs-font mb-3">
          <Link to="/bibliotheque" className="textPrimary fw-bold">
            Accueil{" "}
          </Link>
          <Link to="/bibliotheque/rapports-suivis-recommandation">
            / Rapports de suivi et recommandations partagés
          </Link>
          {recommandation && (
            <Link
              to={`/bibliotheque/rapports-suivis-recommandation/${recommandation.rapport.id}`}
            >
              / {recommandation.rapport.libelle}
            </Link>
          )}
          {recommandation && <span>/ {recommandation.libelle}</span>}
        </div>

        <div className="content-body">
          <h4 className="page-title mt-5">
            Recommandation : {recommandation && recommandation.libelle}{" "}
          </h4>
          <div className="container-fluid mt-3 mb-3">
            <div className="row mt-3 p-3">
              <div className="card mb-2  ">
                <div className="card-body ">
                  <div className=" pull-right">
                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ||
                      UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) &&
                      recommandation && (
                        <button
                          className="btn btn-success m-1"
                          onClick={() =>
                            window.confirm(
                              "Êtes vous sûr de transmettre cette mention ?"
                            ) && approveRecommandation()
                          }
                        >
                          SANS COMMENTAIRE
                        </button>
                      )}
                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) ||
                      UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) &&
                      recommandation && (
                        <button
                          className="btn btn-warning m-1"
                          onClick={() => {
                            setShowCommentPanel(!showCommentAddPanel);
                            setShowCommentAddPanel(false);
                          }}
                        >
                          DEMANDER UNE REFORMULATION
                        </button>
                      )}
                    {/* {UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) && recommandation && recommandation.etat!="reformule" && <button className="btn btn-warning m-1" onClick={() => setShowCommentPanel(true)}>REFORMULER</button>} */}
                    {
                      <button
                        className="btn  m-1"
                        onClick={() => {
                          setShowCommentAddPanel(!showCommentAddPanel);
                          setShowCommentPanel(false);
                        }}
                      >
                        AJOUTER COMMENTAIRE
                      </button>
                    }
                  </div>
                </div>
              </div>

              {showCommentPanel && (
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <textarea
                      className="form-control"
                      id="comment"
                      type="text"
                      name="comment"
                      placeholder="Poster un commentaire"
                    />
                    <button
                      className="btn btn-warning m-1"
                      onClick={() => postCommentRecommandation(false)}
                    >
                      TRANSMETTRE LA REFORMULATION
                    </button>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => setShowCommentPanel(false)}
                    >
                      ANNULER
                    </button>
                  </div>
                </div>
              )}

              {showCommentAddPanel && (
                <div className="card mb-2  ">
                  <div className="card-body ">
                    <textarea
                      className="form-control"
                      id="comment-add"
                      type="text"
                      name="comment"
                      placeholder="Poster un commentaire"
                    />
                    <button
                      className="btn btn-warning m-1"
                      onClick={() => addCommentRecommandation(false)}
                    >
                      ENVOYER
                    </button>
                    <button
                      className="btn btn-primary m-1"
                      onClick={() => setShowCommentAddPanel(false)}
                    >
                      ANNULER
                    </button>
                  </div>
                </div>
              )}

              <div className="card mt-3 col-8">
                <div className="card-body ">
                  <h5 className="card-title">
                    {recommandation && recommandation.libelle}
                  </h5>
                  {recommandation && (
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item p-4">
                        Etat de la demande{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          <span>
                            {recommandation.etat ? (
                              recommandation.etat == "valide" ? (
                                <span className="badge badge-secondary bckColorDark ">
                                  SANS COMMENTAIRE
                                </span>
                              ) : recommandation.etat == "dem_reformule" ? (
                                <span className="badge badge-secondary secBckColor">
                                  A REFORMULER
                                </span>
                              ) : (
                                <span className="badge badge-secondary  darkGrayColor">
                                  REFORMULÉE
                                </span>
                              )
                            ) : (
                              <span className="badge badge-secondary darkGrayColor">
                                TRANSMIS
                              </span>
                            )}
                          </span>{" "}
                        </span>
                      </li>

                      <li className="list-group-item p-4">
                        Recommandation{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.libelle}{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Responsable{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.agentSuivi &&
                            recommandation.agentSuivi.nom}{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Action à mener{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.ActionsAMener}{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Niveau de mise en oeuvre{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.nivMiseEnOeuvre && (
                            <span className="badge badge-secondary grayBckColor text-uppercase ">
                              {recommandation.nivMiseEnOeuvre.libelle}
                            </span>
                          )}{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Echéance de fin d'exécution{" "}
                        <span className="pull-right text-bold">
                          {recommandation.delaisMisEnOeuvre} mois{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Constatation{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.constatation &&
                            recommandation.constatation.libelle}{" "}
                        </span>
                      </li>
                      <li className="list-group-item p-4">
                        Dernier commentaire{" "}
                        <span className="pull-right text-bold">
                          {" "}
                          {recommandation.commentaire}{" "}
                        </span>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
              <div className="card mb-2 mt-3 col-4 ">
                <div className="card-body  ">
                  <h5> Commentaires-Notifications</h5>
                  <ul className="list-group list-group-flush">
                    {observations.map((o) => (
                      <li className="list-group-item " key={o.id}>
                        <b>
                          {dateFormat(o.createdAt, "DD/MM/YYYY à HH:mm")} :{" "}
                        </b>
                        {o.type == "Commentaire"
                          ? `${o.createdBy.agent.nom} ${o.createdBy.agent.prenom} a posté un commentaire : ${o.observation}`
                          : o.observation}{" "}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return <Layout body={<Body />} />;
}

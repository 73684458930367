import React, { useEffect, useState } from "react";
import Layout from "../../components/biblio/Layout";
import UserStore from "../../store/UserStore";
import { Link } from "react-router-dom";
import { USER_ROLES } from "../../helpers/Constants";
import StructureStrore from "../../store/StructureStore";
import HomeStats from "./HomeStats";
import HomeStore from "../../store/HomeStore";



const Home = () => {

    const Body = () => {
        return (
            <div className="content-body">
                <div className="p-1 breadcrumbs xs-font">
                    <a href="/" className="textPrimary fw-bold">Accueil</a> <span>/ Tableau de bord</span>
                </div>
                <h4 className="page-title">Tableau de bord</h4>
                <div className="container-fluid mt-3  ">

                    <HomeStats />
                    {/* <div className="divideRse">

                        <div className="portalButtonsWrapper">
                            {(UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR) || UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) || UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) &&
                                <>
                                    <Link className="portalButtonWrapper" to="/bibliotheque/rapports-publics">
                                        <div className="portalButton "><span className="portalButtonContent pe-5">Les rapports de missions</span>
                                        </div>
                                    </Link>

                                    <Link className="portalButtonWrapper" to="/bibliotheque/livres">
                                        <div className="portalButton"><span className="portalButtonContent">Les livres de formation</span>
                                        </div>
                                    </Link>
                                </>
                            }
                            {UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) &&
                                <>
                                    <Link className="portalButtonWrapper" to="/bibliotheque/missions">
                                        <div className="portalButton "><span className="portalButtonContent pe-5">Toutes les missions</span>
                                        </div>
                                    </Link>

                                    <Link className="portalButtonWrapper" to="/bibliotheque/ressources">
                                        <div className="portalButton"><span className="portalButtonContent">Les livres de formation</span>
                                        </div>
                                    </Link>
                                </>}
                        </div>

                    </div> */}
                </div>
            </div>
        );
    }
    return <Layout body={<Body />} />
}

export default Home;
import { Link, useLocation } from "react-router-dom";
import { activeLink } from "../../../helpers/Helper";

export default function SubNavCoso() {
   const location = useLocation();
   return (
      <div className="filterArea bg-white pt-5 mb-5">
         <h3 className="page-title">Liste COSO</h3>

         <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">

            <div className="p-2 d-flex bd-highlight hide4mobile">
               <ul className="searchCategory">
                  <li><Link className={activeLink(location && location.pathname, '/bibliotheque/missions/composantes-cosos')} to="/bibliotheque/missions/composantes-cosos">COMPOSANTES COSO</Link></li>

                  <li><Link className={activeLink(location && location.pathname, '/bibliotheque/missions/principes-cosos')} to="/bibliotheque/missions/principes-cosos">PRINCIPES COSO</Link></li>
                  <li><Link className={activeLink(location && location.pathname, '/bibliotheque/missions/controles-audits-cosos')} to="/bibliotheque/missions/controles-audits-cosos">CONTRÔLE AUDIT COSO</Link></li>
               </ul>
            </div>
         </div>
      </div>
   );
}
import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HomeStore from "../../store/HomeStore";
import Footer from "../Footer";
import Loader from "../Loader";
import Marquee from "../Marquee";
import Header from "./Header";


export const AppContext = createContext()


export default function Layout({ body }) {

   const [layout, setLayout] = useState(null);
   const [appState, setAppState] = useState(INITIAL_APP_STATE)


   const {  parent} = useParams();

   useEffect(() => {
      init();
   }, [])

   const init = async () => {
      let _layout = await HomeStore.getHomeLayout()
      const parent_menus = await HomeStore.getMenusItem(parent);
      setAppState({...appState,parent_menus:parent_menus && parent_menus.id})
      setLayout(_layout);
   }

   return (
      <>
         {!layout && <Loader />}
         <AppContext.Provider value={{ appState, setAppState }}>
            <Marquee />
            <Header layout={layout} />
            {body}
            <Footer />
         </AppContext.Provider>


      </>
   )
}

const INITIAL_APP_STATE = { parent_menus: null }
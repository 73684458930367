import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Nav from "../../../components/biblio/missions/Nav";
import SubNavAutre from "../../../components/biblio/missions/SubNavAutre";
import { Pencil, Save, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import { USER_ROLES } from "../../../helpers/Constants";
import { notify } from "../../../helpers/Helper";
import MissionStore from "../../../store/MissionStore";
import UserStore from "../../../store/UserStore";

const Form = ({ source ,reset,close}) => {

   const [current, setCurrent] = useState(source);

   const handleChange = (e) => {
      setCurrent({ ...current, [e.target.name]: e.target.value })
   }

   const handleSubmit = (e) => {
      e.preventDefault();
      MissionStore.saveSource(current).then(_ => {
         notify("Succès", "La source a été enregistrée.", "success");
         setCurrent(reset);
         e.target.reset();
         close()
      })
   }

   return (
      <form onSubmit={handleSubmit}>

         <div className="mb-3">
            <div className="form-group">
               <span>Source de mission <small className="fw-bold">[obligatoire]</small></span>
               <input type="text" name="libelle" className="form-control" value={current.libelle} onChange={handleChange} required placeholder="Source" />
            </div>
         </div>

         <div className="mb-3">
            <div className="form-group">
               <span>Description</span>
               <textarea name="description" cols="30" rows="5" className="form-control" value={current.description} onChange={handleChange}></textarea>
            </div>
         </div>
         <button type="submit" style={{ width: '100%' }} className="btn btn-warning mx-auto d-block"><Save /> Enregister</button>
      </form>
   );
}

export default function Sources() {

   const [sources, setSources] = useState([]);
   const [source, setSource] = useState({ libelle: "", description: "" });
   const [showModal, setShowModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      init();
   }, [])

   const init = async () => {
      setLoaging(true)
      const { data } = await MissionStore.getSources();
      setSources(data);
      setLoaging(false)
   }

   const removeSource = (id) => {
      if (window.confirm("Supprimer cette source?"))
         MissionStore.removeSource(id).then(_ => init())
   }

   const updateSource = (source) => {
      setSource(source);
      setShowModal(true);
   }

   const newSource = (e) => {
      e.preventDefault();
      setSource({});
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setSource({ libelle: "", libUnique: "", description: "" })
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <SubNavAutre/>
            <div className="p-1 breadcrumbs xs-font mt-5 pt-4 pb-4">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Sources missions</span>
            </div>
            <h4 className="page-title">Les sources de missions</h4>
            <button onClick={newSource} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer une source de mission</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
               <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th>Source de mission</th>
                           <th>Description</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {sources.map((source, index) =>
                           <tr key={source.id}>
                              <td>{index + 1}</td>
                              <td>{source.libelle}</td>
                              <td>{source.description}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateSource(source)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeSource(source.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
               </div>

            </div>
            <Modal size="" show={showModal} title="Enregistrer une source de mission" body={<Form source={source} close={onModalClose} reset={{ libelle: "", description: "" }} />} onClose={onModalClose} />
         </>
      );
   }

   return <Layout body={<Body />} />
}
import httpClient from "./HttpClient";

const END_POINT = "/documents";


const ReportStore = {

    state: {
        accessType: []
    },

    async getReports(page = 0) {
        try {
            const response = await httpClient.get(`/rapport_missions?page=${page}`);
            const data = await response.data['hydra:member'];
            const pagination = await response.data['hydra:view'];
            return { data, pagination };
        } catch (e) {
            console.error(e);
        }
    },

    async getReport(id) {
        try {
            const response = await httpClient.get("/rapport_missions/" + id);
            const data = await response.data;//['hydra:member'];
            return data;
        } catch (e) {
            console.error(e);
        }
    },

    saveReport(report) {
        return httpClient.post('/rapport_missions', report);
    },


    async getRapportYear(structureCode, year) {
        try {
            const url = `?page=1&module.libUnique=rapport-activite-generale&annee=${year}&auteur.agent.structure.code=${structureCode}`
            const response = await httpClient.get(END_POINT + url);
            const data = await response.data['hydra:member'];
            return data;
        } catch (e) {
            return [];
        }
    },

    async getUserReports(email, libUnique = 'rapport', page = 1) {
        try {
            const response = await httpClient.get(END_POINT + `?page=${page}&itemsPerPage=10$module.libUnique=${libUnique}&auteur.email=${email}`);
            const data = await response.data['hydra:member'];
            const pagination = await response.data['hydra:member'];
            this.state.accessType = (data !== undefined && data !== 'undefined') ? data : [];
            return { data, pagination };
        } catch (e) {
            return { data: [], pagination: {} };
        }
    }


}

export default ReportStore;
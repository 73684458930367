import React, { useEffect, useState } from "react";
import Report from "../../../components/front/reports/Report";
import { Search, Filter as IconFilter } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination";
import { extract, lastYears, setTitle, useQuery } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import StructureStore from "../../../store/StructureStore";
import Layout from "../../../components/front/Layout";

const FilterForm = ({ structures }) => {
   const { thisYear, years } = lastYears(5)
   return (
      <>
         <div className="modal-body">
            <nav>
               <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Ministere
                  </button>
                  <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Annee
                  </button>

               </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
               <div className="tab-pane fade active show" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <select name="" className="form-control mt-3" onChange={(e) => window.location.href = "/rapport-activite-generale?structureCode=" + e.target.value}>
                     <option value="">--Choisir le Ministère --</option>
                     {structures.map(structure => <option value={structure.id} key={structure.id}>{extract(structure.intitule,80)}</option>)}
                  </select>
               </div>
               <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <select name="" className="form-control mt-3" onChange={(e) => window.location.href = "/rapport-activite-generale?annee=" + e.target.value}>
                     <option value="">--Recherche par année--</option>
                     <option value={thisYear}>{thisYear}</option>
                     {years.map(year => <option key={year} value={year}>{year}</option>)}
                  </select>
               </div>
            </div>
         </div>
      </>
   )
}


export default function RapportGenerals() {

   const [showFilterForm, setshowFilterForm] = useState(false);
   const [reports, setReports] = useState([]);
   const [page, setPage] = useState(1)
   const [pagination, setPagination] = useState({});
   const [structures, setStructures] = useState([]);
   const [favorites, setFavorites] = useState([])

   let query = useQuery();

   const annee = query.get('annee') !== null ? query.get('annee') : '';
   //const structureCode = query.get('structureCode') !== null ? query.get('structureCode') : '';
   const search = query.get('q') !== null ? query.get('q') : '';

   const title = "Les rapports d'activités générales " + annee + " " + search;

   const [q, setQ] = useState(search)

   setTitle(title);

   useEffect(() => {
      init();
   }, [page])

   const setCurrentPage = (page) => {
      setPage(page);
   }

   const init = async () => {
      const { data, pagination } = await DocumentStore.getDocuments('rapport-activite-generale', page, query.get('annee'), query.get('q'));
      setPagination(pagination)
      setReports(data);
      const _structures = await StructureStore.getAll();
      setStructures(_structures);
      const { data: noticationData } = await DocumentStore.getFavoriteDocuments(page, 100)
      setFavorites(noticationData)
   }

   const searchReport = (e) => {
      e.preventDefault();
      window.location.href = "/rapport-activite-generale?q=" + q;
   }


const Body = () => {
   return (
      <>
         <div className="filterArea bg-white">
            <div className="d-flex  justify-content-between bd-highlight border-bottom mb-3">
               <div className="p-2 bd-highlight ">
                  <div className="search-box">
                     <input type="text" placeholder="Que recherchez-vous ?" className="search-input" value={q} onChange={(e) => setQ(e.target.value)} />
                     <a href="/" className="search-btn" onClick={searchReport}>
                        <Search />
                     </a>
                  </div>
               </div>

               <div className="p-2 d-flex bd-highlight me-3 hide4mobile">
                  <div className="p-2 flex-shrink-1 bd-highlight  hide4mobile">
                     <button className="btn btn-sm btn-warning" onClick={() => setshowFilterForm(true)} data-bs-toggle="modal" data-bs-target="#filterModal">Filtre <IconFilter /></button>
                  </div>
               </div>
            </div>
         </div>
         <div className="page-info">
            <h2 className="text-center text-white"> {title}</h2>
            <div className="bread-crumb">
               <div className="container">
                  <span><a href="/">ACCUEIL</a> / </span>
                  <span className="text-uppercase">Les Rapports</span>
               </div>
            </div>
         </div>

         <div className="container-fluid mb-4 page-content">
            <div className="row">
               {reports.map((report, index) => <Report key={index} report={report} favorites={favorites} module='rapport-activite-generale' />)}
               {!reports.length && <h2 className="text-center" style={{ textTransform: 'uppercase', color: "#9a2e48" }}>Aucun document disponible.</h2>}
               <Pagination pagination={pagination} setCurrentPage={(setCurrentPage)} />
            </div>
         </div>
         <Modal show={showFilterForm} onClose={() => setshowFilterForm(false)} body={<FilterForm structures={structures} />} title="Filtrer par" />

      </>
   )
}

return <Layout body={<Body />} />

}
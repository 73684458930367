
export default function Pagination({ pagination, setCurrentPage }) {

   if (pagination !== undefined) {
      const getPage = (url) => {
         const searchQuery = url.split("?")[1]
         const query = new URLSearchParams("?" + searchQuery);
         return query.get('page');
      }

      const nextPage = (pagination['hydra:next'] !== undefined) ? getPage(pagination['hydra:next']) : null;
      const prevPage = (pagination['hydra:previous'] !== undefined) ? getPage(pagination['hydra:previous']) : null;
      const firstPage = (pagination['hydra:first'] !== undefined) ? getPage(pagination['hydra:first']) : null;
      const lastPage = (pagination['hydra:last'] !== undefined) ? getPage(pagination['hydra:last']) : null;

      const paginate = (page) => {
         setCurrentPage(page);
      }

      return (
         <div>
            <nav aria-label="" className=" mt-3">
               <ul className="pagination w-25 mx-auto">
                  <li className={prevPage !== null ? 'page-item' : 'page-item disabled'}>
                     <a onClick={(e) => { e.preventDefault(); paginate(firstPage); }} className="page-link" href="/" tabIndex="-1" aria-disabled="true"><i className="fas fa-angle-double-left"></i></a>
                  </li>
                  <li className={prevPage !== null ? 'page-item' : 'page-item disabled'}><a onClick={(e) => { e.preventDefault(); paginate(prevPage); }} className="page-link" href="/">{'Précédent'}</a></li>
                  <li className={nextPage !== null ? 'page-item' : 'page-item disabled'}><a onClick={(e) => { e.preventDefault(); paginate(nextPage); }} className="page-link" href="/">{'Suivant'}</a></li>
                  <li className={nextPage !== null ? 'page-item' : 'page-item disabled'}>
                     <a onClick={(e) => { e.preventDefault(); paginate(lastPage); }} className="page-link" href="/"><i className="fas fa-angle-double-right"></i></a>
                  </li>
               </ul>
            </nav>
         </div>
      );
   }
   else return '';
}
import React, { useEffect, useState } from 'react';
import { useParams, } from 'react-router-dom';
import Audio from '../../../components/Audio';
import Layout from "../../../components/front/Layout";
import { Clock, Eye, Download } from "../../../components/Icons";
import PDF from '../../../components/PDF';
import Video from '../../../components/Video';
import { SITE_URL } from '../../../helpers/Constants';
import { dateFormat, setTitle } from '../../../helpers/Helper';
import DocumentStore from '../../../store/DocumentStore';


export default function Documents() {

    const { id } = useParams();
    const [document, setDocument] = useState({})

    useEffect(() => {
        init();
    }, [id])

    const init = async () => {
        const doc = await DocumentStore.getDocument(id);
        setDocument(doc);
        setTitle(doc.libelle);
    }

    const Body = () => {
        return (
            <>
                <div className="page-info">
                    <h2 className="text-center text-white"> {document.libelle}</h2>
                    <div className="bread-crumb">
                        <div className="container">
                            <span><a href="/">ACCUEIL</a> / </span>
                            <span className="text-uppercase">Documentations / {document.libelle}</span>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mb-4 page-content p-0">
                    <div className="container documentDetail">
                        <div className="card rounded p-3">
                            <h2 className="text-secondary pb-3 border-bottom">
                                {document.libelle}
                            </h2>
                            <div className="d-flex justify-content-between mt-2">
                                <div>
                                    <small>
                                        <span className="badge rounded-pill bg-warning text-dark">Documents</span>
                                        <span className="documentTime">
                                            <Clock /> {dateFormat(document.createdAt)}
                                        </span>

                                    </small>
                                </div>
                                <div>
                                    {(document.fichier !== undefined && document.categorie.libUnique === 'word') &&
                                        <a rel="noreferrer" target="_blank" href={SITE_URL + '/doc/download/' + document.id} className="btn btn-sm btn-outline-warning" title="TELECHARGE"><Download /></a>}

                                    {/* <button className="btn btn-sm btn-outline-warning" title="LIRE"><Eye /></button> */}
                                </div>
                            </div>
                            <p className="mt-3" dangerouslySetInnerHTML={{ __html: document.description }} />
                            {(document.categorie !== undefined && document.categorie.libUnique === 'pdf') && <PDF src={SITE_URL + '/doc/download/' + document.id} />}
                            {(document.categorie !== undefined && document.categorie.libUnique === "videos") && <Video src={SITE_URL + '/doc/download/' + document.id} />}
                            {(document.categorie !== undefined && document.categorie.libUnique === "audio") && <Audio src={SITE_URL + '/doc/download/' + document.id} />}

                        </div>
                    </div>
                </div>
            </>
        );
    }

    return <Layout body={<Body />} />
}

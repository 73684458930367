import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../../../components/biblio/Layout";
import { Eye, Trash } from "../../../components/Icons";
import Pagination from "../../../components/Pagination";
import {  notify } from "../../../helpers/Helper";
import ResourceStore from "../../../store/ResourceStore";


export default function Favorite() {
    const [page, setPage] = useState(1)
    const [books, setBooks] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading,setLoading]=useState(false)

    useEffect(() => {
        init();
    }, [page])

    const init = async () => {
        setLoading(true)
        const { data, pagination } = await ResourceStore.getFavoriteBooks(page)
        setPagination(pagination)
        setBooks(data);
        setLoading(false)
    }

    const deleteFavorite = (id) => {
        ResourceStore.deleteFavorite(id).then(_ => { init(); notify('Succès', "Le document a été supprimé des favoris.", "success") })
    }
    const setCurrentPage = (page) => {
        setPage(page);
    }

    const Body = () => {
        return (
            <>

                <div className="p-1 breadcrumbs xs-font">
                    <Link to="/bibliotheque" className="textPrimary fw-bold">Accueil</Link> <span>/ Livres favoris</span>
                </div>
                <div className="content-body">
                    <h4 className="page-title">Livres favoris</h4>
                    <div className="container mt-3">
                        <div className="card p-2">
                            <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                                <thead>
                                    <tr>
                                        <th style={{ width: '40%' }}>Titre</th>
                                        <th>Auteur</th>
                                        <th>Editeur</th>
                                        <th>Année d'edition</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {books.map(book =>
                                        <tr key={book.id}>
                                            <td>{book.livre.titre}</td>
                                            <td>{book.livre.auteurLivre}</td>
                                            <td>{book.livre.editeur}</td>
                                            <td>{book.livre.annee}</td>
                                            <td>
                                                <Link style={{ color: '#fff', marginRight: '10px' }} className="btn btn-info mr-3" to={`/bibliotheque/livres/${book.livre.id}`}>
                                                    <Eye />
                                                </Link>
                                                <button className="btn btn-sm btn-danger" onClick={() => deleteFavorite(book.id)}><Trash/></button>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                            <Pagination setCurrentPage={setCurrentPage} pagination={pagination} />
                        </div>
                    </div>
                </div>

            </>
        );
    }
    return <Layout body={<Body />} />

}
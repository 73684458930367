import React from "react";
import {Link, useLocation} from "react-router-dom";
import {USER_ROLES} from "../../helpers/Constants";
import {activeLink, doLogout} from "../../helpers/Helper";
import SharedStore from "../../store/SharedStore";
import UserStore from "../../store/UserStore";

export default function Nav() {
    const location = useLocation();
    const navClass = SharedStore(state => state.sideNavClass);
    const setSideNavClass = SharedStore(state => state.setSideNavClass)

    const closeSideNav = () => {
        setSideNavClass('hide4mobile');
    }

    return (
        <nav className={`sidebar ${navClass}`}>
            <button className="rounded-circle btn btn-warning show4Mobile fw-bold" id="fermer-sideNav" onClick={closeSideNav}>X</button>

            <div className="side-menu">
                <ul>

                    <li className={activeLink(location.pathname, '/bibliotheque')}>
                        <Link to="/bibliotheque">
                            <div className="d-flex ">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-tachometer-alt"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Tableau de bord</div>
                            </div>
                        </Link>
                    </li>
                    <li className="dividernav"/>

                    {!UserStore.hasRole(USER_ROLES.ROLE_COLLABORATEUR) &&
                    <li className={activeLink(location.pathname, '/bibliotheque/partager')}>
                        <Link to="/bibliotheque/partager">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-upload"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Partager</div>
                            </div>
                        </Link>
                    </li>}

                    {!UserStore.hasRole(USER_ROLES.ROLE_COLLABORATEUR) &&
                    <>
                        <li className="dividernav"/>
                        <li className={activeLink(location.pathname, '/bibliotheque/youtube-videos')}>
                            <Link to="/bibliotheque/youtube-videos">
                                <div className="d-flex">
                                    <div className="menu-icon-holder">
                                        <i className="fa fa-youtube"/>
                                    </div>
                                    <div className="flex-shrink-1 menu-text">Chaine youtube</div>
                                </div>
                            </Link>
                        </li>
                    </>}

                    {!UserStore.hasRole(USER_ROLES.ROLE_COLLABORATEUR) && <>
                        <li className="dividernav"/>
                        <li className={activeLink(location.pathname, '/bibliotheque/rapports-proteges')}>
                            <Link to="/bibliotheque/rapports-proteges">
                                <div className="d-flex">
                                    <div className="menu-icon-holder">
                                        <i className="fas fa-file-pdf"/>
                                    </div>
                                    <div className="flex-shrink-1 menu-text">Bibliothèque rapports</div>
                                </div>
                            </Link>
                        </li>
                    </>}

                    <li className={activeLink(location.pathname, '/bibliotheque/livres')}>
                        <Link to="/bibliotheque/livres">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-atlas"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Bibliothèque Livres</div>
                            </div>
                        </Link>
                    </li>
                    <li className="dividernav"/>

                    {!UserStore.hasRole(USER_ROLES.ROLE_COLLABORATEUR) &&
                    <>
                        <li className={activeLink(location.pathname, '/bibliotheque/mes-documents')}>
                            <Link to="/bibliotheque/mes-documents">
                                <div className="d-flex">
                                    <div className="menu-icon-holder">
                                        <i className="fas fa-file-alt"/>
                                    </div>
                                    <div className="flex-shrink-1 menu-text">
                                        {UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) ? 'Mes rapports' : 'Mes documents'}                                </div>
                                </div>
                            </Link>
                        </li>

                        <li className={activeLink(location.pathname, '/bibliotheque/mes-livres')}>
                            <Link to="/bibliotheque/mes-livres">
                                <div className="d-flex">
                                    <div className="menu-icon-holder">
                                        <i className="fas fa-book"/>
                                    </div>
                                    <div className="flex-shrink-1 menu-text">Mes Livres</div>
                                </div>
                            </Link>
                        </li>
                        <li className="dividernav"/>
                    </>}

                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) || UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) &&
                    <li className={activeLink(location.pathname + '?showToValidated=1', '/bibliotheque/documents?showToValidated=1')}>
                        <Link to="/bibliotheque/documents?showToValidated=1">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-file"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Documents/Rapports suggérés</div>
                            </div>
                        </Link>
                    </li>
                    }

                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) || UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) &&
                    <li className={activeLink(location.pathname, '/bibliotheque/suggestions/livres')}>
                        <Link to="/bibliotheque/suggestions/livres">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fa fa-book"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Livres suggérés</div>
                            </div>
                        </Link>
                    </li>
                    }
                    {(UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) || UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR))  &&
                    <li className="dividernav"/>}

                    {!UserStore.hasRole(USER_ROLES.ROLE_COLLABORATEUR) &&
                    <li className={activeLink(location.pathname, '/bibliotheque/livres/favoris')}>
                        <Link to="/bibliotheque/livres/favoris">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-star"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Livres Favoris</div>
                            </div>
                        </Link>
                    </li>}

                    {!UserStore.hasRole(USER_ROLES.ROLE_COLLABORATEUR) &&
                    <li className={activeLink(location.pathname, '/bibliotheque/rapports/favoris')}>
                        <Link to="/bibliotheque/rapports/favoris">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-star"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Rapports Favoris</div>
                            </div>
                        </Link>
                    </li>}
                    <li className="dividernav"/>

                    {(UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) || (UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) || UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) ) &&
                    <li className={activeLink(location.pathname, '/bibliotheque/missions/missions')}>
                        <Link to="/bibliotheque/missions/missions">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-folder"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">GESTION DES MISSIONS</div>
                            </div>
                        </Link>
                    </li>
                    }
                    {UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR) &&
                        <li className={activeLink(location.pathname, '/bibliotheque/historiques')}>
                            <Link to="/bibliotheque/historiques">
                                <div className="d-flex">
                                    <div className="menu-icon-holder">
                                        <i className="fas fa-history"/>
                                    </div>
                                    <div className="flex-shrink-1 menu-text">HISTORIQUE ACTIVITÉS</div>
                                </div>
                            </Link>
                        </li>
                    }
                    <li>
                        <a href="/">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-arrow-left"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Retour à l'accueil</div>
                            </div>
                        </a>
                    </li>

                    <li>
                        <a onClick={doLogout} href="/">
                            <div className="d-flex">
                                <div className="menu-icon-holder">
                                    <i className="fas fa-power-off"/>
                                </div>
                                <div className="flex-shrink-1 menu-text">Deconnection</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

        </nav>
    )
}
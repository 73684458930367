import React, { useEffect, useState } from "react";
import Layout from "../../../components/biblio/Layout";
import Detail from "../../../components/biblio/missions/RapportDetail";
import Form from "../../../components/biblio/missions/RapportForm";
import Nav from "../../../components/biblio/missions/Nav";
import { Eye, Pencil, Trash } from "../../../components/Icons";
import Modal from "../../../components/Modal";
import MissionStore from "../../../store/MissionStore";
import { dateFormat, setTitle } from "../../../helpers/Helper";
import Pagination from "../../../components/Pagination";
import UserStore from "../../../store/UserStore";
import { USER_ROLES } from "../../../helpers/Constants";

const $rapport = { intitule: "", odreMission: "", numRapportSp: "", planRecommandation: false, montantArembourser: "", montantEnCause: "", montantBudgetController: "", structureController: {}, dateEnvoieFinalSv: "", dateDepotSp: "" };

export default function Rapport() {

   const [page, setPage] = useState(1);
   const [pagination, setPagination] = useState({});
   const [ordres, setOrdres] = useState([]);
   const [rapports, setRapports] = useState([]);
   const [rapport, setRapport] = useState($rapport);
   const [showModal, setShowModal] = useState(false);
   const [showDetailModal, setShowDetailModal] = useState(false);
   const [loading, setLoaging] = useState(false);

   useEffect(() => {
      if (!UserStore.hasRole(USER_ROLES.ROLE_GESTIONS_MISSION) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE ) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR )){
         window.location.href = "/bibliotheque";
      }
      setTitle("Rapports de missions");
      init();
   }, [page])
   const setCurrentPage = (page) => {
      setPage(page);
   }
   const init = async () => {
      setLoaging(true)
      const { data: _ordres } = await MissionStore.getOrdres();
      const { data: _rapports, pagination } = await MissionStore.getRapports(page);
      setOrdres(_ordres)
      setRapports(_rapports);
      setPagination(pagination)
      setLoaging(false)
   }

   const removeRapport = (id) => {
      if (window.confirm("Supprimer ce rapport de mission?"))
         MissionStore.removeRapport(id).then(_ => init())
   }

   const updateRapport = (rapport) => {
      setRapport(rapport);
      setShowModal(true);
   }

   const showRapport = (rapport) => {
      setRapport(rapport);
      setShowDetailModal(true);
   }

   const newRapport = (e) => {
      e.preventDefault();
      setRapport($rapport);
      setShowModal(true);
   }

   const onModalClose = () => {
      init();
      setRapport($rapport)
      setShowModal(false);
   }

   const Body = () => {
      return (
         <>
            <Nav />
            <div className="p-1 breadcrumbs xs-font">
               <a href="/bibliotheque" className="textPrimary fw-bold">Accueil</a> <span>/ Rapports missions</span>
            </div>
            <h4 className="page-title">Les rapports de missions</h4>
            <button onClick={newRapport} className="btn btn-primary" style={{ float: "right", marginRight: "30px" }}>Créer un rapport de mission</button>
            <div className="container-fluid mt-4 mb-5">
               <div className="card p-3">
               <table className={`table table-striped ${loading ? 'loading' : ''}`}>
                     <thead>
                        <tr>
                           <th>L'intitulé du rapport de mission </th>
                           <th>L'ordre de mission</th>
                           <th className="text-center">La date de dépot du rapport</th>
                           <th className="text-center">La date d'envoi finale du rappport</th>
                           <th className="text-center">Le montant à controller</th>
                           <th className="text-center"></th>
                        </tr>
                     </thead>
                     <tbody>
                        {rapports.map((rapport, index) =>
                           <tr key={rapport.id}>
                              <td>{rapport.intitule}</td>
                              <td>{rapport.odreMission.reference}</td>
                              <td className="text-center">{dateFormat(rapport.dateDepotSp)}</td>
                              <td className="text-center">{dateFormat(rapport.dateEnvoieFinalSv)}</td>
                              <td className="text-center">{rapport.montantBudgetController}</td>
                              <td>
                                 <button type="button" className="btn btn-sm btn-info" style={{ marginRight: "20px", color: "#FFFFFF" }} onClick={() => showRapport(rapport)} title="Détails"><Eye /></button>
                                 <button type="button" className="btn btn-sm btn-success" style={{ marginRight: "20px" }} onClick={() => updateRapport(rapport)} title="Modifier"><Pencil /></button>
                                 <button type="button" className="btn btn-sm btn-danger" onClick={() => removeRapport(rapport.id)} title="Supprimer"><Trash /></button>
                              </td>
                           </tr>
                        )}

                     </tbody>
                  </table>
                  <Pagination pagination={pagination} setCurrentPage={setCurrentPage} />
               </div>

            </div>
            <Modal show={showModal} title="Enregistrer une rapport" body={<Form rapport={rapport} ordres={ordres} reset={$rapport} close={onModalClose} />} onClose={onModalClose} />
            <Modal show={showDetailModal} title={rapport.intitule} body={<Detail rapport={rapport} />} onClose={() => setShowDetailModal(false)} />

         </>
      );
   }

   return <Layout body={<Body />} />
}
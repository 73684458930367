import { useState } from "react";
import { fileExtension, notify } from "../../../helpers/Helper";
import DocumentStore from "../../../store/DocumentStore";
import HttpClient from "../../../store/HttpClient";



export default function  Upload({ next, required = false, type = "file", title = "", path = "/media_objects" }) {

   const [file, setFile] = useState("ou glisser et déposer des fichiers ici")
   const [progress, setProgress] = useState(0)
   const [currentFile, setCurrentFile] = useState(false)

   const handleSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      if (required && formData.get('file').name === "")
         return notify('Erreur', "Veuillez selectionner un fichier", "danger")

      if (formData.get('file').name !== "") {
         setCurrentFile(true)
         try {
            const response = await HttpClient.post(path, formData, {
               onUploadProgress: function (progressEvent) {
                  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                  setProgress(percentCompleted)
               }
            });
            DocumentStore.state[type] = await response.data;
            DocumentStore.state.extension = fileExtension(formData.get('file').name);

         } catch (error) {
            return notify('Erreur', 'Erreur ajout fichier.', 'danger');
         }
         setCurrentFile(false);
         //await DocumentStore.uploadMedia(formData, type);
      }
      next();
   }

   return (
      <form onSubmit={handleSubmit} encType="multipart/form-data">
         <h4 style={{ width: '100%', textAlign: 'center' }}>{title}</h4>
         <div className="file-drop-area">
            <span className="fake-btn">Choisir un fichier et/ou continuer</span>
            <span className="file-msg">{file}</span>
            <input className="file-input" type="file" name="file" onChange={(e) => setFile(e.target.value)} />
         </div>
         {currentFile && (
            <div className="progress">
               <div
                  className="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progress + "%", backgroundColor: '#3b9969' }}
               >
                  {progress}%
               </div>
            </div>
         )}
         <ul className="list-inline">
            <li>
               <button type="submit" disabled={currentFile} className={currentFile ? "default-btn btn-secondary prev-step" : "default-btn next-step"}>
                  Continue
               </button>
            </li>
         </ul>
      </form>
   );
}
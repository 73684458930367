import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { USER_ROLES } from '../../helpers/Constants';
import HomeStore from '../../store/HomeStore';
import UserStore from '../../store/UserStore';
import Loading from "../../helpers/Loading";

const HomeStats = () => {

    const [stats, setStats] = useState({ general: [], biblio: [] })
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        let st = stats
        const current_user = await UserStore.getCurrentUser();

        if (UserStore.hasRole(USER_ROLES.ROLE_INSPECTEUR) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) {
            st = await HomeStore.getHomeStat(`stats/me`)
        }

        if (UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE) && !UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR)) {

            if (current_user.agent) {
                let isIgf = current_user.agent.structure.isIgf
                if (isIgf) {
                    st = await HomeStore.getHomeStat(`stats/controle`)
                } else {
                    st = await HomeStore.getHomeStat(`stats/structure`)

                }
            }
        }




        if (UserStore.hasRole(USER_ROLES.ROLE_SUPERVISEUR) && !UserStore.hasRole(USER_ROLES.ROLE_RESPONSABLE_ORGANE)) {
            st = await HomeStore.getHomeStat(`stats/supervision`)
        }




        setLoading(false);

        if (st && st.general && st.biblio) {
            setStats(st)
        }

    }

    const url_Gen_type = type => {
        let url = ''
        switch (type) {
            case 'PARTAGE':
                url = 'bibliotheque/rapports-suivis-recommandation-partage'

                break;

            case 'SUIVI_RAPPORT':
                url = 'bibliotheque/rapports-suivis-recommandation'

                break;

            case 'REC':
                url = 'bibliotheque/rapports-suivis-recommandations-list'

                break;

            case 'LIVRE':
                url = 'bibliotheque/livres'

                break;

            case 'RAPPORT':
                url = 'bibliotheque/rapports-proteges'

                break;

            default:
                break;
        }
        return url
    }


    return (

        <div className="container-fluid">

            {loading && <Loading />}

            {!loading && <>
                {/* {stats.general && <section>
                    <div className="row">
                        <div className="col-12 mt-3 mb-1">
                            <h5 className="text-uppercase mb-2">Statistique Générale</h5>
                        </div>
                    </div>
                    <div className="row">

                        {stats.general.map((d, i) => (

                            <div className="col-xl-4 col-md-12 mb-4" key={`general-stat${i}`}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between p-md-1">
                                            <div className="d-flex flex-row">
                                                <div className="align-self-center">
                                                    <i className="fas fa-newspaper text-warning fa-3x me-4"></i>
                                                </div>
                                                <Link to={url_Gen_type(d.type)}>
                                                    <p>{d.description}</p>
                                                </Link>
                                            </div>
                                            <div className="align-self-center">
                                                <h4 className="h1 mb-0">{d.total}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </section>} */}


                {stats.biblio && <section>
                    <div className="row">
                        <div className="col-12 mt-3 mb-1">
                            <h5 className="text-uppercase  mb-2">Statistique Bibliothèque</h5>
                            {/* <p>Statistics on minimal cards with Title &amp; Sub Title.</p> */}
                        </div>
                    </div>
                    <div className="row">

                        {stats.biblio.map((d, i) => (

                            <div className="col-xl-4 col-md-12 mb-4" key={`general-stat${i}`}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between p-md-1">
                                            <div className="d-flex flex-row">
                                                <div className="align-self-center">
                                                    <i className="fas fa-newspaper textPrimary fa-3x me-4"></i>
                                                </div>
                                                <Link to={url_Gen_type(d.type)}>
                                                    <p>{d.description}</p>
                                                    {/* <p className="mb-0">Les rapports de suivi  transmis</p> */}
                                                </Link>
                                            </div>
                                            <div className="align-self-center">
                                                <h4 className="h1 mb-0 ">{d.total}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </section>}

            </>}

            {/* <Link to="bibliotheque/rapports-suivis-recommandation">
                                                <h4>Rapports de suivi transmis</h4>
                                                <p className="mb-0">Les rapports de suivi  transmis</p>
                                            </Link> */}

        </div>
    );
}

export default HomeStats;
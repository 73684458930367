import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import PublicationStore from "../store/PublicationStore";

export default function Marquee() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const data = await PublicationStore.getMarquees();
    setPosts(data);
  };

  return (
    // <marquee
    //   id="marquee"
    //   behavior="scroll"
    //   direction="left"
    //   className="w-100 flex-grow-1 p-1 ps-3 "
    // >
    //   {posts &&
    //     posts.map((post) => (
    //       <Link
    //         key={post.id}
    //         style={{ color: "#000" }}
    //         className="p-5"
    //         to={`/activites/${post.slug}`}
    //       >
    //         {post.titre + ". "}
    //       </Link>
    //     ))}
    // </marquee>
    <></>
  );
}
